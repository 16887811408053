import { Configuration } from './../../Constants/Configuration';
import { HttpClient } from '../../Ajax/AjaxHttpClient';
import { HttpInterceptor } from '../../Ajax/HttpInterceptor';
import ObfInjector from '../../Helpers/Injector';
import { HeadersOptions } from './HeaderBuilder';
import { tap, retryWhen, mergeMap, map } from 'rxjs/operators';
import { Observable, throwError, timer, of } from 'rxjs';
import { SDKValidationResponseService } from '../SDKValidationResponseService';
import { Request } from '../../Api/Interfaces/Request';
import { RequestOptions } from '../../Api/Interfaces/requestOptions';

export class BackEndXrmService {
    private _http: HttpClient;
    private _httpInterceptor: HttpInterceptor;
    private _validator: SDKValidationResponseService;

    constructor() {
        this._validator =
            ObfInjector.getRegistered('SDKValidationResponseService') as SDKValidationResponseService;
        const _configuration: Configuration = ObfInjector.getRegistered('Configuration');
        this._http = new HttpClient(_configuration.apiServer);
        this._httpInterceptor = ObfInjector.getRegistered('HttpInterceptor');
    }

    public changeApi() {
        const _configuration: Configuration = ObfInjector.getRegistered('Configuration');
        this._http = new HttpClient(_configuration.apiServer);
    }

    public get(path: string, requestOptions: RequestOptions = { skipInterceptor: false }) {
        const headers = ObfInjector.getRegistered('HeaderBuilder').makeHeaders(requestOptions.headersOptions);
        let timeout;
        let withCredentials;

        if (requestOptions.headersOptions) {
            timeout = requestOptions.headersOptions.timeout ? requestOptions.headersOptions.timeout : undefined;
            // tslint:disable-next-line: max-line-length
            withCredentials = requestOptions.headersOptions.withCredentials ? requestOptions.headersOptions.withCredentials : undefined;
        }

        // if (headersOptions && headersOptions.withCredentials) {
        return this._http.get(path, headers, withCredentials, timeout)
            .pipe(
                map(response => {
                    if (requestOptions.validateResponseType) {
                        // tslint:disable-next-line: max-line-length
                        response = this._validator.ValidationResponseStructure(response, requestOptions.validateResponseType);
                    }
                    return response;
                }),
                tap(
                    (response: any) => { // Success
                        // Check response
                        if (!requestOptions.skipInterceptor) {
                            this.checkResponse(response);
                        }
                    },
                    (response: any) => { // Error
                        // Check response
                        throwError(response);
                    }
                ),
                retryWhen(
                    // errors => {return errors.pipe(delay(1000), take(3));}
                    this.errorHandler(requestOptions.skipInterceptor)
                ),
            );
    }

    public post(path: string, data: any, requestOptions: RequestOptions = { skipInterceptor: false }) {
        const headers = ObfInjector.getRegistered('HeaderBuilder').makeHeaders(requestOptions.headersOptions);
        let timeout;
        let withCredentials;

        if (requestOptions.headersOptions) {
            timeout = requestOptions.headersOptions.timeout ? requestOptions.headersOptions.timeout : undefined;
            // tslint:disable-next-line: max-line-length
            withCredentials = requestOptions.headersOptions.withCredentials ? requestOptions.headersOptions.withCredentials : undefined;
        }

        return this._http.post(path, data, headers, withCredentials, timeout)
            .pipe(
                map(response => {
                    // Batch request
                    if (requestOptions.validateResponseType) {
                        // Additional logic in order to let the validator perform check on batch request
                        if (
                            response &&
                            response.responses &&
                            response.responses.length &&
                            requestOptions.validateResponseType === 'batchRequest' &&
                            requestOptions.additionalValidationData
                        ) {

                            // tslint:disable-next-line: max-line-length
                            for (let requestIndex: number = 0; requestIndex < requestOptions.additionalValidationData.length; requestIndex++) {
                                const requestData = requestOptions.additionalValidationData[requestIndex];

                                switch (requestData.requestName) {
                                    case 'configuration':
                                        response.responses[requestData.requestIndex] =
                                            // tslint:disable-next-line: max-line-length
                                            this._validator.ValidationResponseStructure(response.responses[requestData.requestIndex], 'configurationResponse');

                                        break;
                                    case 'validations':
                                        response.responses[requestData.requestIndex] =
                                            // tslint:disable-next-line: max-line-length
                                            this._validator.ValidationResponseStructure(response.responses[requestData.requestIndex], 'validationResponse');

                                        break;
                                    case 'socialProviders':
                                        response.responses[requestData.requestIndex] =
                                            // tslint:disable-next-line: max-line-length
                                            this._validator.ValidationResponseStructure(response.responses[requestData.requestIndex], 'socialProvidersResponse');

                                        break;
                                    case 'leaveReasons':
                                        response.responses[requestData.requestIndex] =
                                            // tslint:disable-next-line: max-line-length
                                            this._validator.ValidationResponseStructure(response.responses[requestData.requestIndex], 'leaveReasonsResponse');

                                        break;
                                }
                            }

                        } else { // Normal validation type
                            // tslint:disable-next-line: max-line-length
                            response = this._validator.ValidationResponseStructure(response, requestOptions.validateResponseType);
                        }
                    }
                    return response;
                }),
                tap(
                    (response: any) => { // Success
                        // Check response
                        if (!requestOptions.skipInterceptor) {
                            this.checkResponse(response);
                        }
                    },
                    (response: any) => { // Error

                        // Check response
                        throwError(response);
                    }
                ),
                retryWhen(
                    this.errorHandler(requestOptions.skipInterceptor)
                ),
            );

    }

    private checkForSkip(error: any) {

        const errorParse = error[0];
        let skipRetry = false;

        if (errorParse && errorParse.hasOwnProperty('failedRequest')) {
            skipRetry = errorParse.failedRequest.match(/server_time/g);
        }

        return skipRetry;
    }

    private errorHandler(skipInterceptor: boolean) {
        let errorCount = 0;

        return (errorObserver: Observable<any>) => {
            return errorObserver.pipe(
                mergeMap((error: any, i) => {
                    errorCount += 1;
                    // Skip retry for server_time we need that for the proxy feature
                    if (this.checkForSkip(error)) {
                        errorCount = 4
                    }
                    // if maximum number of retries have been met
                    // or response is a status code we don't wish to retry, throw error
                    if (
                        errorCount > 4 ||
                        error.statusCode === 200
                    ) {
                        if (!skipInterceptor) {
                            this.checkResponse(error);
                        }
                        return throwError(error);
                    }
                    // retry after 1s, 2s, etc...
                    return timer(1000);
                }));
        };
    }

    public batch(path: string, requests: Request[]) {
        return this.post(path, requests);
    }

    /**
     * Apply OBF Response Checking algorythm in each request that SDK performs
     * @param response http response
     */
    private checkResponse(response: any): void {
        if (this._httpInterceptor.isResponseCheckerRegistered()) {
            this._httpInterceptor.checkResponse('apiError', response);
        }
    }
}
