import { HttpParameterCodec, HttpUrlEncodingCodec } from '@angular/common/http';
import { Injectable } from '@angular/core';
/**
 * Is use in ParentGuard
 */
@Injectable({
    providedIn: 'root',
})
export class QueryParamsService {
    private initQueryParams: ObfQueryParams = {};
    private encoder: HttpParameterCodec;

    constructor(
    ) {
        this.encoder = new HttpUrlEncodingCodec();
    }

    /**
     * Will fetch query params and validate them
     * @param queryParams
     */
    // public checkParams(queryParams): Promise<boolean> {
    public checkParams(queryParams): void {

        this.fetchQueryParamsAndValidate(queryParams);

        const payloadQueryParams: any = {};

        // Check for payload properties
        Object.keys(queryParams).map((queryParamsKey) => {
            if (queryParamsKey.indexOf('fs_payload') !== -1 && queryParamsKey.indexOf(']') !== -1 && queryParamsKey.indexOf('[') !== -1) {

                const splittedParam = queryParamsKey.split('[');
                const payloadProperty = splittedParam[1].split(']')[0];

                if (payloadProperty) {
                    payloadQueryParams[payloadProperty] = queryParams[queryParamsKey];
                }

            }
        });

        if (payloadQueryParams) {
            this.fetchQueryParamsAndValidate(payloadQueryParams);
        }

    }

    public getQueryParams() {
        return this.initQueryParams;
    }

    /**
     * By given object with query params will filter them and sets the values
     * @param queryParams
     */
    private fetchQueryParamsAndValidate(queryParams) {
        Object.keys(queryParams).map((queryParamsKey) => {
            if (queryParamsKey.indexOf('[') === -1) {
                const queryParamValue = this.encoder.decodeValue(queryParams[queryParamsKey]);
                switch (queryParamsKey) {
                    case 'postcode':
                        this.initQueryParams.postcode = queryParamValue;
                        break;
                    case 'promo_deal':
                        this.initQueryParams.promo_deal = queryParamValue;
                        break;
                    case 'service_id':
                        this.initQueryParams.service_id = queryParamValue;
                        break;
                    case 'category_id':
                        this.initQueryParams.category_id = queryParamValue;
                        break;
                    case 'cross_token':
                        this.initQueryParams.cross_token = queryParamValue;
                        break;
                    case 'profile_id':
                        this.initQueryParams.profile_id = queryParamValue;
                        break;
                    case 'profile':
                        this.initQueryParams.profile = queryParamValue;
                        break;
                    case 'cross_id':
                        this.initQueryParams.cross_id = queryParamValue;
                        break;
                    case 'special_source':
                        this.initQueryParams.special_source = queryParamValue;
                        break;
                    case 'booking_transaction_id':
                        this.initQueryParams.booking_transaction_id = queryParamValue;
                        break;
                    case 'payment_intent':
                        this.initQueryParams.payment_intent = queryParamValue;
                        break;
                    case 'payment_method_id':
                        this.initQueryParams.payment_method_id = queryParamValue;
                        break;
                    case 'paymethod_type':
                        this.initQueryParams.paymethod_type = queryParamValue;
                        break;
                    case 'redirect_status':
                        this.initQueryParams.redirect_status = queryParamValue;
                        break;
                    case 'booking_id':
                        this.initQueryParams.booking_id = queryParamValue;
                        break;
                    case 'return_url':
                        this.initQueryParams.return_url = queryParamValue;
                        break;
                    case 'click_id':
                        this.initQueryParams.click_id = queryParamValue;
                        break;
                    case 'voucher':
                        this.initQueryParams.voucher = queryParamValue;
                        break;
                    case 'free_quote':
                        this.initQueryParams.free_quote = queryParamValue;
                        break;
                    case 'parentOrigin':
                        this.initQueryParams.parent_origin = queryParamValue;
                        break;
                    case 'campaign_phone':
                        this.initQueryParams.campaign_phone = queryParamValue;
                        break;
                    case 'campaign_id':
                        this.initQueryParams.campaign_id = queryParamValue;
                        break;
                    case 'campaign_abbr':
                        this.initQueryParams.campaign_abbr = queryParamValue;
                        break;
                    case 'exit_position':
                        this.initQueryParams.exit_position = queryParamValue;
                        break;
                    case 'hide_phone':
                        this.initQueryParams.hide_phone = queryParamValue;
                        break;
                    case 'covering_postcode': 
                        this.initQueryParams.covering_postcode = queryParamValue;
                        break;
                    case 'csid':
                        this.initQueryParams.csid = queryParamValue;
                        break;
                    default:
                        // console.warn('Unknown query param ' + queryParamsKey);
                        break;
                }
            }
        });

    }

    private validatePostCode() { }

    private validateTransactionId() { }
}

export interface ObfQueryParams {
    // ! NORMAL
    postcode?: string;
    promo_deal?: string;
    service_id?: string;
    promocode?: string;
    free_quote?: string;
    campaign_phone?: string;
    campaign_id?: string;
    campaign_abbr?: string;
    exit_position?: string;
    covering_postcode?:string;
    hide_phone?:string;
    // ! PRO BOOKING
    cross_token?: string;
    profile_id?: string;
    profile?: string;
    cross_id?: string;
    special_source?: string;
    // ! SHARED
    booking_id?: string;
    csid?: string;

    parent_origin?: string;

    // old maybe
    return_url?: string;
    click_id?: string;

    [key: string]: any;
}
