import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DynamicColorChangeModule } from '../../../directives/dynamic-color-change/dynamic-color-change.module';
import { DynamicColorChangerDirective } from '../../../directives/dynamic-color-change/dynamic-color-changer.directive';
import { ButtonComponent } from './button/button.component';

@NgModule({
    declarations: [
        ButtonComponent,
    ],
    imports: [
        CommonModule,
        DynamicColorChangeModule,
    ],
    exports: [
        ButtonComponent,
    ],
})
export class ButtonsModule { }
