<div class="obf-register-box">
    <div class="holder-title">
        <h2 localizedText [textKeyword]="'resetPasswordSectionTitle'"></h2>
    </div>
    <div
        class="obf-message-box obf-error"
        *ngIf="componentSettings.errorMessages.length"
    >
        <div class="obf-content-box">
            <div class="obf-icon">
                <i class="icon-si-close-circle" aria-hidden="true"></i>
            </div>
            <div class="obf-messages-holder">
                <span
                    class="obf-message"
                    *ngFor="let error of componentSettings.errorMessages"
                >
                    {{ error.message }}
                </span>
            </div>
        </div>
    </div>
    <form
        [formGroup]="resetPassword"
        (ngSubmit)="processForm()"
        #templateResetPForm="ngForm"
        *ngIf="!emailSent; else confirmationMessageBox"
    >
        <div class="obf-form-part forgot-holder">
            <div class="mb-3">
                <p localizedText [textKeyword]="'resetPasswordSectionDescription'"></p>
            </div>
            <div class="obf-fs-input-wrap">
                <div>
                    <input
                        [fsTextField]="email.value"
                        [labelText]="componentSettings?.fields?.email?.label"
                        FsFocusHandler
                        [inputScrollParent]="'.obf-lr-modal'"
                        [errorMessage]="
                            email.errors?.email && email.value.length
                                ? componentSettings?.fields?.email?.errMsgs
                                      ?.format
                                : componentSettings?.fields?.email?.errMsgs
                                      ?.required
                        "
                        [error]="email.invalid && templateResetPForm.submitted"
                        type="text"
                        id="reset-email"
                        name="email"
                        formControlName="email"
                        [placeholder]="
                            componentSettings?.fields?.email?.placeholder
                        "
                    />
                </div>
            </div>

            <div>
                <app-button
                    [isSubmitButton]="true"
                    [label]="componentSettings?.fields?.submitButton?.label"
                    [type]="'primary'"
                    [state]="'default'"
                    [isFullWidth]="true"
                ></app-button>
            </div>
        </div>
    </form>

    <ng-template #confirmationMessageBox>
        <p>
            {{ confirmationMessage }} <b>{{ confirmationEmail }}</b>
        </p>
    </ng-template>
</div>
<div class="back-to-login">
    <span
        class="link-color cursor"
        (click)="gotoLogin()"
        localizedText
        [textKeyword]="'backToSignInLabel'"></span>
</div>
