export const localhostConfig = {
    DEFAULT: {
        env: 'localhost',
        api: {
            baseUrl: 'https://uspaah.serviceos.com/api/v2.2/',
            accounts: 'https://accounts-uspaah.1dxr.com/'
        },
        country: 'UK',
        XApplication: '4rd4spxv8eg4tsl5l9bs4nmeh92i47z2fx8oyljnwcoqkq0kyxd74brtwgre3xj7',
        XProfile: '2',
        cookieKey: 'LMrofhHejiTWJeRYuVI6wV4cPmcb7H7u1nQg1M3UW8g',
        googleApiKey: '',
        googleSuggestAddress: true, // This is only for address not postcode.
        paymentSecurityBadge: {
            enable: false,
            href: ''
        },
        defaultPhoneCode: '44'
    },
};
