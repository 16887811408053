<div class="obf-modal-overlay"></div>
<div class="obf-container obf-popup-holder-box">
    <div class="popup">
        <div class="obf-popup-header">
            <span *ngIf="modalType === 'warning'" class="close-btn icon-si-close-cancel" aria-label="Close" (click)="closeModal()"></span>
        </div>

        <div class="obf-popup-body">
            <h4 *ngIf="modalTitle !== 'none'" class="obf-title">{{ modalTitle }}</h4>

            <div class="obf-description obf-nice-scroll" [innerHTML]="modalMessage">
            </div>
        </div>

        <div class="obf-popup-footer" [ngClass]="{ 'single-action-only': modalButtons?.length === 1 }">
            <div class="service-price-info" *ngIf="modalServicePrice">
                <span class="title">{{ _localizationProvider.getLocalizedText('crossSellModalServicePriceTitle') }}</span>
                <span class="price">{{ modalServicePrice }}</span>
            </div>
            <div class="obf-btns-holder">
                <app-button
                    *ngFor="let button of modalButtons"
                    (click)="buttonClick(button)"
                    [label]="button.label"
                    [type]="button.type"
                    [state]="button.state"
                    [size]="button.size"
                    [isFullWidth]="button.isFullWidth"
                >
                </app-button>
            </div>
        </div>
    </div>
</div>

