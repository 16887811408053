import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from '@services/navigation.service';
import { BehaviorSubject, config } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ObfOptions } from '../../../../lib/obf-client/src/interfaces/obf-options.interface';
import { deprecatedKeywords } from './../../../assets/languages/deprecated-keywords';
import { Environment } from './../../models/environment.model';
import { PostMessageService } from './../../services/post-message.service';
import { localLanguages } from './../../../assets/languages/languages';
import { transformLanguageKey } from 'src/app/helpers/global-functions';
import { LocalizationProvider } from '@providers/localization.provider';

/**
 * Contain the obf configuration data
 */
@Injectable()
export class MainDataProvider {

    /**
     * Contain obf options from window.obfOptions
     */
    private initializeObfOptions: ObfOptions;
    private obfOptions: BehaviorSubject<ObfOptions>;

    /**
     * Contain the environment data
     */
    public obfEnv: Environment;

    // private currentLanguage: string = null;
    // private defaultLanguage: string = 'en';
    // private profileLanguages: any = null;

    private sid: BehaviorSubject<null | string> = new BehaviorSubject(null);
    private colorSchema: BehaviorSubject<{ [key: string]: string }> = new BehaviorSubject(
        {
            primary_color: '#e83e8c',
            secondary_color: '#005a96',
            text_color: '#394e66'
        }
    );
    public activeReactSearch = true;

    public isMembershipTermsAcceptedByModal = false;

    public socialAuthentications: {
        googleAuthKey: string,
        googleProviderId: number,
        facebookAuthKey: string | number,
        facebookProviderId: number,
        appleAuthKey: string,
        appleProviderId: number,
        azure: {
            client_id: string,
            tenant: string,
            policy: string
        },
        oauth2: {
            client_id: string | number,
            authorization_url: string,
            provider_id: string | number
        } 

    } | null = null;

    public constructor(
        private navigationService: NavigationService,
        private postMessageService: PostMessageService,
        private router: Router,
        private _localizationProvider: LocalizationProvider
    ) { }

    public getResourceObfOptions(): ObfOptions {
        return this.obfOptions.getValue();
    }

    public getInitializeObfOptions(): ObfOptions {
        return JSON.parse(JSON.stringify(this.initializeObfOptions));
    }

    public setSessionId(sid: null | string): void {
        this.sid.next(sid);
    }

    public getSessionIdObserver() {
        return this.sid;
    }

    public getResourceObserverObfOptions(): BehaviorSubject<ObfOptions> {
        return this.obfOptions;
    }

    public setActiveReactSearch(reactSearch) {
        this.activeReactSearch = reactSearch;
    }

    public setMembershipTermsFlag(flag) {
        this.isMembershipTermsAcceptedByModal = flag;
    }

    public getMembershipTermsFlag() {
        return this.isMembershipTermsAcceptedByModal;
    }

    public setResourceObfOptions(obfOptions) {
        // * Apply Environment changes base on obfOptions.
        if (obfOptions?.theme) {
            this.changeColorSchema(obfOptions.theme);
        }

        if (
            this.obfEnv &&
            this.obfEnv.additionalConfig &&
            this.obfEnv.additionalConfig.logos &&
            this.obfEnv.additionalConfig.logos.brandName === ''
        ) {
            this.obfEnv.additionalConfig.logos.brandName = obfOptions.website_name?.replace(/&#039;/g, '\'').replace(/&amp;/g, '&');
        }
        // * --------------------------------------------- 


        if (this.obfOptions) {
            this.obfOptions.next(obfOptions);
        } else {
            this.obfOptions = new BehaviorSubject(obfOptions);
        }
    }

    public getEndpointConfig() {
        const language = this._localizationProvider.getCurrentLanguage();
        return {
            application_token: this.obfEnv.XApplication,
            build: environment.VERSION,
            profile: this.obfEnv.XProfile,
            server_url: this.obfEnv.api.baseUrl.replace('api/v2.2/', ''),
            sid: this.sid.getValue(),
            language: language ? language : ''
        }
    }

    // * This is trigger only on form init check init-config-data.service.ts.
    public setEnvironmentData(environment: Environment, obfOptions: ObfOptions): string[] {
        const errors = [];

        this.obfEnv = environment;

        // Check if is dynamic configuration.
        if (!this.obfEnv.api) {
            if (obfOptions?.accounts_url && obfOptions?.api_url) {
                this.obfEnv.api = {
                    baseUrl: `${obfOptions.api_url}api/v2.2/`,
                    accounts: obfOptions.accounts_url,
                    proxy: `${obfOptions.api_url}proxy/api/v2.2/`
                };
            } else {
                errors.push('Is dynamic configuration and api_url and accounts_url are not present in obfOptions');
            }
        }

        if (obfOptions.key) {
            this.obfEnv.XApplication = obfOptions.key;
        } else {
            errors.push('Missing obfOptions.key');
        }

        if (obfOptions.profile_id) {
            this.obfEnv.XProfile = obfOptions.profile_id;
        } else {
            errors.push('Missing obfOptions.profile_id');
        }

        if (obfOptions.profile) {
            this.obfEnv.XProfile = obfOptions.profile;
        } 

        if (obfOptions.country) {
            this.obfEnv.country = obfOptions.country;
        } else {
            errors.push('Missing obfOptions.country');
        }

        if (window !== parent) {
            this.obfEnv.obfType = 'iframe';
        } else {
            this.obfEnv.obfType = 'page';
        }

        this.initializeObfOptions = obfOptions;

        this.setResourceObfOptions(obfOptions);

        return errors;
    }

    private fillMissingColorProperties(type, properties) {

        if (type === 'old') {
            if (properties.includes('accent-color')) {
                if (!properties.includes('accent-color-lighter')) {
                    properties.push('accent-color-lighter')
                }
                if (!properties.includes('accent-color-light')) {
                    properties.push('accent-color-light')
                }
                if (!properties.includes('accent-color-dark')) {
                    properties.push('accent-color-dark')
                }
                if (!properties.includes('accent-color-darker')) {
                    properties.push('accent-color-darker')
                }
            }
        } else {
            if (properties.includes('secondary-color')) {
                if (!properties.includes('secondary-color-lighter')) {
                    properties.push('secondary-color-lighter')
                }
                if (!properties.includes('secondary-color-light')) {
                    properties.push('secondary-color-light')
                }
                if (!properties.includes('secondary-color-dark')) {
                    properties.push('secondary-color-dark')
                }
                if (!properties.includes('secondary-color-darker')) {
                    properties.push('secondary-color-darker')
                }
            }
        }

        if (properties.includes('primary-color')) {
            if (!properties.includes('primary-color-lighter')) {
                properties.push('primary-color-lighter')
            }
            if (!properties.includes('primary-color-light')) {
                properties.push('primary-color-light')
            }
            if (!properties.includes('primary-color-dark')) {
                properties.push('primary-color-dark')
            }
            if (!properties.includes('primary-color-darker')) {
                properties.push('primary-color-darker')
            }
        }

        return properties;
    }

    private toRGBStyle(rgb) {

        return `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`;

    }

    private rgbShade(rgb, factor) {
        const shadeFactor = 1 - Math.min(Math.max(factor, 0), 1);
        return {
            r: Math.floor(rgb.r * shadeFactor),
            g: Math.floor(rgb.g * shadeFactor),
            b: Math.floor(rgb.b * shadeFactor)
        }
    }

    private rgbTint(rgb, factor) {
        const tintFactor = Math.min(Math.max(factor, 0), 1)
        return {
            r: Math.floor(rgb.r + (255 - rgb.r) * tintFactor),
            g: Math.floor(rgb.g + (255 - rgb.g) * tintFactor),
            b: Math.floor(rgb.b + (255 - rgb.b) * tintFactor)
        }
    }

    private rgbToObject(rgb) {

        if (!rgb) {
            rgb = 'rgb(28, 69 ,152)';
        }

        const result = rgb.match(/(\d[\d\.]*)/g);
        return result ? {
            r: parseInt(result[0]),
            g: parseInt(result[1]),
            b: parseInt(result[2])
        } : null;
    }

    private hexToRgb(hex) {
        if (!hex) {
            hex = '#1c4598';
        }
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    }

    public changeColorSchema(theme) {

        let isThemeHasPrimaryValue = false,
            isThemeHasSecondaryValue = false;

        // check which properties are set in theme and map them only
        for (const key in theme) {

            if (theme[key] !== '' ) {
                if (key.includes('primary')) {
                    isThemeHasPrimaryValue = true;
                } else if (key.includes('secondary')) {
                    isThemeHasSecondaryValue = true;
                }
            }
        }
        
        if (theme && (isThemeHasPrimaryValue || isThemeHasSecondaryValue)) {
            const colorTheme = JSON.parse(JSON.stringify(this.colorSchema.getValue()));
            let colorsProperties = Object.keys(theme);

            if (colorsProperties && colorsProperties.length) {

                if (colorsProperties.includes('accent-color')) {

                    colorsProperties = this.fillMissingColorProperties('old', colorsProperties);

                    // OLD LOGIC (accent + primary)
                    colorsProperties.map((property) => {
                        let name = property;

                        if (property.includes('accent-color')) {

                            name = 'primary-color' + property.replace('accent-color', '');

                            if (property === 'accent-color') {
                                document.documentElement.style.setProperty('--primary-color' + property.replace('accent-color', ''), theme[property]);
                            } else if (property === 'accent-color-lighter') {
                                if (!theme[property]) {
                                    document.documentElement.style.setProperty('--primary-color-lighter' + property.replace('accent-color-lighter', ''), this.toRGBStyle(this.rgbTint(theme['accent-color'].includes('rgb') ? this.rgbToObject(theme['accent-color']) : this.hexToRgb(theme['accent-color']), 0.7)));
                                } else {
                                    document.documentElement.style.setProperty('--primary-color-lighter' + property.replace('accent-color-lighter', ''), theme[property]);
                                }
                            } else if (property === 'accent-color-light') {
                                if (!theme[property]) {
                                    document.documentElement.style.setProperty('--primary-color-light' + property.replace('accent-color-light', ''), this.toRGBStyle(this.rgbTint(theme['accent-color'].includes('rgb') ? this.rgbToObject(theme['accent-color']) : this.hexToRgb(theme['accent-color']), 0.7)));
                                } else {
                                    document.documentElement.style.setProperty('--primary-color-light' + property.replace('accent-color-light', ''), theme[property]);
                                }
                            } else if (property === 'accent-color-dark') {
                                if (!theme[property]) {
                                    document.documentElement.style.setProperty('--primary-color-dark' + property.replace('accent-color-dark', ''), this.toRGBStyle(this.rgbShade(theme['accent-color'].includes('rgb') ? this.rgbToObject(theme['accent-color']) : this.hexToRgb(theme['accent-color']), 0.4)));
                                } else {
                                    document.documentElement.style.setProperty('--primary-color-dark' + property.replace('accent-color-dark', ''), theme[property]);
                                }
                            } else if (property === 'accent-color-darker') {
                                if (!theme[property]) {
                                    document.documentElement.style.setProperty('--primary-color-darker' + property.replace('accent-color-darker', ''), this.toRGBStyle(this.rgbShade(theme['accent-color'].includes('rgb') ? this.rgbToObject(theme['accent-color']) : this.hexToRgb(theme['accent-color']), 0.7)));
                                } else {
                                    document.documentElement.style.setProperty('--primary-color-darker' + property.replace('accent-color-darker', ''), theme[property]);
                                }
                            }
                            
                            return;
                        }
                        if (property.includes('primary-color')) {

                            name = 'secondary-color' + property.replace('primary-color', '');

                            if (property === 'primary-color') {
                                document.documentElement.style.setProperty('--secondary-color' + property.replace('primary-color', ''), theme[property]);
                            } else if (property === 'primary-color-lighter') {
                                if (!theme[property]) {
                                    document.documentElement.style.setProperty('--secondary-color-lighter' + property.replace('primary-color-lighter', ''), this.toRGBStyle(this.rgbTint(theme['primary-color'].includes('rgb') ? this.rgbToObject(theme['primary-color']) : this.hexToRgb(theme['primary-color']), 0.7)));
                                } else {
                                    document.documentElement.style.setProperty('--secondary-color-lighter' + property.replace('primary-color-lighter', ''), theme[property]);
                                }
                            } else if (property === 'primary-color-light') {
                                if (!theme[property]) {
                                    document.documentElement.style.setProperty('--secondary-color-light' + property.replace('primary-color-light', ''), this.toRGBStyle(this.rgbTint(theme['primary-color'].includes('rgb') ? this.rgbToObject(theme['primary-color']) : this.hexToRgb(theme['primary-color']), 0.7)));
                                } else {
                                    document.documentElement.style.setProperty('--secondary-color-light' + property.replace('primary-color-light', ''), theme[property]);
                                }
                            } else if (property === 'primary-color-dark') {
                                if (!theme[property]) {
                                    document.documentElement.style.setProperty('--secondary-color-dark' + property.replace('primary-color-dark', ''), this.toRGBStyle(this.rgbShade(theme['primary-color'].includes('rgb') ? this.rgbToObject(theme['primary-color']) : this.hexToRgb(theme['primary-color']), 0.4)));
                                } else {
                                    document.documentElement.style.setProperty('--secondary-color-dark' + property.replace('primary-color-dark', ''), theme[property]);
                                }
                            } else if (property === 'primary-color-darker') {
                                if (!theme[property]) {
                                    document.documentElement.style.setProperty('--secondary-color-darker' + property.replace('primary-color-darker', ''), this.toRGBStyle(this.rgbShade(theme['primary-color'].includes('rgb') ? this.rgbToObject(theme['primary-color']) : this.hexToRgb(theme['primary-color']), 0.7)));
                                } else {
                                    document.documentElement.style.setProperty('--secondary-color-darker' + property.replace('primary-color-darker', ''), theme[property]);
                                }
                            }


                            return;
                        }

                        colorTheme[name.replace(/-/g, '_')] = theme[property];
                        document.documentElement.style.setProperty('--' + name, theme[property]);
                    });

                } else {
                    colorsProperties = this.fillMissingColorProperties('new', colorsProperties);

                    // NEW LOGIC (primary + secondary)
                    colorsProperties.map((property) => {

                        colorTheme[property.replace(/-/g, '_')] = theme[property];
                        document.documentElement.style.setProperty('--' + property, theme[property]);

                        if (theme[property]) {
                            document.documentElement.style.setProperty('--' + property, theme[property]);
                        } else {
                            if (property.includes('primary-color') && isThemeHasPrimaryValue) {
                                if (property.includes('light')) {
                                    document.documentElement.style.setProperty('--' + property, this.toRGBStyle(this.rgbTint(theme['primary-color'].includes('rgb') ? this.rgbToObject(theme['primary-color']) : this.hexToRgb(theme['primary-color']), 0.7)));
                                } else if (property.includes('dark')) {
                                    document.documentElement.style.setProperty('--' + property, this.toRGBStyle(this.rgbShade(theme['primary-color'].includes('rgb') ? this.rgbToObject(theme['primary-color']) : this.hexToRgb(theme['primary-color']), 0.4)));
                                }
                            } else if (property.includes('secondary-color') && isThemeHasSecondaryValue) {
                                if (property.includes('light')) {
                                    document.documentElement.style.setProperty('--' + property, this.toRGBStyle(this.rgbTint(theme['secondary-color'].includes('rgb') ? this.rgbToObject(theme['secondary-color']) : this.hexToRgb(theme['secondary-color']), 0.7)));
                                } else if (property.includes('dark')) {
                                    document.documentElement.style.setProperty('--' + property, this.toRGBStyle(this.rgbShade(theme['secondary-color'].includes('rgb') ? this.rgbToObject(theme['secondary-color']) : this.hexToRgb(theme['secondary-color']), 0.4)));
                                }
                            }
                            
                        }
                        // here
                    });
                }
            }

            this.colorSchema.next(colorTheme);
        }
    }

    public getColorSchema$() {
        return this.colorSchema;
    }

    /**
     * Social authentication data setter
     * @param socialAuthData
     */
    public setSocialAuthData(socialAuthData): void {
        if (socialAuthData && Object.keys(socialAuthData).length) {
            this.socialAuthentications = socialAuthData;
        }
    }

    /**
     * Social authentication data getter
     */
    public getSocialAuthData() {
        return this.socialAuthentications;
    }

    public updateAdditionalConfig(profileConfig) {
        try {
            const profileLayout = JSON.parse(profileConfig);
            this.obfEnv.additionalConfig.logos.brandLogo = profileLayout.brand_logo_url;

            // contact setup
            this.obfEnv.additionalConfig.concierge.email = profileConfig.contact_mail;
            this.obfEnv.additionalConfig.concierge.formattedPhoneNumber = profileConfig.phone;
            this.obfEnv.additionalConfig.concierge.phoneNumber = '+' + profileConfig.country_code + profileConfig.phone.substring(1);
        } catch (e) {

        }
    }
}
