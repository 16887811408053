/**
 * Simple check for JSON
 * @param {string} jsonString
 */
export function isValidJson(jsonString: string): boolean {
    if (typeof jsonString !== 'string') {
        return false;
    }

    try {
        JSON.parse(jsonString);
    } catch (error) {
        return false;
    }

    return true;
}

export function popupCenter() {
    const left = (screen.width / 2) - (630 / 2),
        top = (screen.height / 2) - (710 / 2);

    return {
        left: Math.abs(left),
        top: Math.abs(top),
    };
}

export function cleanPrice(price) {
    // Trim any whitespace at the beginning and end of the price string
    price = price.trim();

    // Remove any non-numeric characters at the beginning and end of the string, except for the minus sign at the start
    price = price.replace(/^[^\d-]+|[^\d]+$/g, '');

    // Regular expression to match any characters that are not digits, commas, periods, or hyphens
    var regex = /[^\d,.-]+/g;

    // Replace the matched symbols with an empty string
    price = price.replace(regex, '');

    // Ensure only one minus sign at the beginning if it exists
    price = price.replace(/(?!^)-/g, '');

    return +price;
}

/**
 * Concat random hex numbers
 */
export function genGUID() {
    return getRandom4SymbolString() + getRandom4SymbolString() + getRandom4SymbolString();
}

export function gen32GUID() {
    return getRandom10SymbolString() + getRandom10SymbolString() + getRandom4SymbolString() + getRandom4SymbolString() + getRandom4SymbolString();
}

export function genLongGUID() {
    return getRandom10SymbolString() + getRandom10SymbolString() + getRandom10SymbolString() + getRandom10SymbolString();
}

/**
 * Generate random 4 digit string
 */
function getRandom4SymbolString() {
    return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
}

/**
 * Generate random 10 digit string
 */
function getRandom10SymbolString() {
    return Math.floor((1 + Math.random()) * 0x1000000000)
        .toString(16);
}

export function checkIsObject(a) {
    return (!!a) && (a.constructor === Object);
}

/**
 * Remove white spaces and - signs
 * @param brand
 */
export function cardBrandFormatter(brand) {
    return brand.replace(/[\s-]/g, '').toLowerCase();
}

/**
 * Remove all non alphanumeric characters
 * @param string
 */
export function stripNonAlphanumericChars(string) {
    return string.replace(/[^0-9a-z]/gi, '');
}

/**
 * Copy to clipboard for iOS
 * @param el
 */
export function iosCopyToClipboard(el) {
    const oldContentEditable = el.contentEditable,
        oldReadOnly = el.readOnly,
        range = document.createRange();

    el.contentEditable = true;
    el.readOnly = false;
    range.selectNodeContents(el);

    const s = window.getSelection();
    s.removeAllRanges();
    s.addRange(range);

    el.setSelectionRange(0, 999999); // A big number, to cover anything that could be inside the element.

    el.contentEditable = oldContentEditable;
    el.readOnly = oldReadOnly;

    document.execCommand('copy');
}

/**
 * Mapping method for different variations of card brands
 * @param brand
 */
export function mapCardBrand(brand: string): string | null {
    const brandVariations: any = {
        amex: [
            'american-express',
            'american_express',
            'American Express',
        ],
    },
    brandNames: Array<string> = Object.keys(brandVariations);

    for (let brandNameIndex = 0; brandNameIndex < brandNames.length; brandNameIndex++) {
        const brandName: string = brandNames[brandNameIndex],
            foundVariation: string | undefined =
                brandVariations[brandName].find((brandVariation: string) => stripNonAlphanumericChars(brandVariation).toLowerCase() === stripNonAlphanumericChars(brand).toLowerCase());

        if (foundVariation) { return brandName; }
    }
}
