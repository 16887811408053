import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HighlightPipe } from './highlight.pipe';

@NgModule({
    declarations: [HighlightPipe],
    imports: [
        CommonModule,
    ],
    providers: [ // Add it in providers array in order to be able to use it in component class/service
        HighlightPipe,
    ],
    exports: [
        HighlightPipe,
    ],
})
export class HighlightPipeModule { }
