import { Injectable } from '@angular/core';
import {NGXLoggerMonitor, NGXLogInterface} from 'ngx-logger';
import { environment } from './../../../environments/environment';

@Injectable()
export class MyLoggerMonitor implements NGXLoggerMonitor {
  public onLog(log: NGXLogInterface) {
      const originalLog = console.log;

    // Enable Original Console Log
      console.log('[OBF INFO] Enable standard logs: ', environment.standardConsoleLog);
      if (environment.standardConsoleLog) {
        window.console.log = originalLog;
    } else {
        window.console.log = function() {};
        console.debug('[OBF DEBUG] ', log.message, ...log.additional);
    }
  }
}
