/**
    Bootstrap Affiliates Script
    Can be loaded or executed On Init and On Booking Completion.
    All the abbrivation and script codes are stored here.
**/

import { Injectable } from '@angular/core';


interface Scripts {
    id: number;
    name: string;
    src: string;
    type: string;
    load: string;
}


// What type of script
const TYPE_OPTIONS = {
    inner: 'inner',
    src: 'src',
};

// Where will be executed
const LOAD_OPTIONS = {
    confirm: 'confirm',
    init: 'init',
};

// Affiliates Abbriviations
const TRACKING_SCRIPTS = {
    gtmTracking: 'GTM',
    gtagTracking: 'GTAG'
};

// Auto loader for scripts, if you don't want to pass the scripts from the init-config-data.services
export const ScriptStore: Scripts[] = [
    // Google Tag Manager
    { id: 0, name: TRACKING_SCRIPTS.gtmTracking, src: null, type: TYPE_OPTIONS.inner, load: LOAD_OPTIONS.init },
];

declare var document: any;

@Injectable({
    providedIn: 'root',
})

export class TrackingScriptsLoaderService {

    private scripts: any = {};

    constructor(
    ) {
        ScriptStore.forEach((script: any) => {
            this.scripts[script.id] = {
                load: script.load,
                loaded: false,
                name: script.name,
                src: script.src,
                type: script.type,
            };
        });
    }

    private fillInnerScripts(key, merchant_id, type): void {

        console.log('[OBF INFO] init script report: ', type+':'+ merchant_id);

        switch (type) {
            case TRACKING_SCRIPTS.gtmTracking:
                this.scripts[key].src = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({\'gtm.start\': new Date().getTime(),event:\'gtm.js\'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!=\'dataLayer\'?\'&l=\'+l:\'\';j.async=true;j.src=\'https://www.googletagmanager.com/gtm.js?id=\'+i+dl;f.parentNode.insertBefore(j,f);})(window,document,\'script\',\'dataLayer\','${merchant_id}');`;
            break;
            case TRACKING_SCRIPTS.gtagTracking:
                this.scripts[key].src = `function async(a){var e=document,t="script",n=e.createElement(t),t=e.getElementsByTagName(t)[0];n.src=a,t.parentNode.insertBefore(n,t)}function gtag(){dataLayer.push(arguments)}async("https://www.googletagmanager.com/gtag/js?id=${merchant_id}"),window.dataLayer=window.dataLayer||[],gtag("js",new Date),gtag("config","${merchant_id}");`;
            break;
            default:
        }
    }




    public load(type: string, load?: string, ...scripts: string[]): any {
        const promises: any[] = [];
        
        scripts.forEach((script) => promises.push(this.loadScript(script, type, load)));
        return Promise.all(promises);
    }

    private loadScript(name: string, type: string, load?: string): any {
        return new Promise((resolve, reject) => {

            Object.keys(this.scripts).forEach((key) => {

                if (load === this.scripts[key].load) {
                    

                    if (this.scripts[key].name && !this.scripts[key].loaded) {

                        // load script
                        const script = document.createElement('script');
                        
                        // External Script include
                        if (this.scripts[key].type !== TYPE_OPTIONS.inner) {
                            
                            script.type = 'text/javascript';
                            script.src = this.scripts[key].src;
                            script.async = true;
                            script.defer = true;
                        } else {
                            // Fill Script
                            this.fillInnerScripts(key, name, type);
                            script.innerHTML = this.scripts[key].src;
                        }

                        if (script.readyState) {  // IE
                            script.onreadystatechange = () => {
                                if (script.readyState === 'loaded' || script.readyState === 'complete') {
                                    script.onreadystatechange = null;
                                    this.scripts[key].loaded = true;
                                    resolve({ script: name, loaded: true, status: 'Loaded' });
                                }
                            };
                        } else {  // Others
                            script.onload = () => {
                                this.scripts[key].loaded = true;
                                resolve({ script: name, loaded: true, status: 'Loaded' });
                            };
                        }
                        script.onerror = (error: any) => reject({ script: name, loaded: false, status: 'Loaded' });
                        document.getElementsByTagName('head')[0].appendChild(script);

                    } else {
                        resolve({ script: name, loaded: true, status: 'Already Loaded' });
                    }
                }
            });
        });
    }

}
