import { Injectable, Injector } from '@angular/core';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { InteractionController } from '@serviceos/interaction-pixel/dist';
import { PostMessageService } from '@services/post-message.service'
import { UserDataService } from '@services/user-data.service';
import { OrderData } from '@providers/order-data.provider';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})


export class EventTrackingService {
    
    private interactionController:InteractionController
    constructor(
        private _angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
        private _postMessageService: PostMessageService,
        private _injector: Injector
    ) { }

    public sentEvents = [];
    public oneTimeEvents = [
        // 'obf_config',
        // 'obf_on_availability',
        // 'obf_after_availability',
        // 'obf_summary',
        // 'obf_payment',
        // 'view_item',
        // 'add_to_cart',
        // 'begin_checkout',
        // 'add_payment_info'
    ];

    public resetSentEvents() {
        this.sentEvents = [];
    }

    // Default GTM Push
    public push(data): void {
        
        data = this.extendData(data);

        // Do not send events if they are already sent and are in one time list events
        if (this.sentEvents.indexOf(data.event) > -1 && this.oneTimeEvents.indexOf(data.event) > -1) return;

        // Push the event in the list of pushed events
        if (data?.event) {
            this.sentEvents.push(data.event);
        }

        // Send GA4 event on fs
        if (environment.project === 'fantastic-services' && data?.version === 'GA4') {
            delete data.version; 

            this._angulartics2GoogleTagManager.pushLayer(data);
            this._postMessageService.sendState('data-layer', data);

        // Send all events when its not fs
        } else if (environment.project !== 'fantastic-services') {

            this._angulartics2GoogleTagManager.pushLayer(data);
            this._postMessageService.sendState('data-layer', data);
        }

    }
    
    // ServiceOS Pixel tracking push
    public sosInteractionsPush(event_name, payload?): void {
        this.interactionController = new InteractionController(window,document);   
        this.interactionController.tag('event', event_name, payload); 
    }

    // Add additional data based on event name
    private extendData(data) {

        // Inject Services 
        const _orderData = this._injector.get(OrderData);
        const activeData = _orderData.activeBooking.getValue() ? _orderData.activeBooking.getValue().get() : null;
        const _userDataService = this._injector.get(UserDataService);
        const userData = _userDataService?.getUserData();
        const membershipPriceItem = activeData?.price?.price_breakdown?.find((item) => {
            return item.type === 'membership'
        });

        // Add prop to all events with excepts
        if (!data.type) {
            // add default type property if missing
            data.type = _userDataService.isUserLogged() ? 'logged_in' : 'not_logged_in';
        }
        data.position = _orderData.getCurrentStep()?.position ? _orderData.getCurrentStep().position : null;


        data.membership = userData ? userData.membership ? 'member' : membershipPriceItem ? 'new member' : 'regular' : '';
        
        // Add custom props to specific events and do specific things
        switch (data.event) {
            case 'obf_clicks':
                // ..
                data.method = _orderData.bookingType
                break;
            case 'obf_open':
                
                // Reset sent data layer events list
                this.resetSentEvents();

                data.method = _orderData.bookingType
                // ..
                break;
            case 'view_item_list':
                // ..
                break;
            case 'obf_init':
                // ..
                break;
            case 'obf_close':
                // ..
                break;
            case 'obf_search_error':
                // ..
                break;
            case 'obf_postcode':
                // ..
                break;
            case 'obf_postcode_no_coverage':
                // ..
                break;
            case 'obf_postcode_no_coverage_notify':
                // ..
                break;
            case 'obf_config':
                // ..
                break;
            case 'view_item':
                // ..
                break;
            case 'obf_on_availability':
                // ..
                break;
            case 'obf_after_availability':
                // ..
                break;
            case 'obf_summary':
                // ..
                break;
            case 'obf_payment':
                // ..
                break;
            case 'add_payment_info':
                // ..
                break;
            case 'obf_service_booked':
                // ..
                break;
            case 'purchase':
                // ..
                break;
            case 'obf_service_lead':
                // ..
                break;
            case 'generate_lead':
                // ..
                break;
            case 'login':
                // ..
                break;
            case 'login_error':
                // ..
                break;
            case 'logout':
                // ..
                break;
            case 'sign_up':
                // ..
                break;
            case 'claim_account':
                // ..
                break;
            case 'view_promotion':
                // ..
                break;
            case 'select_promotion':
                // ..
                break;
            case 'user_info':
                // ..
                break;
            case 'add_to_cart':
                // ..
                break;
        }

        return data;
    }

}
