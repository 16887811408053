import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FsSpecialFieldsDirective } from './fs-special-fields.directive';

@NgModule({
    declarations: [
        FsSpecialFieldsDirective,
    ],
    imports: [
        CommonModule,
    ],
    exports: [
        FsSpecialFieldsDirective,
    ],
})
export class FsSpecialFieldsModule { }
