import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sort',
})
export class SortPipe implements PipeTransform {

    /**
	 * Sorts an array by passed property name
	 * @param arrayToSort array/list to be sorted
	 * @param sortByProperty property name for sorting
	 */
    public transform(arrayToSort: Array<any>, sortByProperty: string): any {
        if (!Array.isArray(arrayToSort) || !sortByProperty) {
            return arrayToSort;
        }

        const sortedArray: Array<any> = arrayToSort
            .sort((currentElement, nextElement) => {
                if (currentElement.hasOwnProperty(sortByProperty)) {
                    if (currentElement[sortByProperty] < nextElement[sortByProperty]) { // Place current paymethod lower
                        return -1;
                    } else if (currentElement[sortByProperty] > nextElement[sortByProperty]) { // Place current paymethod higher
                        return 1;
                    }

                    // Both payment method sorts are equal
                    return 0;
                } else {
                    //  Array remains the same
                    return 0;
                }
            });

        return sortedArray;
    }

}
