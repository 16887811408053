import { AfterContentInit, Directive, ElementRef, HostListener, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[fsTextField]',
    exportAs: 'TextFieldValues',
    providers: [NgModel],
})
export class FsTextFieldDirective implements AfterContentInit, OnChanges {
    @Input()
    public labelText;

    @Input()
    public errorMessage;

    @Input()
    public error;

    @Input()
    public fsTextField;

    @Input() set ngModel(value: any) {
        if (value && value !== '') {
            this.el.nativeElement.value = value;
        }
    }

    @Input()
    public disableInput = false;

    private errorElement: HTMLElement;
    private labelElement: HTMLElement;

    private inputParent;
    private errorParent;

    constructor(private el: ElementRef) {}

    /**
     * Listener for change of input data
     */
    public ngAfterContentInit() {
        const parent: HTMLElement = this.el.nativeElement.parentElement;
        parent.classList.add('obf-fs-text-input');

        if (this.disableInput) {
            parent.classList.add('obf-disabled');
            this.el.nativeElement.disabled = true;
        }

        this.createLabel();
        this.createErrorMessage();
    }

    /**
     * Focus listener
     */
    @HostListener('focus')
    public onfocus(): void {
        const parent: HTMLElement = this.el.nativeElement.parentElement;
        parent.classList.add('obf-focus');
        parent.classList.add('obf-selected');
    }

    /**
     * Blur listener
     */
    @HostListener('blur')
    public onblur(): void {
        const parent: HTMLElement = this.el.nativeElement.parentElement;
        if (this.el.nativeElement.value === '' && !this.error) {
            parent.classList.remove('obf-focus');
        }
        parent.classList.remove('obf-selected');
    }

    /**
     * Keypress listener
     * @param event
     */
    @HostListener('keypress', ['$event'])
    public onKeypress(event: any): void {
        if (this.disableInput) {
            event.preventDefault();
        }
    }

    @HostListener('change', ['$event'])
    public onChange(event: any): void {
    }

    public ngOnChanges(changes: SimpleChanges): void {
        const parent: HTMLElement = this.el.nativeElement.parentElement;

        if (this.el.nativeElement.value !== '' || document.activeElement === this.el.nativeElement) {
            parent.classList.add('obf-focus');
        } else {
            parent.classList.remove('obf-focus');
        }

        this.showError();

        if (this.disableInput) {
            parent.classList.add('obf-disabled');
            this.el.nativeElement.disable = true;
        } else {
            parent.classList.remove('obf-disabled');
            this.el.nativeElement.disable = false;
        }

        if (this.labelElement) {
            this.labelElement.textContent = this.labelText;
        }

        if (this.errorElement) {
            this.errorElement.textContent = this.errorMessage;
        }
    }

    /**
     * Create the label and link it to the input
     */
    private createLabel() {
        if (this.labelText) {
            this.labelElement = this.createElement('label', this.labelText, true);
            this.labelElement.setAttribute('for', this.el.nativeElement.getAttribute('id'));
            this.labelElement.parentElement.classList.add('obf-hasLabel');
        }
    }

    /**
     * Create error and add the class for style
     */
    private createErrorMessage() {
        if (this.errorMessage) {
            this.errorParent = this.el.nativeElement.parentElement.parentElement;
            this.errorElement = this.createElement('small', this.errorMessage, false, this.errorParent);
            this.errorElement.classList.add('obf-fs-error');
        }
    }

    /**
     * Error handler method
     */
    private showError(): void {
        const parent: HTMLElement = this.el.nativeElement.parentElement;
        const errorParent = this.el.nativeElement.parentElement.parentElement;
        if (this.error) {
            parent.classList.add('obf-error');

            errorParent.classList.add('obf-error');
            parent.classList.add('obf-focus');
        } else {
            errorParent.classList.remove('obf-error');
            parent.classList.remove('obf-error');
        }
    }

    /**
     * Is use to generate the label and the error messages
     * @param {string} type type of the element
     * @param {string} textInside text inside of the element
     * @param {boolean} insertBefore flag where to insert the created element
     */
    private createElement(type, textInside?, insertBefore = false, appendTo?: HTMLElement): HTMLElement {
        const parent: HTMLElement = appendTo ? appendTo : this.el.nativeElement.parentElement;
        const createdEl = document.createElement(type);
        createdEl.textContent = textInside;
        if (insertBefore) {
            parent.insertBefore(createdEl, this.el.nativeElement);
        } else {
            parent.appendChild(createdEl);
        }

        return createdEl;
    }
}
