import { ChoicesPositions } from 'src/app/constants/choice';
import { NavigationService } from '@services/navigation.service';
import { MainDataProvider } from './../../providers/xrm/main-data.provider';
import { PopupModalsService } from './../../services/popup-modals.service';

import { Injectable } from '@angular/core';
import { ChoiceItemsTypes } from '@constants/choice-items';
import { BehaviorSubject } from 'rxjs';
import { EventTrackingService } from '@services/tracking/event-tracking.service';
import { ConfigurationService } from '@services/configuration.service';
import { ChoiceItem } from '@models/choice-item';

@Injectable({
    providedIn: 'root',
})
export class ChoicesService {
    private refreshChoicesNeeded: BehaviorSubject<void> = new BehaviorSubject(
        null
    );

    /**
     *  list of noCalcDuration choice items.
     */
    public noCalcDuration = [
        ChoiceItemsTypes.Address,
        ChoiceItemsTypes.TextField,
        ChoiceItemsTypes.Attachment,
    ];

    constructor(
        private _eventTrackingService: EventTrackingService,
        private popupModalsService: PopupModalsService,
        private _mainDataProvider: MainDataProvider,
        private _navigationService: NavigationService,
        private _configurationService: ConfigurationService
    ) {}

    /**
     * To calculate current time only pass
     * trigger only to get calculateDuration (null, null, choicesArr)
     * trigger change on calculateDuration (item, flag, choicesArr)
     * flag=true for increase,
     * flag=false for decrease
     * flag=null for don't change.
     * flag=undefined calculate with reset the hours component value
     */
    public calculateDuration(item, flag, choices: any[]) {
        let currentChoiceFind = false;
        let currentItemChoice;

        let hoursChoiceItem;
        let hoursStep;
        let hoursChoice;

        choices.recursiveMap((el) => {
            // Find current choice.
            if (!currentChoiceFind && el.positions) {
                currentItemChoice = el;
            }

            if (item == el) {
                currentChoiceFind = true;
            }

            // Find current hours choice and his step.
            if (!hoursChoiceItem && el.positions) {
                hoursChoice = el;
            }

            if (el.type === ChoiceItemsTypes.Hour) {
                hoursChoiceItem = el;
                if (
                    el.customize &&
                    el.customize !== null &&
                    el.customize.obfHoursStep
                ) {
                    hoursStep = +el.customize.obfHoursStep / 60;
                } else {
                    hoursStep = 0.5;
                }
            }

            return el.choice_items;
        });

        if (!hoursChoiceItem) {
            return null;
        }

        if (
            (item && this.noCalcDuration.indexOf(item.type) === -1) ||
            item == null ||
            flag ||
            flag === false
        ) {
            // call duration calculation and return the created object
            return ((settings, flag: boolean | null) => {
                let sumOfTime: number,
                    currentTime = 0;

                choices.recursiveMap((el) => {
                    currentTime += this.getTime(el);

                    if (
                        el.choice_items != null &&
                        el.choice_items.length &&
                        el.value != 0
                    ) {
                        return el.choice_items;
                    }
                });

                if (currentTime < 60 * settings.min_value) {
                    currentTime = 60 * settings.min_value;
                }

                if (currentTime % 60 > 0) {
                    // eslint-disable-next-line no-bitwise
                    sumOfTime =
                        currentTime % 60 > 50
                            ? ((currentTime / 60) | 0) + 1
                            : ((currentTime / 60) | 0) + 0.5;
                } else if (currentTime % 60 === 0) {
                    sumOfTime = currentTime / 60;
                }

                const recommendedDuration = sumOfTime;

                // TESTING HOURS BEFORE AVAILABILITY
                if (
                    hoursChoice.positions.includes(
                        ChoicesPositions.BeforeAvailability
                    ) &&
                    hoursChoiceItem.customize.initial_value
                ) {
                    // flag is change in choice-question base on conditions.
                    if (flag !== undefined) {
                        if (flag === true) {
                            hoursChoiceItem.value =
                                hoursChoiceItem.value + hoursStep;
                        } else if (flag === false) {
                            hoursChoiceItem.value =
                                hoursChoiceItem.value - hoursStep;
                        } else if (flag == null) {
                            hoursChoiceItem.value = hoursChoiceItem.value;
                        }
                    } else {
                        hoursChoiceItem.value =
                            Number(hoursChoiceItem.customize.initial_value);
                    }
    
                    hoursChoiceItem.calculateDurationDetails = {
                        sumDuration: hoursChoiceItem.value,
                        recommendedDuration:
                            hoursChoiceItem.customize.initial_value,
                        recommendedHoursError:
                            hoursChoiceItem.value <
                            Number(hoursChoiceItem.customize.initial_value),
                        additionDetails: this.calTimeAndNumOfWorkers(
                            settings,
                            hoursChoiceItem.value
                        ),
                    };
    
                    return hoursChoiceItem.calculateDurationDetails;
                } else {
                    if (flag !== undefined) {
                        if (flag === true) {
                            sumOfTime = settings.value + hoursStep;
                        } else if (flag === false) {
                            sumOfTime = settings.value - hoursStep;
                        } else if (flag == null) {
                            sumOfTime = settings.value;
                        }
                    } else {
                        settings.value = 0;
                    }
    
                    if (sumOfTime > settings.max_value) {
                        sumOfTime = settings.max_value;
                    } else if (sumOfTime < settings.min_value) {
                        sumOfTime = settings.min_value;
                    }
    
                    const calculateDurationDetails = {
                        sumDuration: sumOfTime,
                        recommendedDuration:
                            recommendedDuration > settings.max_value
                                ? settings.max_value
                                : recommendedDuration,
                        recommendedHoursError:
                            sumOfTime <
                            (recommendedDuration > settings.max_value
                                ? settings.max_value
                                : recommendedDuration),
                        additionDetails: this.calTimeAndNumOfWorkers(
                            settings,
                            sumOfTime
                        ),
                    };
    
                    settings.value = sumOfTime;
                    settings.calculateDurationDetails = calculateDurationDetails;
    
                    return calculateDurationDetails;
                }

                
            })(hoursChoiceItem, flag);
        } else {
            return hoursChoiceItem.calculateDurationDetails;
        }
    }

    /**
     * Calculate time by item type
     * @param item choice
     */
    private getTime(item: any) {
        let time = 0;
        switch (item.type) {
            case ChoiceItemsTypes.Checkbox:
                time = this.checkForIsActive(item);
                break;
            case ChoiceItemsTypes.Radio:
                time = this.checkForIsActive(item);
                break;
            case ChoiceItemsTypes.Stepper:
                time = this.getDurationSumByValue(item);
                break;
            default:
                time = 0;
                break;
        }
        return time;
    }

    /**
     * Check item is active and sum the item duration
     * @param item
     */
    private checkForIsActive(item) {
        if (item.value !== 0) {
            return item.duration;
        }
        return 0;
    }

    /**
     * Sum the duration of item on base value * duration
     * @param item
     */
    private getDurationSumByValue(item) {
        let time = 0;
        time = item.value * item.duration;
        return time;
    }

    /**
     * Calculate time of service and the number of workers we going to send
     * @param settings
     * @param sumTime
     */
    private calTimeAndNumOfWorkers(settings: any, sumTime: number) {
        if (
            settings &&
            settings.customize &&
            settings.customize.hasOwnProperty('obf_max_hours_per_pro') &&
            settings.customize.obf_max_hours_per_pro &&
            settings.customize.obf_max_pros &&
            settings.customize.obf_max_hours_per_pro !== '' &&
            settings.customize.obf_max_pros !== ''
        ) {
            let hours: string,
                // eslint-disable-next-line no-bitwise
                numWorkers =
                    ((sumTime /
                        parseFloat(settings.customize.obf_max_hours_per_pro)) |
                        0) +
                    1; // set the min workers

            // calculate the max number of workers
            if (numWorkers > settings.customize.obf_max_pros) {
                numWorkers = parseInt(settings.customize.obf_max_pros);
            }

            sumTime = (sumTime * 60) / numWorkers;
            // eslint-disable-next-line no-bitwise
            hours =
                (sumTime - (sumTime %= 60)) / 60 +
                (9 < sumTime ? ':' : ':0') +
                (sumTime | 0);

            return {
                hoursOnWorker: hours,
                numWorkers,
                workerTypeSingularForm: settings.customize.obfHoursPerson,
                workerTypePluralForm: settings.customize.obfHoursPersons,
            };
        } else {
            // Maybe in some cases we don't have customize with these properties?
            return {
                hoursOnWorker: null,
                numWorkers: null,
                workerTypeSingularForm: null,
                workerTypePluralForm: null,
            };
        }
    }

    /**
     *
     * @param choices
     */
    public summaryItems(choices: any[]) {
        let sidebarData = [];
        const selectedParents = [];

        choices.recursiveMap((el) => {
            if (el.hasOwnProperty('positions') && el.positions) {
                sidebarData.push({
                    id: el.id,
                    choice_items: [],
                    editable: true,
                });
            }

            if (el.parent_id === 0 && el.value !== 0) {
                // Push the parent for selection check
                selectedParents.push(el.id);
                // Add Parent in the sidebar
                if (el.value !== 0 && el.is_in_summary) {
                    sidebarData[sidebarData.length - 1].choice_items.push(el);
                }
            }

            if (
                el.value !== 0 &&
                el.is_in_summary &&
                selectedParents.indexOf(el.parent_id) !== -1
            ) {
                sidebarData[sidebarData.length - 1].choice_items.push(el);
            }

            if (el.value !== 0 && el.choice_items && el.choice_items.length) {
                return el.choice_items;
            }

            if (el.type === 'default') {
                return el.choice_items;
            }
        });

        // Remove choices with no choice_items
        sidebarData = sidebarData.filter((choice) => {
            return choice && choice.choice_items && choice.choice_items.length;
        });

        return sidebarData;
    }

    public checkForLogicJs(
        item,
        customize
    ): Promise<{
        serviceId: string;
        skip_popup: undefined | boolean;
        item: any;
    } | null> {
        return new Promise((res, rej) => {
            'use strict';
            if (
                customize &&
                customize.hasOwnProperty('logic_js') &&
                customize.logic_js
            ) {
                const logicJs = new Function(
                    'return ' +
                        customize.logic_js
                            .replace('getRequiredActionForState', '')
                            .replace(/\r?\n|\r/g, '')
                )();

                const logicReturn = logicJs([item]);

                if (logicReturn == null) {
                    res(null);
                } else {
                    const responseParse = JSON.parse(logicReturn);
                    if (
                        this._mainDataProvider.obfEnv.additionalConfig
                            .functionality.showRedirectPopup &&
                        !responseParse.skip_popup
                    ) {
                        this._eventTrackingService.push({
                            event: 'virtual-modal-view',
                            virtualModalURL: '/booking-form/redirect-modal/',
                            virtualModalTitle: 'Booking Form Modal Redirect',
                        });

                        this.popupModalsService
                            .openRedirectPopup(responseParse)
                            .then((userAction) => {
                                if (userAction === 'redirect') {
                                    res({ ...responseParse, item });
                                } else {
                                    rej();
                                }
                            })
                            .catch((dissmisAction) => {
                                rej();
                            });
                    } else {
                        res({ ...responseParse, item });
                    }
                }
            } else {
                res(null);
            }
        });
    }

    /**
     * Choices refresher getter
     */
    public getRefreshChoicesSubscriber(): BehaviorSubject<void> {
        return this.refreshChoicesNeeded;
    }

    /**
     * Refresh choices emitter
     */
    public emitRefreshChoices(): void {
        this.refreshChoicesNeeded.next();
    }

    /**
     * Price formatter
     * @param priceValue 
     * @returns 
     */
    private formatPrice(priceValue: number): any {
        const configurationSettings: any = this._configurationService.getConfigurationSettings();

        if (configurationSettings && configurationSettings.application) {
            const applicationModule: any = configurationSettings.application;

            if (applicationModule.settings && applicationModule.settings.currency && applicationModule.settings.locale) {
                const currency: string = applicationModule.settings.currency,
                    locale: string = applicationModule.settings.locale.replace(/_/g, '-');

                return new Intl.NumberFormat(locale, {
                    style: 'currency',
                    currency
                }).format(priceValue);
            }
        } 
        
        return priceValue.toString();
    }

    /**
     * Choice item price extraction method
     * @param choiceItem 
     * @returns 
     */
    public getChoiceItemPrice(choiceItem: ChoiceItem): string {
        if (choiceItem && choiceItem.display_price) {
            if (typeof choiceItem.price !== 'number' || (choiceItem.type === 'radio' || choiceItem.type === 'checkbox')) return choiceItem.display_price;
            else return this.formatPrice(!choiceItem.value ? choiceItem.price : choiceItem.value * choiceItem.price);
        }

        return '';
    }
}
