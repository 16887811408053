
import { BackEndXrmService } from './backendservices/BackEndXrmService';
import { ParamsOptionsBuilder } from '../Helpers/ParamsOptionsBuilder';
import ObfInjector from '../Helpers/Injector';
import { tap } from 'rxjs/operators';
import { pipe } from 'rxjs';
import { SDKQueryParams } from '../Api/Interfaces/SDKQueryParams';

export class SDKAccountService {
    private _backEndXrmService: BackEndXrmService;
    private _paramsOptionsBuilder: ParamsOptionsBuilder;

    private apiPoints = {
        resetPassword: 'client/request_reset_password',
    }

    constructor() {
        this._backEndXrmService = ObfInjector.getRegistered('BackEndXrmService') as BackEndXrmService;
        this._paramsOptionsBuilder = ObfInjector.getRegistered('ParamsOptionsBuilder') as ParamsOptionsBuilder;
    }

    /**
     * Reset password functionality
     * @param data new data
     * @param queryParams http query params
     */
    public resetPassword(data: any, queryParams?: SDKQueryParams) {
        let params = this._paramsOptionsBuilder.paramBuilder(queryParams);
        params = params ? '/?' + params : '';

        return new Promise((resolve, reject) => {
            const subscriber = this._backEndXrmService
                .post(this.apiPoints.resetPassword + '/' + params, data)
                .pipe(
                    tap(resData => { })
                )
                .subscribe(
                    res => {
                        resolve(res);
                    },
                    err => {
                        reject(err);
                    },
                    () => {
                        subscriber.unsubscribe();
                    }
                );
        });
    }

}