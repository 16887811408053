import { PostMessageService } from './../post-message.service';
import { QueryParamsService } from './../query-params.service';

import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { getDomainName } from 'src/app/helpers/global-functions';
import { MainDataProvider } from 'src/app/providers/xrm/main-data.provider';
import { BatchRequestStructure } from '../../../../lib/xrm-sdk/src/lib/Api/Interfaces/BatchRequestStructure';
import { SDKBatchRequestService } from './../../../../lib/xrm-sdk/src/lib/Services/SDKBatchRequestService';
import { environment } from './../../../environments/environment';
import { AuthenticationService } from './../../services/authentication/authentication.service';
import { ErrorReportingService } from './../../services/errors/error-reporting.service';
import { BookingTransactionsService } from './booking-transactions.service';
import { ConfigurationService } from './configuration.service';
import { InitConfigDataService } from './init-config-data.service';
import { UserDataService } from './user-data.service';
import { XRMApiCommunicatorService } from './xrmapi-communicator.service';

@Injectable({
    providedIn: 'root',
})
export class InitGlobalDataService {
    private _batchRequestService: SDKBatchRequestService;

    constructor(
        private initConfigDataService: InitConfigDataService,
        private _authenticationService: AuthenticationService,
        private _configurationService: ConfigurationService,
        private _errorReportingService: ErrorReportingService,
        private _bookingTransactionService: BookingTransactionsService,
        private _XRMApiCommunicatorService: XRMApiCommunicatorService,
        private mainDataProvider: MainDataProvider,
        private queryParamsService: QueryParamsService,
    ) {
        this._batchRequestService = this._XRMApiCommunicatorService.getBatchRequestService();
    }

    public initGlobalData() {
        return new Promise(async (resolve, reject) => {
            try {
                await this.fetchInitialData();

                const queryParams = this.queryParamsService.getQueryParams();
                await this._authenticationService.isAuthorize(queryParams.csid);

                const disableReactSearch: any = this._configurationService.getPropFromConfig('disable_react_search');

                disableReactSearch && disableReactSearch.disable_react_search && this.mainDataProvider.setActiveReactSearch(false);
            } catch (err) {
                let errReportReason;
                let errReportSubject;

                if (err.hasOwnProperty('cookie')) { // check if is the error from checkLogInUser and modify repose
                    err = err.error;
                }

                if (err.fatal) {
                    this.initConfigDataService.sendFailCreateEnv(err.message);

                    reject('Fail Init Global Data');
                }

                if (err && err.hasOwnProperty('message') && err.message === 'Fail get login user') { // its not a dynamic message but works for now
                    errReportReason = err.message;
                    errReportSubject = 'Fail Get Login User';
                } else {
                    errReportReason = 'Fail to init global data';
                    errReportSubject = 'Fail Init Global Data';
                }

                const errorModel = {
                    reason: errReportReason,
                    message: err,
                },
                    reportSubject = errReportSubject;

                this._errorReportingService.report(
                    {
                        emailErrors: [errorModel],
                    },
                    false,
                    reportSubject,
                );
            }

            resolve(true);
        });
    }

    /**
     * Fetch initial data by applying provider and cache logic on each one of them
     * @returns {Promise}
     */
    // private fetchInitialData(): Promise<any> {
    private async fetchInitialData() {
        const useCache = true,
            configurationStatusObject = this._configurationService.getConfigurationStatus(useCache),
            validationsStatusObject = this._configurationService.getValidationsStatus(useCache),
            socialProvidersStatusObject = this._authenticationService.getSocialProvidersStatus();
            // leaveReasonsStatusObject = await this._bookingTransactionService.getLeaveReasonsStatus(useCache); // Note: This is the only one asynchronous method

        let batchedRequests: Array<BatchRequestStructure> = new Array(),
            configurationResponseIndex: number = null,
            validationsResponseIndex: number = null,
            socialProvidersResponseIndex: number = null,
            leaveReasonsResponseIndex: number = null,
            performedRequests: Array<{ requestName: string, requestIndex: number }> = new Array(); // This array is used to notify XRM SDK which requests are performed in the Batch Request (for validations)

        // Check Configuration
        if (configurationStatusObject && configurationStatusObject.status === 'missing') {
            // This request is first in the array
            if (!batchedRequests.length) {
                configurationResponseIndex = 0;
            } else {
                configurationResponseIndex = batchedRequests.length;
            }

            // Add to batched requests
            batchedRequests.push(
                this._batchRequestService.createBatchObject(
                    'GET',
                    'configuration',
                ),
            );

            // Add request in order to be validated by XRM SDK
            performedRequests.push(
                {
                    requestName: 'configuration',
                    requestIndex: configurationResponseIndex,
                },
            );
        }

        // Check Validations
        if (validationsStatusObject && validationsStatusObject.status === 'missing') {
            // This request is first in the array
            if (!batchedRequests.length) {
                validationsResponseIndex = 0;
            } else {
                validationsResponseIndex = batchedRequests.length;
            }

            // Add to batched requests
            batchedRequests.push(
                this._batchRequestService.createBatchObject(
                    'GET',
                    'validations',
                ),
            );

            // Add request in order to be validated by XRM SDK
            performedRequests.push(
                {
                    requestName: 'validations',
                    requestIndex: validationsResponseIndex,
                },
            );
        }

        // Check Social Providers
        if (socialProvidersStatusObject && socialProvidersStatusObject.status === 'missing') {
            // This request is first in the array
            if (!batchedRequests.length) {
                socialProvidersResponseIndex = 0;
            } else {
                socialProvidersResponseIndex = batchedRequests.length;
            }

            // Add to batched requests
            batchedRequests.push(
                this._batchRequestService.createBatchObject(
                    'GET',
                    'social_providers',
                ),
            );

            // Add request in order to be validated by XRM SDK
            performedRequests.push(
                {
                    requestName: 'socialProviders',
                    requestIndex: socialProvidersResponseIndex,
                },
            );
        }

        // Check Leave Reasons
        // if (leaveReasonsStatusObject && leaveReasonsStatusObject.status === 'missing') {
        //     // This request is first in the array
        //     if (!batchedRequests.length) {
        //         leaveReasonsResponseIndex = 0;
        //     } else {
        //         leaveReasonsResponseIndex = batchedRequests.length;
        //     }

        //     // Add to batched requests
        //     batchedRequests.push(
        //         this._batchRequestService.createBatchObject(
        //             'GET',
        //             'leave_reasons',
        //         ),
        //     );

        //     // Add request in order to be validated by XRM SDK
        //     performedRequests.push(
        //         {
        //             requestName: 'leaveReasons',
        //             requestIndex: leaveReasonsResponseIndex,
        //         },
        //     );
        // }

        return new Promise((resolve: Function, reject: Function) => {
            if (batchedRequests.length) {
                this._batchRequestService.makeBatchRequest({ requests: batchedRequests }, performedRequests)
                    .then(
                        async (batchResponse) => { // Success
                            if (batchResponse.hasOwnProperty('responses')) {
                                try {
                                    // Configuration request was performed in batch
                                    if (configurationResponseIndex || configurationResponseIndex === 0) {

                                        const configurationResponse = batchResponse.responses[configurationResponseIndex];

                                        await configurationStatusObject.processingMethod(configurationResponse, useCache);
                                    }

                                    // Validations request was performed in batch
                                    if (validationsResponseIndex || validationsResponseIndex === 0) {
                                        const validationsResponse = batchResponse.responses[validationsResponseIndex];

                                        await validationsStatusObject.processingMethod(validationsResponse, useCache);
                                    }

                                    // Social Providers request was performed in batch
                                    if (socialProvidersResponseIndex || socialProvidersResponseIndex === 0) {
                                        const socialProvidersResponse = batchResponse.responses[socialProvidersResponseIndex];

                                        await socialProvidersStatusObject.processingMethod(socialProvidersResponse);
                                    }

                                    // Leave Reasons request was performed in batch
                                    // if (leaveReasonsResponseIndex || leaveReasonsResponseIndex === 0) {
                                    //     const leaveReasonsResponse = batchResponse.responses[leaveReasonsResponseIndex];

                                    //     await leaveReasonsStatusObject.processingMethod(leaveReasonsResponse, useCache);
                                    // }

                                    resolve();
                                } catch (error) {
                                    // reject(error);
                                    reject({ message: 'Unexpected error on initial batch request', error, fatal: true });
                                }
                                // reject({ message: 'Fail get social providers', fatal: true });
                            } else {
                                // Batch Request Error
                                reject({ message: 'Initial batch request has failed', response: batchResponse, fatal: true });
                            }
                        },
                        (batchResponse) => { // Error
                            reject({ message: 'Initial batch request has failed', response: batchResponse, fatal: true });
                        },
                    );
            } else {
                resolve();
            }
        });
    }
}
