import { Injectable } from '@angular/core';

/**
 * Not in use for now
 */
@Injectable({
    providedIn: 'root',
})

export class LocalStorageService {
    constructor() {}

    private getUser(): string {
        let localStorageValue: any;
        try {
            localStorageValue =  localStorage.getItem('currentUser');
        } catch (e) {
            //..
        }

        return localStorageValue;
    }

    public setUser(value: string): void {
        try {
            localStorage.setItem('currentUser', value);
        } catch (e) {
            //..
        }
    }

    // in case of Log Out functionality
    public removeUser(): void {
        try {
            localStorage.removeItem('currentUser');
        } catch (e) {
            //..
        }
    }

    public getItem(name) {
        let localStorageValue: any;
        try {
            localStorageValue = localStorage.getItem(name);
        } catch (e) {
            //..
        }

        return localStorageValue;
    }

    public setItem(name, value) {
        try {
            localStorage.setItem(name, value);
        } catch (e) {
            //..
        }
    }
}
