/**
 * Implement Google Analytics 4 (GA4) ecommerce features using Google Tag Manager on a website.
 * GA4 ecommerce enables product list, impression, promotion, and sales data to be sent with a number of suggested Ecommerce events.
 * https://developers.google.com/tag-manager/ecommerce-ga4
 */

import { Injectable, Injector } from '@angular/core';
import { Position } from '@models/position';
import { ConfigurationData } from '@providers/configuration-data.provider';
import { MainDataProvider } from '@providers/main-data.provider';
import { UserData } from '@providers/user-data.provider';
import { environment } from '../../../environments/environment';
import { ChoicesPositions } from '../../constants/choice';
import { ErrorReportingService } from '../errors/error-reporting.service';
import { OrderData } from './../../providers/xrm/order-data.provider';
import { ServiceData } from './../../providers/xrm/service-data.provider';
import { NavigationService } from './../navigation.service';
import { DataLayerCustomDimensions } from './custom-dimensions';
import { EventTrackingService } from './event-tracking.service';
import { FullCustomDimensionsSet } from './full-custom-dimensions-set';
import { cleanPrice } from './../../helpers/helper-functions'
import { ServicesService } from '@services/services.service';

// This is a type that can be used for a partial version of the dimensions set,
// Where there are gaps and some dimensions are empty
export interface IPartialCustomDimensionsSet { [key: string]: string; }

@Injectable({
    providedIn: 'root',
})

export class TrackingCustomDimensionsService {
    private currentSet: FullCustomDimensionsSet;
    private transactionIdUpdated = null;
    private transactionTypeOnConfirmation = null;

    constructor(
        private eventTrackingService: EventTrackingService,
        private navigationService: NavigationService,
        private errorReportingService: ErrorReportingService,
        private orderData: OrderData,
        private mainDataProvider: MainDataProvider,
        private serviceData: ServiceData,
        private configurationData: ConfigurationData,
        private userDataProvider: UserData,
        private injector: Injector
    ) {

        // Enable data layer info eCommerce for all clients
        if (environment.project !== 'uspaah' && environment.project !== 'fantastic-services-nonbrand') {
           this._generateEmptyDimensionsSet();
           this.navigationService.successNavigation.subscribe((data) => {
               this.successfulNavigation(data.currentPosition, data.prevPosition);
           });
       }
      
    }

    set dimensions(someDimensions: IPartialCustomDimensionsSet) {
        Object.keys(DataLayerCustomDimensions).map((key) => DataLayerCustomDimensions[key])
            .forEach((key) => {
                this.currentSet[key] = someDimensions[key];
            });
    }

    public trigger(): void {
        this.eventTrackingService.push(this.currentSet);
    }

    private successfulNavigation(currentPositionObj: Position, previousPositionObj: Position): void {

            const obfOptions = this.mainDataProvider.getResourceObfOptions();
            let queryParams: any = {};

            queryParams['query[sections][0]'] = 'most_popular';
            
    
            if (obfOptions?.default_category_id) {
                queryParams['query[default_category_id]'] = obfOptions.default_category_id;
            }
    
            if (obfOptions.covering_postcode) {
                queryParams['query[covering_postcode]'] = obfOptions.covering_postcode;
            }

            if (currentPositionObj.position === ChoicesPositions.Init && currentPositionObj.activeChild?.position === ChoicesPositions.OnWelcome && this.orderData.formOpen) {

                const _servicesService: ServicesService = this.injector.get(ServicesService);

                _servicesService.getServiceSearchResults(queryParams)
                .then(
                    (results: any[]) => {
                        if (results) {
                            let popularItems = [];
                            let suggestedServices = results.filter((result: any) => result.type !== 'delimiter');

                            suggestedServices.forEach((item) => {
                                popularItems.push({
                                    id: item.id,
                                    name: item.title
                                })
                            });

                            const dataLayerObject = {
                                event: 'view_item_list',
                                version: 'GA4',
                                ecommerce: {
                                    item_list_id: obfOptions?.default_category_id ? obfOptions.default_category_id : 0,
                                    items: [...popularItems]
                                }
                            };
                            this.eventTrackingService.push(dataLayerObject);
                        }

                    },
                    (error: any) => {
                        //
                    },
                );
            }

            try {
                // DEBUG
                // console.log(arguments);
                let productObj = null;
                let choiceItemsInSummary = null;
                const activeData = this.orderData.activeBooking.getValue() ? this.orderData.activeBooking.getValue().get() : null;
                const obfOptions = this.mainDataProvider.getResourceObfOptions();
                const profileConfig = this.configurationData.getConfiguration();
                const userData = this.userDataProvider.getUserData();
                let transactionType = activeData && activeData?.booking_id !== 0 ? 'reschedule' : 'new booking';
               
                // 1 - Anonymous
                // 2 - Generic (register form)
                // 3 - Social (Facebook)
                // 4 - Offline
                let isUserLogged = userData && userData?.type_id !== 1  ? true : false;

                //  console.log('DEBUG:', activeData, obfOptions, userData);
                this.pageView({
                    [DataLayerCustomDimensions.Brand]: obfOptions.website_name,
                    [DataLayerCustomDimensions.SourcePhone]: obfOptions.phone,
                    [DataLayerCustomDimensions.Market]: obfOptions.country ? obfOptions.country : profileConfig.country,
                    [DataLayerCustomDimensions.Membership]: isUserLogged && userData?.membership !== null ? userData?.membership.hasOwnProperty('valid_to_formatted') ? userData?.membership['valid_to_formatted'] : null : null,
                    [DataLayerCustomDimensions.UserId]: isUserLogged ? userData.id.toString() : null,
                    [DataLayerCustomDimensions.Profile]: obfOptions.hasOwnProperty('profile_id') ? obfOptions.profile_id : obfOptions.hasOwnProperty('profile') ? obfOptions.profile : '1',
                    [DataLayerCustomDimensions.TransactionType]: transactionType,
                    [DataLayerCustomDimensions.Confirmed]: activeData && activeData?.booking_id !== 0 ? 'Yes' : 'No',
                    [DataLayerCustomDimensions.IsAnonymous]: isUserLogged ? 'No' : 'Yes',
                    [DataLayerCustomDimensions.LastPosition]: currentPositionObj.position,
                    [DataLayerCustomDimensions.Currency]: profileConfig.currency_code,
                }, currentPositionObj);
                // console.log('currentPositionObj', currentPositionObj);
                // console.log('obfOptions', obfOptions);
               
                // Set the last transaction type in the booking proccess because on confirm always will have booking_id
                if (currentPositionObj.position !== ChoicesPositions.Confirmation) {
                   this.transactionTypeOnConfirmation = transactionType;
                }

                // console.log('DEBUG:',this.transactionTypeOnConfirmation);
                if (activeData) {

                    const categoryTitle = this.getCategoryNameByServiceId(activeData.service.id);
                    const transactionPostcode = this.getPostcodeFromActiveBooking();
                    
                    // Send event based on position navigate flow / when submit after_availability and navigate forward
                    if (previousPositionObj?.position === ChoicesPositions.AfterAvailability && currentPositionObj?.index > previousPositionObj?.index) {
                        let isMembershipPurchasedInBT = false;

                        if (this.orderData.activeBooking.getValue() && this.orderData.activeBooking.getValue().get().price && this.orderData.activeBooking.getValue().get().price.price_breakdown) {
                            isMembershipPurchasedInBT = this.orderData.activeBooking.getValue().get().price.price_breakdown.find((price) => price.type === 'membership');
                        }

                        let reducedChoicesForAfterAvailability = [];

                        if (this.getTrackingChoiceItems(activeData.service.choices) && this.getTrackingChoiceItems(activeData.service.choices).length) {

                            this.getTrackingChoiceItems(activeData.service.choices).forEach((item) => {
                                reducedChoicesForAfterAvailability.push({
                                    title: item.title,
                                    value: item.value
                                })
                            });
                        }

                        const dataLayerObjectAfterAvailability = {
                            event: 'obf_after_availability',
                            version: 'GA4',
                            booking_id: activeData.id,
                            postcode: transactionPostcode,
                            service_name: activeData.service?.title,
                            service_details: reducedChoicesForAfterAvailability,
                            membership_added: isMembershipPurchasedInBT ? true : false,
                            service_redirected: false,
                            redirected_service_name: undefined
                        };

                        if (this.orderData.getServiceRedirectData().flag) {
                            dataLayerObjectAfterAvailability.service_redirected = true;
                            dataLayerObjectAfterAvailability.redirected_service_name = this.orderData.getServiceRedirectData().oldServiceTitle;
                        }

                        this.eventTrackingService.push(dataLayerObjectAfterAvailability);
                    }

                    switch (currentPositionObj.position) {

                        case ChoicesPositions.Init:

                            // Do this as soon as you have enough information to fill the data layer
                            this.currentSet = {
                                [DataLayerCustomDimensions.Brand]: obfOptions.website_name,
                                [DataLayerCustomDimensions.SourcePhone]: obfOptions.phone,
                                [DataLayerCustomDimensions.Market]: obfOptions.country ? obfOptions.country : profileConfig.country,
                                [DataLayerCustomDimensions.Membership]: isUserLogged && userData?.membership !== null ? userData?.membership.hasOwnProperty('valid_to_formatted') ? userData?.membership['valid_to_formatted'] : null : null,
                                [DataLayerCustomDimensions.UserId]: isUserLogged ? userData.id.toString() : null,
                                [DataLayerCustomDimensions.Profile]: obfOptions.hasOwnProperty('profile_id') ? obfOptions.profile_id : obfOptions.hasOwnProperty('profile') ? obfOptions.profile : '1',
                                [DataLayerCustomDimensions.TransactionType]: transactionType,
                                [DataLayerCustomDimensions.Confirmed]: activeData && activeData?.booking_id !== 0 ? 'Yes' : 'No',
                                [DataLayerCustomDimensions.IsAnonymous]: isUserLogged ? 'No' : 'Yes',
                                [DataLayerCustomDimensions.LastPosition]: currentPositionObj.position,
                                [DataLayerCustomDimensions.Currency]: profileConfig.currency_code,
                            };

                            // Do this only AFTER having set the data
                            this.trigger();
                            break;
                        case ChoicesPositions.Configurator:

                            const isTransactionAlreadyUpdatedWithEvent = this.transactionIdUpdated === activeData?.id;
                            
                            // Send ONCE per transaction unique id
                            if (!isTransactionAlreadyUpdatedWithEvent) {

                                // Send OBF CONFIG Event
                                const dataLayerObjectConfigurator: any = {
                                    event: 'obf_config',
                                    booking_id: activeData.id,
                                    version: 'GA4',
                                    postcode: transactionPostcode,
                                    service_name: activeData?.service?.title,
                                    service_redirected: this.orderData.getServiceRedirectData().flag ? true : false,
                                    redirected_service_name: this.orderData.getServiceRedirectData().flag ? this.orderData.getServiceRedirectData().oldServiceTitle : undefined
                                };

                                this.eventTrackingService.push(dataLayerObjectConfigurator);

                                this.transactionIdUpdated = activeData.id;

                                // Send VIEW ITEM Event
                                const dataLayerObjectViewItem = {
                                    event: 'view_item',
                                    version: 'GA4',
                                    booking_id: activeData.id,
                                    ecommerce: {
                                        item_category: categoryTitle,
                                        currency: profileConfig?.currency_code,
                                        value: activeData?.price?.type !== 'no_price' && activeData.price?.total ? activeData.price.total : 0,
                                        items: [{
                                            item_id: activeData.service?.id,
                                            item_name: activeData.service?.title
                                        }]
    
                                    }
                                };
                                this.eventTrackingService.push(dataLayerObjectViewItem);

                                productObj = {
                                    transaction_id: activeData.id,
                                    category: categoryTitle,
                                    id: activeData.service.id,
                                    keywords: activeData.service.keywords !== null ? activeData.service.keywords : [],
                                    price: activeData.price,
                                    title: activeData.service.title,
                                    address_display: activeData.address_display_text,
                                    variants: null,
                                };

                                this.onProductSelect(productObj);
                            }

                            break;
                        
                        case ChoicesPositions.BeforeAvailability:
                            break;
                        
                        case ChoicesPositions.OnAvailability:

                            let reducedChoicesForOnAvailability = [];
                            
                            if (this.getTrackingChoiceItems(activeData.service.choices) && this.getTrackingChoiceItems(activeData.service.choices).length) {
                                
                                this.getTrackingChoiceItems(activeData.service.choices).forEach((item) => {
                                    reducedChoicesForOnAvailability.push({
                                        title: item.title,
                                        value: item.value
                                    })
                                });
                            }
                            
                            let reducedPriceBreakdownOnAvailability = [];

                            if (activeData?.price?.price_breakdown?.length) {
                                activeData.price.price_breakdown.forEach((item) => {
                                    reducedPriceBreakdownOnAvailability.push({
                                        title: item.name,
                                        value: item.value
                                    })
                                });
                            }

                            const dataLayerObjectOnAvailability = {
                                event: 'obf_on_availability',
                                version: 'GA4',
                                booking_id: activeData.id,
                                currency: profileConfig?.currency_code,
                                price_breakdown: reducedPriceBreakdownOnAvailability,
                                postcode: transactionPostcode,
                                service_name: activeData.service?.title,
                                service_details: reducedChoicesForOnAvailability,
                                service_redirected: false,
                                redirected_service_name: undefined
                            };

                            if (this.orderData.getServiceRedirectData().flag) {
                                dataLayerObjectOnAvailability.service_redirected = true;
                                dataLayerObjectOnAvailability.redirected_service_name = this.orderData.getServiceRedirectData().oldServiceTitle;
                            }

                            this.eventTrackingService.push(dataLayerObjectOnAvailability);

                            break;
                        
                        case ChoicesPositions.AfterAvailability:
                            break;

                        case ChoicesPositions.BeforeSummary:
                            break;

                        case ChoicesPositions.OnSummary:
                            // console.log('activeData', activeData)

                            let reducedChoicesForOnSummary = [];

                            if (this.getTrackingChoiceItems(activeData.service.choices) && this.getTrackingChoiceItems(activeData.service.choices).length) {

                                this.getTrackingChoiceItems(activeData.service.choices).forEach((item) => {
                                    reducedChoicesForOnSummary.push({
                                        title: item.title,
                                        value: item.value
                                    })
                                });
                            }

                            let reducedPriceBreakdownOnSummary = [];
                            
                            if (activeData?.price?.price_breakdown?.length) {
                                activeData.price.price_breakdown.forEach((item) => {
                                    reducedPriceBreakdownOnSummary.push({
                                        title: item.name,
                                        value: item.value
                                    })
                                });
                            }

                            const dataLayerObjectOnSummary = {
                                event: 'obf_summary',
                                version: 'GA4',
                                booking_id: activeData.id,
                                postcode: transactionPostcode,
                                currency: profileConfig?.currency_code,
                                price_breakdown: reducedPriceBreakdownOnSummary,
                                service_name: activeData.service?.title,
                                service_details: reducedChoicesForOnSummary,
                                service_redirected: false,
                                redirected_service_name: undefined
                            };

                            if (this.orderData.getServiceRedirectData().flag) {
                                dataLayerObjectOnSummary.service_redirected = true;
                                dataLayerObjectOnSummary.redirected_service_name = this.orderData.getServiceRedirectData().oldServiceTitle;
                            }

                            this.eventTrackingService.push(dataLayerObjectOnSummary);

                            const servicePriceItemPurchase = activeData.price && activeData.price.hasOwnProperty('price_breakdown') ? activeData.price.price_breakdown.find((item) => {
                                return item.type === 'service_price'
                            }) : null;

                            const dataLayerObjectBeginCheckout = {
                                event: 'begin_checkout',
                                version: 'GA4',
                                booking_id: activeData.id,
                                ecommerce: {
                                    currency: profileConfig?.currency_code,
                                    value: activeData?.price?.type !== 'no_price' && activeData.price?.total ? activeData.price.total : 0,
                                    items: [{
                                        item_id: activeData.service?.id,
                                        item_name: activeData.service?.title,
                                        price: servicePriceItemPurchase?.value ? cleanPrice(servicePriceItemPurchase.value) : null
                                    }]
                                }
                            };
                            this.eventTrackingService.push(dataLayerObjectBeginCheckout);

                            choiceItemsInSummary = this.isInSummary(activeData.service.choices);

                            productObj = {
                                address_display: activeData.address_display_text, 
                                transaction_id: activeData.id,
                                category: categoryTitle,
                                id: activeData.service.id,
                                keywords: activeData.service.keywords !== null ? activeData.service.keywords : [],
                                price: activeData.price,
                                title: activeData.service.title,
                                variants: choiceItemsInSummary,
                            };

                            this.onProductAdd(productObj);
                            break;

                        case ChoicesPositions.BeforeConfirmation:

                            let reducedChoicesForBeforeConfirmation = [];

                            if (this.getTrackingChoiceItems(activeData.service.choices) && this.getTrackingChoiceItems(activeData.service.choices).length) {

                                this.getTrackingChoiceItems(activeData.service.choices).forEach((item) => {
                                    reducedChoicesForBeforeConfirmation.push({
                                        title: item.title,
                                        value: item.value
                                    })
                                });
                            }

                            const dataLayerObjectBeforeConfirmation = {
                                event: 'obf_payment',
                                version: 'GA4',
                                booking_id: activeData.id,
                                postcode: transactionPostcode,
                                service_name: activeData.service?.title,
                                service_details: reducedChoicesForBeforeConfirmation,
                                service_redirected: false,
                                redirected_service_name: undefined
                            };

                            if (this.orderData.getServiceRedirectData().flag) {
                                dataLayerObjectBeforeConfirmation.service_redirected = true;
                                dataLayerObjectBeforeConfirmation.redirected_service_name = this.orderData.getServiceRedirectData().oldServiceTitle;
                            }

                            this.eventTrackingService.push(dataLayerObjectBeforeConfirmation);

                            const servicePriceItem = activeData.price && activeData.price.hasOwnProperty('price_breakdown') ? activeData.price.price_breakdown.find((item) => {
                                return item.type === 'service_price'
                            }) : null;

                            const dataLayerObjectBeforeConfirmationPaymentInfo = {
                                event: 'add_payment_info',
                                version: 'GA4',
                                booking_id: activeData.id,
                                ecommerce: {
                                    currency: profileConfig?.currency_code,
                                    value: activeData?.price?.type !== 'no_price' && activeData.price?.total ? activeData.price.total : 0,
                                    items: [{
                                        item_id: activeData.service?.id,
                                        item_name: activeData.service?.title,
                                        price: servicePriceItem?.value ? cleanPrice(servicePriceItem.value) : null
                                    }]
                                }
                            };
                            this.eventTrackingService.push(dataLayerObjectBeforeConfirmationPaymentInfo);

                            choiceItemsInSummary = this.isInSummary(activeData.service.choices);

                            productObj = {
                                address_display: activeData.address_display_text,
                                transaction_id: activeData.id,
                                category: categoryTitle,
                                id: activeData.service.id,
                                keywords: activeData.service.keywords !== null ? activeData.service.keywords : [],
                                price: activeData.price,
                                title: activeData.service.title,
                                variants: choiceItemsInSummary,
                            };

                            this.onCheckout(productObj);
                            break;
                        case ChoicesPositions.Confirmation:
                            
                            // Reset service redirect data
                            this.orderData.setServiceRedirectData(false);

                            let reducedChoicesForConfirmation = [];

                            if (this.getTrackingChoiceItems(activeData.service.choices) && this.getTrackingChoiceItems(activeData.service.choices).length) {

                                this.getTrackingChoiceItems(activeData.service.choices).forEach((item) => {
                                    reducedChoicesForConfirmation.push({
                                        title: item.title,
                                        price: item.value
                                    })
                                });
                            }

                            if (activeData?.price?.type !== 'no_price') {

                                let reducedPriceBreakdownServiceBooked = [];
                            
                            if (activeData?.price?.price_breakdown?.length) {
                                activeData.price.price_breakdown.forEach((item) => {
                                    reducedPriceBreakdownServiceBooked.push({
                                        title: item.name,
                                        value: item.value
                                    })
                                });
                            }

                                const dataLayerObjectConfirmation = {
                                    event: 'obf_service_booked',
                                    version: 'GA4',
                                    booking_id: activeData.id,
                                    currency: profileConfig?.currency_code,
                                    price_breakdown: reducedPriceBreakdownServiceBooked,
                                    postcode: transactionPostcode,
                                    service_name: activeData.service?.title,
                                    service_details: reducedChoicesForConfirmation,
                                    service_redirected: false,
                                    redirected_service_name: undefined
                                };

                                if (this.orderData.getServiceRedirectData().flag) {
                                    dataLayerObjectConfirmation.service_redirected = true;
                                    dataLayerObjectConfirmation.redirected_service_name = this.orderData.getServiceRedirectData().oldServiceTitle;
                                }

                                this.eventTrackingService.push(dataLayerObjectConfirmation);

                                const servicePriceItemPurchase = activeData.price && activeData.price.hasOwnProperty('price_breakdown') ? activeData.price.price_breakdown.find((item) => {
                                    return item.type === 'service_price'
                                }) : null;

                                const dataLayerObjectPurchase = {
                                    event: 'purchase',
                                    version: 'GA4',
                                    booking_id: activeData.id,
                                    postcode: transactionPostcode,
                                    ecommerce: {
                                        currency: profileConfig?.currency_code,
                                        value: activeData?.price?.type !== 'no_price' && activeData.price?.total ? activeData.price.total : 0,
                                        items: [{
                                            item_id: activeData.service?.id,
                                            item_name: activeData.service?.title,
                                            price: servicePriceItemPurchase?.value ? cleanPrice(servicePriceItemPurchase.value) : null
                                        }]
                                    }
                                };
                                this.eventTrackingService.push(dataLayerObjectPurchase);

                                if (this.userDataProvider.isMembershipPurchased()) {

                                    const membershipItem =  activeData.price && activeData.price.hasOwnProperty('price_breakdown') ? activeData?.price?.price_breakdown.find((item) => {
                                        if(item.type === 'membership')
                                            return  item;
                                    }) : null;

                                    const dataLayerObjectMembPurchase = {
                                        event: 'membership_purchase',
                                        version: 'GA4',
                                        postcode: transactionPostcode,
                                        service_name: activeData.service?.title,
                                        membership_price: membershipItem?.value ? membershipItem.value : null,
                                    }
    
                                    this.eventTrackingService.push(dataLayerObjectMembPurchase);
                                }

                            } else {

                                let reducedChoicesForServiceLead = [];

                                if (this.getTrackingChoiceItems(activeData.service.choices) && this.getTrackingChoiceItems(activeData.service.choices).length) {

                                    this.getTrackingChoiceItems(activeData.service.choices).forEach((item) => {
                                        reducedChoicesForServiceLead.push({
                                            title: item.title,
                                            value: item.value
                                        })
                                    });
                                }

                                const dataLayerObjectoServiceLead = {
                                    event: 'obf_service_lead',
                                    version: 'GA4',
                                    booking_id: activeData.id,
                                    postcode: transactionPostcode,
                                    service_name: activeData.service?.title,
                                    service_details: reducedChoicesForServiceLead,
                                    service_redirected: false,
                                    redirected_service_name: undefined
                                };

                                if (this.orderData.getServiceRedirectData().flag) {
                                    dataLayerObjectoServiceLead.service_redirected = true;
                                    dataLayerObjectoServiceLead.redirected_service_name = this.orderData.getServiceRedirectData().oldServiceTitle;
                                }

                                this.eventTrackingService.push(dataLayerObjectoServiceLead);

                                const dataLayerObjectGenerateLead = {
                                    event: 'generate_lead',
                                    version: 'GA4',
                                    booking_id: activeData.id,
                                    service_name: activeData.service?.title,
                                    ecommerce: {
                                        currency: profileConfig?.currency_code,
                                        value: activeData?.price?.type !== 'no_price' && activeData.price?.total ? activeData.price.total : 0
                                    }
                                };
                                this.eventTrackingService.push(dataLayerObjectGenerateLead);
                            }

                            const membershipItem =  activeData.price && activeData.price.hasOwnProperty('price_breakdown') ? activeData?.price?.price_breakdown.find((item) => {
                                if(item.type === 'membership')
                                    return  item;
                            }) : null;

                            this.updateCustomDimensions({
                               [DataLayerCustomDimensions.TransactionType]: this.transactionTypeOnConfirmation,
                            });

                            choiceItemsInSummary = this.isInSummary(activeData.service.choices);
                       
                            productObj = {
                                category: categoryTitle,
                                coupon: activeData.voucher,
                                address_display: activeData.address_display_text,
                                // eslint-disable-next-line max-len
                                currency: profileConfig?.currency_code,
                                id: activeData.service.id,
                                keywords: activeData.service.keywords !== null ? activeData.service.keywords : [],
                                price: activeData.price,
                                shipping: null,
                                tax: null,
                                title: activeData.service.title,
                                transaction_id: activeData.id,
                                reference_number: activeData.reference_number,
                                username: activeData.hasOwnProperty('email') ? activeData.email : 'guest@guest.com',  
                                membership: isUserLogged && userData?.membership !== null ? userData?.membership.hasOwnProperty('valid_to_formatted') ? userData?.membership['valid_to_formatted'] : null : null,
                                membership_price: membershipItem?.value ? membershipItem?.value : null,
                                payment_method: activeData?.payment_method_title ? activeData?.payment_method_title : null,
                                app_time: activeData.hasOwnProperty('timeslot_formatted') ? activeData.timeslot_formatted : null,                  
                                value: activeData.price && activeData.price.hasOwnProperty('total') ? activeData.price.total : null,
                                variants: choiceItemsInSummary,
                                transaction_total: activeData.price.type !== 'no_price' && activeData.price?.total ? activeData.price.total : '0',
                            };

                            this.onPurchase(productObj);
                            break;


                        default:
                            break;
                    }
                }

            } catch (error) {
                console.error(error);
                let formattedReportMessage = '';

                if (error && error.message && error.name) {
                    // eslint-disable-next-line one-var
                    const exception: string = 'Exception: "' + error.message + '"\n',
                        reason: string = 'Caused by: ' + error.name + '\n',
                        stack: string = error.stack ? 'Stack Trace: ' + error.stack + '\n' : '';

                    formattedReportMessage = exception + reason + stack;
                } else {
                    formattedReportMessage = 'No stacktrace provided';
                }

                // eslint-disable-next-line one-var
                const errorModel = {
                    message: formattedReportMessage,
                    reason: 'Data Layer Push problem',
                },
                    reportSubject = 'Data Layer push';

                this.errorReportingService.report(
                    {
                        emailErrors: [errorModel],
                    },
                    false,
                    reportSubject,
                );
            }
    }

    private _generateEmptyDimensionsSet(): void {
        this.currentSet = {
            [DataLayerCustomDimensions.Brand]: undefined,
            [DataLayerCustomDimensions.SourcePhone]: undefined,
            [DataLayerCustomDimensions.Market]: undefined,
            [DataLayerCustomDimensions.Membership]: undefined,
            [DataLayerCustomDimensions.UserId]: undefined,
            [DataLayerCustomDimensions.Profile]: undefined,
            [DataLayerCustomDimensions.TransactionType]: undefined,
            [DataLayerCustomDimensions.Confirmed]: undefined,
            [DataLayerCustomDimensions.IsAnonymous]: undefined,
            [DataLayerCustomDimensions.LastPosition]: undefined,
            [DataLayerCustomDimensions.Currency]: undefined,
        };
    }

    private updateCustomDimensions(someDimensions: IPartialCustomDimensionsSet): void {
        this.dimensions = someDimensions;
        this.trigger();
    }

    private getCategoryNameByServiceId(serviceId: any): void {
        const categories = this.serviceData.getCategories();
        let currentServiceCategoryTitle = null;

        if (categories) {
            // Get Category Name for the service and wait to return it, then pushing the data
            const serviceCategory = categories.find((category) => {
               if (category.services) {
                   return category.services.find((service) => service.id === serviceId);
               }
            });

            currentServiceCategoryTitle = serviceCategory ? serviceCategory.title : null;

            return currentServiceCategoryTitle ? currentServiceCategoryTitle : null;
        }
    }

    private pageView(someDimensions: IPartialCustomDimensionsSet, overridePath?: any): void {
        const microStepObj = overridePath.hasOwnProperty('activeChild') ? overridePath.activeChild : overridePath;
        // console.log('microStepObj', microStepObj);

        this.dimensions = someDimensions;
        this.trigger();

        this.eventTrackingService.push({
            event: 'virtual_page_view',
            virtual_page_URL: overridePath.url,
            virtual_page_title: microStepObj.title,
        });
    }

    /**
     * Call this function when a user clicks on a product link.
     * @param productObj An object that represents the product that is clicked.
     */
    private onProductSelect(productObj): void {
        this.eventTrackingService.push({
            event: 'select_item',
            // eslint-disable-next-line 
            ecommerce: {
                address_display: productObj.address_display,
                transaction_id: productObj.transaction_id,
                selected_service: productObj.title, 
                items: [{
                    item_name: productObj.title, // Name or ID is required.
                    // eslint-disable-next-line 
                    item_id: productObj.id,
                    item_category: productObj.category,
                    item_keyword: productObj.keywords.length > 0 ? productObj.keywords[0] : null,
                    item_keyword2: productObj.keywords.length > 1 ? productObj.keywords[1] : null,
                    item_keyword3: productObj.keywords.length > 2 ? productObj.keywords[2] : null,
                    item_variant: productObj.variants,
                    quantity: 1,
                }],
            },
        });
    }

    /**
     * Call this function when a user clicks on a product link.
     * @param productObj An object that represents the product that is clicked.
     */
    private onProductAdd(productObj): void {
        // Measure when a product is added to a shopping cart
        this.eventTrackingService.push({
            event: 'add_to_cart',
            // eslint-disable-next-line 
            ecommerce: {
                transaction_id: productObj.transaction_id,
                address_display: productObj.address_display,
                selected_service: productObj.title, 
                items: [{
                    item_id: productObj.id,
                    item_name: productObj.title, // Name or ID is required.
                    price: productObj.price,
                    // eslint-disable-next-line 
                    item_category: productObj.category,
                    item_keyword: productObj.keywords.length > 0 ? productObj.keywords[0] : null,
                    item_keyword2: productObj.keywords.length > 1 ? productObj.keywords[1] : null,
                    item_keyword3: productObj.keywords.length > 2 ? productObj.keywords[2] : null,
                    item_variant: productObj.variants,
                    quantity: 1,
                }],
            },
        });
    }

    /**
     * A function to handle a click on a checkout button.
     */
    private onCheckout(productObj): void {
        this.eventTrackingService.push({
            event: 'begin_checkout',
            ecommerce: {
                transaction_id: productObj.transaction_id,
                address_display: productObj.address_display,
                selected_service: productObj.title, 
                items: [{
                    item_id: productObj.id,
                    item_name: productObj.title, // Name or ID is required.
                    price: productObj.price,
                    // eslint-disable-next-line
                    item_category: productObj.category,
                    item_keyword: productObj.keywords.length > 0 ? productObj.keywords[0] : null,
                    item_keyword2: productObj.keywords.length > 1 ? productObj.keywords[1] : null,
                    item_keyword3: productObj.keywords.length > 2 ? productObj.keywords[2] : null,
                    item_variant: productObj.variants,
                    quantity: 1,
                }],
            },
        });
    }

    /**
     * A function to handle a click on a complete booking button.
     */
    private onPurchase(productObj): void {
        this.eventTrackingService.push({
            event: 'purchase',
            ecommerce: {
                transaction_id: productObj.transaction_id,
                selected_service: productObj.title, 
                reference_number: productObj.reference_number,
                address_display: productObj.address_display,
                username: productObj.username,
                membership: productObj?.membership,
                membership_price: productObj?.membership_price,
                app_time: productObj.app_time,
                affiliation: null,
                value: productObj.value,
                payment_method: productObj?.payment_method,
                tax: productObj.tax,
                shipping: productObj.shipping,
                currency: productObj.currency,
                coupon: productObj.coupon,
                transaction_total: productObj.transaction_total, 
                items: [{
                    item_name: productObj.title,
                    item_id: productObj.id,
                    price: productObj.price,
                    item_category: productObj.category,
                    item_variant: productObj.variants,
                    quantity: 1,
                }],
            },
        });

        // reset on complete
        this.transactionTypeOnConfirmation = null;
    }

    private getTrackingChoiceItems(choices) {
        const trackingItems = [];
        if (choices && choices.length !== 0) {
            choices.forEach((choice) => {
                if (choice.choice_items) {
                    choice.choice_items.forEach((chItem) => {

                        if (chItem.choice_items && chItem.value > 0) {
                            chItem.choice_items.forEach((subChItem) => {
                                if (subChItem.value !== 0 &&
                                    subChItem.value !== '' &&
                                    subChItem.type !== 'address' &&
                                    !(
                                        typeof subChItem.value !== 'undefined'
                                        && subChItem.value !== null
                                        && subChItem.value.length !== null
                                        && subChItem.value.length === 0
                                    )) {
                                    if (subChItem.customize && subChItem.customize.add_to_data_layer) {
                             
                                        const objectData: { [key: string]: any } = {
                                            id: subChItem.id,
                                            title: subChItem.title,
                                            value: subChItem.value
                                        };

                                        if (
                                            subChItem.hasOwnProperty('display_price') &&
                                            subChItem.hasOwnProperty('display_price').length !== 0
                                        ) {
                                            objectData.price = subChItem.display_price;
                                        }

                                        trackingItems.push(objectData);
                                    }
                                }
                            });
                        }

                        if (chItem.value !== 0 &&
                            chItem.value !== '' &&
                            chItem.type !== 'address' &&
                            !(
                                typeof chItem.value !== 'undefined'
                                && chItem.value !== null
                                && chItem.value.length !== null
                                && chItem.value.length === 0
                            )
                        ) {
                            if (chItem.customize && chItem.customize.add_to_data_layer) {
                               
                                const objectData: any = {
                                    id: chItem.id,
                                    title: chItem.title,
                                    value: chItem.value
                                };

                                if (chItem.hasOwnProperty('display_price') && chItem.hasOwnProperty('display_price').length !== 0) {
                                    objectData.price = chItem.display_price;
                                }

                                trackingItems.push(objectData);
                            }
                        }
                    });
                };
            });
        }

        return trackingItems;
    }

    /**
     * Add array of choices from  Booking Transaction
     * @param choices
     * @returns Return all the choice items from the summary
     */
    private isInSummary(choices): any {
        const summaryItems = [];
        if (choices && choices.length !== 0) {
            choices.forEach((choice) => {
                if (choice.choice_items) {
                    choice.choice_items.forEach((chItem) => {

                        if (chItem.choice_items && chItem.value > 0) {
                            chItem.choice_items.forEach((subChItem) => {
                                if (subChItem.value !== 0 &&
                                    subChItem.value !== '' &&
                                    subChItem.type !== 'address' &&
                                    !(
                                        typeof subChItem.value !== 'undefined'
                                        && subChItem.value !== null
                                        && subChItem.value.length !== null
                                        && subChItem.value.length === 0
                                    )) {
                                    if (subChItem.customize && subChItem.customize.gtm_item_tracking && subChItem.customize.gtm_item_tracking !== 'default') {
                                        // summaryItems.push({ id: subChItem.id, title: subChItem.title, value: subChItem.value, price: subChItem.display_price, type: subChItem.customize.gtm_item_tracking });

                                        const objectData: { [key: string]: any } = {
                                            id: subChItem.id,
                                            title: subChItem.title,
                                            value: subChItem.value,
                                            type: subChItem.customize.gtm_item_tracking,
                                        };

                                        if (
                                            subChItem.hasOwnProperty('display_price') &&
                                            subChItem.hasOwnProperty('display_price').length !== 0
                                        ) {
                                            objectData.price = subChItem.display_price;
                                        }

                                        summaryItems.push(objectData);
                                    } else {
                                        // console.log('chItem', chItem);
                                        const choiceType = choice.type === 'cross_sell' ? 'cross_sell' : 'default';
                                        const objectData: any = {
                                            id: subChItem.id,
                                            title: subChItem.title,
                                            value: subChItem.value,
                                            type: choiceType,
                                        };

                                        if (subChItem.hasOwnProperty('display_price') && subChItem.hasOwnProperty('display_price').length !== 0) {
                                            objectData.price = subChItem.display_price;
                                        }

                                        summaryItems.push(objectData);
                                    }
                                }
                            });
                        }

                        if (chItem.value !== 0 &&
                            chItem.value !== '' &&
                            chItem.type !== 'address' &&
                            !(
                                typeof chItem.value !== 'undefined'
                                && chItem.value !== null
                                && chItem.value.length !== null
                                && chItem.value.length === 0
                            )
                        ) {
                            if (chItem.customize && chItem.customize.gtm_item_tracking && chItem.customize.gtm_item_tracking !== 'default') {
                                // summaryItems.push({ id: chItem.id, title: chItem.title, value: chItem.value, price: chItem.display_price, type: chItem.customize.gtm_item_tracking });
                                const objectData: any = {
                                    id: chItem.id,
                                    title: chItem.title,
                                    value: chItem.value,
                                    type: chItem.customize.gtm_item_tracking,
                                };

                                if (chItem.hasOwnProperty('display_price') && chItem.hasOwnProperty('display_price').length !== 0) {
                                    objectData.price = chItem.display_price;
                                }

                                summaryItems.push(objectData);
                            } else {
                                const choiceType = choice.type === 'cross_sell' ? 'cross_sell' : 'default';
                                // console.log('choice', choice.type, chItem.title, chItem.value, choiceType);
                                const objectData: any = {
                                    id: chItem.id,
                                    title: chItem.title,
                                    value: chItem.value,
                                    type: choiceType,
                                };

                                if (chItem.hasOwnProperty('display_price') && chItem.hasOwnProperty('display_price').length !== 0) {
                                    objectData.price = chItem.display_price;
                                }

                                summaryItems.push(objectData);
                                // summaryItems.push({ id: chItem.id, title: chItem.title, value: chItem.value, price: chItem.display_price, type: choiceType });
                            }
                        }
                    });
                }
            });
        }

        return summaryItems;
    }

    private getPostcodeFromActiveBooking() {
        const activeData = this.orderData.activeBooking.getValue() ? this.orderData.activeBooking.getValue().get() : null;
        const choices = activeData?.service?.choices?.length ? activeData.service.choices : null;

        if (!choices) return null;

        const initChoice = choices.find((choice) => {
            if (choice.hasOwnProperty('positions') && choice.positions) {
                return choice.positions?.indexOf(ChoicesPositions.Init) > -1;
            }
        });

        return initChoice &&
            initChoice.choice_items &&
            initChoice.choice_items.length
            ? initChoice.choice_items[0].value.postcode
            : null;
        
    }
}
