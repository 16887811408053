<div class="obf-team-wrapper">

    <div *ngIf="currentUnit; else teamView ">
        <span
            class="back-to-team-button"
            (click)="currentUnit = null"
            localizedText
            [textKeyword]="'backToTeamBtnLabel'">
            <i class="obf-icon icon-si-arrow-left"></i>
        </span>
        <app-pro [proInformation]="currentUnit" [showAddFavorite]="false"></app-pro>
    </div>

    <ng-template #teamView>
        <div class="obf-team-header">
            <div class="obf-team-avatar" [ngStyle]="{background: 'center / cover no-repeat #dbe2eb url(' + teamInformation.image_url + ')'}">
            </div>
            <div class="obf-team-info">

                <div *ngIf="teamInformation.name" class="obf-team-title">
                    <span class="title">{{ teamInformation?.name }}</span>
                </div>
                <div *ngIf="teamInformation && teamInformation.stats && teamInformation.stats.rating" class="obf-team-additional-info">
                    <span class="unit-type" localizedText [textKeyword]="'wordTeam'"></span>
                    <span class="rating" *ngIf="teamInformation?.stats?.rating"><i class="obf-icon icon-si-rating"></i> {{ teamInformation.stats.rating }}</span>
                    
                    <span class="unit-social-holder">
                        <span class="add-to-favorites" *ngIf="teamInformation.hasOwnProperty('favorite')" (click)="addAsFavorite()">
                            <i class="obf-icon icon-si-add-to-favourites{{ favoriteClassName }}"></i>
                        </span>
                    </span>
                </div>
    
                <div class="obf-line-devider-box">
                    <span class="line"></span>
                </div>

                <div *ngIf="teamInformation && teamInformation.stats && teamInformation.stats.total_bookings && teamInformation.stats.experience" class="obf-team-experience-holder">
                    <ul>
                        <li>
                            <span
                                localizedText
                                [textKeyword]="'unitTotalBookingsLabel'"
                                [insertionType]="'prepend'">
                                <i class="obf-icon icon-si-total-bookings"></i>
                                :
                            </span>
                            {{ teamInformation?.stats?.total_bookings }}
                        </li>
                        <li>
                            <span
                                localizedText
                                [textKeyword]="'unitExperienceLabel'"
                                [insertionType]="'prepend'">
                                <i class="obf-icon icon-si-clock-time-start-at"></i>
                                :
                            </span>
                            {{ teamInformation?.stats?.experience }}
                        </li>
                        <li *ngIf="teamInformation?.details?.languages">
                            <span
                                localizedText
                                [textKeyword]="'unitLanguagesLabel'"
                                [insertionType]="'prepend'">
                                <i class="obf-icon icon-si-global-planet-icon"></i>
                                :
                            </span>
                            {{ teamInformation?.details?.languages ? (teamInformation?.details?.languages.toString().replace('[','').replace(']','')) : '' }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div *ngIf="descriptionTabsCount && !(descriptionTabsCount === 1 && teamInformation && teamInformation.stats && teamInformation.stats.rating && teamInformation.stats.total_ratings)" class="obf-team-description-tabs">
            <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="obf-nav-tabs" [ngClass]="{'no-cursor': descriptionTabsCount === 1}">
                <li [ngbNavItem]="1" *ngIf="teamInformation?.badges" class="nav-item">
                    <a ngbNavLink><span localizedText [textKeyword]="'unitBadgesLabel'"></span></a>
                    <ng-template ngbNavContent>
                        <ul class="obf-badges-list" *ngIf="teamInformation?.badges">
                            <li *ngFor="let badge of teamInformation?.badges">
                                <div class="badge-box" [ngStyle]="{'background-color':  badge.background_color }">
                                    <img src="{{ badge.icon_url }}" alt="{{ badge.name }}" title="{{ badge.description }}">
                                    <span>{{ badge.name }}</span>
                                    <span *ngIf="badge.count_formatted && badge.count_formatted !== '0'" [ngStyle]="{'background-color':  badge.count_color, 'color': badge.text_color }" class="badge-counter">{{ badge.count_formatted }}</span>
                                </div>
                            </li>
                        </ul>
                    </ng-template>
                </li>
                <li [ngbNavItem]="2" *ngIf="teamInformation && teamInformation.stats && teamInformation.stats.rating && teamInformation.stats.total_ratings" class="nav-item">
                    <a ngbNavLink><span localizedText [textKeyword]="'unitRatingLabel'"></span></a>
                    <ng-template ngbNavContent>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="rating-head-holder">
                                    <span class="rating-head"><i class="obf-icon icon-si-rating"></i>{{ teamInformation?.stats?.rating }}</span>
                                    <span class="rating-subhead"><span localizedText [textKeyword]="'wordFrom'"></span> {{ teamInformation?.stats?.total_ratings }} <span localizedText [textKeyword]="'wordReviews'"></span></span>
                                </div>
                                
                            </div>
                            
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="3" *ngIf="teamInformation?.details?.documents" class="nav-item">
                    <a ngbNavLink><span localizedText [textKeyword]="'unitDocumentsLabel'"></span></a>
                    <ng-template ngbNavContent>
                        <ul class="obf-documents-list" *ngIf="teamInformation?.details?.documents">
                            <li *ngFor="let document of teamInformation?.details?.documents">
                                <span class="doc-title">{{document.name}}</span>
                                <span class="doc-type">{{document.type}}</span>
                                <span class="doc-view">
                                    <a
                                        href="{{document.url}}"
                                        target="_blank"
                                        localizedText
                                        [textKeyword]="'unitDocumentViewButtonLabel'">
                                    </a>
                                </span>
                            </li>
                        </ul>
                    </ng-template>
                </li>
                <li [ngbNavItem]="4" *ngIf="teamInformation?.units && teamInformation?.units.length" class="nav-item">
                    <a ngbNavLink><span localizedText [textKeyword]="'teamUnitsLabel'"></span></a>
                    <ng-template ngbNavContent>
                        <div class="obf-units-print">
                            <div class="row">
                                <div class="col-12 col-md-6 col-lg-4" *ngFor="let pro of teamInformation?.units">
                                    <div class="obf-pick-unit-button-box">
                                        <div class="obf-pick-unit-content pointer obf-setting_{{ pro?.id }}">
                                            <div class="obf-unit-avatar-holder">
                                                <span class="obf-pick-unit-avatar"
                                                    [ngStyle]="{background: 'center / cover no-repeat #dbe2eb url(' + pro?.image_url + ')'}"></span>
                                            </div>
                                            <span class="obf-item-title">
                                                {{ pro?.name }}
                                            </span>
        
                                            <div class="obf-unit-info-holder">
                                                <span class="unit-type">{{pro?.details?.gender}}</span>
                                            </div>
        
                                            <div class="obf-unit-action-holder">
                                                <span class="obf-pick-pro-info-icon" (click)="viewUnitInfo(pro)">View</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </ng-template>
                </li>
              </ul>
              <div [ngbNavOutlet]="nav" class="mt-2 tab-content"></div>
        </div>
    </ng-template>
</div>
