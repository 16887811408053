import { Attribute, Directive, forwardRef } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

/**
 * Validator for notEmptyString in template driven forms
 */
@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[validateEmpty][formControlName],[validateEmpty][formControl],[validateEmpty][ngModel]',
    providers: [{ provide: NG_VALIDATORS, useExisting: forwardRef(() => NotEmptyStringValidator), multi: true }],
})
export class NotEmptyStringValidator implements Validator {

    constructor(@Attribute('validateEmpty') public validateEmpty: string) {}

    /**
     * The validation for equal
     * @param {AbstractControl} control
     */
    public validate(control: AbstractControl): { [key: string]: any } {
        // self value
        if (control.value.replace(/\s/g, '').length === 0) {
            return {
                validateNotEmptyString: true,
            };
        }

        return null;
    }
}
