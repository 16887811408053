import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { Environment } from './../models/environment.model';
import { MainDataProvider } from './../providers/xrm/main-data.provider';
import { ErrorReportingService } from './errors/error-reporting.service';

declare global {
    interface Window {
        fingerprint: any;
    }
} 

@Injectable({
    providedIn: 'root',
})

export class CacheService {
    private isLSEnabled = false;

    private cacheVersion = '';

    constructor(
        private mainDataProvider: MainDataProvider,
        private http: HttpClient,
        private errorReportingService: ErrorReportingService,
    ) {
        this.isLSEnabled = this.isLocalStorageEnabled();
    }

    /**
     * Get cache method
     * @param propertyName
     */
    public getCache(propertyName: string): any {
        let cache: any;

        if (!this.isLSEnabled) {
            return undefined;
        }

        try {
            cache = JSON.parse(window.localStorage.getItem('obf-cache-' + propertyName));
        } catch (e) {
            return undefined;
        }

        if (cache) {
            cache.data.statusCode = 777;
        }

        return cache;
    }

    /**
     * Set cache method
     * @param propertyName
     * @param data
     * @param validity
     * @param path
     */
    public setCache(propertyName: string, data: any, validity: number = 1, path: string): void {

        if (!this.isLSEnabled) {
            return;
        }

        const expireDate = new Date(Date.now() + validity * 24 * 60 * 60 * 1000).toUTCString();

        const cacheEndPoint = {
            data,
            expireDate,
            path,
            propertyName,
            version: environment.VERSION,
            cacheVersion: this.cacheVersion,
            profile: this.mainDataProvider.obfEnv.XProfile,
        };

        const cachedData = JSON.stringify(cacheEndPoint);

        // TODO we will need to separate the each endpoint cache in different values and validate them one by one
        // For the tests you can use localStorage Flooder - https://arty.name/localstorage.html
        if (this.testCacheSize(cachedData)) {
            window.localStorage.setItem('obf-cache-' + propertyName, cachedData);
        }
    }

    /**
     * Check for property in cache
     * @param propertyName
     * @param path
     */
    public checkCache(propertyName: string, path: string): boolean {

        if (!this.isLSEnabled) {
            return;
        }

        const cache = this.getCache(propertyName);

        if (
            cache &&
            cache.path === path &&
            !this.isCacheExpired(cache) &&
            cache.version === environment.VERSION &&
            (cache.cacheVersion === this.cacheVersion && this.cacheVersion !== '') &&
            cache.profile === this.mainDataProvider.obfEnv.XProfile
        ) {

            return true;
        }

        return false;
    }

    /**
     * Check for client local storage capacity
     * @param value
     */
    private testCacheSize(value: any): boolean {
        try {
            window.localStorage.test = value;
            window.localStorage.removeItem('test');
            // console.log('[CACHE] Local Storage capacity is OK.');
            return true;
        } catch (e) {
            // console.log('[CACHE] Local Storage capacity is full.');
            return false;
        }
    }

    /**
     * Check for cache expiry
     * @param cache
     */
    private isCacheExpired(cache: CacheObject): boolean {
        const expireDate: Date = new Date(cache.expireDate);

        if (new Date() > expireDate) {
            return true;
        }
        return false;
    }

    /**
     * Metohod for clearing cache
     */
    public clearOldCache(): boolean {
        try {
            const localStorageEntries = Object.entries(window.localStorage);

            localStorageEntries.forEach((el) => {
                const key = el[0];

                if (key.includes('obf-cache-')) {
                    window.localStorage.removeItem(key);
                }
            });

            return true;
        } catch (error) {
            return false;
        }
    }

    /**
     * Expect profile data.
     */
    public getActualDataVersion(): Promise<any> {
        return new Promise<void>((res, rej) => {
            const env: Environment = this.mainDataProvider.obfEnv;
            if (env.env === 'localhost') {
                console.log('THE CACHE IS DISABLE FOR LOCALHOST');
                res();
                return;
            }

            // const response =  fetch('./hash-check.php?api_url=' + env.api.baseUrl + '&api_profile=' + env.XProfile + '&api_application=' + env.XApplication + '&fingerprint=' + window.fingerprint, {
            //     method: 'GET',
            //     headers: {
            //         'Content-Type': 'application/json',
            //     },
            //     keepalive: true
            // });

            this.http.get('./hash-check.php?api_url=' + env.api.baseUrl + '&api_profile=' + env.XProfile + '&api_application=' + env.XApplication + '&fingerprint=' + window.fingerprint).toPromise().then((e) => {
                // console.log(e);
            }).catch((e) => {
                // console.log(e);
            });

            this.http.get('./version_manifest.json').toPromise().then(
                (response: any) => {
                    if (response && response.hasOwnProperty(window.fingerprint)) {
                        this.cacheVersion = response[window.fingerprint];
                    }
                    res();
                },
            ).catch((error) => {
                // nothing
                res();
            });
        });
    }

    /**
     * Check if local storae is enabled or not
     */
    public isLocalStorageEnabled(): boolean {
        let localStorageEnable = false;
        try {
            localStorage.setItem('test-local-storage', 'test-data');
            localStorage.removeItem('test-local-storage');
            localStorageEnable = true;
        } catch (e) {
            console.log('[CACHE] Local Storage is disabled.');
            localStorageEnable = false;
        }

        return localStorageEnable;
    }

}

interface CacheObject {
    propertyName: string;
    data: any | { [key: string]: any };
    expireDate: string | any;
    path: string;
}
