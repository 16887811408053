import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import * as Sentry from '@sentry/angular';
import {
    MailReportInterface,
    MailReportOptionsInterface,
    MailSender,
} from '@serviceos-ng/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NGXLogger } from 'ngx-logger';
import { finalize } from 'rxjs/operators';
import { ChoicesPositions } from '../../constants/choice';
import { findChoiceItemByType } from '../../helpers/global-functions';
import { genGUID } from '../../helpers/helper-functions';
import { environment } from './../../../environments/environment';
import { MainDataProvider } from './../../providers/xrm/main-data.provider';
import { OrderData } from './../../providers/xrm/order-data.provider';
import { UserData } from './../../providers/xrm/user-data.provider';
import { NavigationService } from './../navigation.service';

declare global {
    interface Window {
        botCheck: any;
    }
}

/**
 * Need to be build
 */
@Injectable({
    providedIn: 'root',
})
export class ErrorReportingService {
    [x: string]: any;
    private path = '/shared/exceptions';
    private clientData: any = {
        name: 'N/A',
        email: 'N/A',
    };
    private activeBooking: any;
    private deviceInfo: any = null;
    private hotjarSessionId: any = null;
    private clientIp: any = null;
    private clientId: string = null;
    private appVersion: any = null;
    private reportsStack: Array<any> = [];

    // @todo catch data from booking process and send it too [pavel edit implement in sdk don't use backEndService]
    constructor(
        private _navigationService: NavigationService,
        private _userDataProvider: UserData,
        private _orderData: OrderData,
        private _mainDataProvider: MainDataProvider,
        private _deviceService: DeviceDetectorService,
        private logger: NGXLogger,
        private http: HttpClient,
        private _injector: Injector
    ) {
        this._navigationService = this._injector.get(NavigationService);
        this._mainDataProvider = this._injector.get(MainDataProvider);

        this.deviceInfo = this._deviceService.getDeviceInfo();

        this.clientIp = this.clientId = this.getClientId();

        this.getClientIp()
            .then((res) => {
                if (res) {
                    sessionStorage.removeItem('cl_gen');
                    this.clientIp = res;
                }
            })
            .catch((error) => {
                //
            });

        this.appVersion = environment.VERSION;
    }

    private getClientId(): string {
        let clientId;

        try {
            clientId = localStorage.getItem('cl_gen');
        } catch (err) {
            this.logger.debug(err);
        }

        if (!clientId) {
            try {
                clientId = sessionStorage.getItem('cl_gen');
            } catch (err) {
                this.logger.debug(err);
            }
        }

        if (!clientId) {
            // clientId don't exist
            clientId = genGUID(); // generate new client id.
        }

        // save the clientId if is posable
        try {
            localStorage.setItem('cl_gen', clientId);
        } catch (error) {
            this.logger.debug(error);
            try {
                sessionStorage.setItem('cl_gen', clientId);
            } catch (e) {
                // the session storage is disable
                this.logger.debug(e);
            }
        }

        return clientId;
    }

    /**
     * Send the error data to EXR Monitoring
     */
    private sendXRMMonitoring(data) {
        // remove backednservice;
        // this._backEndService.post(this.path, data).subscribe(
        //     response => {
        //          # console.log(response);
        //     },
        //     error => {
        //          # console.log(error);
        //     }
        // );
    }

    /**
     * Sends proper report
     * @param {object} reports contains all possible reports
     * @param {string} emailSubject optional email subject
     */
    public report(
        reports: any,
        sendToObfReport: boolean = false,
        emailSubject?: string
    ) {

        this._navigationService = this._injector.get(NavigationService);
        this._mainDataProvider = this._injector.get(MainDataProvider);

        if (this._mainDataProvider.obfEnv.env === 'localhost') return;

        console.error(reports, sendToObfReport, emailSubject);

        // Get current transaction
        this.activeBooking = this._orderData.activeBooking.getValue()
            ? this._orderData.activeBooking.getValue().get()
            : null;

        // Set user data
        this.setClientData();

        const errorNames: Array<string> = Object.keys(reports);

        // Iterate through all types of reports and send them (Monitoring Responses, Monitoring Exceptions, Email report, XRM Email)
        // for (let errorsName in reports) {
        for (
            let errorNameIndex = 0;
            errorNameIndex < errorNames.length;
            errorNameIndex++
        ) {
            const errorsName: string = errorNames[errorNameIndex];

            if (reports[errorsName].length) {
                let environmentConfig: string =
                    this._mainDataProvider.obfEnv.config.toUpperCase();
                const formState: boolean = this._orderData.getFormState();
                // eslint-disable-next-line max-len
                const currentPosition: string =
                    this._navigationService.getCurrentStep() &&
                    this._navigationService.getCurrentStep().activeChild
                        ? this._navigationService.getCurrentStep().activeChild
                              .position
                        : this._navigationService.getCurrentStep()
                        ? this._navigationService.getCurrentStep().position
                        : 'unknown';
                const currentPositionString: string = formState
                    ? `| Position ${currentPosition} `
                    : '';
                let formattedReport: any = {};
                let subject = '';
                const isBot: string = window.botCheck ? ' | BOT | ' : '';
                const projectNameString: string =
                    environment && environment.project !== 'fantastic-services'
                        ? `${environment.project.toUpperCase()} | `
                        : '';
                const additionalInfoString = `${projectNameString}${isBot}${this.clientIp}`;

                if (
                    this._mainDataProvider.obfEnv.env === 'localhost' ||
                    this._mainDataProvider.obfEnv.env === 'development' ||
                    this._mainDataProvider.obfEnv.env === 'stage'
                ) {
                    environmentConfig =
                        this._mainDataProvider.obfEnv.env.toUpperCase();
                }

                // Concat passed email subject
                if (emailSubject) {
                    // subject = emailSubject;
                    subject = `[OBF2 - ${environmentConfig}] ${additionalInfoString} | ${emailSubject} ${currentPositionString}`;
                } else {
                    // Set generic "OBF Error" subject
                    subject = `[OBF2 - ${environmentConfig}] ${additionalInfoString} | OBF Error ${currentPositionString}`;
                }

                switch (errorsName) {
                    case 'emailErrors':
                        formattedReport = this.formatEmailReport(
                            reports[errorsName]
                        );

                        const userHotjarId: any = this.getHotjarId();

                        // Add User Data and additional data to the Sentry
                        Sentry.configureScope((scope) => {
                            scope.setTag(
                                'full-domain-url',
                                formattedReport.fullDomainUrl
                            );
                            scope.setTag(
                                'current-position',
                                formattedReport.currentPosition
                            );
                            scope.setTag(
                                'service-name',
                                formattedReport.service
                            );
                            scope.setTag(
                                'website-phone',
                                formattedReport.website_phone
                            );
                            scope.setTag(
                                'app-version',
                                formattedReport.version
                            );
                            scope.setTag('hotjar-id', userHotjarId);

                            scope.setLevel(Sentry.Severity.Error);
                            scope.setExtra('parse-error', true);

                            scope.setExtra(
                                'response error',
                                formattedReport.errors
                            );
                            scope.setExtra(
                                'booking transaction',
                                formattedReport.transaction
                            );

                            // Flag the Error with the tags data
                            Sentry.captureMessage(
                                formattedReport.errors[0].reason ||
                                    formattedReport.errors[0].message
                            );
                        });

                        // if OBF is NOT open - send the report only if its important
                        if (
                            !this._orderData.formOpen &&
                            !this.isImportant(subject)
                        ) {
                            // put it in the stack for later use
                            this.reportsStack.push({
                                data: formattedReport,
                                reportOptions: {
                                    subject,
                                    emails: sendToObfReport
                                        ? ['system']
                                        : ['team'],
                                },
                            });
                        } else {
                            // Send it right away
                            this.sendReport(formattedReport, {
                                subject,
                                emails: sendToObfReport ? ['system'] : ['team'],
                            });
                        }

                        break;
                    case 'RatingSurveyEmail':
                        formattedReport = this.formatRatingSurveyEmail(
                            reports[errorsName]
                        );

                        const reportOptions: MailReportOptionsInterface = {
                            subject: emailSubject,
                            emails: [
                                'fantastic-services-nonbrand',
                                'fantastic-services',
                            ].includes(environment.project)
                                ? ['fs_rating']
                                : ['rating'],
                            reportType: 'RatingSurvey',
                        };

                        // Important - send the passed email subject, not the formatted one from above!
                        this.sendReport(formattedReport, reportOptions);

                        break;
                    case 'monitorResponseErrors':
                        // formattedReport = this.formatMonitorResponseReport(error);

                        //  Send the actual report
                        // this.sendReport(formattedReport, emailSubject);

                        break;
                    case 'monitorExceptionErrors':
                        // formattedReport = this.formatMonitorExceptionReport(error);

                        //  Send the actual report
                        // this.sendReport(formattedReport, emailSubject);

                        break;
                    default:
                }
            }
        }
    }

    /**
     * Gets client ip
     * @returns
     */
    public async getClientIp() {
        // return new Promise<boolean>(async (res, rej) => {
        //     await fetch('./get-ip.php', {
        //         method: 'GET',
        //         headers: {
        //             'Content-Type': 'application/json',
        //         },
        //         keepalive: true
        //     }).then(
        //         (response: any) => {
        //             if (response && response.client_ip) {
        //                 res(response.client_ip);
        //             } else {
        //                 res(null);
        //             }
        //         },
        //         (error) => {
        //             rej(null);
        //         }
        //     )
        // });
        // ! fix same function in error-handling service
        return new Promise<boolean>((res, rej) => {
            // If is localhost env skip fetch for client ip
            const subscription = this.http
                .get('./get-ip.php')
                .pipe(finalize(() => {}))
                .subscribe(
                    (response: any) => {
                        subscription.unsubscribe();
                        if (response && response.client_ip) {
                            res(response.client_ip);
                        } else {
                            res(null);
                        }
                    },
                    (response) => {
                        subscription.unsubscribe();
                        res(null);
                    }
                );
        });
    }

    /**
     * Method for formatting email report
     * @param {object} formattedError - formatted error object, received from ErrorHandlingService
     */
    private formatEmailReport(errors: any): any {
        const formState: boolean = this._orderData.getFormState();
        let currentPosition: string =
            this._navigationService.getCurrentStep() &&
            Object.keys(this._navigationService.getCurrentStep()).length
                ? this._navigationService.getCurrentStep().position
                : ChoicesPositions.Init;

        currentPosition = formState ? currentPosition : 'Unknown'; // Set Unknown if the form is not open yet
        // TODO set all the emails in the profile configuration
        const formattedErrorReport = {
            email: this.clientData.email, // Client email
            name: this.clientData.name, // Client name
            clientId: this.clientId ? this.clientId : 'N/A',
            postcode: this.getTransactionAddress()
                ? this.getTransactionAddress().postcode
                : 'N/A',
            currentPosition,
            hotjarSessionId: this.getHotjarId(),
            clientInfo: this.deviceInfo ? this.deviceInfo : 'N/A', // Client Browser
            service: this.activeBooking
                ? this.activeBooking.service.title
                : 'N/A',
            domainUrl: this._mainDataProvider.getResourceObfOptions().main_url
                ? this._mainDataProvider.getResourceObfOptions().main_url
                : 'N/A',
            fullDomainUrl: this._mainDataProvider.getResourceObfOptions()
                .full_domain_url
                ? this._mainDataProvider.getResourceObfOptions().full_domain_url
                : 'N/A',
            website_phone: this._mainDataProvider.getResourceObfOptions().phone
                ? this._mainDataProvider.getResourceObfOptions().phone
                : 'N/A',
            version: this.appVersion,
            errors,
            transaction: this.activeBooking,
            meta: {
                urid: null,
            },
        };

        return formattedErrorReport;
    }

    /**
     * Method for formatting XRM Email
     * @param errors
     */
    private formatRatingSurveyEmail(emailObjects): any {
        const formattedErrorReport = {
            brand: this._mainDataProvider.obfEnv.additionalConfig.logos
                .brandName,
            email: this.clientData.email, // Client email
            name: this.clientData.name, // Client name
            service: emailObjects[0].service ? emailObjects[0].service : 'N/A',
            category: emailObjects[0].category
                ? emailObjects[0].category
                : 'N/A',
            postcode: emailObjects[0].postcode
                ? emailObjects[0].postcode
                : 'N/A',
            rating: emailObjects[0].rating ? emailObjects[0].rating : 'N/A',
            feedback: emailObjects[0].feedback
                ? emailObjects[0].feedback
                : 'N/A',
            meta: {
                urid: null,
            },
        };

        return formattedErrorReport;
    }

    /**
     * Method for formatting Monitor Responses report
     * @param {array} errors
     */
    private formatMonitorResponseReport(errors: any): any {
        /**
         * TODO: Format Report for Monitoring Served Response
         */
    }

    /**
     * Method for formatting Monitor Exceptions report
     * @param {array} errors
     */
    private formatMonitorExceptionReport(errors: any): any {}

    // Get HotJar User Session ID if we have one
    private getHotjarId(): void {
        const hj = window.hj;

        try {
            this.hotjarSessionId = hj.pageVisit.property
                .get('userId')
                .split('-')
                .shift();
        } catch (e) {
            try {
                this.hotjarSessionId = hj.globals
                    .get('userId')
                    .split('-')
                    .shift();
            } catch (e) {
                this.hotjarSessionId = 'N/A';
            }
        }
        return this.hotjarSessionId;
    }

    /**
     *
     * @param {object} formattedErrorReport
     * @param {string} reportOptions 
     */
    private sendReport(
        formattedErrorReport: MailReportInterface,
        reportOptions: MailReportOptionsInterface
    ): void {
        const mailSender = new MailSender(
            environment.mailReportLocation,
            this._mainDataProvider.obfEnv.cookieKey
        );

        this.logger.debug(
            'Subject: ' + reportOptions.subject,
            'Error Report: ' + formattedErrorReport
        );

        mailSender.send(formattedErrorReport, reportOptions);
    }

    /**
     * Set client dara
     */
    private setClientData(): void {
        const userData = this._userDataProvider.getUserData();
        const clientName =
            userData && userData.first_name && userData.last_name
                ? userData.first_name + ' ' + userData.last_name
                : 'N/A';
        let clientEmail =
            userData && userData.username ? userData.username : '';

        // Get email from completed booking when user data is no available
        if (!clientEmail && this._orderData.activeBooking.getValue()) {
            const activeBooking = this._orderData.activeBooking.getValue().get();

            clientEmail = activeBooking?.email ? activeBooking.email : 'N/A';
        }

        // Reset client data
        this.clientData.name = clientName;
        this.clientData.email = clientEmail;
    }

    /**
     * Find and return recored address in booking transaction
     */
    public getTransactionAddress(): any {
        try {
            const transaction = this._orderData.activeBooking.getValue()
                ? this._orderData.activeBooking.getValue().get()
                : null;
            const transactionServiceChoices = transaction
                ? transaction.service.choices
                : null;

            if (transactionServiceChoices) {
                transactionServiceChoices.forEach((choice) => {
                    if (
                        choice.positions.indexOf(ChoicesPositions.Init) !== -1
                    ) {
                        const addressChoiceItem = findChoiceItemByType(
                            choice,
                            'address'
                        );

                        if (addressChoiceItem) {
                            return Object.assign({}, addressChoiceItem.value);
                        }
                    }
                });
            }
        } catch (error) {
            return false;
        }

        return false;
    }

    /**
     * Parse report subject for importancy
     * @param report
     */
    private isImportant(reportSubject: string): boolean {
        if (!reportSubject) {
            return false;
        }

        const keywords: Array<string> = [
            'Fail Init Global Data',
            'proxy',
            'Unexpected error',
            'JSON Response Error',
            'Invalid application',
            'Invalid profile',
            'Empty API Response',
            'Unknown language keywords in profile config',
        ];

        return !!keywords.find((word) => {
            return reportSubject.indexOf(word) !== -1;
        });
    }

    /**
     * Send all reports that has been stacked up before OBF opening
     */
    public sendStackedReports(): void {
        if (!this.reportsStack.length) {
            return;
        }

        this.reportsStack.forEach((report: any) => {
            this.sendReport(report.data, report.reportOptions);
        });

        // reset reports stack
        this.reportsStack = [];
    }
}
