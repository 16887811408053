import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SortPipe } from './sort.pipe';

@NgModule({
    declarations: [SortPipe],
    imports: [
        CommonModule,
    ],
    providers: [ // Add it in providers array in order to be able to use it in component class/service
        SortPipe,
    ],
    exports: [
        SortPipe,
    ],
})
export class SortPipeModule { }
