import { Injectable } from '@angular/core';
import { LocalizationProvider } from '@providers/localization.provider';
import { CryptoService } from '@services/crypto.service';
import { Authorize } from 'lib/xrm-sdk/src/lib/Api/ApiAuthorize';
import ObfInjector from 'lib/xrm-sdk/src/lib/Helpers/Injector';
import { BehaviorSubject, interval, Subscription } from 'rxjs';
import { popupCenter } from 'src/app/helpers/helper-functions';
import { MainDataProvider } from './../../providers/xrm/main-data.provider';
import { LoaderService } from './../loader.service';

/**
 * Declarate global property gapi in window scope / Add gapi to window property interface
 */
declare global {
    interface Window {
        gapi: any;
    }
}

/**
 * Google authentication services use in register and login components
 */
@Injectable({
    providedIn: 'root',
})
export class GoogleAuthService {
    private googleAuth = {
        login: 'ext/google-login',
        register: 'ext/google-register', // still dont have this endpoint this if we need it.
    };

    constructor(
        private mainDataProvider: MainDataProvider,
        private loaderService: LoaderService,
        private cryptoService: CryptoService,
        private _localizationProvider: LocalizationProvider,
    ) { }

    /**
     * @param requestType
     */
    public beginAuth(requestType: 'login' | 'register') {
        return new Promise((res, rej) => {
            
            const loader = this.loaderService.showLoader(this._localizationProvider.getLocalizedText('loaderMsg14'));

            const clientId = this.mainDataProvider.getSocialAuthData().googleAuthKey;
            const redirectUrl =
                this.mainDataProvider.obfEnv.api.accounts +
                (requestType === 'login' ? this.googleAuth.login : this.googleAuth.register);

            // eslint-disable-next-line max-len
            const googleAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?scope=email%20profile&include_granted_scopes=true&response_type=token&state=state_parameter_passthrough_value;&redirect_uri=${encodeURIComponent(redirectUrl)}&client_id=${clientId}`;

            let authenticationResponse;
            const googlePostMessageHandler = ((event) => {
                if (event.data.c_google_login_data) {
                    authenticationResponse = event.data.c_google_login_data;
                    googlePopup.close();
                }
            });
            // append listener for event
            window.addEventListener('message', googlePostMessageHandler);

            const centerPopup = popupCenter();
            const googlePopup = window.open(
                googleAuthUrl,
                'popUpWindow',
                'height=710,width=630,left=' + centerPopup.left + ',top=' + centerPopup.top + ',resizable=no,scrollbars=no,toolbar=no,menubar=no,location=no,directories=no, status=no',
            );

            const subscription = interval(1000).subscribe(() => {
                // Wait to close popup.
                if (googlePopup && !googlePopup.closed) { return; }

                if (authenticationResponse == null) {
                    try {
                        authenticationResponse =
                        localStorage.getItem('c_google_login_data');
                        localStorage.removeItem('c_google_login_data');
                    } catch (e) {}
                }

                if (authenticationResponse) {
                    // decryptData data.
                    const socialData: any = this.cryptoService.decryptData(authenticationResponse, this.mainDataProvider.obfEnv.cookieKey);

                    if (socialData && !socialData.error) {
                        res(socialData);
                    } else {
                        rej(socialData);
                    }
                } else {
                    rej(null);
                }

                window.removeEventListener('message', googlePostMessageHandler);
                this.loaderService.hideLoader(loader);
                subscription.unsubscribe();
            });

        });
    }
}
