import { Component, ComponentRef, HostListener, OnInit } from '@angular/core';
import { TipTrack } from '@models/tip-track';
import { EventTrackingService } from '@services/tracking/event-tracking.service';
interface TipProPayload {
	booking_id?: number;

}

@Component({
    selector: 'app-tip-pro-modal',
    templateUrl: './tip-pro-modal.component.html',
    styleUrls: ['../custom-modal/custom-modal.component.scss'],
})
export class TipProModalComponent {
	public tipProPayload: TipProPayload = null;
	private callback: Function | null = null;
	private componentRef: ComponentRef<TipProModalComponent> = null;
	private closeOnOverlayClick = false;

	@HostListener('document:click', ['$event.target']) public clickedOutside(targetElement) {
        if (targetElement && targetElement.className.indexOf('obf-modal-overlay') !== -1 && this.closeOnOverlayClick) {
            this.closeModal();
        }
    }

    constructor(
        private _eventTrackingService: EventTrackingService
    ) {}

    public onTipEvent(eventObject): void {

        if (
            eventObject &&
            eventObject.event &&
            eventObject.event === 'track'
        ) {

            if(eventObject.data) {

                const dataLayerObject: TipTrack = {
                    event: eventObject.data.event,
                    ... eventObject.data.data
                };

                this._eventTrackingService.push(dataLayerObject);
            }
        }

        if (
            eventObject &&
            eventObject.event &&
            eventObject.event === 'did_finish_tipping'
        ) {
			if (typeof this.callback === 'function') this.callback(eventObject);

            this.componentRef.destroy();
        }
    }

	/**
     * Sets data needed for popup visualisation
     * @param {object} modalData
     * @param {object} modalComponentRef reference to component itself
     */
	 public setModalData(modalComponentRef: ComponentRef<TipProModalComponent>, modalData: any) {
		if (modalData && modalData.payload) this.tipProPayload = modalData.payload;

        if (modalData.callback && typeof modalData.callback === 'function') this.callback = modalData.callback;

        // this.closeOnOverlayClick = modalData.closeOnOverlayClick ? modalData.closeOnOverlayClick : false;

        // Safe components own reference in order to destroy it
        this.componentRef = modalComponentRef;
    }

	/**
     * Destroy component by itself
     */
	 public closeModal(): void {
        if (this.callback && typeof this.callback === 'function') this.callback();

        this.componentRef.destroy();
    }
}
