import { Injectable } from '@angular/core';
import { Position } from '@models/position';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from '../../environments/environment';
import { ChoicesPositions } from '../constants/choice';
import { UserData } from '../providers/xrm/user-data.provider';
import { ConfigurationData } from './../providers/xrm/configuration-data.provider';
import { MainDataProvider } from './../providers/xrm/main-data.provider';
import { OrderData } from './../providers/xrm/order-data.provider';
import { ServiceData } from './../providers/xrm/service-data.provider';
import { ErrorReportingService } from './../services/errors/error-reporting.service';
import { NavigationService } from './navigation.service';
import { EventTrackingService } from './tracking/event-tracking.service';

const PAGES_WITHOUT_MICROSTEPS = [
    ChoicesPositions.OnAvailability,
    ChoicesPositions.Confirmation,
    ChoicesPositions.BeforeSummary,
    ChoicesPositions.BeforeAvailability,
    ChoicesPositions.BeforeConfirmation,
    ChoicesPositions.OnSummary,
];
const PAGE_EVENT = 'virtual-page-view';
const DEFAULT_ABBR = 'GORG';

@Injectable({
    providedIn: 'root',
})

export class DataLayerPush {
    private hotjarSessionId = null;
    private deviceInfo = null;
    private obfIsOpen: boolean;
    private completedSteps = [];
    private referrals = [];
    private bookingCompleted = false;
    private currentPositionIndex = null;
    private configratorMicroStep = false;
    private categoryTitle = null;

    constructor(
        private _eventTrackingService: EventTrackingService,
        private _orderData: OrderData,
        private _userDataProvider: UserData,
        private _deviceService: DeviceDetectorService,
        private _mainDataProvider: MainDataProvider,
        private _serviceDataProvider: ServiceData,
        private _errorReportingService: ErrorReportingService,
        private _navigationService: NavigationService,
        private _configurationData:ConfigurationData
    ) {
        
        // Enable data layer info only for USPAAH and NON BRAND
        if (environment.project === 'uspaah' || environment.project === 'fantastic-services-nonbrand') {
            this._navigationService.successNavigation.subscribe((data) => {
                this.successfulNavigation(data.navigationOptions, data.currentPosition, data.prevPosition);
            });
        }
        
      

        this.deviceInfo = this._deviceService.getDeviceInfo();
        this.setVisible(false);
        this.completedSteps = [];
        this.bookingCompleted = false;
        this.categoryTitle = null;
    }

    /**
     * Set OBF Visibility for the Init and Open the OBF data pushing
     * @param openObfFlag flag property
     * @returns boolean
     */
    public setVisible(openObfFlag: boolean): any {
        return (this.obfIsOpen = openObfFlag);
    }

    public successfulNavigation(currentNavigationData, currentStepData: Position, previousStepData: Position): void {

       
        try {

            // DEBUG
            // console.log(arguments);

            // console.log('DEBUG DATA Current Step: ', this.getServiceCategory(currentNavigationData, currentStepData));

            // Main Steps
            this.stepData(currentStepData, previousStepData, currentNavigationData);

            // Init MicroSteps
            if (currentStepData.position !== ChoicesPositions.Configurator) {

                // MicroStep Active Child Check
                if (currentStepData.hasOwnProperty('activeChild')) {
                    this.microStepData(currentStepData.activeChild.id, currentStepData, previousStepData);
                    this.configratorMicroStep = false;
                }
            }

            // Configurator MicroSteps
            if (currentNavigationData.hasOwnProperty('options') && currentStepData.position === ChoicesPositions.Configurator) {

                // Micro Step pathParams check for choice and get the index
                if (
                    currentNavigationData.options &&
                    currentNavigationData.options.pathParams &&
                    currentNavigationData.options.pathParams.length
                    ) {
                    const activeData = this._orderData.activeBooking.getValue() ? this._orderData.activeBooking.getValue().get() : null;

                    if (activeData) {
                        const choicesConfigurator = activeData.service.choices.filter((el) =>
                        el.positions.indexOf(ChoicesPositions.Configurator) !== -1);

                        const choiceIndex = choicesConfigurator.findIndex((choice) => {
                            return choice.id === currentNavigationData.options.pathParams[0];
                        });

                        const currentChoice = choicesConfigurator.find((choice) => {
                            return choice.id === currentNavigationData.options.pathParams[0];
                        });

                        this.microStepData(choiceIndex, currentStepData, previousStepData, currentChoice);
                        this.configratorMicroStep = true;
                    }
                }
            }

        } catch (error) {
            console.error(error);
            let formattedReportMessage = '';

            if (error && error.message && error.name) {
                const exception: string = 'Exception: "' + error.message + '"\n',
                    reason: string = 'Caused by: ' + error.name + '\n',
                    stack: string = error.stack ? 'Stack Trace: ' + error.stack + '\n' : '';

                formattedReportMessage = exception + reason + stack;
            } else {
                formattedReportMessage = 'No stacktrace provided';
            }

            const errorModel = {
                reason: 'Data Layer Push problem',
                message: formattedReportMessage,
            },
                reportSubject = 'Data Layer push';

            this._errorReportingService.report(
                {
                    emailErrors: [errorModel],
                },
                false,
                reportSubject,
            );
        }

    }


    /**
     * Send Current Position and Completed Position from the step manager
     * @param curPosition
     * @param completedPosition
     */
    private stepData(currentPosition, completedPosition, currentNavigationData?) {
        const dataLayerObject = {};
        let completedPositionStep = completedPosition ? completedPosition.position : null;
        const currentPositionStep = currentPosition ? currentPosition.position : null;

        const virtualPageTitle = currentPosition.title;

        const activeData =
            currentPositionStep !== ChoicesPositions.Init ? this._orderData.activeBooking.getValue()?.get() : null;

        const bookingType =
            currentPositionStep !== ChoicesPositions.Init && this._orderData.hasOwnProperty('bookingType') ? this._orderData.bookingType : 'normal';

        let redirect = currentPositionStep !== ChoicesPositions.Init && currentPositionStep !== ChoicesPositions.BeforeConfirmation ? this._orderData.redirect : false;
        let skipStep = currentPositionStep !== ChoicesPositions.Init && currentPositionStep !== ChoicesPositions.BeforeConfirmation ? this._orderData.skipStep : false;
        let userData = this._userDataProvider.getUserData();
        let isUserLogged = userData ? true : false;
        let obfOptions = this._mainDataProvider.getResourceObfOptions();
        let country = currentPosition.index !== -1 && obfOptions.hasOwnProperty('country') ? obfOptions.country.toUpperCase() : this._configurationData.getConfiguration().country;
        let completedStep = completedPosition ? completedPosition.slug + '-input-data' : null;
        const virtualPageReferrerSlug = completedPosition && completedPosition.hasOwnProperty('slug') ? completedPosition.slug : document.location.origin;
        const virtualPageReferrerPosition = completedPosition && completedPosition.hasOwnProperty('position') ? completedPosition.position : document.location.origin;
        const bookAgain = completedPositionStep === ChoicesPositions.Confirmation && currentPositionStep === ChoicesPositions.Configurator ? true : false;
        let choiceType = '';
        let addedCrossSells = [];

        this.categoryTitle = null;

        // Reset ActiveBooking for BookAgain
        if (bookAgain) {
            this.configratorMicroStep = false;
        }

        // BOOKING COMPLETED FLAG
        this.bookingCompleted = completedPositionStep === ChoicesPositions.Confirmation ? true : false;
        if (this.bookingCompleted) {
            this.completedSteps = []; // Reset
        }

        const checkBackPosition = this.checkPrevSteps(currentPosition.index);

        const activeDataChoice = activeData ? activeData.service.choices : null;
        const currentChoice = this.getCurrentChoice(activeDataChoice, currentNavigationData, currentPosition);

        if (Array.isArray(currentChoice)) {
            currentChoice.forEach((choice) => {
                choiceType += choice ? ' ' + choice.type + ' choice' : '';
            });
        } else {
            choiceType = currentChoice ? ' ' + currentChoice.type + ' choice' : '';
        }

        if (activeData) {
            const categories = this._serviceDataProvider.getCategories();
            let currentServiceCategoryTitle = null;

            if (categories) {
                // Get Category Name for the service and wait to return it, then pushing the data
                const serviceCategory = categories.find((category) => {
                    if (category.services) {
                        return category.services.find((service) => service.id === activeData.service.id);
                    }
                });

                currentServiceCategoryTitle = serviceCategory ? serviceCategory.title : null;

                this.categoryTitle = currentServiceCategoryTitle ? currentServiceCategoryTitle : null;
            }

        }

        // Active MicroSteps on Init
        const completePositionActive = completedPosition && completedPosition.hasOwnProperty('activeChild') ? completedPosition.activeChild : completedPosition;

        // Set completeStep to pass thrue init, fake the input
        if (redirect || bookAgain) {
            completedPositionStep = ChoicesPositions.Init;
            completedStep = ChoicesPositions.Init + '-input-data';
        }

        if (checkBackPosition) {
            completedPositionStep = null; // RESET when we go back
        }

        if (completedPositionStep !== null && currentPosition.position === completedPosition.position && !redirect && completedPositionStep !== ChoicesPositions.Confirmation) {
            completedPositionStep = null; // RESET when is microstep
        }

        // console.log(
        //     '[Main Step] Current Position: ', currentPositionStep,
        //     ' OBF Open: ', this.obfIsOpen,
        //     ' Complete Position: ', completedPositionStep,
        //     ' Booking Completed: ', this.bookingCompleted,
        //     ' Active MicroStep: ', this.configratorMicroStep,
        //     ' checkBackPosition: ', checkBackPosition
        // );

        // Completed Steps Data Pushing
        switch (completedPositionStep) {
            case ChoicesPositions.Init:
                Object.assign(dataLayerObject, {
                    event: completedStep,
                    country: country,
                    categoryName: this.categoryTitle,
                    serviceName: activeData.service.title,
                    serviceId: activeData.service.id,
                    paymentMethod: activeData.paymethod,
                    bookingType,
                    refNum: bookingType === 'reschedule' ? activeData.hasOwnProperty('reference_number') ? activeData.reference_number : null : null,
                    hotjarSessionId: this.getHotjarId(),
                    membership: isUserLogged ? userData.membership : null,
                    userId: isUserLogged ? userData.id : null,
                    isServiceRedirected: redirect,
                    isSkipStep: skipStep,
                    servType: null,
                    marChannel: obfOptions.hasOwnProperty('source_abbr') ? obfOptions.source_abbr : DEFAULT_ABBR,
                    servPhone: obfOptions.hasOwnProperty('phone') ? obfOptions.phone : null,
                    clientInfo: this.deviceInfo,
                });
                
                break;
            case ChoicesPositions.Configurator:
                const choiceItemsInSummary = this.isInSummary(activeData.service.choices);
                Object.assign(dataLayerObject, {
                    event: completedStep,
                    country: country,
                    isProUser: 'false', // TODO add flag for Pro
                    categoryName: this.categoryTitle,
                    serviceName: activeData.service.title,
                    serviceId: activeData.service.id,
                    hotjarSessionId: this.getHotjarId(),
                    userId: isUserLogged ? userData.id : null,
                    isServiceRedirected: redirect,
                    isSkipStep: skipStep,
                    marChannel: obfOptions.hasOwnProperty('source_abbr') ? obfOptions.source_abbr : DEFAULT_ABBR,
                    servType: activeData.service.type,
                    servDuration: activeData.work_time,
                    servDetails: choiceItemsInSummary,
                    bookingType,
                    clientInfo: this.deviceInfo,
                });
                break;
            case ChoicesPositions.BeforeAvailability:
                const choiceItemsInSummary2 = this.isInSummary(activeData.service.choices);
                Object.assign(dataLayerObject, {
                    event: completedStep,
                    country: country,
                    isProUser: 'false', // TODO add flag for Pro
                    categoryName: this.categoryTitle,
                    serviceName: activeData.service.title,
                    serviceId: activeData.service.id,
                    hotjarSessionId: this.getHotjarId(),
                    userId: isUserLogged ? userData.id : null,
                    isServiceRedirected: redirect,
                    isSkipStep: skipStep,
                    marChannel: obfOptions.hasOwnProperty('source_abbr') ? obfOptions.source_abbr : DEFAULT_ABBR,
                    servType: activeData.service.type,
                    servDuration: activeData.work_time,
                    servDetails: choiceItemsInSummary2,
                    bookingType,
                    clientInfo: this.deviceInfo,
                });
                break;
            case ChoicesPositions.OnAvailability:
                Object.assign(dataLayerObject, {
                    event: completedStep,
                    hasPrice: activeData.price && activeData.price.type === 'no_price' ? false : true,
                    price_breakdown: activeData.price && activeData.price.hasOwnProperty('price_breakdown') ? activeData.price.price_breakdown : null,
                    servDuration: activeData.work_time,
                    bookingType,
                });
                break;
            case ChoicesPositions.BeforeSummary:

                if (activeData.price && activeData.price.hasOwnProperty('price_breakdown')) {
                    const selectedChoiceItems = this.isInSummary(activeData.service.choices);
                    addedCrossSells = this.getCrossSaleItems(selectedChoiceItems);
                    // console.log('CROSS SALE:', addedCrossSells);
                }

                Object.assign(dataLayerObject, {
                    event: completedStep,
                    hasPrice: activeData.price && activeData.price.type === 'no_price' ? false : true,
                    successfullyAddedCrossSales: addedCrossSells.length ? addedCrossSells : '',
                    price_breakdown: activeData.price && activeData.price.hasOwnProperty('price_breakdown') ? activeData.price.price_breakdown : null,
                    servDuration: activeData.work_time,
                    bookingType,
                });

                break;
            case ChoicesPositions.OnSummary:
                Object.assign(dataLayerObject, {
                    event: completedStep,
                    hasPrice: activeData.price && activeData.price.type === 'no_price' ? false : true,
                    price_breakdown: activeData.price && activeData.price.hasOwnProperty('price_breakdown') ? activeData.price.price_breakdown : null,
                    servDuration: activeData.work_time,
                    refNum: activeData.hasOwnProperty('reference_number') ? activeData.reference_number : null,
                    app_time: activeData.hasOwnProperty('timeslot_formatted') ? activeData.timeslot_formatted : null,
                    country: country,
                    bookingType,
                });
                // console.log('DATALAYER',dataLayerObject);
                break;
            case ChoicesPositions.BeforeConfirmation:
                // Push page completed data
                Object.assign(dataLayerObject, {
                    event: completedStep,
                    bookingType,
                    promoCode: activeData.voucher,
                    membership: isUserLogged ? userData.membership : null,
                    refNum: activeData.reference_number,
                    country: country,
                    paymentType: activeData.payment_method_title ? activeData.payment_method_title : 'Call Us',
                    servOptions: activeData.service_summary,
                    price_breakdown: activeData.price && activeData.price.hasOwnProperty('price_breakdown') ? activeData.price.price_breakdown : null,
                    userId: isUserLogged ? userData.id : null,
                    username: activeData.hasOwnProperty('email') ? activeData.email : 'guest@guest.com',  
                    app_time: activeData.hasOwnProperty('timeslot_formatted') ? activeData.timeslot_formatted : null,                  
                });
                // console.log('DATALAYER',dataLayerObject);                
                break;
            case ChoicesPositions.NoCoverage: // Coverage Data if we need it
                break;
            default:
                break;
        }
        // Virtual Page View Data Push
        switch (currentPositionStep) {

            // Special Push for step 1 when we have redirects or skip steps
            case ChoicesPositions.Configurator:

                // Check is OBF Open
                if (this.obfIsOpen) {
                    // Check we hit back
                    if (!checkBackPosition) {

                        Object.assign(dataLayerObject, {
                            categoryName: this.categoryTitle,
                            servType: activeData.service.type,
                        });

                        if (!this.configratorMicroStep || redirect) {
                            // console.log(this._configurationData.getConfiguration());
                            // console.log('DATALAYER',dataLayerObject);
                            this.pushDataLayerObject(dataLayerObject);
                        }

                        // I will need it to set it here for the redirection
                        const object = {
                            event: PAGE_EVENT,
                            virtualPageSlug: '/' + currentPosition.slug + '/',
                            virtualPageTitle: virtualPageTitle + choiceType,
                            virtualPagePosition: currentPosition.position,
                            virtualPageReferrerSlug: completePositionActive ? '/' + completePositionActive.slug + '/' : null,
                            virtualPageReferrerPosition: completePositionActive ? completePositionActive.position : null,
                        };

                        if (!this.configratorMicroStep) {
                            this.pushVirtualPage(object);
                        }
                    }
                }
                break;
            case ChoicesPositions.Init:
            case ChoicesPositions.BeforeAvailability:
            case ChoicesPositions.OnAvailability:
            case ChoicesPositions.BeforeSummary:
            case ChoicesPositions.OnSummary:
            case ChoicesPositions.BeforeConfirmation:
            case ChoicesPositions.Confirmation:
                if (this.obfIsOpen) {

                    // Skip Complete Step for pushing again
                    if (!checkBackPosition) {
                        if (currentPositionStep === ChoicesPositions.OnAvailability) {
                            Object.assign(dataLayerObject, {
                                categoryName: this.categoryTitle,
                            });

                            this.pushDataLayerObject(dataLayerObject);
                            // console.log('DATALAYER',dataLayerObject);
                            // Only Push Virtual Page View for the Steps without Micro steps
                            if (PAGES_WITHOUT_MICROSTEPS.indexOf(currentPositionStep) !== -1) {
                                const object = {
                                    event: PAGE_EVENT,
                                    virtualPageSlug: '/' + currentPosition.slug + '/',
                                    virtualPageTitle,
                                    virtualPagePosition: currentPosition.position,
                                    virtualPageReferrerSlug: '/' + virtualPageReferrerSlug + '/',
                                    virtualPageReferrerPosition,
                                };
                                this.pushVirtualPage(object);
                            }
                        } else {
                            this.pushDataLayerObject(dataLayerObject);
                            // console.log('DATALAYER',dataLayerObject);

                            // Only Push Virtual Page View for the Steps without Micro steps
                            if (PAGES_WITHOUT_MICROSTEPS.indexOf(currentPositionStep) !== -1) {
                                const object = {
                                    event: PAGE_EVENT,
                                    virtualPageSlug: '/' + currentPosition.slug + '/',
                                    virtualPageTitle: virtualPageTitle + choiceType,
                                    virtualPagePosition: currentPosition.position,
                                    virtualPageReferrerSlug: '/' + virtualPageReferrerSlug + '/',
                                    virtualPageReferrerPosition,
                                };
                                this.pushVirtualPage(object);
                            }
                        }
                    } else {
                        // Only Push Virtual Page View for the Steps without Micro steps
                        if (PAGES_WITHOUT_MICROSTEPS.indexOf(currentPositionStep) !== -1) {
                            const object = {
                                event: PAGE_EVENT,
                                virtualPageSlug: '/' + currentPosition.slug + '/',
                                virtualPageTitle: virtualPageTitle + choiceType,
                                virtualPagePosition: currentPosition.position,
                                virtualPageReferrerSlug: '/' + virtualPageReferrerSlug + '/',
                                virtualPageReferrerPosition,
                            };
                            this.pushVirtualPage(object);
                        }
                    }
                }
                break;
            case ChoicesPositions.NoCoverage: // Coverage
                if (this.obfIsOpen) {
                    const object = {
                        event: PAGE_EVENT,
                        virtualPageSlug: '/' + currentPosition.slug + '/',
                        virtualPageTitle,
                        virtualPagePosition: currentPosition.position,
                        virtualPageReferrerSlug: '/' + virtualPageReferrerSlug + '/',
                        virtualPageReferrerPosition,
                    };
                    this.pushVirtualPage(object);
                    // Skip Complete Step for pushing again
                    if (!checkBackPosition) {
                        this.pushDataLayerObject(dataLayerObject);
                    }
                }
                break;
            default:
                break;
        }

        // Set Global Properties
        this.currentPositionIndex = currentPosition.index;
    }

    // Get Current Choice
    private getCurrentChoice(choices, currentNavigationData, currentPosition) {
        // console.log(currentNavigationData);
        if (choices) {
            const currentChoices = choices.filter((el) => el.positions && el.positions.indexOf(currentPosition.position) !== -1);
            // Choice on Configurator position
            if (currentPosition.position === ChoicesPositions.Configurator && currentNavigationData) {
                const currentChoiceIndex = currentChoices.findIndex((choice) => {
                    if (currentNavigationData.options && currentNavigationData.options.pathParams && currentNavigationData.options.pathParams.length) {
                        return choice.id === currentNavigationData.options.pathParams[0];
                    }
                });
                // console.log('Current Choices Configurator: ', currentChoices[currentChoiceIndex]);
                return currentChoices[currentChoiceIndex];
                // or other choices
            } else {
                // TODO build for array
                // console.log('Current Choices All: ', currentChoices);

                if (Array.isArray(currentChoices) && currentChoices.length !== 0) {
                    return currentChoices;
                } else {
                    return null;
                }
            }
        } else {
            return null;
        }
    }

    /**
     * Micros step data
     * @param microStepIndex
     */
    public microStepData(microStepIndex: number, currentPosition, completedPosition?, currentChoice?) {

        const microStepMod = this.currentPositionIndex + '-' + microStepIndex;
        let virtualPageSlug = null;
        let pushData = false;
        let virtualPageTitle = null;
        let impressionItems = null;
        let virtualPageReferrerSlug, virtualPageReferrerPosition = null;
        const checkBackPosition = this.checkPrevSteps(microStepMod);
        const redirect = currentPosition.position !== ChoicesPositions.Init && currentPosition.position !== ChoicesPositions.BeforeConfirmation ? this._orderData.redirect : false;

        const choiceType = currentChoice && currentChoice.type ? ' ' + currentChoice.type + ' choice' : '';

        if (currentChoice) {
            impressionItems = this.getSaleImpressionItems(currentChoice);
        }

        // Active MicroSteps on Init
        const hasActiveChild = currentPosition && currentPosition.hasOwnProperty('activeChild') ? true : false;
        const completePositionActive = completedPosition && completedPosition.hasOwnProperty('activeChild') ? completedPosition.activeChild : completedPosition;
        const currentPositionActive = currentPosition && currentPosition.hasOwnProperty('activeChild') ? currentPosition.activeChild : currentPosition;
        // console.log('Active Complete',completePositionActive);

        if (hasActiveChild) {

            // eslint-disable-next-line max-len
            virtualPageReferrerSlug = completePositionActive && completePositionActive.hasOwnProperty('slug') && completePositionActive.slug !== currentPositionActive.slug ? completePositionActive.slug : document.location.href;
            // eslint-disable-next-line max-len
            virtualPageReferrerPosition = completePositionActive && completePositionActive.hasOwnProperty('position') && completePositionActive.position !== currentPositionActive.position ? completePositionActive.position : document.location.href;
        } else {
            // eslint-disable-next-line max-len
            virtualPageReferrerSlug = completePositionActive && completePositionActive.hasOwnProperty('slug') ? completePositionActive.slug : document.location.href;
            // eslint-disable-next-line max-len
            virtualPageReferrerPosition = completePositionActive && completePositionActive.hasOwnProperty('position') ? completePositionActive.position : document.location.href;
        }

        // console.log(
        //     '[MICRO STEP] Current Pos: ', this.currentPositionIndex,
        //     ' Micro Step: ', microStepMod,
        //     ' Open OBF: ', this.obfIsOpen,
        //     ' HIT BACK: ', checkBackPosition,
        //     ' REDIRECT: ', redirect
        // );

        // Micro Steps Slug Mapping
        switch (microStepMod) {
            case '0-0': // coverage
            case '0-1': // before_welcome
            case '0-2': // on_welcome
                virtualPageSlug = '/' + currentPositionActive.slug + '/';
                virtualPageTitle = currentPositionActive.title;
                pushData = true;
                break;
            case '1-0': // Configurator
                virtualPageSlug = '/' + currentPosition.slug + '/';
                virtualPageTitle = currentPosition.title + choiceType;
                pushData = redirect ? true : checkBackPosition;
                break;
            case '2-0':
            case '2-1':
                pushData = false;
                break;
            default:
                virtualPageSlug = '/' + currentPosition.slug + '/';
                virtualPageTitle = currentPosition.title + choiceType;
                pushData = true;
                break;
        }

        if (pushData && this.obfIsOpen) {

            const object = {
                event: PAGE_EVENT,
                virtualPageSlug,
                virtualPageTitle,
                virtualPagePosition: currentPositionActive.position,
                virtualPageReferrerSlug: '/' + virtualPageReferrerSlug + '/',
                virtualPageReferrerPosition,
            };
            this.pushVirtualPage(object);

            // Push Sale Impression Choice Items for the current view
            if (impressionItems && impressionItems.length !== 0) {
                const saleImpressionObject = {
                    event: 'additional-sale-impression-first-step',
                    data: { impressionItems },
                };
                this.pushCustomEvents(saleImpressionObject);
            }

            pushData = false;
            this._orderData.redirect = false; // reset redirect flag

        }
    }

    /**
    * Get Service and Service Category
    * @returns Return array with choice items with Sale Impression
    */
    private getServiceCategory(currentNavigationData, currentPosition) {
        let serviceTitle = null;
        let categoryTitle = null;
        let currentPositionChoices = null;

        // Get when the booking is on Init
        if (this._serviceDataProvider && currentPosition.position === ChoicesPositions.Init) {
            const lastServiceInit: any = this._serviceDataProvider.getLastServiceInitData();

            if (lastServiceInit) {
                const serviceCategory = this._serviceDataProvider.getCategories().find((category) => {
                    return category.services && category.services.find((service) => service.id === lastServiceInit.id);
                });

                if (serviceCategory) {
                    categoryTitle = serviceCategory.title;
                }

                serviceTitle = lastServiceInit.title;

                currentPositionChoices = this.getCurrentChoice(lastServiceInit.choices, currentNavigationData, currentPosition);

            }
            // When the Service Init is empty
        } else {

            const activeData = this._orderData.activeBooking.getValue()?.get();

            if (activeData) {

                const serviceCategory = this._serviceDataProvider.getCategories().find((category) => {
                    return category.services && category.services.find((service) => service.id === activeData.service.id);
                });

                if (serviceCategory) {
                    categoryTitle = serviceCategory.title;
                }

                serviceTitle = activeData.service.title;

                currentPositionChoices = this.getCurrentChoice(activeData.service.choices, currentNavigationData, currentPosition);

            }

        }

        return { serviceTitle, categoryTitle, currentPositionChoices };
    }

    /**
     * Add one or array of choices from Booking Transaction for the current step
     * @param currentChoices
     * @returns Return array with choice items with Sale Impression
     */
    private getSaleImpressionItems(currentChoices) {
        let impressionItems = [];
        // console.log('SALE IMPRESSION', currentChoices);

        // Choices
        if (currentChoices && Array.isArray(currentChoices) && currentChoices.length !== 0) {
            currentChoices.forEach((choice) => {
                impressionItems.push(this.collectSingleChoiceSaleImpression(choice));
            });

            // or Choice
        } else {
            impressionItems = this.collectSingleChoiceSaleImpression(currentChoices);
        }

        return impressionItems;
    }

    /**
     * Dig single choice from Booking Transaction for Sale Impression
     * @param currentChoice
     * @returns Return array with choice items with Sale Impression
     */
    private collectSingleChoiceSaleImpression(currentChoice) {
        const impressionItems = [];
        // Single Choice
        if (currentChoice.hasOwnProperty('choice_items') && currentChoice.choice_items) {
            currentChoice.choice_items.forEach((chItem) => {
                // Dig Deep
                if (chItem.hasOwnProperty('choice_items') && chItem.choice_items) {
                    chItem.choice_items.forEach((subChItem) => {
                        // Get the Sale Impression choice item
                        if (typeof subChItem.value !== 'undefined' && subChItem.value !== null) {
                            if (subChItem.customize && subChItem.customize.gtm_item_tracking && subChItem.customize.gtm_item_tracking !== 'default') {
                                impressionItems.push({ id: subChItem.id, title: subChItem.title, type: subChItem.customize.gtm_item_tracking });
                                // console.log('Sale Impression choice item....', subChItem.title);
                            }
                        }
                    });
                }
                // Get the Sale Impression choice item
                if (typeof chItem.value !== 'undefined' && chItem.value !== null) {
                    if (chItem.customize && chItem.customize.gtm_item_tracking && chItem.customize.gtm_item_tracking !== 'default') {
                        impressionItems.push({ id: chItem.id, title: chItem.title, type: chItem.customize.gtm_item_tracking });
                        // console.log('Sale Impression choice item....', chItem.title);
                    }
                }

            });
        }

        return impressionItems;
    }

    /**
     * Add array of choices from  Booking Transaction
     * @param choices
     * @returns Return all the choice items from the summary
     */
    private isInSummary(choices) {
        const summaryItems = [];
        if (choices && choices.length !== 0) {
            choices.forEach((choice) => {
                if (choice.choice_items) {
                    choice.choice_items.forEach((chItem) => {

                        if (chItem.choice_items && chItem.value > 0) {
                            chItem.choice_items.forEach((subChItem) => {
                                if (subChItem.value !== 0 &&
                                    subChItem.value !== '' &&
                                    subChItem.type !== 'address' &&
                                    !(
                                        typeof subChItem.value !== 'undefined'
                                        && subChItem.value !== null
                                        && subChItem.value.length !== null
                                        && subChItem.value.length === 0
                                    )) {
                                    if (subChItem.customize && subChItem.customize.gtm_item_tracking && subChItem.customize.gtm_item_tracking !== 'default') {
                                        // summaryItems.push({ id: subChItem.id, title: subChItem.title, value: subChItem.value, price: subChItem.display_price, type: subChItem.customize.gtm_item_tracking });

                                        const objectData: { [key: string]: any } = {
                                            id: subChItem.id,
                                            title: subChItem.title,
                                            value: subChItem.value,
                                            type: subChItem.customize.gtm_item_tracking,
                                        };

                                        if (
                                            subChItem.hasOwnProperty('display_price') &&
                                            subChItem.hasOwnProperty('display_price').length !== 0
                                        ) {
                                            objectData.price = subChItem.display_price;
                                        }

                                        summaryItems.push(objectData);
                                    } else {
                                        // console.log('chItem', chItem);
                                        const choiceType = choice.type === 'cross_sell' ? 'cross_sell' : 'default';
                                        const objectData: any = {
                                            id: subChItem.id,
                                            title: subChItem.title,
                                            value: subChItem.value,
                                            type: choiceType,
                                        };

                                        if (subChItem.hasOwnProperty('display_price') && subChItem.hasOwnProperty('display_price').length !== 0) {
                                            objectData.price = subChItem.display_price;
                                        }

                                        summaryItems.push(objectData);
                                    }
                                }
                            });
                        }

                        if (chItem.value !== 0 &&
                            chItem.value !== '' &&
                            chItem.type !== 'address' &&
                            !(
                                typeof chItem.value !== 'undefined'
                                && chItem.value !== null
                                && chItem.value.length !== null
                                && chItem.value.length === 0
                            )
                        ) {
                            if (chItem.customize && chItem.customize.gtm_item_tracking && chItem.customize.gtm_item_tracking !== 'default') {
                                // summaryItems.push({ id: chItem.id, title: chItem.title, value: chItem.value, price: chItem.display_price, type: chItem.customize.gtm_item_tracking });
                                const objectData: any = {
                                    id: chItem.id,
                                    title: chItem.title,
                                    value: chItem.value,
                                    type: chItem.customize.gtm_item_tracking,
                                };

                                if (chItem.hasOwnProperty('display_price') && chItem.hasOwnProperty('display_price').length !== 0) {
                                    objectData.price = chItem.display_price;
                                }

                                summaryItems.push(objectData);
                            } else {
                                const choiceType = choice.type === 'cross_sell' ? 'cross_sell' : 'default';
                                // console.log('choice', choice.type, chItem.title, chItem.value, choiceType);
                                const objectData: any = {
                                    id: chItem.id,
                                    title: chItem.title,
                                    value: chItem.value,
                                    type: choiceType,
                                };

                                if (chItem.hasOwnProperty('display_price') && chItem.hasOwnProperty('display_price').length !== 0) {
                                    objectData.price = chItem.display_price;
                                }

                                summaryItems.push(objectData);
                                // summaryItems.push({ id: chItem.id, title: chItem.title, value: chItem.value, price: chItem.display_price, type: choiceType });
                            }
                        }
                    });
                }
            });
        }

        return summaryItems;
    }

    private getCrossSaleItems(choice) {
        const addedCrossSells = new Array();
        // console.log(choice);

        for (let index = 0; index < choice.length; index++) {
            const crossSellChoice = choice[index];

            if (crossSellChoice.type === 'cross_sell') {

                const formattedCrossSell = {};

                Object.assign(formattedCrossSell, {
                    id: crossSellChoice.id,
                    serviceName: crossSellChoice.title,
                    value: crossSellChoice.price,
                    // description: crossSellChoice.description
                });
                addedCrossSells.push(formattedCrossSell);
            }
        }

        return addedCrossSells;
    }

    /**
     * Push custom events
     * @param customEvents
     */
    private pushCustomEvents(object) {
        this._eventTrackingService.push(object);
    }

    /**
     * Push virtual page
     * @param virtualPageObject
     */
    private pushVirtualPage(virtualPageObject) {
        this._eventTrackingService.push(virtualPageObject);
    }

    /**
     * Push data layer object
     * @param dataLayerObject
     */
    private pushDataLayerObject(dataLayerObject) {
        if (!this.isEmpty(dataLayerObject)) {
            this._eventTrackingService.push(dataLayerObject);
        }
    }

    /**
     * Check for empty Object
     * @param obj
     * @returns boolean
     */
    private isEmpty(obj) {
        return !(obj && Object.keys(obj).length);
    }

    /**
     * Checks previous steps
     * @param currentStep
     * @returns boolean
     */
    private checkPrevSteps(currentStep) {

        this.completedSteps.push(currentStep);
        this.completedSteps = this.uniq(this.completedSteps);
        this.completedSteps.sort();
        let backTrigger = false;

        // Referrals URL
        this.referrals.push(currentStep);
        this.referrals = this.uniq(this.referrals);

        // Check if its MicroStep
        if (currentStep && (typeof currentStep === 'string') && (currentStep.search('-') !== -1)) {
            const maxCompletedMicroStep = this.completedSteps[this.completedSteps.length - 1]; // Get Last Step
            const stepSplit = currentStep.split('-');
            const combineNextMicroStep = stepSplit[0] + '-' + (parseInt(stepSplit[1], 10));

            // console.log('MicroStep:', combineNextMicroStep);
            // console.log('MicroStepCompleted:', maxCompletedMicroStep);
            // console.log('MicroStep Back Position Check:', maxCompletedMicroStep > combineNextMicroStep);

            if (maxCompletedMicroStep > combineNextMicroStep) {
                this.completedSteps.pop(); // remove the last step from the array
                backTrigger = true;
            }

        } else {

            const completedStepsIntOnly = this.completedSteps.reduce((count, value) => {
                if (!isNaN(value)) { count.push(Math.floor(value)); }
                return count;
            }, []);

            const maxCompletedStep = Math.max(...completedStepsIntOnly); // Get Last Step

            // console.log('Step:', currentStep);
            // console.log('StepCompleted:', maxCompletedStep);

            // remove the last step from the array becouse Coverage is bigger number from the Configuration
            // if (maxCompletedStep === 5) {
            //     this.completedSteps.pop();
            //     maxCompletedStep = Math.max(...completedStepsIntOnly);
            // }

            // console.log('Step Back Position Check:', maxCompletedStep > currentStep);

            if (maxCompletedStep > currentStep) {
                this.completedSteps.pop(); // remove the last step from the array
                backTrigger = true;
            }
        }

        if (backTrigger && this.completedSteps && PAGES_WITHOUT_MICROSTEPS.indexOf(currentStep) === -1) {
            const currentStepPosition = this.searchPosition(currentStep, this.completedSteps);
            if (currentStepPosition !== -1) {
                const corectionConstant = (currentStepPosition + 3);
                this.completedSteps.length = corectionConstant;

            }

        }

        return backTrigger;
    }

    /**
     * Uniq data layer push
     * @param a
     * @returns Return Unique Array items
     */
    private uniq(a) {
        const seen = {};
        return a.filter((item) => {
            return seen.hasOwnProperty(item) ? false : (seen[item] = true);
        });
    }

    /**
     * Return searched position from the array
     */
    private searchPosition(elementToFind, array) {
        return array.map((el) => {
            return el;
        }).indexOf(elementToFind);
    }

    /**
     * Gets hotjar id
     * @returns hotjar id
     */
    private getHotjarId(): void {
        const hj = window.hj;
        try {
            this.hotjarSessionId = hj.pageVisit.property
                .get('userId')
                .split('-')
                .shift();
        } catch (e) {
            try {
                this.hotjarSessionId = hj.globals
                    .get('userId')
                    .split('-')
                    .shift();
            } catch (e) {
                this.hotjarSessionId = 'N/A';
            }
        }
        return this.hotjarSessionId;
    }
}
