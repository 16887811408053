import { NgxLoggerLevel } from 'ngx-logger';

export * from 'src/environments/projects/uspaah/configs/index.config';

export const environment = {
    project: require('./../../angular.json').defaultProject,
    VERSION: require('../../package.json').version + '.' + require('../../package.json').build,
    envName: 'production',
    production: true,
    standardConsoleLog: false,
    apiUrl: 'http://localhost:68552/', // Replace with local API
    logLevel: NgxLoggerLevel.LOG, // TRACE|DEBUG|INFO|LOG|WARN|ERROR|FATAL|OFF
    serverLogLevel: NgxLoggerLevel.OFF, // TRACE|DEBUG|INFO|LOG|WARN|ERROR|FATAL|OFF
    integrate: false,
    mailReportLocation: 'https://web.serviceos.app/reports/'
};

