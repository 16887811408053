
interface Array<T> {
    recursiveMap(callback: Function): void;
}

interface String {
    toCamelCase(): string;
}

interface String {
    capitalize(): string;
}

Array.prototype.recursiveMap = function(callback: Function) {
    this.map((el) => {
        const arr = callback(el);
        if (arr && Array.isArray(arr)) {
            arr.recursiveMap(callback);
        }
    });
};

String.prototype.toCamelCase = function(): string {
    return this.replace(/(?:^\w|[A-Z]|-|\b\w)/g,
       (ltr, idx) => idx === 0
              ? ltr.toLowerCase()
              : ltr.toUpperCase(),
    ).replace(/\s+|-/g, '');
};

String.prototype.capitalize = function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
};
