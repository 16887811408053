<div class="obf-unit-popup {{ popupSettings.icon }}">
    <span class="obf-close-btn icon-si-close-cancel" (click)="action('exit')"></span>
    
    <div class="obf-unit-popup-header">
    </div>
    <div class="obf-unit-popup-body">
        <div [ngSwitch]="unitType" class="obf-description">

            <!-- LOADING PART -->
            <div *ngSwitchCase="'loading'" localizedText [textKeyword]="'unitLoadingText'"></div>

            <!-- TEAM PART -->
            <div *ngSwitchCase="'team'">
                <app-team 
                    [teamInformation]="popupSettings.shownData"
                    (favoriteActionPerformed)="onFavoriteActionPerformed($event)"></app-team>
            </div>

            <!-- UNIT PART -->
            <div *ngSwitchCase="'unit'">
                <app-pro 
                    [proInformation]="popupSettings.shownData"
                    (favoriteActionPerformed)="onFavoriteActionPerformed($event)"></app-pro>
            </div>

        </div>
    </div>
    <div class="obf-unit-popup-footer">
        <div class="row justify-content-lg-center">
            <div class="col-lg-6">
                <app-button 
                    *ngIf="!onlyView"
                    [isSubmitButton]="true"
                    [isFullWidth]="true"
                    [label]="unitModalCtaLabel"
                    [type]="'primary'"
                    (click)="action('confirm')"
                >
                </app-button>
            </div>
        </div>
    </div>
</div>
