/**
 * Use to store instances
 */
export default class ObfInjector {
    private static registry: { [key: string]: any } = {};

    /**
     * Will return the reference to instance important will throw error if is not declared
     * @param key
     */
    public static getRegistered(key: string): any {
        const registered = ObfInjector.registry[key];

        if (registered) {
            return registered;
        } else {
            return undefined;
            // throw new Error(`Error: ${key} was not registered.`);
        }
    }

    /**
     * Will register the instance
     * @param key
     * @param value
     */
    public static register(key: string, value: any) {
        const registered = ObfInjector.registry[key];

        if (registered) {
            throw new Error(`Error: ${key} is already registered.`);
        }
        ObfInjector.registry[key] = value;
    }
}
