import { NavigationService } from './navigation.service';
import { Injectable } from '@angular/core';
import { Choice } from '@models/choice';
import { ChoiceItem } from '@models/choice-item';
import moment from 'moment-mini';
import { BehaviorSubject, Observable } from 'rxjs';
import { ChoicesPositions } from '../constants/choice';
import { ChoicesService } from '../modules/obf-ui/choices.service';
import { MainDataProvider } from '../providers/xrm/main-data.provider';
import { BookingTransactionsService } from './xrm/booking-transactions.service';
import { findChoiceByChoiceItemType } from '../helpers/global-functions';
import { ChoiceItemsTypes } from '@constants/choice-items';
import { LocalizationProvider } from '@providers/localization.provider';

/**
 * Calculate and collect all booking details for sidebar like work time selected choice items and others
 */
@Injectable({
    providedIn: 'root',
})
export class BookingDetailsService {
    private defaultTransactionDetails;

    private _transactionDetailsBehaviorSubject: BehaviorSubject<any>;

    private sidebarPriceBreakdownAction: BehaviorSubject<String> =
        new BehaviorSubject(null);

    private slotInfoBarDetails: BehaviorSubject<any> =
        new BehaviorSubject(null);

    private sidebarLocalLoaderAction: BehaviorSubject<Boolean> =
        new BehaviorSubject(null);

    private sidebarModalAction: BehaviorSubject<Boolean> = new BehaviorSubject(
        null
    );

    constructor(
        private choicesService: ChoicesService,
        private _bookingTransactionsService: BookingTransactionsService,
        private _mainDataProvider: MainDataProvider,
        private _localizationProvider: LocalizationProvider,
        private _navigationService: NavigationService
    ) {
        this.defaultTransactionDetails = {
            postcode: {
                value: null,
            },
            workTime: {
                formatted: null,
                value: null,
                additionalInfo: {
                    durationPerWorker: null,
                    typeWorker: 'pro',
                    numberOfWorkers: null,
                },
            },
            serviceDetails: {
                value: null,
            },
            unit: {
                title: this._localizationProvider.getLocalizedText('wordUnit'),
                value: null,
            },
            appointment: {
                value: null,
            },
            infos: {
                value: null,
            },
            service: {
                title: '',
                value: '',
            },
        };

        this._transactionDetailsBehaviorSubject = new BehaviorSubject(
            JSON.parse(JSON.stringify(this.defaultTransactionDetails))
        );
    }

    get transactionDetailsSnapshot() {
        return JSON.parse(
            JSON.stringify(this._transactionDetailsBehaviorSubject.getValue())
        );
    }

    get transactionDetailsBehavior() {
        return this._transactionDetailsBehaviorSubject;
    }

    private setTransactionDetails(value) {
        // console.warn('DEBUG: ', JSON.parse(JSON.stringify(value)));
        this._transactionDetailsBehaviorSubject.next(value);
    }

    public resetTransactionDetails() {
        this.setTransactionDetails(
            JSON.parse(JSON.stringify(this.defaultTransactionDetails))
        );
    }

    public setTransactionDetailsFromBooking() {
        const bookingTransaction =
            this._bookingTransactionsService.getTransaction();

        if (!bookingTransaction) {
            throw new Error('Missing activeBooking property');
        }

        const newTransactionDetails =
            this.getTransactionDetailsFromBooking(bookingTransaction);

        // update transactionDetailsSubject
        this.setTransactionDetails(newTransactionDetails);

        // return the newTransactionDetails
        return JSON.parse(JSON.stringify(newTransactionDetails));
    }

    public getTransactionDetailsFromBooking(bookingTransaction) {
        // create new transactionDetails from defaultTransactionDetails
        const newTransactionDetails = JSON.parse(
            JSON.stringify(this.defaultTransactionDetails)
        );

        // postcode
        if (
            bookingTransaction.service.choices &&
            bookingTransaction.service.choices.length
        ) {
            newTransactionDetails.postcode.value = this.getPostcode(
                bookingTransaction.service.choices
            );
        }

        // pick unit
        if (
            bookingTransaction.service.choices &&
            bookingTransaction.service.choices.length
        ) {
            const pickUnitChoice: Choice =
                bookingTransaction.service.choices.find((choice) => {
                    return choice.type === 'pick_unit';
                });

            // Add unit only if there's selected one
            if (
                pickUnitChoice &&
                pickUnitChoice.choice_items &&
                pickUnitChoice.choice_items.length
            ) {
                const hasSelectedPickUnit: boolean =
                    pickUnitChoice.choice_items.filter(
                        (choiceItem: ChoiceItem) => {
                            const choiceItemValue: any =
                                    this._bookingTransactionsService.extractChoiceItemValue(
                                        pickUnitChoice.choice_items,
                                        choiceItem.id
                                    ),
                                isChoiceItemSelected: boolean =
                                    choiceItemValue &&
                                    !this._bookingTransactionsService.isChoiceItemEmpty(
                                        choiceItemValue
                                    );

                            return isChoiceItemSelected;
                        }
                    ).length
                        ? true
                        : false;

                if (hasSelectedPickUnit) {
                    newTransactionDetails.unit.value = pickUnitChoice;
                }
            }
        }

        // infos
        if (
            bookingTransaction.service.infos &&
            bookingTransaction.service.infos.length
        ) {
            newTransactionDetails.infos.value =
                bookingTransaction.service.infos;
        }

        // timeslot
        const availabilityChoices: Array<Choice> =
            this._bookingTransactionsService.getServiceChoices(
                ChoicesPositions.OnAvailability
            );

        if (availabilityChoices && availabilityChoices.length) {
            newTransactionDetails.appointment.value = availabilityChoices;
        }

        let choices: Array<Choice> = [];
        const currentPosition = this._navigationService.getCurrentStep();
        if (
            currentPosition.position == ChoicesPositions.Configurator ||
            currentPosition.position == ChoicesPositions.BeforeAvailability
        ) {
            choices = this._bookingTransactionsService
                .getServiceChoices()
                ?.filter(
                    (choice) =>
                        choice?.positions?.includes(
                            ChoicesPositions.Configurator
                        ) ||
                        choice?.positions?.includes(
                            ChoicesPositions.BeforeAvailability
                        )
                );
        }

        const hoursChoice = findChoiceByChoiceItemType(
            choices,
            ChoiceItemsTypes.Hour
        );

        // service hours test
        if (
            currentPosition.position == ChoicesPositions.Configurator ||
            (currentPosition.position == ChoicesPositions.BeforeAvailability &&
                hoursChoice &&
                hoursChoice?.positions?.includes(
                    ChoicesPositions.BeforeAvailability
                ))
        ) {
            newTransactionDetails.workTime.formatted = null;
            newTransactionDetails.workTime.value = null;
            newTransactionDetails.workTime.additionDetails = {
                durationPerWorker: null,
                typeWorker: 'pro',
                numberOfWorkers: null,
            };
        } else if (bookingTransaction.work_time) {
            // from transaction's work_time
            if (bookingTransaction.work_time_formatted) {
                newTransactionDetails.workTime.formatted =
                    bookingTransaction.work_time_formatted;
            }

            if (bookingTransaction.work_time == 1440) {
                newTransactionDetails.workTime.value = '24';
            } else {
                newTransactionDetails.workTime.value = moment
                    .utc()
                    .startOf('day')
                    .add({ minutes: bookingTransaction.work_time })
                    .format(
                        bookingTransaction.work_time % 60 == 0 ? 'H' : 'H:mm'
                    );
            }
        } else {
            // from service choices
            if (
                bookingTransaction.service.choices &&
                bookingTransaction.service.choices.length
            ) {
                const configuratorChoices =
                    bookingTransaction.service.choices.filter((el) => {
                        return (
                            el.positions?.indexOf(
                                ChoicesPositions.Configurator
                            ) !== -1
                        );
                    });

                const calcDurationDetails =
                    this.choicesService.calculateDuration(
                        null,
                        null,
                        configuratorChoices
                    );
                if (calcDurationDetails) {
                    console.warn(calcDurationDetails.sumDuration);
                    newTransactionDetails.workTime.value = moment
                        .utc()
                        .startOf('day')
                        .add({ minutes: calcDurationDetails.sumDuration * 60 })
                        .format(
                            calcDurationDetails.sumDuration % 1 == 0
                                ? 'H'
                                : 'H:mm'
                        ); // add moment js convert to string

                    if (calcDurationDetails.additionDetails.hoursOnWorker) {
                        newTransactionDetails.workTime.additionalInfo.durationPerWorker =
                            calcDurationDetails.additionDetails.hoursOnWorker;

                        newTransactionDetails.workTime.additionalInfo.typeWorker =
                            calcDurationDetails.additionDetails
                                .workerTypeSingularForm
                                ? calcDurationDetails.additionDetails
                                      .workerTypeSingularForm
                                : 'pro';

                        newTransactionDetails.workTime.additionalInfo.numberOfWorkers =
                            calcDurationDetails.additionDetails.numWorkers;
                    }
                }
            }
        }

        // service details
        if (
            bookingTransaction.service.choices &&
            bookingTransaction.service.choices.length
        ) {
            const configuratorChoices =
                bookingTransaction.service.choices.filter((el) => {
                    return (
                        el.positions?.indexOf(ChoicesPositions.Configurator) !==
                        -1
                    );
                });

            if (configuratorChoices && configuratorChoices.length) {
                newTransactionDetails.serviceDetails.value =
                    this.choicesService.summaryItems(configuratorChoices);
            }
        }

        // service
        if (bookingTransaction.service) {
            newTransactionDetails.service.title =
                bookingTransaction.service.title;
            newTransactionDetails.service.value = JSON.parse(
                JSON.stringify(bookingTransaction.service)
            );
        }

        // return the newTransactionDetails
        return JSON.parse(JSON.stringify(newTransactionDetails));
    }

    public getSlotInfoBarDetails(): BehaviorSubject<String> {
        return this.slotInfoBarDetails;
    }

    public setSlotInfoBarDetails(data: any):void {
        if (data) this.slotInfoBarDetails.next(data);
    }

    /**
     * Sidebar pricebreakdown action subscription getter
     * @returns
     */
    public getSidebarPriceDownActionSubscription(): BehaviorSubject<String> {
        return this.sidebarPriceBreakdownAction;
    }

    /**
     * Sidebar pricebreakdown action subscription setter
     * @param action
     */
    public setSidebarPriceBreakdownAction(action: string): void {
        if (action) this.sidebarPriceBreakdownAction.next(action);
    }

    /**
     * Sidebar local loader action subscription getter
     * @returns
     */
    public getSidebarLocalLoaderActionSubscription(): BehaviorSubject<Boolean> {
        return this.sidebarLocalLoaderAction;
    }

    /**
     *
     * @param action Sidebar local loader action setter
     */
    public setSidebarLocalLoaderAction(flag: Boolean): void {
        this.sidebarLocalLoaderAction.next(flag);
    }

    /**
     * Sidebar modal action subscription getter
     * @returns
     */
    public getSidebarModalActionSubscription(): BehaviorSubject<Boolean> {
        return this.sidebarModalAction;
    }

    /**
     *
     * @param action Sidebar modal action setter
     */
    public setSidebarModalAction(flag: Boolean): void {
        this.sidebarModalAction.next(flag);
    }

    private getPostcode(choices: any[]) {
        const initChoice = choices.find((choice) => {
            if (choice.hasOwnProperty('positions') && choice.positions) {
                return choice.positions?.indexOf(ChoicesPositions.Init) > -1;
            }
        });

        return initChoice &&
            initChoice.choice_items &&
            initChoice.choice_items.length
            ? initChoice.choice_items[0].value.postcode
            : null;
    }

    public setNewSummaryDetails(value) {
        const transactionDetails = JSON.parse(
            JSON.stringify(this._transactionDetailsBehaviorSubject.getValue())
        );

        transactionDetails.serviceDetails.value = value;

        this.setTransactionDetails(transactionDetails);
    }

    public setNewDurationDetails(calculateDuration) {
        const transactionDetails = JSON.parse(
            JSON.stringify(this._transactionDetailsBehaviorSubject.getValue())
        );

        // if (calculateDuration) {
        //     console.warn(calculateDuration.sumDuration);

        //     transactionDetails.workTime.formatted = null;
        //     transactionDetails.workTime.value =
        //         moment
        //             .utc()
        //             .startOf('day')
        //             .add({ minutes: (calculateDuration.sumDuration * 60) })
        //             .format(calculateDuration.sumDuration % 1 == 0 ? 'H':'H:mm'); // add moment js convert to string ;

        //     if (calculateDuration.additionDetails.hoursOnWorker) {
        //         transactionDetails.workTime.additionalInfo.durationPerWorker =
        //             calculateDuration.additionDetails.hoursOnWorker;
        //         transactionDetails.workTime.additionalInfo.typeWorker =
        //             calculateDuration.additionDetails.workerTypeSingularForm ?
        //                 calculateDuration.additionDetails.workerTypeSingularForm : 'pro';
        //         transactionDetails.workTime.additionalInfo.numberOfWorkers =
        //             calculateDuration.additionDetails.numWorkers;
        //     } else {
        //         transactionDetails.workTime.additionDetails = {
        //             durationPerWorker: null,
        //             typeWorker: 'pro',
        //             numberOfWorkers: null,
        //         };
        //     }
        // } else {
        transactionDetails.workTime.formatted = null;
        transactionDetails.workTime.value = null;
        transactionDetails.workTime.additionDetails = {
            durationPerWorker: null,
            typeWorker: 'pro',
            numberOfWorkers: null,
        };
        // }

        this.setTransactionDetails(transactionDetails);
    }

    public setNewPickUnit(pickUnitChoice) {
        const transactionDetails = JSON.parse(
            JSON.stringify(this._transactionDetailsBehaviorSubject.getValue())
        );

        if (
            pickUnitChoice &&
            pickUnitChoice.choice_items &&
            pickUnitChoice.choice_items.length
        ) {
            const hasSelectedPickUnit: boolean =
                pickUnitChoice.choice_items.filter((choiceItem: ChoiceItem) => {
                    const choiceItemValue: any =
                            this._bookingTransactionsService.extractChoiceItemValue(
                                pickUnitChoice.choice_items,
                                choiceItem.id
                            ),
                        isChoiceItemSelected: boolean =
                            choiceItemValue &&
                            !this._bookingTransactionsService.isChoiceItemEmpty(
                                choiceItemValue
                            );

                    return isChoiceItemSelected;
                }).length
                    ? true
                    : false;

            if (hasSelectedPickUnit) {
                const selectedUnit = pickUnitChoice.choice_items?.find(
                    (el) => el.value
                );

                transactionDetails.unit.value = pickUnitChoice;
                transactionDetails.unit.title =
                    selectedUnit?.summary_title ||
                    this._localizationProvider.getLocalizedText('wordUnit');

                this.setTransactionDetails(transactionDetails);
            } else {
                // Reset unit section
                transactionDetails.unit.value = null;

                this.setTransactionDetails(transactionDetails);
            }
        }
    }
}
