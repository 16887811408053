import { Injectable, Injector, NgZone } from '@angular/core';
import { DependencyMethod } from '@models/dependency-method';
import { ResponseError } from '@models/response-error';
import { LocalizationProvider } from '@providers/localization.provider';
import { ExceptionsService } from '@services/exceptions.service';
import { EventTrackingService } from '@services/tracking/event-tracking.service';
import { NGXLogger } from 'ngx-logger';
import { environment } from 'src/environments/environment';
import { ModalsService } from '../modals.service';
import { PopupModalsService } from '../popup-modals.service';
import { PostMessageService } from '../post-message.service';
import { SDKQueryParams } from './../../../../lib/xrm-sdk/src/lib/Api/Interfaces/SDKQueryParams';
import { ChoicesPositions } from './../../constants/choice';
import { MainDataProvider } from './../../providers/xrm/main-data.provider';
import { OrderData } from './../../providers/xrm/order-data.provider';
import { LoaderService } from './../loader.service';
import { NavigationService } from './../navigation.service';
import { ErrorReportingService } from './error-reporting.service';

@Injectable({
    providedIn: 'root',
})

/**
 *
 * TODO: Perform actual HANDLE (switch step or another action, depending on the error). Only reporting functionality for now.
 *
 */
export class ErrorHandlingService {
    private emailErrors: Array<any> = new Array();
    private monitorResponseErrors: Array<any> = new Array();
    private monitorExceptionErrors: Array<any> = new Array();
    private exceptionStatuses = null;
    private errorCodesWithoutAction: Array<number> = [
        450, // An error occurred while processing the card
        4570, // Three-D-Security Required
        5505, // Email already taken
        5511, // Your social account does not seem to be verified yet
        5507, // Invalid login request: social data or username and password required
        5512, // Social account already registered
        5117,
        5142,
        5500, // Default error code
        5502, // Invalid email
        5504,
        5538, // CLAIM ACCOUNT  Hmm.. It seems like you haven't used this email when booking your service. Please make sure that you spell your e-mail correctly or create an account instead.
        5537, // CLAIM ACCOUNT  The e-mail address you provided is already claimed. Please log in or reset your password.
        5513, // Invalid referral code
        5519,
        5530, // Email not found (reset password functionality)
        6000, // Invalid promocode
        6001, // Invalid promocode
        6002, // Invalid promocode
        6003, // Expired promocode
        6004, // Invalid promocode
        6008, // Invalid promocode for current location
        6009, // Promocode cannot be applied (ex: promocode is for members only or the opposite)
        6010, // This promo code does not apply to your service preferences.
        6013, // Sorry! This promo code is not valid. Please check the details of your promo code
        6015, // Promocode already redeemed
        6016, // Sorry! This promo code has reached the limit of usage
        6020, // Promo code cannot be applied to this rescheduled booking
        6029, // You have used this voucher for the service already!
        6500, // Time slot is unavailable
        6501, // Time slots for this service tend to get booked very quickly and this one is no longer available
        6502,
        6503, // Time slot for the selected unit is no longer available
        6550, // Unit is not available anymore
        6710, // Invalid transaction token
        // 6711, // Invalid service
        6712, // Invalid address
        6714, // Postcode not covered
        6720, // Client Miss match
        6725, // Selected choice item does not belong to the service
        6726, // Choice item required
        6732,
        6734,
        6741,
        6745,
        6746, // Block user
        6761, // outstanding payments
        6727, // The number you have entered is incomplete or incorrect. Please try again
        // 7003, // Wrong postcode
        7020, // An error occurred while processing with not supported used card
        7021, // An error occurred while processing with not supported new card
        7025, // Time slot is for members only
        9501, // The transaction was declined. Please use a different card or contact your bank.
        91000, //
    ];
    private depMethods: Array<DependencyMethod> = new Array();

    public constructor(
        private _errorReportingService: ErrorReportingService,
        private _mainDataProvider: MainDataProvider,
        private _localizationProvider: LocalizationProvider,
        private _eventTrackingService: EventTrackingService,
        private _injector: Injector,
        private logger: NGXLogger,
        private ngZone: NgZone,
    ) {
    }

    /**
     * Generic method for logging error
     * @param {string} requestType - the type of request made
     * @param {object} response - received response object
     */
    public handle(requestType: string, response: any) {
        if (!this._mainDataProvider || this._mainDataProvider.obfEnv.env === 'development' || this._mainDataProvider.obfEnv.env === 'stage' || this._mainDataProvider.obfEnv.env === 'production') {
            switch (requestType) {
                case 'jsError':
                    this.handleJsError(response);
                    break;
                case 'ChunkLoadError':
                    this.handleChunkFailLoad(response);
                    break;
                case 'apiError':
                    this.handleApiError(response);

                    if (response.decoder && response.decoder.error) { // TODO add warning validation in progress (response.decoder.error || response.decoder.warning)
                        this.handlerApiStructureErrors(response);
                    }

                    break;
            }
        }
    }

    private offlineStateCheck() {
        return navigator.onLine;
    }

    private handlerApiStructureErrors(response) {
        const errorModel = {
            reason: 'Missing property',
            message: { response },
        };

        this._errorReportingService.report(
            {
                emailErrors: [errorModel],
            },
            false,
            'Missing important property',
        );
    }

    /**
     * Method handler for javascript errors
     * @param {object} error
     */
    private handleJsError(error: Error | any) {
        const exception: string = 'Exception: "' + error.message + '"\n',
            reason: string = 'Caused by: ' + error.name + '\n',
            stack: string = error.stack ? 'Stack Trace: ' + error.stack + '\n' : '',
            startIndex = 0,
            endIndex: number = error.message.indexOf('\n') > 0 ? error.message.indexOf('\n') : error.message.length,
            reportSubject = `JS Error: ${error.message.substring(startIndex, endIndex).trim()}`,
            formattedReportMessage: string = exception + reason + stack,
            reportObject: any = {
                exception: formattedReportMessage,
                message: 'Javascript error',
            };

        this.emailErrors.push(reportObject);

        // Detect Connection Error, stop JavaScript Spamming for error
        if (this.offlineStateCheck()) {
            try {
                // Send report if there's any found
                if (this.emailErrors.length || this.monitorResponseErrors.length || this.monitorExceptionErrors.length) {
                    this._errorReportingService.report(
                        {
                            emailErrors: this.emailErrors,
                            monitorResponseErrors: this.monitorResponseErrors,
                            monitorExceptionErrors: this.monitorExceptionErrors,
                        },
                        false,
                        reportSubject,
                    );
                }
            } catch (error) {
                console.warn('ErrorReportingService Error - ', error);
            }
        }

        const exceptionData: any = {
            message: error.message.substring(startIndex, endIndex).trim(),
            stacktrace: error.stack.split('\n').slice(0, 4).join('\n') // get first 4 lines from stacktrace
        };

        this.sendException(exceptionData);

        // Clear errors
        this.clearErrors();
    }

    private handleChunkFailLoad(response) {
        // send the error.
        const _postMessageService: PostMessageService = this._injector.get(PostMessageService);
        const _modalsService: ModalsService = this._injector.get(ModalsService);
        const loader: LoaderService = this._injector.get(LoaderService);
        const _orderData: OrderData = this._injector.get(OrderData);
        const errorModel = {
            reason: 'ChunkLoadError',
            message: 'Exception: "' + response.message + '"\n',
        };

        this._errorReportingService.report(
            {
                emailErrors: [errorModel],
            },
            false,
            'ChunkLoadError',
        );

        if (_postMessageService.obfCurrentState === 'close') {
            _postMessageService.sendState('error', {
                errorType: 'ChunkLoadError',
                openModal: true,
            });
            return;
        }

        const modalData = {
            message: 'Something went wrong! Please, check your internet connection and try again!',
            buttons: [
                {
                    label: 'OK',
                    closeOnClick: true,
                    type: 'primary',
                    state: 'default',
                    size: 'normal',
                    action: () => {
                        const loaderObject = loader.showLoader();
                        _postMessageService.sendState('error', {
                            errorType: 'ChunkLoadError',
                            openModal: false,
                        });
                    },
                },
            ]
        };

        // _modalsService.open('custom', modalData);
        // run the function in ngZone to trigger change detection.
        this.ngZone.runTask(
            _modalsService.open, _modalsService, ['custom', modalData],
        );
    }

    /**
     * Method handler for API Error (XRM validations and more)
     * @param {object} error
     */
    private handleApiError(response: any) {

        const statusCode: number = response.statusCode,
            errors: ResponseError[] = response.error || [],
            _orderData: OrderData = this._injector.get(OrderData);

        switch (statusCode) {
            // Cached request
            case 777: // ! TODO:
                break;
            // Request Timeout
            case -1:
                if (errors && (_orderData && _orderData.getFormState())) {
                    for (let index = 0; index < errors.length; index++) {
                        const error = errors[index];
                        const reportObject = {
                            status: -1,
                            exceptionStatus: 'RequestTimeoutException',
                            message: error.message ? error.message : 'N/A',
                        };

                        // Attach 'failedRequest' property
                        if (error.hasOwnProperty('failedRequest') && error.failedRequest) {
                            Object.assign(reportObject, {
                                failedRequest: error.failedRequest,
                            });
                        }

                        // Attach 'response' property
                        if (error.hasOwnProperty('response') && error.response) {
                            Object.assign(reportObject, {
                                response: error.response,
                            });
                        }

                        this.emailErrors.push(reportObject);

                        const reportSubject = 'Request timeout';

                        this._errorReportingService.report(
                            {
                                emailErrors: this.emailErrors,
                            },
                            true,
                            reportSubject,
                        );

                        // Clear errors
                        this.clearErrors();

                        return;
                    }
                }

                break;
            case 200:
                // Iterate through errors
                if (errors) {
                    for (let index = 0; index < errors.length; index++) {
                        const error = errors[index];
                        this.checkResponseStatus200(response, error);
                    }
                }

                break;
            // SDK Request Error
            case 100100:
                if (errors && (_orderData && _orderData.getFormState())) {
                    for (let index = 0; index < errors.length; index++) {
                        const error = errors[index];

                        Object.assign(error, {
                            status: statusCode,
                            exceptionStatus: 'SDKNetworkErrorException',
                        });

                        this.emailErrors.push(error);

                        const reportSubject = 'Connection Error';

                        this._errorReportingService.report(
                            {
                                emailErrors: this.emailErrors,
                            },
                            true,
                            reportSubject,
                        );

                        // Clear errors
                        this.clearErrors();

                        return;
                    }
                }

                break;
            // Server Error
            case 100101:
                if (errors) {
                    for (let index = 0; index < errors.length; index++) {
                        const error = errors[index];

                        Object.assign(error, {
                            status: statusCode,
                            exceptionStatus: 'JSON Response Error',
                        });

                        this.emailErrors.push(error);

                        const reportSubject = 'JSON Response Error';

                        this._errorReportingService.report(
                            {
                                emailErrors: this.emailErrors,
                            },
                            true,
                            reportSubject,
                        );

                        // Clear errors
                        this.clearErrors();

                        return;
                    }
                }

                break;
            // Empty API Error
            case 100102:
                if (errors) {
                    for (let index = 0; index < errors.length; index++) {
                        const error = errors[index];

                        Object.assign(error, {
                            status: statusCode,
                            exceptionStatus: 'Empty API Response',
                        });

                        this.emailErrors.push(error);

                        const reportSubject = 'Empty API Response';

                        this._errorReportingService.report(
                            {
                                emailErrors: this.emailErrors,
                            },
                            true,
                            reportSubject,
                        );

                        // Clear errors
                        this.clearErrors();

                        return;
                    }
                }

                break;
            // Undefined Response Type - response didn't match our criteria - by status code and response type by ResponseTypeValidator
            case 100103:
                if (errors) {
                    for (let index = 0; index < errors.length; index++) {
                        const error = errors[index];

                        Object.assign(error, {
                            status: statusCode,
                            exceptionStatus: 'Undefined Response Type',
                        });

                        this.emailErrors.push(error);

                        const reportSubject = 'Undefined Response Type';

                        this._errorReportingService.report(
                            {
                                emailErrors: this.emailErrors,
                            },
                            true,
                            reportSubject,
                        );

                        // Clear errors
                        this.clearErrors();

                        return;
                    }
                }

                break;
            case 400:
                if (errors) {
                    for (let index = 0; index < errors.length; index++) {
                        const error = errors[index];
                        this.checkResponseStatus400(error);

                    }

                    // Change with simpe for loop
                    // for (let error of errors) {
                    //     this.checkResponseStatus400(error);
                    // }
                }

                break;
            case 401:
                if (errors) {
                    for (let index = 0; index < errors.length; index++) {
                        const error = errors[index];
                        // reportObject.statusMessage = TODO: Send statusMessage from AjaxHttpClient,

                        // Add to reports, which will be send to email
                        this.emailErrors.push({
                            status: 401,
                            exceptionStatus: 'UnauthorizedException',
                            message: error.message ? error.message : 'N/A',
                            code: error.code,
                        });

                        // Add to reports, which will be send to monitoring - exceptions section
                        // this.monitorExceptionErrors.push({
                        //     status: 401,
                        //     exceptionStatus: 'UnauthorizedException',
                        //     message: error.message ? error.message : 'N/A',
                        //     code: error.code,
                        // });
                    }

                    // Change with simple for loop
                    // for (let error of errors) {
                    //     // reportObject.statusMessage = TODO: Send statusMessage from AjaxHttpClient,

                    //     // Add to reports, which will be send to email
                    //     this.emailErrors.push({
                    //         status: 401,
                    //         exceptionStatus: 'UnauthorizedException',
                    //         message: error.message ? error.message : 'N/A',
                    //         code: error.code,
                    //     });

                    //     // Add to reports, which will be send to monitoring - exceptions section
                    //     this.monitorExceptionErrors.push({
                    //         status: 401,
                    //         exceptionStatus: 'UnauthorizedException',
                    //         message: error.message ? error.message : 'N/A',
                    //         code: error.code,
                    //     });
                    // }
                }

                break;
            case 404:
                if (errors) {
                    for (let index = 0; index < errors.length; index++) {
                        const error = errors[index];
                        // reportObject.statusMessage = TODO: Send statusMessage from AjaxHttpClient,

                        // Add to reports, which will be send to email
                        this.emailErrors.push({
                            status: 404,
                            exceptionStatus: 'NotFoundHttpException',
                            message: error.message ? error.message : 'N/A',
                            code: error.code,
                        });

                        // Add to reports, which will be send to monitoring - exceptions section
                        // this.monitorExceptionErrors.push({
                        //     status: 404,
                        //     exceptionStatus: 'NotFoundHttpException',
                        //     message: error.message ? error.message : 'N/A',
                        //     code: error.code,
                        // });
                    }

                    // Change with simple for loop
                    // for (let error of errors) {
                    //     // reportObject.statusMessage = TODO: Send statusMessage from AjaxHttpClient,

                    //     // Add to reports, which will be send to email
                    //     this.emailErrors.push({
                    //         status: 404,
                    //         exceptionStatus: 'NotFoundHttpException',
                    //         message: error.message ? error.message : 'N/A',
                    //         code: error.code,
                    //     });

                    //     // Add to reports, which will be send to monitoring - exceptions section
                    //     this.monitorExceptionErrors.push({
                    //         status: 404,
                    //         exceptionStatus: 'NotFoundHttpException',
                    //         message: error.message ? error.message : 'N/A',
                    //         code: error.code,
                    //     });
                    // }
                }

                break;
            case 500:
                if (errors) {
                    for (let index = 0; index < errors.length; index++) {
                        const error = errors[index];
                        if (error.code === 5001) {
                            this.exceptionStatuses = 'TimeSlotUnavailableException';
                            this._eventTrackingService.push({
                                event: 'live-availability-missing',
                            });
                        } else {
                            // exceptionStatuses = 'InternalServerErrorException';
                            // exceptionMessage = 'Internal Server Error';

                            // Add to reports, which will be send to email
                            this.emailErrors.push({
                                status: 404,
                                exceptionStatus: 'NotFoundHttpException',
                                message: error.message ? error.message : 'N/A',
                                code: error.code,
                            });

                            // Add to reports, which will be send to monitoring - exceptions section
                            // this.monitorExceptionErrors.push({
                            //     status: 404,
                            //     exceptionStatus: 'NotFoundHttpException',
                            //     message: error.message ? error.message : 'N/A',
                            //     code: error.code,
                            // });
                        }
                    }
                }

                break;
            case 500:
                if (errors) {
                    for (let index = 0; index < errors.length; index++) {
                        const error = errors[index];
                        if (error.code === 5001) {
                            // exceptionStatuses = 'TimeSlotUnavailableException';
                            // dataLayer.push({
                            //     'event': 'obfLiveAvailabilityMissing'
                            // });
                            // dataLayer.push({
                            //     'event': 'noTimeSlot'
                            // });
                        } else {
                            // exceptionStatuses = 'InternalServerErrorException';
                            // exceptionMessage = 'Internal Server Error';

                            // Add to reports, which will be send to email
                            this.emailErrors.push({
                                status: 500,
                                exceptionStatus: 'InternalServerErrorException',
                                message: error.message ? error.message : 'N/A',
                                code: error.code,
                            });

                            // Add to reports, which will be send to monitoring - exceptions section
                            // this.monitorExceptionErrors.push({
                            //     status: 500,
                            //     exceptionStatus: 'InternalServerErrorException',
                            //     message: error.message ? error.message : 'N/A',
                            //     code: error.code,
                            // });
                        }
                    }
                }

                break;
        }

        try {
            // Send report if there's any found
            if (this.emailErrors.length || this.monitorResponseErrors.length || this.monitorExceptionErrors.length) {
                let reportSubject = 'OBF Error';

                // Get first error message as report subject
                if (this.emailErrors.length) {
                    reportSubject = this.emailErrors[0].exceptionStatus ? this.emailErrors[0].exceptionStatus : this.emailErrors[0].message;
                }

                this._errorReportingService.report(
                    {
                        emailErrors: this.emailErrors,
                        monitorResponseErrors: this.monitorResponseErrors,
                        monitorExceptionErrors: this.monitorExceptionErrors,
                    },
                    true,
                    reportSubject,
                );
            }
        } catch (error) {
            console.warn('ErrorReportingService Error - ', error);
        }

        // Clear errors
        this.clearErrors();
    }

    /**
     * Run check for error with response status 200 and format proper messages for report
     * @param {object} error
     */
    private checkResponseStatus200(response: any, error: ResponseError) {
        let reportObject = null;
        switch (error.code) {
            case 5001:
                reportObject = this.getBlankErrorReportObject();

                // reportObject.statusMessage = TODO: Send statusMessage from AjaxHttpClient,
                reportObject.status = 200;
                reportObject.exceptionStatus = 'ProfileMismatch';
                reportObject.message = error.message ? error.message : 'N/A';
                reportObject.code = error.code;

                // Add to reports, which will be send to email
                this.emailErrors.push(reportObject);
                this._eventTrackingService.push({
                    event: 'profile-mismatch',
                });
                // doNotSendEmailErrors = false;
                // doNotSendMonitorExceptionErrors = true;
                // doNotSendResponseMonitorErrors = true;
                break;
            case 6745:
                // reportObject = this.getBlankErrorReportObject();

                // // reportObject.statusMessage = TODO: Send statusMessage from AjaxHttpClient,
                // reportObject.status = 200;
                // reportObject.exceptionStatus = 'CrossSaleMissingSlots';
                // reportObject.message = error.message ? error.message : 'N/A';
                // reportObject.code = error.code;

                // // Add to reports, which will be send to email
                // this.emailErrors.push(reportObject);

                this._eventTrackingService.push({
                    event: 'cross-sale-missing-slots',
                    responseCode: error.code,
                });

                break;
            case 5510:
                this._eventTrackingService.push({
                    event: 'wrong-password',
                    responseCode: error.code,
                });

                break;
            case 5505:
                this._eventTrackingService.push({
                    event: 'email-already-taken',
                    responseCode: error.code,
                });

                break;
            case 5516:
                this._eventTrackingService.push({
                    event: 'password-length-error',
                    responseCode: error.code,
                });

                break;
            case 6714:
                // Move in coverage component.
                // this._eventTrackingService.push({
                //     'event': 'service-coverage',
                //     'responseCode': error.code
                // });

                break;
            case 5504:
                this._eventTrackingService.push({
                    event: 'uncomplete-phone-number',
                    responseCode: error.code,
                });

                break;
            case 6500:
                this.exceptionStatuses = 'TimeSlotUnavailableException';
                this._eventTrackingService.push({
                    event: 'live-availability-missing',
                    responseCode: error.code,
                });

                break;
            case 6501:
                this.exceptionStatuses = 'TimeSlotUnavailableException';
                this._eventTrackingService.push({
                    event: 'live-availability-missing',
                    responseCode: error.code,
                });

                break;
            case 6017:
                this.exceptionStatuses = 'VoucherAlreadyApplied';
                this._eventTrackingService.push({
                    event: 'voucher-already-applied',
                    responseCode: error.code,
                });

                break;
            case 6000:
                this.exceptionStatuses = 'WrongPromoCode';
                this._eventTrackingService.push({
                    event: 'wrong-promo-code',
                    responseCode: error.code,
                });

                break;
            case 6001:
                this.exceptionStatuses = 'NotValidPromoCode';
                this._eventTrackingService.push({
                    event: 'not-valid-promo-code',
                    responseCode: error.code,
                });

                break;
            case 6002:
                this.exceptionStatuses = 'LocationPromoCode';
                this._eventTrackingService.push({
                    event: 'location-promo-code',
                    responseCode: error.code,
                });

                break;
            case 6003:
                this.exceptionStatuses = 'ExpiredPromoCode';
                this._eventTrackingService.push({
                    event: 'expired-promo-code',
                    responseCode: error.code,
                });

                break;
            case 6004:
                this.exceptionStatuses = 'PromoCodeNotApply';
                this._eventTrackingService.push({
                    event: 'promo-code-do-not-apply',
                    responseCode: error.code,
                });

                break;
            case 6008:
                this.exceptionStatuses = 'PromoCodeNotApply';
                this._eventTrackingService.push({
                    event: 'promo-code-do-not-apply-location',
                    responseCode: error.code,
                });

                break;
            case 6009:
                this.exceptionStatuses = 'PromoCodeNotApply';
                this._eventTrackingService.push({
                    event: 'promo-code-do-not-apply-service',
                    responseCode: error.code,
                });

                break;
            case 6010:
                this.exceptionStatuses = 'PromoCodeNotApply';
                this._eventTrackingService.push({
                    event: 'promo-code-do-not-apply-service',
                    responseCode: error.code,
                });

                break;
            case 6015:
                this.exceptionStatuses = 'PromoCodeAlreadyRedeemed';
                this._eventTrackingService.push({
                    event: 'promo-code-already-redeemed',
                    responseCode: error.code,
                });

                break;
            case 7003:
                this.exceptionStatuses = 'InvalidPostcode';
                this._eventTrackingService.push({
                    event: 'invalid-postcode',
                    responseCode: error.code,
                });

                break;
            case 7017:
                this.exceptionStatuses = 'InvalidCard';
                this._eventTrackingService.push({
                    event: 'invalid-card',
                    responseCode: error.code,
                });

                break;
            case 7025:
                this.exceptionStatuses = 'MembershipSlotSelected';
                this._eventTrackingService.push({
                    event: 'membership-slot-selected',
                    responseCode: error.code,
                });

                break;
            case 4100:
                reportObject = this.getBlankErrorReportObject();

                // reportObject.statusMessage = TODO: Send statusMessage from AjaxHttpClient,
                reportObject.exceptionStatus = 'UnauthorizedException';
                reportObject.message = error.message ? error.message : 'N/A';
                reportObject.status = 400;
                reportObject.code = error.code;

                // Add to reports, which will be send to email
                this.emailErrors.push(reportObject);

                break;
            case 492:
                reportObject = this.getBlankErrorReportObject();

                // reportObject.statusMessage = TODO: Send statusMessage from AjaxHttpClient,
                reportObject.exceptionStatus = 'PaymentProviderIdException';
                reportObject.message = error.message ? error.message : 'N/A';
                reportObject.status = 400;
                reportObject.code = error.code;

                // Add to reports, which will be send to email
                this.emailErrors.push(reportObject);

                break;
            case 4571: // Your card was declined, please try again or use a different one (happens when 3DS Token is refused by some reason [bank issue or so])
                if (!this._mainDataProvider || this._mainDataProvider.obfEnv.env === 'development' || this._mainDataProvider.obfEnv.env === 'stage' || this._mainDataProvider.obfEnv.env === 'production') {

                    const errorModel = {
                        reason: 'Card declined',
                        errorResponse: response,
                    },
                        reportSubject: string = errorModel.reason;

                    this._errorReportingService.report(
                        {
                            emailErrors: [errorModel],
                        },
                        false,
                        reportSubject,
                    );
                }
                break;
            case 5140: // Invalid application
                if (!this._mainDataProvider || this._mainDataProvider.obfEnv.env === 'development' || this._mainDataProvider.obfEnv.env === 'stage' || this._mainDataProvider.obfEnv.env === 'production') {

                    const errorModel = {
                        reason: 'Invalid application',
                        errorResponse: response,
                    },
                        reportSubject: string = errorModel.reason;

                    this._errorReportingService.report(
                        {
                            emailErrors: [errorModel],
                        },
                        false,
                        reportSubject,
                    );
                }

                break;
            case 5141: // Invalid profile
                if (!this._mainDataProvider || this._mainDataProvider.obfEnv.env === 'development' || this._mainDataProvider.obfEnv.env === 'stage' || this._mainDataProvider.obfEnv.env === 'production') {

                    const errorModel = {
                        reason: 'Invalid profile',
                        errorResponse: response,
                    },
                        reportSubject: string = errorModel.reason;

                    this._errorReportingService.report(
                        {
                            emailErrors: [errorModel],
                        },
                        false,
                        reportSubject,
                    );
                }

                break;
            case 6718: // Invalid paymethod
                if (!this._mainDataProvider || this._mainDataProvider.obfEnv.env === 'development' || this._mainDataProvider.obfEnv.env === 'stage' || this._mainDataProvider.obfEnv.env === 'production') {

                    const errorModel = {
                        reason: 'Invalid paymethod',
                        errorResponse: response,
                    },
                        reportSubject: string = errorModel.reason;

                    this._errorReportingService.report(
                        {
                            emailErrors: [errorModel],
                        },
                        false,
                        reportSubject,
                    );
                }

                break;
            case 6711: // Invalid service
                if (!this._mainDataProvider || this._mainDataProvider.obfEnv.env === 'development' || this._mainDataProvider.obfEnv.env === 'stage' || this._mainDataProvider.obfEnv.env === 'production') {
                    const errorModel = {
                        reason: 'Invalid service',
                        errorResponse: response,
                    },
                        reportSubject: string = errorModel.reason;

                    this._errorReportingService.report(
                        {
                            emailErrors: [errorModel],
                        },
                        false,
                        reportSubject,
                    );
                }

                break;
            case 6742: // Init choice items are required
            case 6726: // Choice item for 'Appointment time' is required
                if (!this._mainDataProvider || this._mainDataProvider.obfEnv.env === 'development' || this._mainDataProvider.obfEnv.env === 'stage' || this._mainDataProvider.obfEnv.env === 'production') {
                    const errorModel = {
                        reason: 'Choice Item Required',
                        errorResponse: response,
                    },
                        reportSubject: string = errorModel.reason;

                    this._errorReportingService.report(
                        {
                            emailErrors: [errorModel],
                        },
                        false,
                        reportSubject,
                    );
                }

                break;
            case 6733: // Invalid cross token
            // Send report
                if (!this._mainDataProvider || this._mainDataProvider.obfEnv.env === 'development' || this._mainDataProvider.obfEnv.env === 'stage' || this._mainDataProvider.obfEnv.env === 'production') {

                    const errorModel = {
                        reason: 'Invalid cross token',
                        errorResponse: response,
                    },
                    reportSubject: string = errorModel.reason;
                    
                    this._errorReportingService.report(
                        {
                            emailErrors: [errorModel],
                        },
                        false,
                        reportSubject,
                    );
                }
                break;
            case 6732: // Unauthorized
            case 5142: // Invalid authorization
            case 6720: // Client Missmatch
                if (!this._mainDataProvider || this._mainDataProvider.obfEnv.env === 'development' || this._mainDataProvider.obfEnv.env === 'stage' || this._mainDataProvider.obfEnv.env === 'production') {
                    const errorModel = {
                        reason: 'Authorization error',
                        errorResponse: response,
                    },
                        reportSubject: string = errorModel.reason;

                    this._errorReportingService.report(
                        {
                            emailErrors: [errorModel],
                        },
                        false,
                        reportSubject,
                    );
                }

                break;
            default:
                // Error is not expected from us
                if (this.errorCodesWithoutAction.indexOf(error.code) === -1) {
                    // Send report
                    if (!this._mainDataProvider || this._mainDataProvider.obfEnv.env === 'development' || this._mainDataProvider.obfEnv.env === 'stage' || this._mainDataProvider.obfEnv.env === 'production') {
                        const errorModel = {
                            reason: 'Unexpected Error',
                            errorResponse: response,
                        },
                            reportSubject: string = errorModel.reason;

                        this._errorReportingService.report(
                            {
                                emailErrors: [errorModel],
                            },
                            false,
                            reportSubject,
                        );
                    }
                }

                this.exceptionStatuses = 'DataException';
                // this.exceptionMessage = "Missing Response Data ";
                this._eventTrackingService.push({
                    event: 'data-exception',
                    responseCode: error.code,
                });
                // doNotSendResponseMonitorErrors = true;
                break;
        }
    }

    /**
     * Run check for error with response status 400 and format proper messages for report
     * @param {object} error
     */
    private checkResponseStatus400(error: ResponseError) {
        let reportObject = null;

        switch (error.code) {
            case 450:
                // exceptionStatuses = 'ProcessingCardError';
                break;

            default:
                // reportObject.statusMessage = TODO: Send statusMessage from AjaxHttpClient,
                reportObject = this.getBlankErrorReportObject();

                reportObject.exceptionStatus = 'NotFoundUndefined';
                reportObject.message = 'NotFoundUndefined';
                reportObject.status = 400;
                reportObject.code = error.code;

                // Add to reports, which will be send to email
                this.emailErrors.push(reportObject);

                // Add to reports, which will be send to monitoring - exceptions section
                // this.monitorExceptionErrors.push(reportObject);

                break;
        }
    }

    /**
     * Method for getting blank error object for monitor exceptions
     */
    private getBlankErrorReportObject(): any {
        const errorObject: {
            status: number;
            exceptionStatus: string;
            message: string;
            code: number;
        } = {
            status: null,
            exceptionStatus: '',
            message: '',
            code: null,
        };

        return errorObject;
    }

    /**
     * Method for clearing all current errors
     */
    private clearErrors(): void {
        this.emailErrors = new Array();
        this.monitorExceptionErrors = new Array();
        this.monitorResponseErrors = new Array();
    }

    /**
     * Set service dependency methods
     * Description: Some error handling functionalities may depend on other services methods.
     * They are being provided from outside in order to avoid cyclic dependency injection.
     * @param methods
     */
    public setDepMethods(methods: Array<DependencyMethod>): void {
        if (methods && methods.length) {
            for (let methodIndex = 0; methodIndex < methods.length; methodIndex++) {
                const method: DependencyMethod = methods[methodIndex],
                    methodAlreadyAdded: boolean = this.depMethods.filter((depMethod: DependencyMethod) => depMethod.name === method.name).length ? true : false;

                // Dependency method array is empty
                if (!this.depMethods.length) {
                    this.depMethods.push(method);

                    continue;
                }

                // This method is never set before
                if (!methodAlreadyAdded) {
                    this.depMethods.push(method);
                }
            }
        }
    }

    /**
     * Handler for different
     * @param error error/warning response
     */
    private responsePayloadHandler(error: any, skipPositionCheck: boolean = false): void {

        // Inject needed services/providers
        const _orderData: OrderData = this._injector.get(OrderData),
            _navigationService: NavigationService = this._injector.get(NavigationService),
            _popupModalsService: PopupModalsService = this._injector.get(PopupModalsService),
            _modalsService: ModalsService = this._injector.get(ModalsService);

        // let shouldCallerAbort: boolean = false;

        if (error && error.payload) {
            // Set falg
            // shouldCallerAbort = true;

            const payload = error.payload,
                payloadPosition: string = error.payload.position,
                payloadPositionData: any = _navigationService.getPositionData(payloadPosition),
                errorMessage: string = error.message ? error.message : this._localizationProvider.getLocalizedText('modalErrMsg2'),
                currentStepData: any = _navigationService.getCurrentStep();

            let actionMethod: Function = null,
                modalData: any = {
                    message: errorMessage,
                    buttons: [
                        {
                            label: this._localizationProvider.getLocalizedText('modalDefaultConfirmButtonTitle'),
                            closeOnClick: true,
                            type: 'confirm',
                        },
                    ],
                };

            // Navigate user using response payload only if position in payload is a previous one
            if (currentStepData && payloadPositionData.index <= currentStepData.index || skipPositionCheck) {
                switch (payloadPosition) {
                    case ChoicesPositions.Init:
                        actionMethod = () => {
                            _orderData.resetActiveBooking(); // we reset the active booking to secure for integrate
                            _navigationService.navigateMe(ChoicesPositions.Init, { forceNavigate: true });
                        };

                        break;
                    case ChoicesPositions.OnWelcome:
                        actionMethod = () => {
                            _orderData.resetActiveBooking(); // we reset the active booking to secure for integrate
                            _navigationService.navigateMe(ChoicesPositions.Init, { forceNavigate: true });
                        };

                        break;
                    case ChoicesPositions.AfterWelcome:
                        actionMethod = () => {
                            _orderData.resetActiveBooking(); // we reset the active booking to secure for integrate
                            _navigationService.navigateMe(ChoicesPositions.Init, { forceNavigate: true });
                        };

                        break;
                    case ChoicesPositions.Configurator:
                        actionMethod = () => {
                            // XRM sent us specific choice_id to navigate to
                            if (payload && payload.choice_id) {
                                const choiceId: number = parseInt(payload.choice_id);

                                _navigationService.navigateMe(ChoicesPositions.Configurator, {
                                    pathParams: [choiceId],
                                });
                            } else {
                                _navigationService.navigateMe(ChoicesPositions.Configurator);
                            }

                        };

                        break;
                    case ChoicesPositions.BeforeAvailability:
                        if (currentStepData && currentStepData.position !== ChoicesPositions.BeforeAvailability) {
                            actionMethod = () => {
                                _navigationService.navigateMe(ChoicesPositions.BeforeAvailability);
                            };
                        }

                        break;
                    case ChoicesPositions.OnAvailability:
                        actionMethod = () => {
                            // refresh availability on [missing slot] error, on step 2 only
                            if (currentStepData && currentStepData.position === ChoicesPositions.OnAvailability) {
                                _orderData.refreshAvailability.next(true);
                            } else {
                                // Navitate user on availability step
                                _navigationService.navigateMe(ChoicesPositions.OnAvailability);
                            }
                        };

                        break;
                    case ChoicesPositions.AfterAvailability:
                        if (currentStepData && currentStepData.position !== ChoicesPositions.AfterAvailability) {
                            actionMethod = () => {
                                _navigationService.navigateMe(ChoicesPositions.AfterAvailability);
                            };
                        }

                        break;
                    case ChoicesPositions.BeforeSummary:
                        if (currentStepData && currentStepData.position !== ChoicesPositions.BeforeSummary) {
                            actionMethod = () => {
                                _navigationService.navigateMe(ChoicesPositions.BeforeSummary);
                            };
                        }

                        break;
                    case ChoicesPositions.OnSummary:
                        if (currentStepData && currentStepData.position !== ChoicesPositions.OnSummary) {
                            actionMethod = () => {
                                _navigationService.navigateMe(ChoicesPositions.OnSummary);
                            };
                        }

                        break;
                    case ChoicesPositions.BeforeConfirmation:
                        if (currentStepData && currentStepData.position !== ChoicesPositions.BeforeConfirmation) {
                            actionMethod = () => {
                                _navigationService.navigateMe(ChoicesPositions.BeforeConfirmation);
                            };
                        }

                        break;
                    case ChoicesPositions.Confirmation:
                        if (currentStepData && currentStepData.position !== ChoicesPositions.Confirmation) {
                            actionMethod = () => {
                                _navigationService.navigateMe(ChoicesPositions.Confirmation);
                            };
                        }

                        break;
                }

            }

            // Invoke proper action
            if (actionMethod) {
                actionMethod();
            }
        }

    }

    /**
     * Checks received response for errors, warnings and payload within
     * @param response
     */
    public validateResponse(response: any, options: { localDepMethods?: Array<DependencyMethod>, skipPositionCheckForModals: boolean } = { skipPositionCheckForModals: false }): Function | null {

        let responsePropertyName: string = response && response.error ? 'error' : 'warning',
            validationCallback: Function = null;

        // Inject needed services/providers
        const _orderData: OrderData = this._injector.get(OrderData),
            _navigationService: NavigationService = this._injector.get(NavigationService),
            _popupModalsService: PopupModalsService = this._injector.get(PopupModalsService),
            _modalsService: ModalsService = this._injector.get(ModalsService);

        // Check if returned response is not blank object
        if (!response || response && !Object.keys(response).length) {
            // Send report
            if (!this._mainDataProvider || this._mainDataProvider.obfEnv.env === 'development' || this._mainDataProvider.obfEnv.env === 'stage' || this._mainDataProvider.obfEnv.env === 'production') {
                const errorModel = {
                    reason: 'Unexpected Error',
                    errorResponse: response,
                },
                    emailSubject = 'Server Error';

                this._errorReportingService.report(
                    {
                        emailErrors: [errorModel],
                    },
                    false,
                    emailSubject,
                );
            }

            _modalsService.open('defaultError');

            return validationCallback;
        }

        // Start looking for error codes in response
        if (response[responsePropertyName] && response[responsePropertyName].length) {
            let errorCode: number = response[responsePropertyName] ? response[responsePropertyName][0].code : 0,
                error: any = response[responsePropertyName] ? response[responsePropertyName][0] : null,
                errorMessage: string = response[responsePropertyName] ? response[responsePropertyName][0].message : '',
                modalData: any = null;

            switch (errorCode) {
                // Unavailable slot
                case 6733: // Invalid cross token
                    validationCallback = () => {
                        const errorModel = {
                            reason: 'Invalid cross token',
                            errorResponse: response,
                        },
                            reportSubject: string = errorModel.reason;

                        this._errorReportingService.report(
                            {
                                emailErrors: [errorModel],
                            },
                            false,
                            reportSubject,
                        );

                        modalData = {
                            message: 'Something went wrong! Please try again later.',
                            buttons: [
                                {
                                    label: this._localizationProvider.getLocalizedText('modalDefaultConfirmButtonTitle'),
                                    closeOnClick: true,
                                    type: 'primary',
                                    state: 'default',
                                    size: 'normal',
                                    action: () => {
                                        // Reset current booking data
                                        _popupModalsService.openExitPopup();
                                    },
                                },
                            ],
                            type: responsePropertyName
                        };

                        _modalsService.open('custom', modalData);
                    };

                    break;
                case 6500: // Time slot is unavailable
                case 6501: // Time slots for this service tend to get booked very quickly and this one is no longer available
                case 6502: // Timeslot or timeslot_formatted are required
                case 6503: // Time slot for the selected unit is no longer available
                case 6550: // Unit is not available anymore
                case 7025: // Time slot is for members only
                    const modalAction: Function = () => {
                        if (responsePropertyName === 'error') {
                            const fetchTransactionMethod: DependencyMethod | undefined = this.depMethods.find((depMethod: DependencyMethod) => {
                                return depMethod.name === 'fetchTransaction';
                            }),
                            transactionId: number = _orderData.activeBooking.getValue() ? _orderData.activeBooking.getValue().get('id') : null,
                            queryParams: SDKQueryParams = { expand: ['all.all.all.all'] };

                            if (fetchTransactionMethod && transactionId) {
                                
                                fetchTransactionMethod.call(transactionId, queryParams, (validationResult) => {
                                    if (validationResult.success) {
                                        this.responsePayloadHandler(error, options.skipPositionCheckForModals);
                                    } else {
                                        this.responsePayloadHandler(error, options.skipPositionCheckForModals);
                                    }
                                });
                            }

                        } else {
                            this.responsePayloadHandler(error, options.skipPositionCheckForModals);
                        }
                    };

                    validationCallback = () => {
                        modalData = {
                            message: errorMessage,
                            buttons: [
                                {
                                    label: this._localizationProvider.getLocalizedText('unavailableSlotModalButtonTitle'),
                                    closeOnClick: true,
                                    type: 'primary',
                                    state: 'default',
                                    size: 'normal',
                                    action: modalAction
                                }
                            ],
                            type: responsePropertyName
                        };

                        _modalsService.open('custom', modalData);
                    };

                    break;
                case 6710: // Invalid transaction token
                    validationCallback = () => {
                        let currentStepData = _navigationService.getCurrentStep(),
                            modalMessage: String = errorMessage;

                        // Open modal with user friendly message and send user to init step with full resets
                        if (currentStepData && (currentStepData.position === ChoicesPositions.OnSummary || currentStepData.position === ChoicesPositions.BeforeConfirmation)) {
                            modalMessage = 'Something went wrong! Please go to "My Bookings" in your Account to see if your booking is saved.';
                        }

                        modalData = {
                            message: modalMessage,
                            buttons: [
                                {
                                    label: this._localizationProvider.getLocalizedText('modalDefaultConfirmButtonTitle'),
                                    closeOnClick: true,
                                    type: 'primary',
                                    state: 'default',
                                    size: 'normal',
                                    action: () => {
                                        // Reset current booking data
                                        _orderData.resetActiveBooking();

                                        // Navitate user on init step
                                        _navigationService.navigateMe(ChoicesPositions.Init, { forceNavigate: true });
                                    },
                                },
                            ],
                            type: responsePropertyName
                        };

                        _modalsService.open('custom', modalData);
                    };

                    break;
                case 5142: // Invalid Authorization
                case 6732: // Unauthorized
                    validationCallback = () => {
                        const logout: DependencyMethod | undefined = this.depMethods
                            .find((depMethod: DependencyMethod) => {
                                return depMethod.name === 'logout';
                            });

                        if (logout) {
                            logout.call()
                                .then(
                                    (responseAuth: any) => { // Success
                                        const currentStep = _navigationService.getCurrentStep();

                                        _popupModalsService.openLoginRegister({
                                            callback: ((status) => {

                                                // After successful registration update choice items and navigate to availability step
                                                if (status && status !== 'exit') {
                                                    if (currentStep && currentStep.position === ChoicesPositions.Configurator) {
                                                        let updateTransaction: DependencyMethod | undefined | null = null;

                                                        // updateTransaction method should be call only by locally provided parameter.
                                                        // Using old passed method with other request params would cause a
                                                        // duplicate previous request.
                                                        if (options.localDepMethods && options.localDepMethods.length) {
                                                            updateTransaction = options.localDepMethods
                                                                .find((depMethod: DependencyMethod) => {
                                                                    return depMethod.name === 'updateTransaction';
                                                                });
                                                        }

                                                        // If updateTransaction method provided - call it
                                                        if (updateTransaction) {
                                                            // eslint-disable-next-line @typescript-eslint/no-shadow
                                                            updateTransaction.call(
                                                                updateTransaction.requestParams.transactionId,
                                                                updateTransaction.requestParams.data,
                                                                updateTransaction.requestParams.queryParams,
                                                                (response: any) => {
                                                                    // Check if returned response is not blank object
                                                                    if (response && Object.keys(response).length) {
                                                                        if (!response.error) {
                                                                            _navigationService.navigateMe(ChoicesPositions.OnAvailability);
                                                                        }
                                                                    }
                                                                });
                                                        }
                                                    } else if (!currentStep) { // OBF Init

                                                        /**
                                                         * !!TODO!! Set additional conditions for Reschedule and Edit booking !!!
                                                         */
                                                        _navigationService.navigateMe(ChoicesPositions.Init);
                                                    }
                                                } else {
                                                    _orderData.resetActiveBooking(); // we reset the active booking then navigate to init step
                                                    _navigationService.navigateMe(ChoicesPositions.Init, { forceNavigate: true });
                                                }

                                            }).bind(this),
                                            closeOnOverlayClick: false,
                                        });
                                    },
                                ).catch((error) => {
                                    // catch error
                                });
                        }
                    };

                    break;
                // Unavailable Cross sell
                case 6745:
                    validationCallback = () => {
                        modalData = {
                            title: 'Sorry, you missed out!',
                            message: errorMessage,
                            buttons: [
                                {
                                    label: this._localizationProvider.getLocalizedText('modalDefaultConfirmButtonTitle'),
                                    closeOnClick: true,
                                    type: 'primary',
                                    state: 'default',
                                    size: 'normal',
                                    action: () => {
                                        this.responsePayloadHandler(error, options.skipPositionCheckForModals);
                                    },
                                },
                            ],
                            type: responsePropertyName
                        };

                        _modalsService.open('custom', modalData);
                    };

                    break;
                // Invalid phone number (update phone functionality)
                case 5117:
                case 5504:
                case 5519:
                case 6741: // Booking cannot be rescheduled due to short notice
                case 9501: // The transaction was declined. Please use a different card or contact your bank.
                    validationCallback = () => {
                        modalData = {
                            message: errorMessage,
                            buttons: [
                                {
                                    label: this._localizationProvider.getLocalizedText('modalDefaultConfirmButtonTitle'),
                                    closeOnClick: true,
                                    type: 'primary',
                                    state: 'default',
                                    size: 'normal'
                                },
                            ],
                            type: responsePropertyName
                        };

                        _modalsService.open('custom', modalData);
                    };

                    break;
                case 6734: // You cannot access this booking!
                case 6720: // Client missmatch
                    validationCallback = () => {
                        modalData = {
                            message: errorMessage,
                            buttons: [
                                {
                                    label: this._localizationProvider.getLocalizedText('modalDefaultConfirmButtonTitle'),
                                    closeOnClick: true,
                                    type: 'primary',
                                    state: 'default',
                                    size: 'normal',
                                    action: () => {

                                        _orderData.resetActiveBooking(); // we reset the active booking to secure for integrate
                                        _navigationService.navigateMe(ChoicesPositions.Init, { forceNavigate: true });
                                    },
                                },
                            ],
                            type: responsePropertyName
                        };

                        _modalsService.open('custom', modalData);
                    };

                    break;
                // Promocode errors
                case 6000: // Invalid promocode
                case 6001: // Invalid promocode
                case 6002: // Invalid promocode
                case 6003: // Expired promocode
                case 6004: // Invalid promocode
                case 6008: // Invalid promocode for current location
                case 6009: // Promocode cannot be applied (ex: promocode is for members only or the opposite)
                case 6010: // This promo code does not apply to your service preferences.
                case 6015: // Promocode already redeemed
                case 6016: // Sorry! This promo code has reached the limit of usage
                case 6020: // Promo code cannot be applied to this rescheduled booking
                case 6029: // The client reached the available limit of this voucher for this service!
                    validationCallback = () => {
                        // ! FIX: Open this error with ModalsService
                        const obfOptions = this._mainDataProvider.getResourceObfOptions(),
                            currentStep = _navigationService.getCurrentStep();

                        // Reset voucher
                        if (obfOptions && obfOptions.voucher) {
                            obfOptions.voucher = null;
                        }

                        const positionsWithSidebar: Array<String> = [
                            ChoicesPositions.OnAvailability,
                            ChoicesPositions.AfterAvailability,
                            ChoicesPositions.BeforeSummary,
                            ChoicesPositions.OnSummary,
                            ChoicesPositions.BeforeConfirmation,
                        ];

                        // On second and third step errors are shown in the sidebar
                        // open error modal everywhere else
                        if (!currentStep || currentStep && positionsWithSidebar.indexOf(currentStep.position) === -1) {
                            modalData = {
                                message: errorMessage,
                                buttons: [
                                    {
                                        label: this._localizationProvider.getLocalizedText('modalDefaultConfirmButtonTitle'),
                                        closeOnClick: true,
                                        type: 'primary',
                                        state: 'default',
                                        size: 'normal'
                                    },
                                ],
                                type: responsePropertyName
                            };

                            _modalsService.open('custom', modalData);
                        }
                    };

                    break;
                case 91000: // Declined debit card (payments microstep)
                case 450: // An error occurred while processing the card (Payment)
                case 7020: // An error occurred while processing with not supported used card
                case 7021: // An error occurred while processing with not supported new card
                case 4571: // Your card was declined, please try again or use a different one (happens when 3DS Token is refused by some reason [bank issue or so])
                case 6761: // outstanding payments
                case 6746: // blocked user
                    validationCallback = () => {
                        modalData = {
                            message: errorMessage,
                            buttons: [
                                {
                                    label: this._localizationProvider.getLocalizedText('modalDefaultConfirmButtonTitle'),
                                    closeOnClick: true,
                                    type: 'primary',
                                    state: 'default',
                                    size: 'normal'
                                },
                            ],
                            type: responsePropertyName
                        };

                        _modalsService.open('custom', modalData);
                    };

                    break;
                case 5500: // Default error code
                case 6712: // Invalid address
                case 6727: // The number you have entered is incomplete or incorrect (update with deleted phone)
                    validationCallback = () => {
                        modalData = {
                            message: errorMessage,
                            buttons: [
                                {
                                    label: this._localizationProvider.getLocalizedText('modalDefaultConfirmButtonTitle'),
                                    closeOnClick: true,
                                    type: 'primary',
                                    state: 'default',
                                    size: 'normal',
                                    action: () => {
                                        // fetchUserData method is provided as intepended dep method
                                        const fetchUserData: DependencyMethod | undefined = this.depMethods
                                            .find((depMethod: DependencyMethod) => {
                                                return depMethod.name === 'fetchUserData';
                                            });

                                        // If fetchUserData method provided - call it
                                        if (fetchUserData) {
                                            const queryParams: SDKQueryParams = {
                                                expand: ['all.all.all.all'],
                                                'paging[limit]': '9999',
                                            };

                                            // eslint-disable-next-line @typescript-eslint/no-shadow
                                            fetchUserData.call(
                                                queryParams,
                                                (response: any) => {
                                                    if (!response.error) {
                                                        // Handled in current method
                                                    } else {
                                                        // Handled in current method
                                                    }
                                                });
                                        }
                                    },
                                },
                            ],
                            type: responsePropertyName
                        };

                        _modalsService.open('custom', modalData);
                    };

                    break;
                case 6725: // Selected choice item does not belong to the service
                    validationCallback = () => {
                        const createTransaction: DependencyMethod | undefined = this.depMethods.find((depMethod: DependencyMethod) => {
                            return depMethod.name === 'createTransaction';
                        }),
                            getTransactionAddress: DependencyMethod | undefined = this.depMethods.find((depMethod: DependencyMethod) => {
                                return depMethod.name === 'getTransactionAddress';
                            }),
                            getChoicesByPositions: DependencyMethod | undefined = this.depMethods.find((depMethod: DependencyMethod) => {
                                return depMethod.name === 'getChoicesByPositions';
                            });

                        if (createTransaction && getTransactionAddress && getChoicesByPositions) {
                            modalData = {
                                title: this._localizationProvider.getLocalizedText('modalDefaultTitle2'),
                                message: this._localizationProvider.getLocalizedText('modalErrMsg6'),
                                buttons: [
                                    {
                                        label: this._localizationProvider.getLocalizedText('modalDefaultConfirmButtonTitle'),
                                        closeOnClick: true,
                                        type: 'primary',
                                        state: 'default',
                                        size: 'normal',
                                        action: () => {
                                            const currentTransaction: any = _orderData.activeBooking.getValue() ? _orderData.activeBooking.getValue().get() : null;

                                            if (currentTransaction && currentTransaction.service) {
                                                const serviceId = currentTransaction.service.id,
                                                    currentTransactionAddresss = getTransactionAddress.call(),
                                                    fetchServicesInitChoices: DependencyMethod | undefined = this.depMethods
                                                        .find((depMethod: DependencyMethod) => {
                                                            return depMethod.name === 'fetchServicesInitChoices';
                                                        });

                                                if (currentTransactionAddresss && fetchServicesInitChoices) {
                                                    fetchServicesInitChoices.call(serviceId, true, false)
                                                        .then(
                                                            (initServiceData: any) => { // Success
                                                                // resolve(initServiceData);
                                                                let serviceChoices: Array<any> = initServiceData.choices || [];

                                                                if (serviceChoices && serviceChoices.length) {
                                                                    // Insert postcode in address choice item
                                                                    serviceChoices.map((choice) => {
                                                                        if (choice.positions.indexOf(ChoicesPositions.Init) !== -1) {
                                                                            if (choice && choice.choice_items && choice.choice_items.length) {
                                                                                choice.choice_items.map((choiceItem) => {
                                                                                    if (choiceItem.type === 'address') {
                                                                                        const newChoiceItemValue = currentTransactionAddresss.hasOwnProperty('id') ? {
                                                                                            id: currentTransactionAddresss.id,
                                                                                        } : {
                                                                                                postcode: currentTransactionAddresss.postcode,
                                                                                            };

                                                                                        choiceItem.value = newChoiceItemValue;
                                                                                    }
                                                                                });
                                                                            }
                                                                        }
                                                                    });
                                                                }

                                                                const createBookingData = {
                                                                    service: {
                                                                        // eslint-disable-next-line radix
                                                                        id: parseInt(serviceId),
                                                                        choices: serviceChoices,
                                                                    },
                                                                };

                                                                const queryParams: SDKQueryParams = {
                                                                    expand: ['all.all.all.all'],
                                                                };

                                                                // eslint-disable-next-line @typescript-eslint/no-shadow
                                                                createTransaction.call(
                                                                    createBookingData,
                                                                    queryParams,
                                                                    (success: Boolean, response: any) => {
                                                                        if (success) {
                                                                            const state = _orderData.getActiveTransactionProgress();

                                                                            _orderData.bookingType = 'normal';

                                                                            // Set Skip Flag
                                                                            _orderData.skipStep = true;

                                                                            _navigationService.navigateMe(state.position, { pathParams: state.choice_id ? [state.choice_id] : [] });

                                                                        } else {
                                                                            modalData = {
                                                                                buttons: [
                                                                                    {
                                                                                        label: this._localizationProvider.getLocalizedText('modalDefaultConfirmButtonTitle'),
                                                                                        closeOnClick: true,
                                                                                        type: 'primary',
                                                                                        state: 'default',
                                                                                        size: 'normal',
                                                                                        action: () => {
                                                                                            _navigationService.navigateMe(ChoicesPositions.Init);
                                                                                        },
                                                                                    },
                                                                                ],
                                                                                type: responsePropertyName
                                                                            };

                                                                            _modalsService.open('custom', modalData);
                                                                        }
                                                                    });
                                                            },
                                                            () => { // Error
                                                                modalData = {
                                                                    buttons: [
                                                                        {
                                                                            label: this._localizationProvider.getLocalizedText('modalDefaultConfirmButtonTitle'),
                                                                            closeOnClick: true,
                                                                            type: 'primary',
                                                                            state: 'default',
                                                                            size: 'normal',
                                                                            action: () => {
                                                                                _navigationService.navigateMe(ChoicesPositions.Init);
                                                                            },
                                                                        },
                                                                    ],
                                                                    type: responsePropertyName
                                                                };

                                                                _modalsService.open('custom', modalData);
                                                            },
                                                        )
                                                        .catch((error) => {
                                                            modalData = {
                                                                buttons: [
                                                                    {
                                                                        label: this._localizationProvider.getLocalizedText('modalDefaultConfirmButtonTitle'),
                                                                        closeOnClick: true,
                                                                        type: 'primary',
                                                                        state: 'default',
                                                                        size: 'normal',
                                                                        action: () => {
                                                                            _navigationService.navigateMe(ChoicesPositions.Init);
                                                                        },
                                                                    },
                                                                ],
                                                                type: responsePropertyName
                                                            };

                                                            _modalsService.open('custom', modalData);
                                                        });
                                                } else {
                                                    modalData = {
                                                        buttons: [
                                                            {
                                                                label: this._localizationProvider.getLocalizedText('modalDefaultConfirmButtonTitle'),
                                                                closeOnClick: true,
                                                                type: 'primary',
                                                                state: 'default',
                                                                size: 'normal',
                                                                action: () => {
                                                                    _navigationService.navigateMe(ChoicesPositions.Init);
                                                                },
                                                            },
                                                        ],
                                                        type: responsePropertyName
                                                    };

                                                    _modalsService.open('custom', modalData);
                                                }
                                            } else {
                                                modalData = {
                                                    buttons: [
                                                        {
                                                            label: this._localizationProvider.getLocalizedText('modalDefaultConfirmButtonTitle'),
                                                            closeOnClick: true,
                                                            type: 'primary',
                                                            state: 'default',
                                                            size: 'normal',
                                                            action: () => {
                                                                _navigationService.navigateMe(ChoicesPositions.Init);
                                                            },
                                                        },
                                                    ],
                                                    type: responsePropertyName
                                                };

                                                _modalsService.open('custom', modalData);
                                            }
                                        },
                                    },
                                ],
                                type: responsePropertyName
                            };

                            _modalsService.open('custom', modalData);
                        } else {
                            modalData = {
                                buttons: [
                                    {
                                        label: this._localizationProvider.getLocalizedText('modalDefaultConfirmButtonTitle'),
                                        closeOnClick: true,
                                        type: 'primary',
                                        state: 'default',
                                        size: 'normal',
                                        action: () => {
                                            _navigationService.navigateMe(ChoicesPositions.Init);
                                        },
                                    },
                                ],
                                type: responsePropertyName
                            };

                            _modalsService.open('custom', modalData);
                        }
                    };

                    break;
                default:
                    /**
                     * All reporting is handled in ErrorHandlingService
                     * here just open default error modal due to unexpected error code
                     */
                    if (this.errorCodesWithoutAction.indexOf(errorCode) === -1) {
                        validationCallback = () => {
                            modalData = {
                                message: errorMessage,
                                buttons: [
                                    {
                                        label: this._localizationProvider.getLocalizedText('modalDefaultConfirmButtonTitle'),
                                        closeOnClick: true,
                                        type: 'primary',
                                        state: 'default',
                                        size: 'normal',
                                        action: () => {
                                            this.responsePayloadHandler(error, options.skipPositionCheckForModals);
                                        },
                                    },
                                ],
                                type: responsePropertyName
                            };

                            _modalsService.open('custom', modalData);
                        };
                    }

                    break;
            }

            this.sendException(error, responsePropertyName, response.requestedUrl);

            if (responsePropertyName === 'error' && typeof validationCallback === 'function') { validationCallback(); } // if it's an error - invoke method

            return responsePropertyName === 'warning' ? validationCallback : null; // callback is meant to work for warnings on next step
        } else {
            return responsePropertyName === 'warning' ? validationCallback : null; // callback is meant to work for warnings on next step
        }
    }

    /**
     * 
     * @param error error object from response
     * @param errorType warning/error
     */
    private sendException(error: any, errorType?: string, requestedUrl?: string): void {
        let exceptionData: any = {
            external_identifier: 'OBF' + (environment && environment.project !== 'fantastic-services' ? `-${environment.project.toUpperCase()}` : ''),
            message: error.message,
            body: {}
        };


        // API error
        if (errorType === 'error' || errorType === 'warning') {
            Object.assign(exceptionData, {
                exception: error.code
            });

            Object.assign(exceptionData.body, {
                error_type: errorType
            });
            
            if (requestedUrl) Object.assign(exceptionData, { path:  requestedUrl});
        } else { // Javascript error
            Object.assign(exceptionData, {
                exception: 'Javascript error'
            });

            Object.assign(exceptionData.body, {
                stacktrace: error.stacktrace
            });
        }


        
        const exceptionsService: ExceptionsService = this._injector.get(ExceptionsService);

        // Dont listen to the returned promise
        exceptionsService.sendException(exceptionData);
    }
}
