import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'userAddresses' })

/**
 * Filter records by uniqueness
 */
export class UniqueRecordsPipe implements PipeTransform {
    /**
     * Filter records
     * @param records array of records to be filtered
     * @param recordPropertyName property name of each record which is unique
     */
    public transform(records: Array<any>, recordPropertyName: string): Array<any> {

        return records.filter((record: any, index: number, array: Array<any>) => {
            return array.map((mapObj: any) => {
                return mapObj[recordPropertyName];
            }).indexOf(record[recordPropertyName]) === index;
        });
    }
}
