<div class="obf-register-box">
    <div class="obf-message-box obf-error" *ngIf="componentSettings.errorMessages.length">
        <div class="obf-content-box">
            <div class="obf-icon">
                <i class="obf-icon-error" aria-hidden="true"></i>
            </div>
            <div class="obf-messages-holder">
                <span class="obf-message"
                    *ngFor="let error of componentSettings.errorMessages">{{ error.message }}</span>
            </div>
        </div>
    </div>

    <div *ngIf="socialLogins.length">
        <div class="row">
            <div class="obf-form-item col-12" *ngFor="let scLogin of socialLogins">
                <app-button
                    (click)="socialLogin(scLogin)"
                    [socialType]="'register'"
                    [attr.data-e2e]="scLogin.type"
                    [type]="scLogin.type"
                    [name]="scLogin.name"
                    [isFullWidth]="true"
                ></app-button>
            </div>
        </div>
    </div>

    <div class="note obf-divider-box"  *ngIf="socialLogins.length && !disableEmailAuthorization">
        <span> {{ componentSettings?.details?.labels?.signUp?.label }} </span>
    </div>

    <ng-container *ngIf="!disableEmailAuthorization || (!socialLogins || !socialLogins.length)">
        <form [formGroup]="registerForm" (ngSubmit)="processForm()" #formDir="ngForm" *ngIf="registerForm">
            <div class="obf-form-part" [ngClass]="{ 'expand' : isBoxExpanded}">
                <div class="obf-fs-input-wrap">
                    <div>
                        <input FsFocusHandler [inputScrollParent]="'.obf-lr-modal'" [fsTextField]="firstName.value"
                            [labelText]="componentSettings?.details?.fields?.firstName?.label"
                            [errorMessage]="componentSettings?.details?.fields?.firstName?.errMsg"
                            [error]="firstName.invalid && formSubmitted" type="text" id="signup-first-name"
                            name="signup-first-name" [attr.data-e2e]="'firstName'" formControlName="firstName"
                            [placeholder]="componentSettings?.details?.fields?.firstName?.placeholder" />
                    </div>
                </div>
                <div class="obf-fs-input-wrap">
                    <div>
                        <input FsFocusHandler [inputScrollParent]="'.obf-lr-modal'" [fsTextField]="lastName.value"
                            [labelText]="componentSettings?.details?.fields?.lastName?.label"
                            [errorMessage]="componentSettings?.details?.fields?.lastName?.errMsg"
                            [error]="lastName.invalid && formSubmitted" type="text" id="signup-last-name"
                            name="signup-last-name" [attr.data-e2e]="'lastName'" formControlName="lastName"
                            [placeholder]="componentSettings?.details?.fields?.lastName?.placeholder" />
                    </div>
                </div>
                <div class="obf-fs-input-wrap" *ngIf="birthdateFormFieldEnabled">
                    <div>
                        <input
                            #birthDateField
                            FsFocusHandler
                            [inputScrollParent]="'.obf-lr-modal'"
                            [fsTextField]="birthDateField"
                            [labelText]="componentSettings?.details?.fields?.birthDate?.label"
                            [placeholder]="componentSettings?.details?.fields?.birthDate?.placeholder"
                            type="text"
                            id="birth-date"
                            name="birth-date"
                            [attr.data-e2e]="'birth-date'"
                            FsSpecialFields
                            [specialFieldType]="'birth-date'"
                        />
                    </div>
                </div>
                <div class="obf-fs-input-wrap customPhoneInput">
                    <div class="obf-fs-text-input">
                        <!-- <input FsFocusHandler [inputScrollParent]="'.obf-lr-modal'" fsTextField
                            [labelText]="componentSettings?.details?.fields?.phone?.label"
                            [errorMessage]="componentSettings?.details?.fields?.phone?.errMsg"
                            [error]="phone.invalid && formSubmitted" type="text" id="phone" name="phone"
                            [attr.data-e2e]="'phone'" formControlName="phone"
                            [placeholder]="componentSettings?.details?.fields?.phone?.placeholder" /> -->
                        <ngx-intl-tel-input
                            [cssClass]="'phone-input'"
                            [preferredCountries]="preferredCountries"
                            [enableAutoCountrySelect]="true"
                            [enablePlaceholder]="true"
                            [customPlaceholder]="componentSettings.details.fields.phone.customPlaceholder"
                            [searchCountryFlag]="true"
                            [searchCountryField]="[
                            SearchCountryField.Iso2,
                            SearchCountryField.Name
                            ]"
                            [selectFirstCountry]="false"
                            [selectedCountryISO]="selectedCountry"
                            [maxLength]="15"
                            [phoneValidation]="true"
                            [separateDialCode]="separateDialCode"
                            [numberFormat]="PhoneNumberFormat.National"
                            name="phone"
                            formControlName="phone"
                        >
                        </ngx-intl-tel-input>
                    </div>
                </div>
                <div class="obf-fs-input-wrap">
                    <div>
                        <input FsFocusHandler [inputScrollParent]="'.obf-lr-modal'" [fsTextField]="email.value"
                            [labelText]="componentSettings?.details?.fields?.email?.label" [errorMessage]="
                                email.errors?.email && email.value.length
                                    ? componentSettings?.details?.fields?.email?.errMsgs?.format
                                    : componentSettings?.details?.fields?.email?.errMsgs?.required
                            " [error]="email.invalid && formSubmitted" type="text" id="signup-email"
                            name="signup-email" [attr.data-e2e]="'email'" formControlName="email"
                            [placeholder]="componentSettings?.details?.fields?.email?.placeholder" />
                    </div>
                </div>
                <div formGroupName="passwordGroup">
                    <div class="obf-fs-input-wrap">
                        <div class="obf-password-box">
                            <input FsFocusHandler [inputScrollParent]="'.obf-lr-modal'" autocomplete="off"
                                [fsTextField]="password.value"
                                [labelText]="componentSettings?.details?.fields?.password?.label" [errorMessage]="
                                    password.errors?.minlength && !password.errors?.required
                                        ? componentSettings?.details?.fields?.password?.errMsgs?.format
                                        : componentSettings?.details?.fields?.password?.errMsgs?.required
                                " [error]="password.invalid && formSubmitted"
                                type="{{ componentSettings?.details?.fields?.password?.showPassword ? 'text' : 'password' }}"
                                id="signup-password" name="signup-password" formControlName="password"
                                [attr.data-e2e]="'password'"
                                [placeholder]="componentSettings?.details?.fields?.password?.placeholder" />
                            <span class="obf-check" (click)="
                                    componentSettings.details.fields.password.showPassword = !componentSettings.details.fields.password
                                        .showPassword
                                ">
                                <i class="obf-icon icon-si-eye" aria-hidden="true"
                                    *ngIf="componentSettings.details.fields.password.showPassword"></i>
                                <i class="obf-icon icon-si-crossed-eye" aria-hidden="true"
                                    *ngIf="!componentSettings.details.fields.password.showPassword"></i>
                            </span>
                        </div>
                    </div>
                </div>
    
                <div class="obf-fs-input-wrap obf-refferal-wrap" *ngIf="referralFormFieldEnabled">
                    <div class="label" [attr.data-e2e]="'showReferral'" (click)="showReferral = !showReferral"
                        [ngClass]="{ 'open': showReferral }">
                        <span class="obf-icon icon-si-plus"></span>
                        <span
                            localizedText
                            [textKeyword]="'addReferralCodeLabel'"
                        >
                        </span>
                    </div>
    
                    <div class="obf-promocode-field obf-fs-input-wrap" [appSlideInOut]="showReferral">
                        <div>
                            <input FsFocusHandler [inputScrollParent]="'.obf-lr-modal'" fsTextField
                                [labelText]="componentSettings?.details?.fields?.referralCodeField?.label"
                                [errorMessage]="componentSettings?.details?.fields?.referralCodeField?.errMsg"
                                [error]="referralCodeField.invalid && formSubmitted" type="text" id="referralCodeField"
                                [attr.data-e2e]="'referral'" name="referralCodeField" formControlName="referralCodeField" />
                        </div>
                    </div>
                </div>
    
                <div class="obf-fs-input-wrap obf-accept-privacy" *ngIf="privacyPolicyUrl && termsAndConditionsUrl"
                    [ngClass]="{ 'obf-error': reg_terms.errors?.required && formSubmitted }">
                    <div class="obf-fs-check-inputs ">
                        <input id="reg_terms" name="reg_terms" type="checkbox" formControlName="reg_terms" />
                        <label for="reg_terms" [attr.data-e2e]="'terms-checkbox'">
                            <span class="obf-checkbox icon-si-check"></span>
    
                            <span class="obf-terms-text-holder">
                                <span localizedText [textKeyword]="'termsAndConditionsText1'"></span> {{ brandName }}'
                                <a
                                    href="{{ privacyPolicyUrl }}"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    localizedText
                                    [textKeyword]="'termsAndConditionsText2'"
                                    >
                                </a>&nbsp;
                                <span localizedText [textKeyword]="'termsAndConditionsText3'"></span>&nbsp;
                                <a
                                    href="{{ termsAndConditionsUrl }}"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    localizedText
                                    [textKeyword]="'termsAndConditionsText4'">
                                </a>.
                            </span>
                        </label>
                    </div>
                    <small class="obf-fs-error">{{ componentSettings?.details?.fields?.terms?.errMsg }} </small>
                </div>
    
                <div class="obf-fs-input-wrap updates-way">
                    <div class="obf-checkbox-optimal-description" localizedText [textKeyword]="'preferencesLabel'"></div>
    
                    <div class="obf-email">
                        <div class="obf-fs-check-inputs">
                            <input id="emailOptIn" name="emailOptIn" type="checkbox" formControlName="emailOptIn" />
                            <label for="emailOptIn">
                                <span class="obf-checkbox icon-si-check"></span>
                                {{ componentSettings?.details?.fields?.emailOptIn?.label }}
                            </label>
                        </div>
                    </div>
                    <div class="obf-sms">
                        <div class="obf-fs-check-inputs ">
                            <input id="smsOptIn" name="smsOptIn" type="checkbox" formControlName="smsOptIn" />
                            <label for="smsOptIn">
                                <span class="obf-checkbox icon-si-check"></span>
                                {{ componentSettings?.details?.fields?.smsOptIn?.label }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <!-- <div class="obf-form-item col-12"> -->
                <app-button
                    id="register-button-component"
                    [isSubmitButton]="true"
                    [label]="componentSettings?.details?.fields?.signUp?.label"
                    [type]="'primary'"
                    [isFullWidth]="true">
                </app-button>
                <!-- </div> -->
            </div>
        </form>
    </ng-container>
    
</div>

