<div class="obf-claim-account-box">
    <div *ngIf="!emailSent; else confirmationMessageBox">
        <div class="claim-account-title">
            <h2 localizedText [textKeyword]="'claimAccountSectionTitle'"></h2>
        </div>
        <div class="obf-message-box obf-error" *ngIf="componentSettings.errorMessages.length">
            <div class="obf-content-box">
                <div class="obf-icon">
                    <i class="icon-si-close-circle" aria-hidden="true"></i>
                </div>
                <div class="obf-messages-holder">
                    <span class="obf-message" *ngFor="let error of componentSettings.errorMessages">
                        {{ error.message }}
                    </span>
                </div>
            </div>
        </div>
        <form [formGroup]="claimAccount" (ngSubmit)="processForm()" #claimAccountForm="ngForm">
            <div class="obf-form-part">
                <div class="mb-3">
                    <p class="mb-3" [innerHTML]="_localizationProvider.getLocalizedText('claimAccountFormDescription')">
                    </p>
                    <p>
                        <span localizedText [textKeyword]="'claimAccountFormNote1'"></span>&nbsp;
                        <span
                            class="link-color cursor"
                            (click)="goToRegister()"
                            localizedText
                            [textKeyword]="'claimAccountFormNote2'">
                        </span>&nbsp;
                        <span localizedText [textKeyword]="'claimAccountFormNote3'"></span>
                    </p>
                </div>
                <div class="obf-fs-input-wrap">
                    <div>
                        <input [fsTextField]="email.value" [labelText]="componentSettings?.fields?.email?.label"
                            FsFocusHandler [inputScrollParent]="'.obf-lr-modal'" [errorMessage]="
                            email.errors?.email && email.value.length
                                ? componentSettings?.fields?.email?.errMsgs
                                      ?.format
                                : componentSettings?.fields?.email?.errMsgs
                                      ?.required
                        " [error]="email.invalid && claimAccountForm.submitted" type="text" id="claim-email"
                            name="email" formControlName="email" [placeholder]="
                            componentSettings?.fields?.email?.placeholder
                        " />
                    </div>
                </div>

                <div>
                    <app-button
                        [isSubmitButton]="true" 
                        [label]="componentSettings?.fields?.submitButton?.label"
                        [type]="'primary'" 
                        [state]="'default'" 
                        [isFullWidth]="true">
                    </app-button>
                </div>
            </div>
        </form>
    </div>
    <div class="back-to-login">
        <span
            class="link-color cursor"
            (click)="gotoLogin()"
            localizedText
            [textKeyword]="'backToSignInLabel'"></span>
    </div>

    <ng-template #confirmationMessageBox>
        <div class="claim-account-title">
            <h2 localizedText [textKeyword]="'thankYouMessage'"></h2>
        </div>
        <div>
            <p>
                <span localizedText [textKeyword]="'claimAccountConfirmationMsg1'"></span>&nbsp;<b>{{ confirmationEmail }}</b>&nbsp;
                <span localizedText [textKeyword]="'claimAccountConfirmationMsg2'"></span>
            </p>
        </div>
    </ng-template>
</div>
