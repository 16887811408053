<div class="obf-modal-overlay"></div>
<div class="obf-container obf-popup-holder-box">
    <div class="popup">
        <div class="obf-popup-header">
            <span class="close-btn icon-si-close-cancel" aria-label="Close" (click)="closeModal()"></span>
        </div>

        <div class="obf-popup-body" style="margin-bottom: 0;">
            <app-tip-pro [payload]="tipProPayload" (tipEvent)="onTipEvent($event)"></app-tip-pro>
        </div>

        <!-- <div class="obf-popup-footer" [ngClass]="{ 'single-action-only': modalButtons?.length === 1 }">
        </div> -->
    </div>
</div>