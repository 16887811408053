import { Position } from '@models/position';

const MAIN_TITLE = '';

export const positionsConfig: Position[] = [
    {
        id: 3,
        position: 'init',
        keywords: ['zero-step', 'init'],
        slug: 'init',
        get title() {
            delete this.title;
            return this.title = MAIN_TITLE + this.slug;
        },
        children: [
            {
                id: 1,
                position: 'on_welcome',
                keywords: ['on_welcome', 'init/select-service'],
                slug: 'select-service',
                get title() {
                    delete this.title;
                    return this.title = MAIN_TITLE + 'Select a Service';
                },
            },
            {
                id: 2,
                position: 'after_welcome',
                keywords: ['after_welcome', 'init/enter-postcode'],
                slug: 'enter-postcode',
                get title() {
                    delete this.title;
                    return this.title = MAIN_TITLE + 'Enter Postcode';
                },
            },
            {
                id: 0,
                position: 'no_coverage',
                keywords: ['no_coverage', 'init/no-coverage'],
                slug: 'no-coverage',
                get title() {
                    delete this.title;
                    return this.title = MAIN_TITLE + 'Coverage';
                },
            },
        ],
    },
    {
        id: 10,
        position: 'configurator',
        keywords: ['configurator', 'configurator'],
        slug: 'configurator',
        get title() {
            delete this.title;
            return this.title = MAIN_TITLE + 'Service details';
        },
    },
    {
        id: 4,
        position: 'before_availability',
        keywords: ['before-availability', 'before_availability'],
        slug: 'before-availability',
        get title() {
            delete this.title;
            return this.title = MAIN_TITLE + 'Before availability';
        },
    },
    {
        id: 5,
        position: 'on_availability',
        keywords: ['on-availability', 'on_availability'],
        slug: 'on-availability',
        get title() {
            delete this.title;
            return this.title = MAIN_TITLE + 'Price & schedule';
        },
    },
    {
        id: 6,
        position: 'after_availability',
        keywords: ['after-availability', 'after_availability'],
        slug: 'after-availability',
        get title() {
            delete this.title;
            return this.title = MAIN_TITLE + 'After availability';
        },
    },
    {
        id: 20,
        position: 'before_summary',
        keywords: ['before-summary', 'before_summary'],
        slug: 'before-summary',
        get title() {
            delete this.title;
            return this.title = MAIN_TITLE + 'Before summary';
        },
    },
    {
        id: 30,
        position: 'on_summary',
        keywords: ['on-summary', 'on_summary'],
        slug: 'on-summary',
        get title() {
            delete this.title;
            return this.title = MAIN_TITLE + 'Contact details';
        },
    },
    {
        id: 40,
        position: 'before_confirmation',
        keywords: ['before-confirmation', 'before_confirmation'],
        slug: 'before-confirmation',
        get title() {
            delete this.title;
            return this.title = MAIN_TITLE + 'Payment details';
        },
    },
    {
        id: 50,
        position: 'confirmation',
        keywords: ['on-confirmation', 'confirmation'],
        slug: 'on-confirmation',
        get title() {
            delete this.title;
            return this.title = MAIN_TITLE + 'Complete booking';
        },
    },
];
