import { ErrorHandler, Injectable, Injector } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { ErrorHandlingService } from '../../services/errors/error-handling.service';
import { ModalsService } from '../../services/modals.service';
import { MainDataProvider } from './../../providers/xrm/main-data.provider';

/**
 * We extends the default angular error handler to fetch the errors
 * and send it to the XRM api point for the errors
 */
@Injectable()
export class CustomErrorHandler extends ErrorHandler {
    constructor(private _injector: Injector, private _mainDataProvider: MainDataProvider) {
        super();
    }

    /**
     * Override base class method
     * @param error
     */
    public handleError(error: any): void {
        const errorHandlingService = this._injector.get(ErrorHandlingService);

        if (error && error.message.indexOf('ChunkLoadError') !== -1) {
            errorHandlingService.handle('ChunkLoadError', error);
            return;
        }

        if (this._mainDataProvider && this._mainDataProvider.obfEnv && this._mainDataProvider.obfEnv.env !== 'localhost') {
            const eventId = Sentry.captureException(error.originalError || error);

            if (this._mainDataProvider.obfEnv.env === 'development' || this._mainDataProvider.obfEnv.env === 'stage') {
                Sentry.showReportDialog({ eventId });
            }
        }

        // TODO Need to be filtrate the stack trace because return part from zone.js
        if (error instanceof Error) {
            errorHandlingService.handle('jsError', error);
        }

        if (this._mainDataProvider && this._mainDataProvider.obfEnv && this._mainDataProvider.obfEnv.env !== 'production') {
            // Simply prints out the error in the console
            super.handleError(error);
            // debugger;
        }

        let knownErrorKeywords: Array<string> = [
            'isTrusted', // Uncaught (in promise): Event: {"isTrusted":true}
            'paypal.com', // Uncaught (in promise): Error: https://c.paypal.com/da/r/fb.js failed to load.
            'localStorage', // Uncaught (in promise): Failed to read the 'localStorage' property from 'Window': Access is denied for this document
        ],
            errorIsKnown = false;

        if (error && error.message) {
            for (let errorKeywordIndex = 0; errorKeywordIndex < knownErrorKeywords.length; errorKeywordIndex++) {
                const errorKeyword = knownErrorKeywords[errorKeywordIndex];

                errorIsKnown = error.message.indexOf(errorKeyword) !== -1 ? true : errorIsKnown;
            }

            if (!errorIsKnown) {
                const modalsService = this._injector.get(ModalsService);

                // Open modal
                modalsService.open('defaultError');
            }
        }

    }
}
