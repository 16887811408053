import { Component, ComponentRef, HostListener, OnInit } from '@angular/core';
import { LocalizationProvider } from '@providers/localization.provider';
import { MainDataProvider } from 'src/app/providers/xrm/main-data.provider';

@Component({
  selector: 'app-custom-modal',
  templateUrl: './custom-modal.component.html',
  styleUrls: ['./custom-modal.component.scss'],
})
export class CustomModalComponent {
    public modalTitle = '';
    public modalMessage = '';
    public modalType = '';
    public modalServicePrice = '';
    public modalButtons: Array<ModalButton>;
    private closeOnOverlayClick = false;
    private componentRef: ComponentRef<CustomModalComponent>;

    private callback: Function;

    @HostListener('document:click', ['$event.target']) public clickedOutside(targetElement) {
        // Close payments dropdown when you click outside of input element
        if (targetElement && targetElement.className.indexOf('obf-modal-overlay') !== -1 && this.closeOnOverlayClick) {
            this.closeModal();
        }
    }

    constructor(public _mainDataProvider: MainDataProvider, public _localizationProvider: LocalizationProvider) { }

    /**
     * Sets data needed for popup visualisation
     * @param {object} modalData
     * @param {object} modalComponentRef reference to component itself
     */
    public setModalData(modalComponentRef: ComponentRef<CustomModalComponent>, modalData: any) {

        const defaultButtons = [
            {
                label: this._localizationProvider.getLocalizedText(
                    'modalDefaultConfirmButtonTitle'
                ),
                closeOnClick: true,
                type: 'primary',
                state: 'default',
                size: 'normal',
            },
        ]

        // set to null if you dont need any title      
        if (modalData.title !== null) {
            this.modalTitle = modalData.title ? modalData.title : this._localizationProvider.getLocalizedText('modalDefaultTitle');
        }
        this.modalMessage = modalData.message ? modalData.message : this._localizationProvider.getLocalizedText('modalErrMsg1');
        this.modalType = modalData.modalType ? modalData.modalType : '';
        this.modalServicePrice = modalData.servicePrice ? modalData.servicePrice : '';
        this.modalButtons = modalData.buttons ? modalData.buttons : defaultButtons;

        if (modalData.callback && typeof modalData.callback === 'function') {
            this.callback = modalData.callback;
        }

        this.closeOnOverlayClick = modalData.closeOnOverlayClick ? modalData.closeOnOverlayClick : false;

        // Safe components own reference in order to destroy it
        this.componentRef = modalComponentRef;
    }

    /**
     * Destroy component by itself
     */
    public closeModal(): void {

        if (this.callback && typeof this.callback === 'function') {
            this.callback();
        }

        this.componentRef.destroy();
    }

    public buttonClick(button: ModalButton): void {
        // If button has action function - invoke it
        if (button.action && typeof button.action === 'function') {
            button.action();
        }

        if (button.closeOnClick) {
            this.closeModal();
        }
    }
}

interface ModalButton {
    label: string;
    action?: Function;
    type: string;
    closeOnClick: boolean;
}
