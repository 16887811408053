import { SDKModuleSettings } from '../Api/Interfaces/SDKModuleSettings';
/**
 * Configuration constants
 */

export class Configuration {
    public apiServer: string;
    public accountsServer: string;
    public cookieKey = 'fantasticservices.com';

    public currentEnv: any;

    constructor(_SDKModuleSettings: SDKModuleSettings) {
            this.currentEnv = _SDKModuleSettings;
            this.apiServer = _SDKModuleSettings.api;
            this.accountsServer = _SDKModuleSettings.accounts_api;
            this.cookieKey = _SDKModuleSettings.cookieKey;
    }

    public changeToProxy() {
        if (this.currentEnv && this.currentEnv.api_proxy) {
            this.apiServer = this.currentEnv.api_proxy;
            return true;
        } else {
            return false;
        }
    }
}
