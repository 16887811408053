import { ConfigurationService } from '@services/configuration.service';
import { ConfigurationData } from './../../providers/xrm/configuration-data.provider';
import { ErrorHandlingService } from './../../services/errors/error-handling.service';
import { PostMessageService } from './../../services/post-message.service';
import { Injectable } from '@angular/core';
import { MainDataProvider } from '@providers/main-data.provider';
import APIDataSource from '@sos-ui/Core/Data/DataSources/API/APIDataSource';
import { Configuration } from '@sos-ui/Core/Domain/DTOs/Configuration/ConfigurationDTO';
import { Subject, BehaviorSubject } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { LocalizationProvider } from '@providers/localization.provider';

@Injectable({
    providedIn: 'root',
})
export class ReactNativeControllerService {
    public apiDataSource: APIDataSource;
    public interceptor = new Subject();

    public searchConfig: any = {};

    public searchModuleConfig: BehaviorSubject<null | Configuration.DTO> =
        new BehaviorSubject(null);

    public generalTexts: any = null;

    constructor(
        private mainDataProvider: MainDataProvider,
        private localizationProvider: LocalizationProvider,
        private postMessageService: PostMessageService,
        private _configurationService: ConfigurationService,
        private _configurationProvider: ConfigurationData,
        private errorHandlingService: ErrorHandlingService
    ) {

        this.setApiDataSourceConfig();

        this.registerInterceptor();

        // register handlers to return configs if the are required from client.js
        this.registerPostMessagesListeners();

        // Subscribe for changes affecting the modules configurations.
        this.subscribeForSessionIdChange();
        this.subscribeForObfOptions();
        this.subscribeThemeChange();
        this.subscribeActiveLanguage();
    }


    private subscribeActiveLanguage() {
        this._configurationProvider.configurationSettingsBS.subscribe((configuration) => {
            this.setLocalizedTexts();
            this.setApiDataSourceConfig();
        });
    }

    private setApiDataSourceConfig() {
        const config = this.mainDataProvider.getEndpointConfig();

        this.searchConfig = {
            name: 'search-web',
            settings: {
                api: {},
                service_icon_property: 'icon_image_url',
            },
            assets: {
                images: {
                    search_icon_source:
                        'https://files.dxr.cloud/VzEFg9PA2htBg4H6C1qqoOZNtIdG40PwqDYz7NWZfIwbMFcaajg1dBtQu7IG',
                    cancel_icon_source:
                        'https://files.dxr.cloud/MEDAFJXOijRqGn1Mhu9bV1xxm5EGEtt12WERnhBF7U0A4oCxVoVJgUvCqPT5',
                },
            },
            theme: {
                typeface: 'Inter',
                font_family: [],
                colors: {
                    white: '#ffffff',
                    custom10: '#c3e2fc',
                    custom20: '#dbe2eb',
                    custom30: '#f1f4f8',
                    custom40: '#f4fafe',
                    custom50: '#e9f6fe',
                    customLightPink: '#fae4ec',
                    veryLightContained: '#fdf2f9',
                    lightContainedColor: '#ef7fc3',
                    containedColor: '#e00087',
                    darkContainedColor: '#c60077',
                    customDarkPink: '#ab286d',
                    lightestGrey: '#a3b3c6',
                    veryLightGrey: '#879aaf',
                    grey: '#687d96',
                    veryDarkGrey: '#394e66',
                    darkestGrey: '#293b51',
                    veryLightMargin: '#8dc3f5',
                    links: '#2e67cb',
                    darkMargin: '#2353af',
                    veryDarkMarginColor: '#172a41',
                    darkestMarginColor: '#092156',
                    customYellow: '#ffe36b',
                    CustomDarkYellow: '#813218',
                    alertColor: '#cd3f41',
                    customGreen: '#85d3b0'
                },
                layouts: {
                    search_bar: {
                        container: {
                            backgroundColor: 'white',
                            borderWidth: 1,
                            borderColor: '#BFCCDA',
                            borderRadius: 5,
                            padding: 5,
                            height: 60,
                            shadowColor: 'transaprent',
                            shadowRadius: 6,
                            shadowOffset: {
                                width: 0,
                                height: 0,
                            },
                        },
                        focused_container: {
                            shadowColor: 'none',
                            borderColor: '#BFCCDA',
                            borderBottomRightRadius: 0,
                            borderBottomLeftRadius: 0,
                        },
                        placeholder_text_color: '',
                        bottom_border: {
                            backgroundColor: 'none',
                        },
                        icon: {
                            tintColor: '',
                            marginTop: 0,
                            height: '100%',
                            marginLeft: 10,
                            width: 20,
                        },
                        input: {
                            fontFamily: 'Inter',
                            color: '#40403F',
                            outline: 'none',
                            marginLeft: 13,
                            marginRight: 10,
                            width: '100%',
                        },
                        button: {
                            container: {
                                width: 16,
                            },
                            icon: {
                                height: 12,
                                width: 12,
                                tintColor: '',
                                marginRight: 13,
                            },
                            title: {
                                fontFamily: 'Inter',
                                fontWeight: 700,
                                color: '',
                                fontSize: 14,
                            },
                        },
                    },
                    search_results: {
                        container: {
                            backgroundColor: 'white',
                            top: 58,
                            borderBottomRightRadius: 5,
                            borderBottomLeftRadius: 5,
                        },
                        focused_container: {
                            borderWidth: 1,
                            borderColor: '#BFCCDA',
                        },
                        service: {
                            container: {
                                paddingHorizontal: 10,
                                paddingVertical: 12,
                                maxHeight: 65,
                            },
                            selectedContainer: {
                                backgroundColor: '#F0F3F7',
                            },
                            image: {
                                width: 20,
                                height: 20,
                                borderRadius: 5,
                                marginTop: 4,
                                marginLeft: 10,
                            },
                            title: {
                                fontFamily: 'Inter',
                                color: '#40403F',
                                marginTop: 0,
                            },
                            description: {
                                fontFamily: 'Inter',
                                color: '#717171',
                                fontSize: 12,
                                fontWeight: 700,
                                marginTop: 5,
                            },
                        },
                        delimiter: {
                            container: {
                                paddingTop: 15,
                                paddingBottom: 10,
                            },
                            title: {
                                fontFamily: 'Inter',
                                fontWeight: 700,
                                color: '#717171',
                                marginLeft: 0,
                            },
                        },
                        button: {
                            container: {},
                            title: {
                                fontFamily: 'Inter',
                                color: '#394E66',
                                fontWeight: 700,
                                fontSize: 16,
                            },
                            button: {
                                marginTop: 0,
                            },
                            button_title: {
                                fontFamily: 'Inter',
                                color: '',
                                fontSize: 14,
                            },
                        },
                    },
                },
            },
            texts: {
                
                    search_placehodler_text: this.localizationProvider.getLocalizedText('serviceSearchPlaceholder'),
                    cancel_button_title: '',
                
            }
        };

        this.apiDataSource = new APIDataSource(
            config.server_url.replace(/^\/+|\/$/gm, ''),
            config.application_token,
            config.profile,
            config.sid,
            config.build,
            config.language
        );
    }

    private subscribeForSessionIdChange() {
        this.mainDataProvider.getSessionIdObserver().subscribe((sid) => {
            this.apiDataSource.sid = sid;
        });
    }

    private setLocalizedTexts() {
        
        this.generalTexts = this._configurationService.getConfigurationSettings()?.customer?.texts?.general;
        this.searchConfig.texts = {
                search_placehodler_text: this.generalTexts?.serviceSearchPlaceholder ? this.generalTexts.serviceSearchPlaceholder : this.localizationProvider.getLocalizedText('serviceSearchPlaceholder'),
                cancel_button_title: '',
            
        }
    }

    private subscribeForObfOptions() {
        this.mainDataProvider
            .getResourceObserverObfOptions()
            .subscribe((obfOptions) => {
                this.setSearchModuleConfig();
            });
    }

    private subscribeThemeChange() {
        this.mainDataProvider.getColorSchema$().subscribe((theme) => {
            this.setSearchModuleConfig();
        });
    }

    public registerPostMessagesListeners() {
        this.postMessageService.registerRequestListeners(
            'search-configuration',
            () => {
                return new Promise(async (res, rej) => {
                    this.getSearchModuleConfigBS()
                        .pipe(
                            filter((config) => !!config),
                            take(1)
                        )
                        .subscribe((config: any) => {
                            let searchConfig = {...config};
                            if (searchConfig?.api?.sid) {
                                delete searchConfig.api.sid;
                            }

                            (searchConfig as any).reactSearch =
                                this.mainDataProvider.activeReactSearch;

                            // TODO [LOCALIZATION] This need to be located in clientjs when is change language from site or obf to send outside event and to rerender the search with correct language.
                            const language = this.localizationProvider.getCurrentLanguage();

                            searchConfig.texts = config.texts;

                            // return config;
                            res(searchConfig);
                        });
                });
            }
        );
    }

    public registerInterceptor() {
        this.apiDataSource.registerInterceptor(async (response) => {
            if (response?.error && response?.error[0]?.code !== 100100) {
                this.errorHandlingService.validateResponse(response);
                this.interceptor.next(response);
            }
        });
    }

    public getSearchModuleConfig() {
        return JSON.parse(JSON.stringify(this.searchModuleConfig.getValue()));
    }

    public getSearchModuleConfigBS() {
        return this.searchModuleConfig;
    }

    public async setSearchModuleConfig() {
        // api config;
        const api = this.mainDataProvider.getEndpointConfig();

        let themeColors = JSON.parse(
            JSON.stringify(this.mainDataProvider.getColorSchema$().getValue())
        );

        const profileConfig =
            await this._configurationService.getConfiguration();

        // set search config fallback.
        let config = JSON.parse(JSON.stringify(this.searchConfig));

        // set search layout style
        if (profileConfig?.application?.theme?.colors) {
            if (config?.theme?.layouts?.search_results?.button?.button_title.hasOwnProperty('color')) {
                config.theme.layouts.search_results.button.button_title.color = profileConfig?.application?.theme?.colors?.primary_color;
            }
        }

        // Get icons form profile configuration -> module_config
        if (profileConfig?.module_config?.data) {

            let searchConfig = profileConfig.module_config.data.find(
                (item) => 
                    item.name === 'search-web'
                
            )

            if (searchConfig?.assets?.images?.search_icon_source) config.assets.images.search_icon_source = searchConfig.assets.images.search_icon_source;
            if (searchConfig?.assets?.images?.cancel_icon_source) config.assets.images.cancel_icon_source = searchConfig.assets.images.cancel_icon_source;
        }

        config.settings.api = api;
        config.theme.colors = { ...config.theme.colors, ...themeColors };

        this.searchModuleConfig.next(config);
    }
}
