import { Component, Input, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MainDataProvider } from 'src/app/providers/xrm/main-data.provider';

@Component({
    selector: 'app-payment-select-modal',
    templateUrl: './payment-select-modal.component.html',
    styleUrls: ['./payment-select-modal.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class PaymentSelectModalComponent {

    @Input()
    public paymentsArray: any[] = [];

    public selectedPayment = null;

    constructor(public activeModal: NgbActiveModal, public _mainDataProvider: MainDataProvider) { }

    public selectPayment(payment) {
        this.selectedPayment = payment;
    }

    public confirmBooking() {
        this.activeModal.close(this.selectedPayment);
    }

    public closeModal() {
        this.activeModal.dismiss('exit');
    }

}
