import { Component, HostListener, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-standard-popup',
    templateUrl: './standard-popup.component.html',
    styleUrls: ['./standard-popup.component.scss'],
})
export class StandardPopupComponent {
    @Input()
    public popupSettings: StandardPopupSettings = {
        icon: 'none',
        title: 'Default',
        image: '',
        description: '',
        buttons: {
            xButton: {
                action: 'close',
            },
            otherButtons: [
                {
                    label: 'Ok',
                    type: 'primary',
                    state: 'default',
                    size: 'normal',
                    action: 'accept',
                },
            ],
        },
    };

    @HostListener('window:keyup', ['$event'])
    public ListenerEscape(e) {
        if (e.key === 'Escape' && this.popupSettings.buttons && this.popupSettings.buttons.xButton && this.popupSettings.buttons.xButton.action) {
            this.action(this.popupSettings.buttons.xButton.action);
        }
    }

    constructor(public activeModal: NgbActiveModal) { }

    public action(action) {
        this.activeModal.close(action);
    }
}

export interface StandardPopupSettings {
    icon: 'redirect' | 'error' | string;
    title: string;
    image: string;
    description: string;
    buttons: {
        xButton: {
            action: string;
        };
        otherButtons: Array<StandardPopupButtonsSettings>;
    };
}

export interface StandardPopupButtonsSettings {
    label: string;
    type: string;
    state: string;
    size: string;
    action: string;
}
