import { AfterContentChecked, AfterContentInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appDynamicColorChanger]',
})
export class DynamicColorChangerDirective implements AfterContentInit, AfterContentChecked {

  constructor(private el: ElementRef) { }

  public ngAfterContentInit(): void {
    this.changeStyle();
  }

  public ngAfterContentChecked(): void {
    // on every view check whe going to check the style of the button to see if is change the color.
    this.changeStyle();
  }

  /**
   * Actual method for changing style
   */
  private changeStyle() {
    if (this.el.nativeElement) {
      try {
        const computedStyles = getComputedStyle(this.el.nativeElement);
        const backgroundColor = computedStyles.backgroundColor;

        let rgb: any = backgroundColor.replace('rgb(', '').replace('rgba(', '').replace(')', '').split(',');

        rgb = rgb.map((el) => parseInt(el));

        // IE Fix
        if (isNaN(rgb[0])) {
          return;
        }

        if (!rgb[3] && rgb[3] !== undefined) {
          const rgb: any = computedStyles.color.replace('rgb(', '').replace('rgba(', '').replace(')', '').split(',');

          const o = Math.round(((rgb[0] * 299) + (rgb[1] * 587) + (rgb[2] * 114)) / 1000);

          if (o > 141) {
            this.el.nativeElement.style.color = 'black';
          }

          return;
        }

        const o = Math.round(((rgb[0] * 299) + (rgb[1] * 587) + (rgb[2] * 114)) / 1000);

        if (o > 141) {
          this.el.nativeElement.style.color = 'black';
        } else {
          this.el.nativeElement.style.color = 'white';
        }

      } catch (e) {

      }

    }

  }

}
