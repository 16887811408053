import { Injectable } from '@angular/core';
import { SDKQueryParams } from '../../../../lib/xrm-sdk/src/lib/Api/Interfaces/SDKQueryParams';
import { SDKServicesDataService } from '../../../../lib/xrm-sdk/src/lib/Services/SDKServicesDataService';
import { OrderData } from '../../providers/xrm/order-data.provider';
import { LoaderService } from '../loader.service';
import { ErrorHandlingService } from './../../../app/services/errors/error-handling.service';
import { XRMApiCommunicatorService } from './xrmapi-communicator.service';

@Injectable({
    providedIn: 'root',
})
export class TeamsUnitsService {
    private xrmConnection: SDKServicesDataService;

    constructor(
        private _XRMApiCommunicatorService: XRMApiCommunicatorService,
        private _orderData: OrderData,
        private _loaderService: LoaderService,
        private _errorHandlingService: ErrorHandlingService,

    ) {
        this.xrmConnection = this._XRMApiCommunicatorService.getServicesDataService();
    }

    /**
     * Get the team detains by teamId
     * @param teamId
     * @param showGlobalLoader By default value is false
     * @returns {Promise}
     */
    public getTeam(teamId: string | number, showGlobalLoader = false): Promise<any> {
        // todo Important add cache for units and teams (To reduce the request to the xrm).

        const bookingId = this._orderData.activeBooking.getValue()?.get('id');
        const queryParams: SDKQueryParams = {
            expand: ['all.all.all'],
            query: {
                transaction_id: bookingId,
            },
        };

        if (typeof teamId === 'number') {
            teamId = teamId.toString(10);
        }

        return new Promise((res, rej) => {
            this.xrmConnection.getTeams((teamId as string), queryParams)
                .then(
                    (response) => {
                        res(response);
                    },
                )
                .catch(
                    (error) => {
                        this._errorHandlingService.validateResponse(error);

                        rej(error);
                    },
                );
        });
    }

    /**
     * Add team as favorite
     * @param proType team or unit
     * @param proId
     * @param showGlobalLoader By default value is false
     * @returns {Promise}
     */
    public addAsFavorite(proType: string, proId: string | number, showGlobalLoader = false): Promise<any> {
        const loader = this._loaderService.showLoader(),
            bookingId = this._orderData.activeBooking.getValue()?.get('id'),
            queryParams: SDKQueryParams = {
            expand: ['all.all.all'],
            query: {
                transaction_id: bookingId,
            },
        };

        if (typeof proId === 'number') {
            proId = proId.toString(10);
        }

        return new Promise((res, rej) => {
            if (proType === 'team') { // Team
                this.xrmConnection.addTeamAsFavorite((proId as string), queryParams)
                    .then(
                        (response) => {
                            this._loaderService.hideLoader(loader);

                            res(response);
                        },
                    )
                    .catch(
                        (error) => {
                            this._errorHandlingService.validateResponse(error);

                            this._loaderService.hideLoader(loader);

                            rej(error);
                        },
                    );
            } else { // Unit
                this.xrmConnection.addUnitAsFavorite((proId as string), queryParams)
                    .then(
                        (response) => {
                            this._loaderService.hideLoader(loader);

                            res(response);
                        },
                    )
                    .catch(
                        (error) => {
                            this._errorHandlingService.validateResponse(error);

                            this._loaderService.hideLoader(loader);

                            rej(error);
                        },
                    );
            }
        });
    }

    /**
     * Remove unit/team from favorites
     * @param proType team or unit
     * @param proId
     * @param showGlobalLoader By default value is false
     * @returns {Promise}
     */
    public removeFromFavorites(proType: string, proId: string | number, showGlobalLoader = false): Promise<any> {
        const loader = this._loaderService.showLoader(),
            bookingId = this._orderData.activeBooking.getValue()?.get('id'),
            queryParams: SDKQueryParams = {
                expand: ['all.all.all'],
                query: {
                    transaction_id: bookingId,
                },
            };

        if (typeof proId === 'number') {
            proId = proId.toString(10);
        }

        return new Promise((res, rej) => {
            if (proType === 'team') { // Team
                this.xrmConnection.removeTeamFromFavorites((proId as string), queryParams)
                    .then(
                        (response) => {
                            this._loaderService.hideLoader(loader);

                            res(response);
                        },
                    )
                    .catch(
                        (error) => {
                            this._errorHandlingService.validateResponse(error);

                            this._loaderService.hideLoader(loader);

                            rej(error);
                        },
                    );
            } else { // Unit
                this.xrmConnection.removeUnitFromFavorites((proId as string), queryParams)
                    .then(
                        (response) => {
                            this._loaderService.hideLoader(loader);

                            res(response);
                        },
                    )
                    .catch(
                        (error) => {
                            this._errorHandlingService.validateResponse(error);

                            this._loaderService.hideLoader(loader);

                            rej(error);
                        },
                    );
            }
        });
    }

    /**
    * Get the unit detains by unitId
    * @param proType
    * @param unitId
    * @param showGlobalLoader By default value is false
    * @returns {Promise}
    */
    public getUnit(unitId: string | number, showGlobalLoader = false): Promise<any> {
        // todo Important add cache for units and teams (To reduce the request to the xrm).

        const bookingId = this._orderData.activeBooking.getValue()?.get('id');
        const queryParams: SDKQueryParams = {
            expand: ['all.all.all'],
            query: {
                transaction_id: bookingId,
            },
        };

        if (typeof unitId === 'number') {
            unitId = unitId.toString(10);
        }

        return new Promise((res, rej) => {
            this.xrmConnection.getUnits((unitId as string), queryParams)
                .then(
                    (response) => {
                        res(response);
                    },
                )
                .catch(
                    (error) => {
                        this._errorHandlingService.validateResponse(error);

                        rej(error);
                    },
                );
        });
    }
}
