// import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { GoogleMapsScriptProtocol, LAZY_MAPS_API_CONFIG, LazyMapsAPILoaderConfigLiteral, MapsAPILoader } from '@agm/core';
import { Inject, Injectable } from '@angular/core';
import { MainDataProvider } from './../providers/xrm/main-data.provider';
import { DocumentRef, WindowRef } from './browser-globals.service';

@Injectable({
    providedIn: 'root',
})

export class CustomLazyAPIKeyLoader extends MapsAPILoader {
    private _scriptLoadingPromise: Promise<void>;
    private _config: LazyMapsAPILoaderConfigLiteral;
    private _windowRef: WindowRef;
    private _documentRef: DocumentRef;

    constructor( @Inject(LAZY_MAPS_API_CONFIG) config: any, w: WindowRef, d: DocumentRef, private _mainDataProvider: MainDataProvider) {
        super();
        this._config = config || {};
        this._windowRef = w;
        this._documentRef = d;
    }

    public load(): Promise<void> {
        if (!this._mainDataProvider.obfEnv.googleApiKey) {
            return new Promise<void>((resolve: Function, reject: Function) => {
                reject('No googleApiKey supplied!');
            });
        }

        if (this._scriptLoadingPromise) {
            return this._scriptLoadingPromise;
        }

        const script = this._documentRef.getNativeDocument().createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.defer = true;
        const callbackName = `angular2GoogleMapsLazyMapsAPILoader`;

        // this.http.get("getKey")
        //     .subscribe((res: any) => {
        //         this._config.apiKey = res._body;
        //         script.src = this._getScriptSrc(callbackName);
        //         this._documentRef.getNativeDocument().body.appendChild(script);
        //     });

        this._config.apiKey = this._mainDataProvider.obfEnv.googleApiKey;
        script.src = this._getScriptSrc(callbackName);
        this._documentRef.getNativeDocument().body.appendChild(script);

        this._scriptLoadingPromise = new Promise<void>((resolve: Function, reject: Function) => {
            (this._windowRef.getNativeWindow() as any)[callbackName] = () => { console.log('loaded'); resolve(); };

            script.onerror = (error: Event) => { reject(error); };
        });

        return this._scriptLoadingPromise;
    }

    private _getScriptSrc(callbackName: string): string {
        const protocolType: GoogleMapsScriptProtocol =
            (this._config && this._config.protocol) || GoogleMapsScriptProtocol.HTTPS;
        let protocol: string;

        switch (protocolType) {
            case GoogleMapsScriptProtocol.AUTO:
                protocol = '';
                break;
            case GoogleMapsScriptProtocol.HTTP:
                protocol = 'http:';
                break;
            case GoogleMapsScriptProtocol.HTTPS:
                protocol = 'https:';
                break;
        }

        const hostAndPath: string = this._config.hostAndPath || 'maps.googleapis.com/maps/api/js';
        const queryParams: { [key: string]: string | Array<string> } = {
            v: this._config.apiVersion || '3',
            callback: callbackName,
            key: this._config.apiKey,
            client: this._config.clientId,
            channel: this._config.channel,
            libraries: this._config.libraries,
            region: this._config.region,
            language: this._config.language,
        };
        const params: string =
            Object.keys(queryParams)
                .filter((k: string) => queryParams[k] != null)
                .filter((k: string) => {
                    // remove empty arrays
                    return !Array.isArray(queryParams[k]) ||
                        (Array.isArray(queryParams[k]) && queryParams[k].length > 0);
                })
                .map((k: string) => {
                    // join arrays as comma seperated strings
                    const i = queryParams[k];
                    if (Array.isArray(i)) {
                        return { key: k, value: i.join(',') };
                    }
                    return { key: k, value: queryParams[k] };
                })
                .map((entry: { key: string, value: string }) => `${entry.key}=${entry.value}`)
                .join('&');
        return `${protocol}//${hostAndPath}?${params}`;
    }
}
