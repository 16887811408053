export class HttpInterceptor {
    constructor() {}

    public checkResponse: Function = null;

    /**
     * Sets error handling method
     * @param responseChecker error handling method from OBF
     */
    public setResponseChecker(responseChecker: Function): void {
        if (typeof responseChecker === 'function') {
            this.checkResponse = responseChecker;
        }
    }

    /**
     * Check wether handler method is set or not
     */
    public isResponseCheckerRegistered(): boolean {
        return this.checkResponse ? true : false;
    }
}
