export const productionConfig = {
    DEFAULT: {
        env: 'production',
        api: {
            baseUrl: 'https://uspaah.serviceos.com/api/v2.2/',
            accounts: 'https://accounts.uspaah.com/',
            proxy: 'https://obf.uspaah.com/proxy/api/v2.2/'
        },
        country: 'UK',
        XApplication: 'qs7vo3nxx66vmy2d5r7hq2g80wnzhzct7syxt9eh63r7i065xac8dnpkhkqbwlwl',
        XProfile: 1,
        cookieKey: 'LMrofhHejiTWJeRYuVI6wV4cPmcb7H7u1nQg1M3UW8g',
        googleApiKey: '',
        googleSuggestAddress: true, // This is only for address not postcode.
        paymentSecurityBadge: {
            enable: false,
            href: ''
        },
        defaultPhoneCode: '44'
    },
};


