import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LocalizationProvider } from '@providers/localization.provider';
import { MainDataProvider } from '@providers/main-data.provider';
import { EventTrackingService } from '@services/tracking/event-tracking.service';
import { UserDataService } from '../../../../../../services/xrm/user-data.service';

@Component({
    selector: 'app-claim-account',
    templateUrl: './claim-account.component.html',
    styleUrls: ['./claim-account.component.scss'],
})
export class ClaimAccountComponent implements OnInit {

    @Output() public modalClose: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    public changeView = new EventEmitter();
    public emailSent = false;

    @Input() set prefillData(data: any) {
        if (this.emailSent) {
            this.emailSent = false;
            this.claimAccount.patchValue({ email: '' });
        }

        if (data) {

        }
    }

    @ViewChild('claimAccountForm', { static: false }) public formTemplateRef: any;

    @Input() set currentView(data) {
        this.componentSettings.errorMessages = [];
        if (this.formTemplateRef) {
            // reset templateFormObject
            this.formTemplateRef.reset();
            this.formTemplateRef.submitted = false;
            // reset reactiveFormObject
            this.claimAccount.reset();
        }
    }

    /**
 * Contain reference to registration form
 */
    public claimAccount: FormGroup;
    public confirmationMessage = '';
    public confirmationEmail = '';

    public componentSettings = {
        fields: {
            email: {
                label: this._localizationProvider.getLocalizedText('wordEmail'),
                placeholder: '',
                errMsgs: {
                    required: this._localizationProvider.getLocalizedText('resetPasswordRequiredErrMsg'),
                    format: this._localizationProvider.getLocalizedText('resetPasswordInvalidErrMsg'),
                },
            },
            submitButton: {
                label: this._localizationProvider.getLocalizedText('continueButtonLabel'),
                type: 'button',
            },
        },
        errorMessages: [],
    };

    constructor(
        private fb: FormBuilder,
        private _userDataService: UserDataService,
        private _eventTrackingService: EventTrackingService,
        public _mainDataProvider: MainDataProvider,
        public _localizationProvider: LocalizationProvider,
    ) { }

    public ngOnInit() {
        this.initialize();
    }

    /**
     * Geter for field in registation form
     */
    get email() {
        return this.claimAccount.get('email');
    }

    /**
     * Component initializr
     */
    private initialize() {
        this.claimAccount = this.fb.group({
            email: ['', [Validators.required, Validators.email]],
        });
    }

    /**
     * Check form validation and registrate user
     */
    public processForm() {
        if (this.claimAccount.valid) {
            const formValue = this.claimAccount.value;

            const formData = {
                email: formValue.email,
            };

            this._userDataService.claimAccount(formData).then((serverResponse) => {
                this.componentSettings.errorMessages = new Array();
                this.emailSent = true;
                this.confirmationMessage = this._localizationProvider.getLocalizedText('resetPasswordConfirmationMessage');
                this.confirmationEmail = formValue.email;
                this._eventTrackingService.push({ event: 'SuccesfulClaim' });

                const userData = this._userDataService.getUserData();

                const dataLayerObj = {
                    event: 'claim_account',
                    version: 'GA4',
                    membership: userData?.membership,
                    user_id: userData?.id
                };
                this._eventTrackingService.push(dataLayerObj);

            }).catch((err) => {
                this.componentSettings.errorMessages = err.error;

                const errorsMessagesArr = err.error.map((el) => {
                    return el.message;
                });
                this._eventTrackingService.push({ event: 'FailedClaim' , errors: errorsMessagesArr });
            });
        }
    }

    /**
     * Change modal tab
     */
    public goToRegister(): void {
        this.changeView.emit({ tab: 'register', data: {} });
    }

    /**
     * Change modal tab
     */
    public gotoLogin(): void {
        this.changeView.emit({ tab: 'login', data: {} });
    }

}
