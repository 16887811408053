import { BackEndXrmService } from './backendservices/BackEndXrmService';
import { tap } from 'rxjs/operators';
import ObfInjector from '../Helpers/Injector';
import { SDKQueryParams } from '../Api/Interfaces/SDKQueryParams';

export class SDKSharedService {
    private _backEndXrmService: BackEndXrmService;
    private apiPoints = {
        uploadFile: 'shared/upload_file',
        userApplications: 'shared/user_applications',
        serverTime: 'shared/server_time',
        exceptions: 'shared/exceptions',
        feedback: 'shared/application_feedback',
        store: 'shared/key_value_store'
    };

    constructor() {
        this._backEndXrmService = ObfInjector.getRegistered('BackEndXrmService');
    }

    /**
     * @param {Object} formData
     * @param {Object} queryParams query parameters
     * @returns {Promise}
     * !!! TODO NEED TO BE BUILD AUTO BOUDERY AND DATA TRANSFORM
     */
    public uploadFile(formData: any, queryParams?: SDKQueryParams): Promise<any> {
        return new Promise((resolve, reject) => {
            const subscriber = this._backEndXrmService
                .post(
                    this.apiPoints.uploadFile,
                    formData,
                    { headersOptions: { contentType: 'undefined' } }
                )
                .pipe(
                    tap(resData => { })
                )
                .subscribe(
                    res => {
                        resolve(res);
                    },
                    err => {
                        reject(err);
                    },
                    () => {
                        subscriber.unsubscribe();
                    }
                );
        });
    }

    /**
     * Set user applications
     * @param {Object} data 
     * @returns {Promise}
     */
    public setUserApplications(data: any): Promise<any> {
        return new Promise((resolve: Function, reject: Function) => {
            const subscriber = this._backEndXrmService
                .post(this.apiPoints.userApplications, data, undefined)
                .subscribe(
                    res => {
                        resolve(res);
                    },
                    err => {
                        reject(err);
                    },
                    () => {
                        subscriber.unsubscribe();
                    }
                );
        });
    }

    /**
     * Make very light request in order to check API 
     * @returns {Promise}
     */
    public getServerTime(): Promise<any> {
        return new Promise((resolve, reject) => {
            const subscriber = this._backEndXrmService
                .get(this.apiPoints.serverTime, { headersOptions: { timeout: 10000 } })
                .subscribe(
                    res => {
                        resolve(res);
                    },
                    err => {
                        reject(err);
                    },
                    () => {
                        subscriber.unsubscribe();
                    }
                );
        });
    }

    /**
     * Send exceptions
     * @param {Object} data exception data
     * @returns {Promise} 
     */
    public sendException(data: any): Promise<any> {
        return new Promise((resolve, reject) => {
            const subscriber = this._backEndXrmService
                .post(this.apiPoints.exceptions, data)
                .subscribe(
                    res => {
                        resolve(res);
                    },
                    err => {
                        reject(err);
                    },
                    () => {
                        subscriber.unsubscribe();
                    }
                );
        });
    }

    /**
     * Send user feedback
     * @param {Object} data feedback data
     * @returns {Promise} 
     */
    public sendUserFeedback(data: any): Promise<any> {
        return new Promise((resolve, reject) => {
            const subscriber = this._backEndXrmService
                .post(this.apiPoints.feedback, data)
                .subscribe(
                    res => {
                        resolve(res);
                    },
                    err => {
                        reject(err);
                    },
                    () => {
                        subscriber.unsubscribe();
                    }
                );
        });
    }

    /**
     * Set data in XRM Store
     * @param {Object} data
     * @returns {Promise} 
     */
     public setInStore(data: any): Promise<any> {
        return new Promise((resolve, reject) => {
            const subscriber = this._backEndXrmService
                .post(this.apiPoints.store, data)
                .subscribe(
                    res => {
                        resolve(res);
                    },
                    err => {
                        reject(err);
                    },
                    () => {
                        subscriber.unsubscribe();
                    }
                );
        });
    }
    
    /**
     * Fetch data from XRM Store
     * @param key
     * @returns 
     */
     public fetchFromStore(): Promise<any> {
        return new Promise((resolve, reject) => {
            const subscriber = this._backEndXrmService
                .get(this.apiPoints.store)
                .subscribe(
                    res => {
                        resolve(res);
                    },
                    err => {
                        reject(err);
                    },
                    () => {
                        subscriber.unsubscribe();
                    }
                );
        });
    }
}
