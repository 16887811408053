
import { Component, Input } from '@angular/core';
import { LocalizationProvider } from '@providers/localization.provider';
import { MainDataProvider } from 'src/app/providers/xrm/main-data.provider';

@Component({
    selector: 'app-unit-modal',
    templateUrl: './unit-modal.component.html',
    styleUrls: ['./unit-modal.component.scss'],
})
export class UnitModalComponent {
    private favoriteActionPerformed: Boolean = false;
    private favoritePropertyValue: Boolean = false;

    public popupSettings: any = {
        icon: 'error',
        title: 'Additional information',
        shownData: '{}',
    };

    public unitModalCtaLabel = this._localizationProvider.getLocalizedText('unitModalCtaLabel');

    @Input()
        public onlyView = false;

    public unitType: 'unit' | 'team' = 'unit';

    @Input() set unit(value) {
        if (value.units) {
            this.unitType = 'team';
        }

        this.popupSettings.shownData = value;

        this.favoritePropertyValue = this.popupSettings.shownData.favorite;
    }

    public modalRef;

    constructor(public _mainDataProvider: MainDataProvider, private _localizationProvider: LocalizationProvider) {
    }


    public action(action) {

        // Statically update favorite property value
        this.popupSettings.shownData.favorite = this.favoritePropertyValue;

        this.modalRef.close({
            action,
            favoriteActionPerformed: this.favoriteActionPerformed,
            favoritePropertyValue: this.favoritePropertyValue,
        });
    }

    public onFavoriteActionPerformed(favoriteValue: Boolean): void {
        this.favoriteActionPerformed = true;

        this.favoritePropertyValue = favoriteValue;
    }
}
