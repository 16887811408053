import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from './environments/environment';

import { AppModule } from './app/app.module';

// enableProdMode();

// if (environment.envName === 'production') {
enableProdMode();
// }

platformBrowserDynamic().bootstrapModule(AppModule);
