import { Injectable } from '@angular/core';
import { LocalizationProvider } from '@providers/localization.provider';
import { interval } from 'rxjs';
import { genGUID, popupCenter } from 'src/app/helpers/helper-functions';
import { MainDataProvider } from 'src/app/providers/xrm/main-data.provider';
import { CryptoService } from '../crypto.service';
import { LoaderService } from '../loader.service';

@Injectable({
    providedIn: 'root',
})
export class AppleAuthService {
    private appleAuth = {
        login: 'ext/apple-social-info',
        register: 'ext/apple-social-info',
    };

    public popupInstance = null;

    constructor(
        private mainDataProvider: MainDataProvider,
        private loaderService: LoaderService,
        private cryptoService: CryptoService,
        private _localizationProvider: LocalizationProvider,
    ) { }

    /**
     * Run Apple authorization cycle
     * @param requestType trying to login or register
     */
    public beginAuth(requestType: 'login' | 'register'): Promise<any> {

        return new Promise((resolve: Function, reject: Function) => {
            const loader = this.loaderService.showLoader(this._localizationProvider.getLocalizedText('loaderMsg23'));

            const appleAuthKey = this.mainDataProvider.getSocialAuthData().appleAuthKey;
            const redirectUrl = this.mainDataProvider.obfEnv.api.accounts +
                (requestType === 'login' ? this.appleAuth.login : this.appleAuth.register);

            // !!
            // !! WA USE STATE FOR FLAG TO DON'T CLOSE THE WINDOW.
            // !! This is because we cant add more from 1 redirect url in Apple. and this why apple auth is different from google and facebook.
            // !! DO NOT CHANGE THIS BEFORE COMMENT WITH WEB ACCOUNTS.
            // !!
            const state = this.cryptoService.encryptData(genGUID() + '_web', this.mainDataProvider.obfEnv.cookieKey);

            // eslint-disable-next-line max-len
            const appleAuthURL = `https://appleid.apple.com/auth/authorize?client_id=${appleAuthKey}&redirect_uri=${redirectUrl}&response_type=code%20id_token&state=${encodeURIComponent(state)}&scope=name%20email&response_mode=form_post`;

            let authenticationResponse: any = null;
            const appleAuthPostMessageHandler: any = (event) => {
                if (event && event.data && event.data.c_apple_login_data) {
                    authenticationResponse = event.data.c_apple_login_data;
                }
            };
            // append listener for event
            window.addEventListener('message', appleAuthPostMessageHandler);

            const centerPopup = popupCenter();
            const appleAuthPopup: any = window.open(
                appleAuthURL,
                'Booking Form Authorization Modal',
                'height=710,width=630,left=' + centerPopup.left + ',top=' + centerPopup.top + ',resizable=no,scrollbars=no,toolbar=no,menubar=no,location=no,directories=no, status=no',
            );

            this.popupInstance = appleAuthPopup;

            const subscription = interval(1000).subscribe(() => {

                if (authenticationResponse == null) {
                    try {
                        authenticationResponse =
                        localStorage.getItem('c_apple_login_data');
                        localStorage.removeItem('c_apple_login_data');
                    } catch (e) {}
                }

                if (authenticationResponse) { // * Post message
                    window.removeEventListener('message', appleAuthPostMessageHandler);
                    this.loaderService.hideLoader(loader);
                    subscription.unsubscribe();

                    const socialData: any = this.cryptoService.decryptData(authenticationResponse, this.mainDataProvider.obfEnv.cookieKey);

                    if (socialData && !socialData.error) {
                        if (requestType === 'login') {
                            resolve({
                                social: {
                                    oauth_id: socialData.code,
                                    social_provider_id: this.mainDataProvider.getSocialAuthData().appleProviderId,
                                    id_token: socialData.id_token,
                                },
                            });
                        } else {
                            // Register
                            resolve({
                                oauth_id: socialData.code,
                                social_provider_id: this.mainDataProvider.getSocialAuthData().appleProviderId,
                                id_token: socialData.id_token,
                                firstName: socialData.user && socialData.user.first_name ? socialData.user.first_name : null,
                                lastName: socialData.user && socialData.user.last_name ? socialData.user.last_name : null,
                                email: socialData.user && socialData.user.email ? socialData.user.email : null,
                            });
                        }
                    } else {
                        reject(socialData);
                    }
                    return;
                }

                // Wait to close popup.
                if (appleAuthPopup && appleAuthPopup.closed) {
                    window.removeEventListener('message', appleAuthPostMessageHandler);
                    this.loaderService.hideLoader(loader);
                    subscription.unsubscribe();

                    this.popupInstance = null;
                    reject(null);
                    return;
                }

            });
        });
    }

}
