import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NotEmptyStringValidator } from './not-empty-string-validator.directive';

@NgModule({
    declarations: [NotEmptyStringValidator],
    imports: [
        CommonModule,
    ],
    providers: [
        NotEmptyStringValidator,
    ],
    exports: [
        NotEmptyStringValidator,
    ],
})
export class EmptyStringValidatorModule { }
