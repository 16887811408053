import { Injectable } from '@angular/core';
import { MainDataProvider } from '@providers/main-data.provider';
import { OrderData } from '@providers/order-data.provider';
import { environment } from 'src/environments/environment';
import { SDKSharedService } from '../../../lib/xrm-sdk/src/lib/Services/SDKSharedService';
import { getHotjarId } from '../helpers/global-functions';
import { NavigationService } from './navigation.service';
import { XRMApiCommunicatorService } from './xrm/xrmapi-communicator.service';

@Injectable({
    providedIn: 'root',
})

export class ExceptionsService {

    private sdkSharedService: SDKSharedService;

    constructor(
        private _XRMApiCommunicatorService: XRMApiCommunicatorService,
        private _orderData: OrderData,
        private _navigationService: NavigationService,
        private _mainDataProvider: MainDataProvider
    ) {
        this.sdkSharedService = this._XRMApiCommunicatorService.getSharedService();
    }

    /**
     * Send exceptions
     * @param data
     */
    public sendException(data: any) {
        // attach transaction id
        if (this._orderData.activeBooking.getValue() && this._orderData.activeBooking.getValue().get('id')) Object.assign(data.body, { transaction_id: this._orderData.activeBooking.getValue().get('id')} );

        // attach hotjar id
        const hotjarId = getHotjarId();
        if (hotjarId && hotjarId !== 'N/A') Object.assign(data.body, { hotjar_id: getHotjarId()} );

        // attach app version
        Object.assign(data.body, { obf_version: environment.VERSION} );
        
        // attach current position
        const currentPosition: string = this._navigationService.getCurrentStep() && this._navigationService.getCurrentStep().activeChild ? this._navigationService.getCurrentStep().activeChild.position : (this._navigationService.getCurrentStep() ? this._navigationService.getCurrentStep().position : 'unknown');

        Object.assign(data.body, { position: currentPosition} );
        
        // attach domain url
        const domainUrl: string = this._mainDataProvider.getResourceObfOptions().main_url ? this._mainDataProvider.getResourceObfOptions().main_url : 'N/A';
        
        Object.assign(data.body, { domain_url: domainUrl} );
        
        return new Promise((resolve: Function, reject: Function) => {
            this.sdkSharedService.sendException(data)
                .then(
                    (response) => {
                        resolve(response);
                    },
                    (response) => {
                        reject(response);
                    });
        });
    }

}
