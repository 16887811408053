import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { getUrlVars } from '../helpers/global-functions';
import { PostMessaging } from './../../../lib/obf-client/src/controllers/IframeConnect/post-messaging';
import { environment } from './../../environments/environment';

@Injectable({
    providedIn: 'root',
})

export class PostMessageService {
    public obfCurrentState = environment.integrate ? 'open' : 'close';

    public chatStatus: BehaviorSubject<any>;
    public formStateBS: BehaviorSubject<boolean>;
    public openFormSubject: Subject<any>;
    public closeFormSubject: Subject<any>;
    public navigationSubject: Subject<any>;
    public openExitSubject: Subject<any>;

    private parentCon: PostMessaging;

    constructor(
        private router: Router,
    ) {
        this.openFormSubject = new Subject();
        this.closeFormSubject = new Subject();

        this.formStateBS = new BehaviorSubject(false);

        this.openExitSubject = new Subject();
        this.navigationSubject = new Subject();

        this.chatStatus = new BehaviorSubject(undefined);

        this.parentCon = new PostMessaging(window.parent, this.getParentUrl());

        this.registerNotificationListeners();
    }

    private registerNotificationListeners() {
        this.parentCon.registerNotificationHandler('open', (e) => {
            this.obfCurrentState = 'open';
            this.formStateBS.next(true);
            this.openFormSubject.next(e);
        });

        this.parentCon.registerNotificationHandler('close', (e) => {
            this.obfCurrentState = 'close';
            this.formStateBS.next(false);
            this.closeFormSubject.next(e);
        });

        this.parentCon.registerNotificationHandler('exit', (e) => {
            this.openExitSubject.next(e);
        });

        this.parentCon.registerNotificationHandler('chat', (e) => {
            this.statusListener(e);
        });

        this.parentCon.registerNotificationHandler('navigate', (e) => {
            this.navigationSubject.next(e);
        });
    }

    public registerRequestListeners(point: string, handler): void {
        this.parentCon.registerRequestHandler(point, handler);
    }

    private getParentUrl() {
        const isInIFrame = (parent !== window);

        if (isInIFrame && document.referrer != '') {
            return document.referrer; // return for chrome and other modern browsers
        } else if (isInIFrame && window.parent.location) {
            return JSON.stringify(window.parent.location.href); // this will return the href for the IE
        }

        const iframeQueryParams = getUrlVars(); // Secure if no one of upper dont work to check for passed origin
        if (iframeQueryParams.parentOrigin) {
            return iframeQueryParams?.parentOrigin;
        }

        return null;
    }

    public updateIframeUrl(): void {
        const message = { slug: '' };

        message.slug = this.router.url.replace('/', '');
        message.slug = message.slug.slice(message.slug.indexOf('/') + 1);

        this.parentCon.sendNotification('navigation', message);
    }

    public sendState(type, data) {
        this.parentCon.sendNotification(type, data);
    }

    public request(point: string, data: any) {
        return new Promise((res, rej) => {
            this.parentCon.sendRequest(point, data, (response) => {
                if (response.error === 'timeout') {
                    rej(response);
                    return;
                }
                res(response);
            });
        });
    }

    public openChat() {
        this.parentCon.sendNotification('chat', 'open');
    }

    public closeChat() {
        this.parentCon.sendNotification('chat', 'close');
    }

    private statusListener(e) {
        this.chatStatus.next(e);
    }
}
