import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'highlight',
})

/**
 * Highlights part of string by search term
 */
export class HighlightPipe implements PipeTransform {
    public transform(text: string, highlightString: string): string {
        const transformedText: string = text.toLowerCase(),
            transformedHighlightString: string = highlightString.toLowerCase(),
            highlightStringIndex: number = transformedText.indexOf(transformedHighlightString);

        if (highlightStringIndex !== -1) {
            // Don't highlight if search term contains one word and it's not in the beginning of string
            if (highlightStringIndex > 0 && highlightString.length === 1) {
                return text;
            }

            const highlightStringLastIndex: number = highlightStringIndex + highlightString.length - 1,
                stringToReplace = text.slice(highlightStringIndex, highlightStringLastIndex + 1);

            text = text.replace(stringToReplace, `<span class="bold">${stringToReplace}</span>`);
        }

        return text;
    }
}
