import { BatchRequestStructure } from '../Api/Interfaces/BatchRequestStructure';
import { BackEndXrmService } from './backendservices/BackEndXrmService';
import { tap } from 'rxjs/operators';
import ObfInjector from '../Helpers/Injector';
export class SDKBatchRequestService {
    private apiPoints = {
        batch: 'client'
    };
    private _backEndXrmService: BackEndXrmService;

    constructor() {
        this._backEndXrmService = ObfInjector.getRegistered('BackEndXrmService');
    }

    /**
     * 
     * @param {String} method request method
     * @param {String} path API endpoint 
     * @param {Object} params queryParameters 
     * @param {Object} data 
     * @returns {Object}
     */
    public createBatchObject(method: 'GET' | 'POST', path: string, params?: any, data?: any) {
        return {
            data: data || null,
            method,
            params: params || {},
            path
        };
    }

    /**
     * Perform batch request (array of requests)
     * @param arr array of requests
     * @returns {Promise}
     */
    public makeBatchRequest(batchedRequestsData: { requests: BatchRequestStructure[] }, performedRequests: Array<{ requestName: string, requestIndex: number }>): Promise<any> {
        return new Promise((resolve, reject) => {
            const subscriber = this._backEndXrmService
                .post(this.apiPoints.batch, batchedRequestsData, { validateResponseType: 'batchRequest', additionalValidationData: performedRequests })
                .pipe(tap(resData => { }))
                .subscribe(
                    res => {
                        resolve(res);
                    },
                    err => {
                        reject(err);
                    },
                    () => {
                        subscriber.unsubscribe();
                    }
                );
        });
    }
}
