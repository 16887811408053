import { MainDataProvider } from './../providers/xrm/main-data.provider';
import { OrderData } from './../providers/xrm/order-data.provider';
/**
    Bootstrap Affiliates Script
    Can be loaded or executed On Init and On Booking Completion.
    All the abbrivation and script codes are stored here.
**/

import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { NGXLogger } from 'ngx-logger';
import { ConfigurationService } from '@services/configuration.service';

interface Scripts {
    id: number;
    name: string;
    src: string;
    type: string;
    load: string;
}

const IFRAME = 'iframe';

// What type of script
const TYPE_OPTIONS = {
    iframe: 'iframe',
    inner: 'inner',
    src: 'src',
};

// Where will be executed
const LOAD_OPTIONS = {
    init: 'init',
    confirm: 'confirm',
};

// Affiliates Abbriviations
const AFFILIATES = {
    doublerUK: 'MAR1574',
    doublerAUS: 'MAR177',
    pepper: 'MAR150',
    webGainUK: 'MAR203',
    webGainAUS: 'MAR142',
    tradeTrackerSales: 'MAR198',
    tradeTrackerLead: 'MAR222',
    tagServe: 'MAR61',
    wowTrkUK: 'MAR51',
    wowTrkAUS: 'MAR99',
    vigLink: 'MAR102',
    bizProfits: 'MAR233',
    CommFactory: 'MAR151',
};

export const ScriptStore: Scripts[] = [
    // LOCAL SCRIPTS - scripts loaded from local files
    // { name: 'random-gen', src: '../../../assets/js/random-num-generator.js' } // from local files

    // EXTERNAL SCRIPTS - loaded from external url
    { id: 1, name: AFFILIATES.CommFactory, src: 'https://t.cfjump.com/tag/50019', type: TYPE_OPTIONS.src, load: LOAD_OPTIONS.confirm },

    // INNER SCRIPTS - All the scripts injected async in to the code on init or confirm
    { id: 2, name: AFFILIATES.CommFactory, src: null, type: TYPE_OPTIONS.inner, load: LOAD_OPTIONS.confirm },
    // { id: 3, name: AFFILIATES.webGainUK, src: null, type: TYPE_OPTIONS.inner, load: LOAD_OPTIONS.init },
    // { id: 4, name: AFFILIATES.webGainUK, src: null, type: TYPE_OPTIONS.inner, load: LOAD_OPTIONS.confirm },
    // { id: 5, name: AFFILIATES.webGainAUS, src: null, type: TYPE_OPTIONS.inner, load: LOAD_OPTIONS.init },
    // { id: 6, name: AFFILIATES.webGainAUS, src: null, type: TYPE_OPTIONS.inner, load: LOAD_OPTIONS.confirm },
    { id: 3, name: AFFILIATES.tradeTrackerLead, src: null, type: TYPE_OPTIONS.inner, load: LOAD_OPTIONS.confirm },

    // IFRAME SCRIPTS - Do not change the line above, that line is used from the all scrypts with type iframe fillProperties
    { id: 4, name: IFRAME, src: null, type: TYPE_OPTIONS.iframe, load: LOAD_OPTIONS.confirm },
];

declare var document: any;

declare global {
    interface Window {
        ttConversionOptions: any;
        cf: any;
        ITCVRQ: any;
    }
}

@Injectable({
    providedIn: 'root',
})

export class AffiliatesScriptsLoaderService {

    private scripts: any = {};

    constructor(
        private _mainDataProvider: MainDataProvider,
        private _orderData: OrderData,
        private cookieService: CookieService,
        private logger: NGXLogger,
        private configurationService: ConfigurationService
    ) {
        ScriptStore.forEach((script: any) => {
            this.scripts[script.id] = {
                loaded: false,
                name: script.name,
                src: script.src,
                type: script.type,
                load: script.load,
            };
        });
    }

    private fillInnerScripts(key, sourceAbbr) {
        let orderReference = '';
        let orderValue = '';
        let serviceTitle = '';
        let serviceId = '';
        let clientId = '';
        let promoCode = '';

        // Get ObfOptions
        let customer = this.cookieService.get('gtm-customer') === undefined ? 'new' : this.cookieService.get('gtm-customer');
        let sourceAbbrValue = this._mainDataProvider.getResourceObfOptions().source_abbr;
        const country = this.configurationService.getConfigurationSettings().country || this._mainDataProvider.obfEnv.country;

        // Set Global Properties
        let activeBooking: any = this._orderData.getResourceActiveBooking();
        // If active booking is empty whe dont filld the information for booking this is when is init.
        if (activeBooking) {
            activeBooking = activeBooking.get();
            clientId = activeBooking.id;
            orderReference = activeBooking.reference_number || orderReference;

            if (activeBooking.service) {
                serviceTitle = activeBooking.service.title || serviceTitle;
                serviceId = activeBooking.service.id || serviceTitle;
            }

            if (activeBooking.price) {
                promoCode = activeBooking.price.type === 'voucher_applied' ? activeBooking.voucher : '';
                orderValue = activeBooking.price.total || orderValue;
            }
        }

        // Web Gains Properties
        // const wgOrderReference = orderReference;
        // const wgOrderValue = orderValue;
        // const wgEventID = country === 'UK' ? 22339 : 25197;
        // const wgComment = '';
        // const wgLang = 'en_EN';
        // const wgsLang = 'javascript-client';
        // const wgVersion = '1.2';
        // const wgProgramID = country === 'UK' ? 12547 : 13513;
        // const wgSubDomain = 'track';
        // const wgCheckSum = '';
        // const wgItems = wgEventID + '::' + orderValue + '::' + serviceTitle + '::' + serviceId + '::' + promoCode;
        // const wgVoucherCode = promoCode;
        // const wgCustomerID = clientId;
        // const wgCurrency = country === 'UK' ? 'GBP' : 'AUD';
        // const wgProtocol = 'https';
        // Web Gains Properties End

        switch (sourceAbbr) {
            // case AFFILIATES.webGainUK:
            // case AFFILIATES.webGainAUS:

            //     const payloadComplete = `ITCVRQ('set', 'trk.programId', '${wgProgramID}');
            //         ITCVRQ('set', 'cvr', {
            //             value: '${wgOrderValue}',
            //             currency: '${wgCurrency}',
            //             language: 'en_EN',
            //             eventId: '${wgEventID}',
            //             orderReference : '${wgOrderReference}',
            //             items: '${wgItems}',
            //             customerId: '${wgCustomerID}',
            //             voucherId: '${wgVoucherCode}'
            //         });
            //         ITCVRQ('conversion');`;

            //     // MAR203 INIT
            //     // eslint-disable-next-line max-len
            //     this.scripts[key].src = this.scripts[key].load === LOAD_OPTIONS.init ? '(function(w,e,b,g,a,i,n,s){w[\'ITCLKOBJ\']=a;w[a]=w[a]||function(){(w[a].q=w[a].q||[]).push(arguments)},w[a].l=1*new Date();i=e.createElement(b),n=e.getElementsByTagName(b)[0];i.async=1;i.src=g;n.parentNode.insertBefore(i,n)})(window,document,\'script\',\'https://analytics.webgains.io/clk.min.js\',\'ITCLKQ\');ITCLKQ(\'set\', \'internal.api\', true);ITCLKQ(\'set\', \'internal.cookie\', true);ITCLKQ(\'click\');' :
            //         // MAR203 CONFIRM
            //         // eslint-disable-next-line max-len
            //         '(function(w,e,b,g,a,i,n,s){w[\'ITCVROBJ\']=a;w[a]=w[a]||function(){(w[a].q=w[a].q||[]).push(arguments)},w[a].l=1*new Date();i=e.createElement(b),n=e.getElementsByTagName(b)[0];i.async=1;i.src=g;n.parentNode.insertBefore(i,n)})(window,document,\'script\',\'https://analytics.webgains.io/cvr.min.js\',\'ITCVRQ\');' + payloadComplete;
            //     break;
            case AFFILIATES.CommFactory:
                // MAR151
                this.scripts[key].src = `(function(a,b,c){a[b]=a[b]||function(){(a[b].q=a[b].q||[]).push(arguments);};a[c]=a[b];})(window,"CommissionFactory","cf");
                cf('set', 'order', '${orderReference}');
                cf('set', 'amount', ${orderValue});
                cf('set', 'currency', 'AUD');
                cf('set', 'coupon', '${promoCode}');
                cf('set', 'customer', '${customer}');
                cf('add', 'items', { 'sku': ${serviceId}, 'price': ${orderValue}, 'quantity': 1 });
                cf('track');
                `;
                break;
            case AFFILIATES.tradeTrackerLead:
                // MAR222
                const ttConversionOptionsLeadPayload = `var ttConversionOptions = ttConversionOptions || [];
                ttConversionOptions.push({
                    type: 'lead',
                    campaignID: '24382',
                    productID: '35561',
                    transactionID: '${orderReference}',
                    email: '',
                    descrMerchant: '',
                    descrAffiliate: ''
                });`;

                // eslint-disable-next-line max-len
                this.scripts[key].src = ttConversionOptionsLeadPayload + '(function(ttConversionOptions) {var campaignID = \'campaignID\' in ttConversionOptions ? ttConversionOptions.campaignID : (\'length\' in ttConversionOptions && ttConversionOptions.length ? ttConversionOptions[0].campaignID : null);	var tt = document.createElement(\'script\'); tt.type = \'text/javascript\'; tt.async = true; tt.src = \'//tm.tradetracker.net/conversion?s=\' + encodeURIComponent(campaignID) + \'&t=m\'; var s = document.getElementsByTagName(\'script\'); s = s[s.length - 1]; s.parentNode.insertBefore(tt, s); })(ttConversionOptions);';

                break;
            case AFFILIATES.tradeTrackerSales:
                // MAR222
                const ttConversionOptionsSalesPayload = `var ttConversionOptions = ttConversionOptions || [];
                    ttConversionOptions.push({
                        type: 'sales',
                        campaignID: '24382',
                        productID: '35561',
                        transactionID: '${orderReference}',
                        transactionAmount: '${orderValue}',
                        quantity: '1',
                        email: '',
                        descrMerchant: '',
                        descrAffiliate: '',
                        currency: 'GBP'
                    });`;

                // eslint-disable-next-line max-len
                this.scripts[key].src = ttConversionOptionsSalesPayload + '(function(ttConversionOptions) {var campaignID = \'campaignID\' in ttConversionOptions ? ttConversionOptions.campaignID : (\'length\' in ttConversionOptions && ttConversionOptions.length ? ttConversionOptions[0].campaignID : null);	var tt = document.createElement(\'script\'); tt.type = \'text/javascript\'; tt.async = true; tt.src = \'//tm.tradetracker.net/conversion?s=\' + encodeURIComponent(campaignID) + \'&t=m\'; var s = document.getElementsByTagName(\'script\'); s = s[s.length - 1]; s.parentNode.insertBefore(tt, s); })(ttConversionOptions);';

                break;
            default:
        }
    }

    private fillProperties() {

        let buildSrcURL = null;

        // Get ObfOptions
        let sourceAbbr = this._mainDataProvider.getResourceObfOptions().source_abbr;
        const country = this.configurationService.getConfigurationSettings().country || this._mainDataProvider.obfEnv.country;


        // console.log('DEBUG ACTIVE', this._orderData.activeBooking.getValue());

        // Set Global Properties
        const orderReference = this._orderData.activeBooking.getValue()?.get('reference_number');
        const orderValue = this._orderData.activeBooking.getValue()?.get('price').total;
        const serviceTitle = this._orderData.activeBooking.getValue()?.get('service').title;
        const serviceId = this._orderData.activeBooking.getValue()?.get('service').id;
        const clientId = this._orderData.activeBooking.getValue()?.get('id');
        const promoCode = this._orderData.activeBooking.getValue()?.get('price') && this._orderData.activeBooking.getValue().get('price').type === 'voucher_applied' ? this._orderData.activeBooking.getValue()?.get('voucher') : '';

        // Web Gains Properties
        // const wgOrderReference = orderReference;
        // const wgOrderValue = orderValue;
        // const wgEventID = country === 'UK' ? 22339 : 25197;
        // const wgComment = '';
        // const wgLang = 'en_EN';
        // const wgsLang = 'javascript-client';
        // const wgVersion = '1.2';
        // const wgProgramID = country === 'UK' ? 12547 : 13513;
        // const wgSubDomain = 'track';
        // const wgCheckSum = '';
        // const wgItems = wgEventID + '::' + orderValue + '::' + serviceTitle + '::' + serviceId + '::' + promoCode;
        // const wgVoucherCode = promoCode;
        // const wgCustomerID = clientId;
        // const wgCurrency = country === 'UK' ? 'GBP' : 'AUD';
        // const wgProtocol = 'https';
        // Web Gains Properties End

        // Fill Iframe Script
        switch (sourceAbbr) {
            // case AFFILIATES.webGainUK:
            // case AFFILIATES.webGainAUS:
            //     // You can load multiple scripts by just providing the key as argument into load method of the service
            //     this.load(LOAD_OPTIONS.confirm, sourceAbbr).then((data) => {
            //         // Script Loaded Successfully
            //     }).catch((error) => this.logger.log(error));

            //     // eslint-disable-next-line max-len
            //     buildSrcURL = wgProtocol + '://' + wgSubDomain + '.webgains.com/transaction.html' + '?wgver=' + wgVersion + '&wgprotocol=' + wgProtocol + '&wgsubdomain=' + wgSubDomain + '&wgslang=' + wgsLang + '&wglang=' + wgLang + '&wgprogramid=' + wgProgramID + '&wgeventid=' + wgEventID + '&wgvalue=' + wgOrderValue + '&wgchecksum=' + wgCheckSum + '&wgorderreference=' + wgOrderReference + '&wgcomment=' + escape(wgComment) + '&wglocation=' + escape(document.referrer) + '&wgitems=' + escape(wgItems) + '&wgcustomerid=' + escape(wgCustomerID) + '&wgvouchercode=' + escape(wgVoucherCode) + '&wgCurrency=' + escape(wgCurrency);
            //     break;

            // case AFFILIATES.pepper:
            //     buildSrcURL = 'https://t.pepperjamnetwork.com/track?INT=DYNAMIC&PROGRAM_ID=8582&ORDER_ID=' + orderReference + '&ITEM_ID1=' + serviceId + '&ITEM_PRICE1=' + orderValue + '&QUANTITY1=1';
            //     break;

            // // MAR51
            // case AFFILIATES.wowTrkUK:
            //     buildSrcURL = 'https://t.wowtrk.com/SL7Cs?amount=' + orderValue;
            //     break;

            // // MAR99
            // case AFFILIATES.wowTrkAUS:
            //     buildSrcURL = 'https://t.wowtrk.com/SL7RC?amount=' + orderValue;
            //     break;

            // MAR102
            case AFFILIATES.vigLink:
                buildSrcURL = 'https://viglink.go2cloud.org/aff_l?offer_id=317&adv_sub=' + clientId;
                break;

            // // MAR233
            // case AFFILIATES.bizProfits:
            //     buildSrcURL = 'https://bizprofits.go2cloud.org/aff_l?offer_id=5666';
            //     break;

            // MAR1574
            case AFFILIATES.doublerUK:
                const trackingIdUK = this.cookieService.get('TRADEDOUBLER');

                let doublerEventIdUK = 385051;

                // Removals - 389470
                const removalsServicesUK = [
                    1182,
                    140,
                    511,
                    1167,
                    510,
                    1016,
                    971,
                    1192,
                    1197,
                    141,
                    771,
                    509,
                    1029,
                    1027,
                    1021,
                    1028,
                    507,
                ];

                // Pest Control - 389472
                const pestControlServicesUK = [
                    897,
                    217,
                    133,
                    25,
                    26,
                ];

                // Handyman & Tradesman - 389474
                const handymanServicesUK = [
                    581,
                    1046,
                    1045,
                    1044,
                    1043,
                    1042,
                    1041,
                    1040,
                    1048,
                    1009,
                    1008,
                    1006,
                    1004,
                    1175,
                    1174,
                    1152,
                    1151,
                    1150,
                    1015,
                    1014,
                    1013,
                    1012,
                    1011,
                    1010,
                    980,
                ];

                const landscapingUK = 13;
                const endOfTenancyCleaning = 991;
                const afterBuildersCleaning = 8;
                const wasteRemoval = 1204;
                const treeSurgery = 11;
                const electricians = 147;
                const carpetCleaning = 6;
                const ovenCleaning = 573;
                const gutterCleaning = 15;
                const regularCleaning = 155;
                const windowCleaning = 585;
                const oneOffCleaning = 234;
                const gardening = 9;
                const jetWash = 785;
                const storage = 509;

                if (handymanServicesUK.indexOf(serviceId) !== -1) {
                    doublerEventIdUK = 389474;
                } else if (removalsServicesUK.indexOf(serviceId) !== -1) {
                    doublerEventIdUK = 389470;
                } else if (pestControlServicesUK.indexOf(serviceId) !== -1) {
                    doublerEventIdUK = 389472;
                } else if (landscapingUK === serviceId) {
                    doublerEventIdUK = 448179;
                } else if (endOfTenancyCleaning === serviceId) {
                    doublerEventIdUK = 448181;
                } else if (afterBuildersCleaning === serviceId) {
                    doublerEventIdUK = 448183;
                } else if (wasteRemoval === serviceId) {
                    doublerEventIdUK = 448185;
                } else if (treeSurgery === serviceId) {
                    doublerEventIdUK = 448187;
                } else if (electricians === serviceId) {
                    doublerEventIdUK = 448189;
                } else if (carpetCleaning === serviceId) {
                    doublerEventIdUK = 448191;
                } else if (ovenCleaning === serviceId) {
                    doublerEventIdUK = 448213;
                } else if (gutterCleaning === serviceId) {
                    doublerEventIdUK = 448215;
                } else if (regularCleaning === serviceId) {
                    doublerEventIdUK = 448217;
                } else if (windowCleaning === serviceId) {
                    doublerEventIdUK = 448219;
                } else if (oneOffCleaning === serviceId) {
                    doublerEventIdUK = 448221;
                } else if (gardening === serviceId) {
                    doublerEventIdUK = 448223;
                } else if (jetWash === serviceId) {
                    doublerEventIdUK = 448225;
                } else if (storage === serviceId) {
                    doublerEventIdUK = 448227;
                }

                // eslint-disable-next-line max-len
                buildSrcURL = 'https://tbs.tradedoubler.com/report?organization=2194032&event=' + doublerEventIdUK + '&orderNumber=' + orderReference + '&orderValue=' + orderValue + '&currency=GBP&tduid=' + trackingIdUK + '&type=iframe&reportInfo=f1%3D' + serviceId;
                break;
            // MAR177
            case AFFILIATES.doublerAUS:
                const trackingIdAUS = this.cookieService.get('TRADEDOUBLER');

                // Default
                let doublerEventIdAUS = 385051;

                // 446720               Carpet Cleaning AU
                const carpetCleaningServicesAUS = [
                    50,
                ];

                // 446718               Spring Cleaning (One off Deep Cleaning) AU
                const sprintCleaningServicesAUS = [
                    64,
                ];

                // 446716               EOL (End of lease Cleaning) AU
                const eolServicesAUS = [
                    49,
                ];

                // 446724               Oven Cleaning AU
                const ovenServicesAUS = [
                    43,
                ];

                // 446726               Window Cleaning AU
                const windowCleningServicesAUS = [
                    566,
                ];

                // 446728               Fantastic Club Membership AU
                const membershipServicesAUS = [
                    774,
                ];


                // Removals - 389470
                const removalsServicesAUS = [
                    451,
                    450,
                    380,
                ];

                // Pest Control - 389472
                const pestControlServicesAUS = [
                    173,
                    239,
                    172,
                    51,
                ];

                // Handyman & Tradesman - 389474
                const handymanServicesAUS = [
                    454,
                    453,
                ];

                if (handymanServicesAUS.indexOf(serviceId) !== -1) {
                    doublerEventIdAUS = 389474;
                } else if (pestControlServicesAUS.indexOf(serviceId) !== -1) {
                    doublerEventIdAUS = 389472;
                } else if (removalsServicesAUS.indexOf(serviceId) !== -1) {
                    doublerEventIdAUS = 389470;
                } else if (carpetCleaningServicesAUS.indexOf(serviceId) !== -1) {
                    doublerEventIdAUS = 446720;
                } else if (sprintCleaningServicesAUS.indexOf(serviceId) !== -1) {
                    doublerEventIdAUS = 446718;
                } else if (eolServicesAUS.indexOf(serviceId) !== -1) {
                    doublerEventIdAUS = 446716;
                } else if (ovenServicesAUS.indexOf(serviceId) !== -1) {
                    doublerEventIdAUS = 446724;
                } else if (windowCleningServicesAUS.indexOf(serviceId) !== -1) {
                    doublerEventIdAUS = 446726;
                } else if (membershipServicesAUS.indexOf(serviceId) !== -1) {
                    doublerEventIdAUS = 446728;
                }

                // eslint-disable-next-line max-len
                buildSrcURL = 'https://tbs.tradedoubler.com/report?organization=2194032&event=' + doublerEventIdAUS + '&orderNumber=' + orderReference + '&orderValue=' + orderValue + '&currency=AUD&tduid=' + trackingIdAUS + '&type=iframe&reportInfo=f1%3D' + serviceId;
                break;

            case AFFILIATES.tradeTrackerSales:
                // You can load multiple scripts by just providing the key as argument into load method of the service
                this.load(LOAD_OPTIONS.confirm, sourceAbbr).then((data) => {
                    // Script Loaded Successfully
                }).catch((error) => this.logger.log(error));

                break;
            case AFFILIATES.tradeTrackerLead:
                // You can load multiple scripts by just providing the key as argument into load method of the service
                this.load(LOAD_OPTIONS.confirm, sourceAbbr).then((data) => {
                    // Script Loaded Successfully
                }).catch((error) => this.logger.log(error));

                break;

            // MAR151
            case AFFILIATES.CommFactory:
                // You can load multiple scripts by just providing the key as argument into load method of the service
                this.load(LOAD_OPTIONS.confirm, sourceAbbr).then((data) => {
                    // Script Loaded Successfully
                }).catch((error) => this.logger.log(error));
                break;

            default:
        }

        return buildSrcURL;

    }

    public getIframeUrl() {
        // Do not execute if we trigger no price
        if (this._orderData.activeBooking.getValue().get('price') && this._orderData.activeBooking.getValue().get('price').type !== 'no_price' && typeof this.scripts !== 'undefined') {
            const findIframeKey = Object.keys(this.scripts).find((key) => ScriptStore[key].type === IFRAME);
            return this.scripts[findIframeKey].src = this.fillProperties();
        } else {
            return null;
        }
    }

    public load(load?: string, ...scripts: string[]) {
        const promises: any[] = [];
        scripts.forEach((script) => promises.push(this.loadScript(script, load)));
        return Promise.all(promises);
    }

    private loadScript(name: string, load?: string) {
        return new Promise((resolve, reject) => {

            Object.keys(this.scripts).forEach((key) => {

                if (this.scripts[key].name === name && load === this.scripts[key].load) {

                    if (this.scripts[key].name && !this.scripts[key].loaded) {

                        // load script
                        const script = document.createElement('script');

                        // External Script include
                        if (this.scripts[key].type !== TYPE_OPTIONS.inner) {
                            script.type = 'text/javascript';
                            script.src = this.scripts[key].src;
                            script.async = true;
                            script.defer = true;
                        } else {
                            // Fill Script
                            this.fillInnerScripts(key, this.scripts[key].name);
                            script.innerHTML = this.scripts[key].src;
                        }

                        if (script.readyState) {  // IE
                            script.onreadystatechange = () => {
                                if (script.readyState === 'loaded' || script.readyState === 'complete') {
                                    script.onreadystatechange = null;
                                    this.scripts[key].loaded = true;
                                    resolve({ script: name, loaded: true, status: 'Loaded' });
                                }
                            };
                        } else {  // Others
                            script.onload = () => {
                                this.scripts[key].loaded = true;
                                resolve({ script: name, loaded: true, status: 'Loaded' });
                            };
                        }
                        script.onerror = (error: any) => reject({ script: name, loaded: false, status: 'Loaded' });
                        document.getElementsByTagName('head')[0].appendChild(script);

                    } else {
                        resolve({ script: name, loaded: true, status: 'Already Loaded' });
                    } 
                }
            });
        });
    }

}
