
export class ParamsOptionsBuilder {
    private primitives: string[] = ['string', 'number', 'boolean', 'symbol', 'bigint'];
    // Todo create interface for the params object !!!
    /**
     * Query params builder by given object
     * @param params
     */
    public paramBuilder(params: any): string {
        if (!params) {
            return '';
        }

        return this.toHttpParams(params);
    }

    private isBuffer(obj: any): boolean {
        if (!obj || typeof obj !== 'object') {
            return false;
        }

        return !!(obj?.constructor?.isBuffer && obj.constructor.isBuffer(obj));
    }

    private toKeyValuePairs(prefix: string, obj: any): any {
        if (obj === null) {
            obj = '';
        } else if (obj && !isNaN(obj) && obj instanceof Date) {
            try {
                obj = obj.toISOString();
            } catch(err) {
                obj = '';
            }
        }

        if (this.primitives.includes(typeof obj) || this.isBuffer(obj)) {
            return [{
                key: prefix,
                value: String(obj)
            }];
        }

        // skip undefined values
        if (typeof obj === 'undefined') {
            return [];
        }
        const keys = Object.keys(obj);
        const pairs = keys.map((key) => {
            const keyPrefix = prefix + (Array.isArray(obj) ? `[${key}]` : `[${key}]`);
            return this.toKeyValuePairs(keyPrefix, obj[key]);
        });

        // [[pair], [pair], [pair]] => [pair, pair, pair]
        return pairs.reduce((accumulator, value) => accumulator.concat(value), []);
    }

    private toHttpParams(obj: any): string {
        const keys = Object.keys(obj);
        const pairs = [];

        for (const key of keys) {
            const keyPairs = this.toKeyValuePairs(key, (obj[key]));
            pairs.push(...keyPairs);
        }

        return pairs.map((pair) => {
            return pair.key + '=' + pair.value;
        }).join('&');

    }
}
