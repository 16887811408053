import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
    public settings: { text: string; overlayEnabled: boolean,  isInvisible: boolean} = {
        text: '',
        overlayEnabled: true,
        isInvisible: false,
    };

    public isHidden = true;
    constructor(private router: Router) {}

    public show(loaderText: string, overlayEnabled = true, isInvisible: boolean): void {
        this.settings.text = loaderText;

        this.settings.overlayEnabled = overlayEnabled;

        if (!isInvisible) {
            this.isHidden = false;
        }
    }

    public hide(): void {
        this.isHidden = true;
    }
}
