import { BackEndXrmService } from './backendservices/BackEndXrmService';
import ObfInjector from '../Helpers/Injector';
import { SDKQueryParams } from '../Api/Interfaces/SDKQueryParams';

export class SDKSystemService {
    private _backEndXrmService: BackEndXrmService;
    private apiPoints = {
        coverageReportDetails: 'system/coverage_report_details',
    };

    constructor() {
        this._backEndXrmService = ObfInjector.getRegistered('BackEndXrmService');
    }

    /**
     * Set user applications
     * @param {Object} data 
     * @returns {Promise}
     */
    public sendCoverageReportDetails(data: any): Promise<any> {
        return new Promise((resolve: Function, reject: Function) => {
            const subscriber = this._backEndXrmService
                .post(this.apiPoints.coverageReportDetails, data, undefined)
                .subscribe(
                    res => {
                        resolve(res);
                    },
                    err => {
                        reject(err);
                    },
                    () => {
                        subscriber.unsubscribe();
                    }
                );
        });
    }
}
