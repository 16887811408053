import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DynamicColorChangerDirective } from './dynamic-color-changer.directive';

@NgModule({
    declarations: [DynamicColorChangerDirective],
    imports: [
        CommonModule,
    ],
    exports: [
        DynamicColorChangerDirective,
    ],
})
export class DynamicColorChangeModule { }
