import { Injectable } from '@angular/core';
import { Category } from '@models/category';
import { Service } from '@models/service';

@Injectable()
export class ServiceData {
    private services: Array<Service> = new Array();
    private categories: Array<Category> = new Array();
    private lastServiceInitData: any = null;

    constructor() {

    }

    /**
     * Services setter
     * @param services
     */
    public setServices(services: Array<Service>): void {
        if (services && services.length) {
            this.services = services;
        }
    }

    /**
     * Services getter
     */
    public getServices(): Array<Service> {
        return this.services;
    }

    /**
     * Categories and services setter (categories contain services)
     * @param services
     */
    public setCategories(categories: Array<Category>): void {
        if (categories && categories.length) {
            this.categories = categories;
        }
    }

    /**
     * Categories getter
     */
    public getCategories(): Array<Category> {
        return this.categories;
    }

    /**
     * Last used service init data setter
     * @param services
     */
    public setLastServiceInitData(data: any): void {
        if (data) {
            this.lastServiceInitData = data;
        }
    }

    /**
     * Last used service init data getter
     */
    public getLastServiceInitData(): Array<Category> {
        return this.lastServiceInitData;
    }
}
