import { SDKAccountService } from './lib/Services/SDKAccountService';
// tslint:disable: ban-types

import { SDKBookingProcessService } from './lib/Services/SDKBookingProcessService';
import './polyfills';
import { SDKBatchRequestService } from './lib/Services/SDKBatchRequestService';
import { SDKAuthenticationService } from './lib/Services/authentication/SDKAuthenticationService';
import { SDKClientService } from './lib/Services/SDKClientService';
import { SDKServicesDataService } from './lib/Services/SDKServicesDataService';
import { Configuration } from './lib/Constants/Configuration';
import { Authorize } from './lib/Api/ApiAuthorize';
import ObfInjector from './lib/Helpers/Injector';
import { SDKProfileService } from './lib/Services/SDKProfileService';
import { BackEndXrmService } from './lib/Services/backendservices/BackEndXrmService';
import { HeaderBuilder } from './lib/Services/backendservices/HeaderBuilder';
import { BackEndAccountsService } from './lib/Services/backendservices/BackEndAccountsService';
import { ParamsOptionsBuilder } from './lib/Helpers/ParamsOptionsBuilder';
import { Crypto } from './lib/Helpers/crypto-js';
import { ObfSDK } from './ObfSDK';
import { SDKModuleSettings } from './lib/Api/Interfaces/SDKModuleSettings';
import { SDKSharedService } from './lib/Services/SDKSharedService';
import { SDKSystemService } from './lib/Services/SDKSystemService';
import { HttpInterceptor } from './lib/Ajax/HttpInterceptor';
import { SDKValidationResponseService } from './lib/Services/SDKValidationResponseService';

class ObfSDKModule {
    constructor() { }

    /**
     * Register all SDK Services and initialization
     * @param {Object} configurationSettings SDK configuration data
     * @param {Function} callback callback method
     * @param {Function} httpInterceptorHandler interceptor handler function
     */
    public init(configurationSettings: SDKModuleSettings, callback: Function, httpInterceptorHandler?: Function): void {

        if (!configurationSettings) {

            const responseObject: any = {
                error: {
                    message: 'Missing configurationSettings'
                }
            };

            callback(responseObject);
        }

        const configuration = new Configuration(configurationSettings);
        ObfInjector.register('Configuration', configuration);

        const auth = new Authorize(configurationSettings.profile, configurationSettings.application);
        ObfInjector.register('Authorize', auth);

        const crypto = new Crypto();
        ObfInjector.register('Crypto', crypto);

        const headerBuilder = new HeaderBuilder();
        ObfInjector.register('HeaderBuilder', headerBuilder);

        const paramsOptionsBuilder = new ParamsOptionsBuilder();
        ObfInjector.register('ParamsOptionsBuilder', paramsOptionsBuilder);

        const httpInterceptor = new HttpInterceptor();
        if (httpInterceptorHandler) {
            httpInterceptor.setResponseChecker(httpInterceptorHandler);
        }
        ObfInjector.register('HttpInterceptor', httpInterceptor);

        // * is use to validate some of the response structure and modify the response data only on success
        const validationResponseService = new SDKValidationResponseService();
        ObfInjector.register('SDKValidationResponseService', validationResponseService);

        const backEnd = new BackEndXrmService();
        ObfInjector.register('BackEndXrmService', backEnd);

        const accountsBackEnd = new BackEndAccountsService();
        ObfInjector.register('BackEndAccountsService', accountsBackEnd);

        /**
         * Public services declarations instances
         */
        const sharedService = new SDKSharedService();
        ObfInjector.register('SDKSharedService', sharedService);

        const profileService = new SDKProfileService();
        ObfInjector.register('SDKProfileService', profileService);

        const clientService = new SDKClientService();
        ObfInjector.register('SDKClientService', clientService);

        const servicesDataService = new SDKServicesDataService();
        ObfInjector.register('SDKServicesDataService', servicesDataService);

        const authenticationService = new SDKAuthenticationService();
        ObfInjector.register('SDKAuthenticationService', authenticationService);

        const accountService = new SDKAccountService();
        ObfInjector.register('SDKAccountService', accountService);

        const bookingProcessService = new SDKBookingProcessService();
        ObfInjector.register('SDKBookingProcessService', bookingProcessService);

        const batchRequestService = new SDKBatchRequestService();
        ObfInjector.register('SDKBatchRequestService', batchRequestService);
        
        const systemService = new SDKSystemService();
        ObfInjector.register('SDKSystemService', systemService);

        /**
         * Initialize public endpoints declarations
         */
        sharedService.getServerTime()
            .then((response) => {
                const obfSDKInstance = new ObfSDK();

                const responseObject: any = {
                    sdkIns: obfSDKInstance
                };

                callback(responseObject);
            }).catch((errorResponse) => {
                let responseObject: any = {
                    error: {
                        message: ''
                    }
                };

                // Set the error message if whe don't have proxy. 
                if (errorResponse.error && errorResponse.error.length) {
                    for (let errorIndex: number = 0; errorIndex < errorResponse.error.length; errorIndex++) {
                        const error: any = errorResponse.error[errorIndex];

                        responseObject.error.message += `${error.message}\n`;
                    }
                } else if (errorResponse.data) {
                    responseObject.error.message = 'No error message supplied in normal response';
                }

                // Check if we have proxy in configuration
                if (configuration.changeToProxy()) {
                    backEnd.changeApi();
                    sharedService.getServerTime()
                        .then((response) => {
                            const obfSDKInstance = new ObfSDK();

                            responseObject = {
                                sdkIns: obfSDKInstance
                            };

                            callback(responseObject);
                        }).catch((proxyError) => {
                            responseObject.error.message = '[proxy]:';

                            // Set the error message.
                            if (errorResponse.error && errorResponse.error.length) {
                                for (let errorIndex: number = 0; errorIndex < errorResponse.error.length; errorIndex++) {
                                    const error: any = errorResponse.error[errorIndex];

                                    responseObject.error.message += `${error.message}\n`;
                                }
                            } else if (errorResponse.data) {
                                responseObject.error.message += 'No error message supplied in normal response';
                            }

                            callback(responseObject)
                        })
                } else {
                    callback(responseObject);
                }
            })
    }

}

export const obfSDKModule = new ObfSDKModule();
