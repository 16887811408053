import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { LocalizationProvider } from '@providers/localization.provider';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[localizedText]',
})
/**
 * Directive which inserts a countdown timer in element
 */
export class LocalizationDirective implements OnInit {
    @Input() public textKeyword: string;
    @Input() public insertionType: string; // possible values: append, prepend
    @Input() public useSpacing: boolean;

    constructor(private element: ElementRef, private _localizationProvider: LocalizationProvider) { }

    public ngOnInit() {
        if (this.textKeyword) {
            if (!this.insertionType) {
                this.element.nativeElement.innerHTML = this._localizationProvider.getLocalizedText(this.textKeyword);
            } else {
                const currentTextValue: string = this.element.nativeElement.innerHTML,
                    spacer: string = this.useSpacing ? ' ' : '';

                let newTextValue = '';

                if (this.insertionType === 'append') { newTextValue = `${currentTextValue}${spacer}${this._localizationProvider.getLocalizedText(this.textKeyword)}`; } else { newTextValue = `${this._localizationProvider.getLocalizedText(this.textKeyword)}${spacer}${currentTextValue}`; }

                this.element.nativeElement.innerHTML = newTextValue;
            }
        }
    }
}
