import { Injectable } from '@angular/core';
import { User } from '@models/user';
import { BehaviorSubject } from 'rxjs';
import { UniqueRecordsPipe } from './../../pipes/unique-records.pipe';
import { UserAddressesPipe } from './../../pipes/user-addresses.pipe';
import { OrderData } from './order-data.provider';
@Injectable()
export class UserData {
    private updatedUserData: BehaviorSubject<User>;
    public accountsMenuConfig: BehaviorSubject<any> = new BehaviorSubject(null);
    private userCameLogged = false;
    private loginModalDefaultTab = 'register';
    private clientType: string = null;
    private isAnonymous: Boolean = false;

    constructor(
        private _userAddressesPipe: UserAddressesPipe,
        private _uniqueRecordsPipe: UniqueRecordsPipe,
        private _orderData: OrderData,
    ) {
        this.updatedUserData = new BehaviorSubject(null);
    }

    // UserData setter
    public setUserData(userData) {
        // Filter user phones
        if (userData && userData.phones && userData.phones.length) {
            userData.phones = this._uniqueRecordsPipe.transform(userData.phones, 'value');
        }
        // Set client type after receiving user data
        if (this.isMembershipPurchased()) {
            this.clientType = 'newmember';
        } else {
            this.clientType = userData && userData.membership ? 'member' : 'regular';
        }

        this.updatedUserData.next(userData);

    }

    /**
     * Check for membership
     */
    public isMembershipPurchased(): boolean {
        if (this._orderData && this._orderData.activeBooking && this._orderData.activeBooking.getValue()) {
            const activeBooking: any = this._orderData.activeBooking.getValue() ? this._orderData.activeBooking.getValue().get() : null;

            if (activeBooking && activeBooking.price && activeBooking.price.price_breakdown && activeBooking.price.price_breakdown.length) {

                const hasPurchasedMembership: boolean = activeBooking.price.price_breakdown.find((el) => el.type === 'membership');

                if (hasPurchasedMembership) {
                    return true;
                }
            }
        }

        return false;
    }

    /**
     * User data resetter
     */
    public resetUserData(): void {
        this.updatedUserData.next(null);
    }

    public getUserDefaultPhone() {
        const userData = this.getUserData();

        if (!userData || !userData?.phones) {
            return null;
        }
        const phone = userData.phones.find(el => el.default);

        return phone ? phone : null;
    }

    /**
     * User data getter
     */
    public getUserData(): User {
        return JSON.parse(JSON.stringify(this.updatedUserData.getValue()));
    }

    /**
     * Retrieve observable for user data
     */
    public getSubscription(): BehaviorSubject<User> {
        return this.updatedUserData;
    }

    /**
     * Web Accounts user menu config getter
     */
    public getAccountsMenuConfig() {
        return JSON.parse(JSON.stringify(this.accountsMenuConfig.getValue()));
    }

    /**
     * Web Accounts user menu config setter
     */
    public setAccountsMenuConfig(accountsMenuConfig: any) {
        //
        this.accountsMenuConfig.next(accountsMenuConfig)
    }

    /**
     * Sets user initial authentication status (did he came logged or not)
     * @param flag
     */
    public setInitialAuthStatus(flag: boolean): void {
        this.userCameLogged = flag;
    }

    /**
     * User initial authentication status getter
     */
    public getInitialAuthStatus(): boolean {
        return this.userCameLogged;
    }

    /**
     * Sets LoginAndRegisterComponent initial tab
     * @param flag
     */
    public setLoginModalDefaultTabName(tabName: string): void {
        this.loginModalDefaultTab = tabName;
    }

    /**
     * LoginAndRegisterComponent default tab getter
     */
    public getLoginModalDefaultTabName(): string {
        return this.loginModalDefaultTab;
    }

    /**
     * Client type setter
     * @param clientType
     */
    public setClientType(clientType: string): void {
        this.clientType = clientType;
    }

    /**
     * Client type getter
     */
    public getClientType(): string {
        return this.clientType;
    }

    /**
     * Anonymous session flag getter
     */
    public isAnonymSession(): Boolean {
        return this.isAnonymous;
    }

    /**
     * Anonymous session flag setter
     */
    public setAnonymSession(flag: Boolean): void {
        this.isAnonymous = flag;
    }
}
