
// tslint:disable-next-line:max-line-length
import { object, string, optional, number, boolean, array, constant, union, fail, succeed, valueAt } from '@mojotech/json-type-validation'
import { ApiResponseStatus } from '../Api/Interfaces/ApiResponseStatus';

function validateStringLength(data: string, minLength = 0) {
    return data.length > minLength ? succeed(data) : fail('not minimal length ' + minLength);
}

export class SDKValidationResponseService {

    private ValidationObjects: any = {
        configurationResponse: {
            fatal:
                object({
                    data: object({
                        privacy_policy_url: string(),
                        terms_and_conditions_url: string(),
                        membership_section_content:
                            string().andThen((data: any): any => {
                                if (data.length === 0) {
                                    return succeed(data);
                                }

                                try {
                                    data = JSON.parse(data);
                                } catch (e) {
                                    return fail('Error parse JSON membership_section_content');
                                }

                                try {
                                    object({
                                        main_section: object({
                                            terms_and_conditions_url: string()
                                                .andThen(validateStringLength)
                                        }),
                                        availability_popup: object({
                                            cancel_button: string().andThen(validateStringLength),
                                            confirm_button: string().andThen(validateStringLength)
                                        })
                                    }).runWithException(data);
                                } catch (e) {
                                    return fail('JSON :: ' + e.at + ' ' + e.message);
                                }

                                return succeed(data);
                            })
                        , // ? add json validation
                        payment_methods: union(array(object({
                            id: number(),
                            sort: number(),
                            title: string(),
                            type: string(),
                            // data: union(
                            //     object({ braintree_key: string() }),
                            //     object({ stripe_key: string() }),
                            //     object({ checkout_key: string() }),
                            //     constant(null)
                            // ),
                            payment_provider_id: union(number(), constant(null)),
                        })), array(string()), constant(null))
                    })
                }),

            warning:
                object({
                    data: object({
                        banners: string(),
                        cancel_policy_url: string(),
                        currency_code: string(),
                        default_category_id: number(),
                        default_service_id: number(),
                        locale: string(),
                        membership_section_content:
                            string().andThen((data: any): any => {

                                if (data.length === 0) {
                                    return succeed(data);
                                }

                                try {
                                    data = JSON.parse(data);
                                } catch (e) {
                                    throw Error('Error parse JSON membership_section_content');
                                }

                                return object({
                                    main_section: object({
                                        terms_and_conditions_url: string()
                                    }),
                                    cancel_button: string(),
                                    confirm_button: string()
                                }).runWithException(data)

                            }),
                        most_booked_search_section: string(),
                        phone: string(),
                        privacy_policy_url: string(),
                        referral_bonus_formatted: string(),
                        request_login_step: number(),
                        show_manage_membership_section: boolean(),
                        terms_and_conditions_url: string(),
                        payment_methods: array(object({
                            id: number(),
                            sort: number(),
                            title: string(),
                            type: string(),
                            data: union(
                                object({ braintree_key: string() }),
                                object({ stripe_key: string() }),
                                object({ checkout_key: string() }),
                                constant(null)
                            ),
                            payment_provider_id: union(number(), constant(null)),
                            payment_method_id: number(),
                            icon_image_url: union(string(), constant(null)),
                            vendor: constant(null),
                            default: boolean()
                        }))
                    })
                }),
        },
        validationResponse: {
            fatal: object({
                data: array(
                    object({
                        path: union(string(), constant(null)),
                        fields: union(array(
                            object({
                                name: union(string(), constant(null)),
                                regex: union(string(), constant(null)),
                                required: union(boolean(), constant(null)),
                            })
                        ), constant(null))
                    })
                )
            }),
            warning: object({
                data: array(
                    object({
                        path: string(),
                        fields: array(
                            object({
                                name: string(),
                                regex: string(),
                                required: boolean()
                            })
                        )
                    })
                )
            })
        },
        leaveReasonsResponse: {
            fatal: object({
                data: union(array(
                    object({
                        id: union(number(), constant(null)),
                        name: union(string(), constant(null)),
                        requires_comment: union(boolean(), constant(null)),
                        positions: union(array(string()), constant(null)),
                        sort: union(number(), constant(null))
                    })
                ), constant(null)) // null empty array 
            }),
            warning: object({
                data: array(
                    object({
                        id: number(),
                        name: string(),
                        requires_comment: boolean(),
                        positions: array(string()),
                        sort: number()
                    })
                )
            })
        },
        socialProvidersResponse: {
            fatal: object({
                data: union(array(
                    object({
                        id: union(number(), constant(null)),
                        name: union(string(), constant(null)),
                        sort: union(string(), number(), constant(null)),
                        type: union(string(), constant(null)),
                        data: union(
                            object({ app_id: union(string(), number()) }),
                            object({ client_id: string() }),
                            object({ expanded: boolean() }),
                            constant(null)
                        )
                    })
                ), constant(null))
            }),
            warning: object({
                data: array(
                    object({
                        id: number(),
                        name: string(),
                        sort: union(string(), number(), constant(null)),
                        type: string(),
                        data: union(
                            object({ app_id: string() }),
                            object({ client_id: string() }),
                            object({ expanded: boolean() }),
                            constant(null)
                        )
                    })
                )
            })
        }
    }

    constructor() {
    }

    public ValidationResponseStructure(data: ApiResponseStatus, type: string): ApiResponseStatus {
        data.decoder = {
            warning: '',
            error: ''
        }

        try {
            this.ValidationObjects[type].warning.run(data);
        } catch (e) {
            data.decoder.warning = e.at + ' ' + e.message
        }

        try {
            this.ValidationObjects[type].fatal.runWithException(data);
        } catch (e) {
            data.decoder.error = e.at + ' ' + e.message;
        }

        return data;
    }
}
