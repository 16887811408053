
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ParentComponent } from './components/parent/parent.component';
import { positionsConfig } from './constants/obf-paths';
import { ParentGuard } from './guards/parent.guard';
import { PathsGuard } from './guards/paths.guard';
import { MainDataProvider } from './providers/xrm/main-data.provider';
import { LocalizationProvider } from '@providers/localization.provider';

const AppRoutes: Routes = [
    {
        path: '',
        component: ParentComponent,
        canActivate: [ParentGuard],
        children: [
            {
                path: '',
                redirectTo: positionsConfig[0].slug,
                pathMatch: 'full',
            },
            {
                path: ':lang/' + positionsConfig[0].slug,
                loadChildren: () => import('./modules/create-booking-transaction/create-booking-transaction.module').then((m) => m.CreateBookingTransactionModule),
            },
            {
                path: ':lang/transaction/:id',
                loadChildren: () => import('./modules/configure-booking-transaction/configure-booking-transaction.module').then((m) => m.ConfigureBookingTransactionModule),
            },
        ],
    },
    {
        path: '**',
        redirectTo: positionsConfig[0].slug,
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(
            AppRoutes,
            {
    enableTracing: false,
    useHash: true,
    initialNavigation: 'disabled',
    onSameUrlNavigation: 'reload',
    relativeLinkResolution: 'legacy'
},
        ),
    ],
    providers: [ParentGuard, MainDataProvider, LocalizationProvider],
    exports: [RouterModule],
})
export class AppRoutingModule {
    constructor() {
    }
}
