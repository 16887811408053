import { Injectable } from '@angular/core';
import { Environment } from '@models/environment.model';
import { LocalizationProvider } from '@providers/localization.provider';
import { MainDataProvider } from '@providers/main-data.provider';
import { CryptoService } from '@services/crypto.service';
import { LoaderService } from '@services/loader.service';
import { interval } from 'rxjs';
import { gen32GUID, popupCenter } from 'src/app/helpers/helper-functions';

/**
 * Declarate global property gapi in window scope / Add gapi to window property interface
 */
declare global {
    interface Window {
        gapi: any;
    }
}

/**
 * Oauth2 authentication services use in register and login components
 */
@Injectable({
    providedIn: 'root',
})
export class Oauth2Service {
    private Oauth2Paths = {
        login: 'ext/login',
        register: 'ext/register'
    };

    public popupInstance = null;

    constructor(
        private _loaderService: LoaderService,
        private _localizationProvider: LocalizationProvider,
        private _mainDataProvider: MainDataProvider,
        private _cryptoService: CryptoService,
    ) { }

    /**
     * @param requestType
     */
    public beginAuth(requestType: 'login' | 'register') {
        return new Promise(async (res, rej) => {
            const loader = this._loaderService.showLoader(this._localizationProvider.getLocalizedText('loaderMsg14'));

            let oauth2Payload = {
                oauth_id: null,
                redirect_uri: '',
                social_provider_id: null,
                // code_verifier: null
            }
            
            const oauth2Data = this._mainDataProvider.getSocialAuthData().oauth2;
            oauth2Payload.social_provider_id = oauth2Data.provider_id;

            const clientId = oauth2Data.client_id;
            const authorizationUrl = oauth2Data.authorization_url;

            const obfOptions = this._mainDataProvider.getResourceObfOptions();

            // const redirectUri = 'http://obf-v2-test.1dxr.com/page.html';
            let redirectUri = obfOptions?.app_url ? obfOptions?.app_url + '/authorization.html' : window.location.origin + '/obf/authorization.html';

            // Localhost debug
            const env: Environment = this._mainDataProvider.obfEnv;
            if (env.env === 'localhost') {
                redirectUri = 'http://localhost/obf-v3/client/authorization.html'
            }

            oauth2Payload.redirect_uri = redirectUri;

            // oauth2Payload.code_verifier = this.generateCodeVerifier();
            // const codeChallenge = await this.getCodeChallenge(oauth2Payload.code_verifier);
            // const codeChallengeMethod = 'S256';

            const oauth2UrlPlain = authorizationUrl + '?client_id=' + clientId + '&redirect_uri=' + redirectUri + '&response_type=code';
            // const oauth2UrlS256 = authorizationUrl + '?client_id=' + clientId + '&redirect_uri=' + redirectUri + '&response_type=code' + '&code_challenge=' + codeChallenge + '&code_challenge_method=' + codeChallengeMethod;

            const popupPostMessageHandler = ((event) => {

                if (window.location?.origin && event?.origin && window.location.origin.indexOf(event.origin) !== -1) {
                    if (event?.data?.code) {
                        oauth2Payload.oauth_id = event.data.code
                        oauth2Popup.close();
                    }
                } else {
                    rej(null);
                }

            });
            // append listener for event
            window.addEventListener('message', popupPostMessageHandler);

            const centerPopup = popupCenter();
            const oauth2Popup = window.open(
                oauth2UrlPlain,
                'popUpWindow',
                'height=710,width=630,left=' + centerPopup.left + ',top=' + centerPopup.top + ',resizable=no,scrollbars=no,toolbar=no,menubar=no,location=no,directories=no, status=no',
            );

            this.popupInstance = oauth2Popup;

            const subscription = interval(1000).subscribe(() => {

                // Wait to close popup.
                if (oauth2Popup && !oauth2Popup.closed) { return; }

                if (oauth2Payload?.oauth_id) {
                    res(oauth2Payload);
                } else {
                    rej(null);
                }

                window.removeEventListener('message', popupPostMessageHandler);
                this._loaderService.hideLoader(loader);
                subscription.unsubscribe();
            });
        

        });
    }

    // GENERATING CODE VERIFIER
    private dec2hex(dec) {
        return ("0" + dec.toString(16)).substr(-2);
    }

    private generateCodeVerifier() {
        let array = new Uint32Array(56 / 2);
        window.crypto.getRandomValues(array);
        return Array.from(array, this.dec2hex).join("");
    }


    // GENERATING CODE CHALLENGE FROM VERIFIER
    private sha256(plain) {
        // returns promise ArrayBuffer
        const encoder = new TextEncoder();
        const data = encoder.encode(plain);
        return window.crypto.subtle.digest("SHA-256", data);
    }

    private base64urlencode(a) {
        let str = "";
        let bytes = new Uint8Array(a);
        let len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            str += String.fromCharCode(bytes[i]);
        }
        
        return btoa(str)
            .replace(/\+/g, "-")
            .replace(/\//g, "_")
            .replace(/=+$/, "");
    }

    async generateCodeChallengeFromVerifier(verifier) {
        let hashed = await this.sha256(verifier);
        let base64encoded = this.base64urlencode(hashed);

        return base64encoded;
    }

    async getCodeChallenge(codeVerifier) {
        
        let codeChalange = null;
        try {
            let code_challenge = await this.generateCodeChallengeFromVerifier(
            codeVerifier
            );
            codeChalange = code_challenge;

            return codeChalange;
        } catch (e) {
            codeChalange = JSON.stringify(e);

            return codeChalange;
        }
    }
}
