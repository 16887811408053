import { ApplicationRef, ComponentFactoryResolver, Directive, EmbeddedViewRef, Injectable, Injector, ViewChild, ViewContainerRef } from '@angular/core';
import { CustomModalComponent } from '../modules/shared/components/modals/custom-modal/custom-modal.component';
import { MainDataProvider } from '../providers/xrm/main-data.provider';
import { EventTrackingService } from './tracking/event-tracking.service';
import { LoaderService } from './loader.service';
import { LocalizationProvider } from '@providers/localization.provider';
import { TipProModalComponent } from '../modules/shared/components/modals/tip-pro-modal/tip-pro-modal.component';

@Injectable({
    providedIn: 'root',
})

export class ModalsService {
    @ViewChild('alertContainer', { read: ViewContainerRef, static: false }) public container;

    public modalStatus = false;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private applicationRef: ApplicationRef,
        private injector: Injector,
        private loaderService: LoaderService,
        private _eventTrackingService: EventTrackingService,
        public _mainDataProvider: MainDataProvider,
        public _localizationProvider: LocalizationProvider,
    ) { }

    /**
 * Dynamically creates modal window
 * @param {object} modalData data needed for modal
 * @param {string} modalType
 */
    // public open(modalData: any): void{
    public open(modalType: string, modalData?: any): void {
        let componentFactory = null,
            componentRef = null,
            domElement = null;

        let componentToResolve = null;
        this.modalStatus = true;

        switch (modalType) {
            case 'custom':
                componentToResolve = CustomModalComponent;

                break;
            // case 'loginAndRegister':
            //         componentToResolve = LoginAndRegisterComponent;
            // break;
            case 'additionalInfo':
                componentToResolve = CustomModalComponent;

                break;
            case 'defaultError':
                componentToResolve = CustomModalComponent;

                modalData = {
                    payload: modalData ? modalData.payload : null,
                    callback: modalData ? modalData.callback : null,
                };

                break;
            case 'tipPro':
                componentToResolve = TipProModalComponent;

                // modalData = {
                //     title: this._localizationProvider.getLocalizedText('modalDefaultTitle'),
                //     message: this._localizationProvider.getLocalizedText('modalErrMsg2'),
                //     buttons: [
                //         {
                //             label: this._localizationProvider.getLocalizedText('modalDefaultButtonTitle'),
                //             closeOnClick: true,
                //             type: 'primary',
                //             state: 'default',
                //             size: 'normal'
                //         },
                //     ],
                //     callback: modalData ? modalData.callback : undefined,
                // };

                break;
            default:

                break;
        }

        this._eventTrackingService.push({
            event: 'virtual-modal-view',
            virtualModalURL: '/booking-form/' + modalType + '/',
            virtualModalTitle: 'Booking Form Modal ' + modalType,
        });
        const givenCallback = modalData.callback;

        modalData.callback = (e) => {
            this.modalStatus = false;

            if (givenCallback && typeof givenCallback === 'function') {
                givenCallback(e);
            }
        };

        // Create modal component factory
        componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentToResolve);

        componentRef = componentFactory.create(this.injector);

        // Invoke component method to set its data
        componentRef.instance.setModalData(componentRef, modalData);

        // Attach component to the appRef so that it's inside the component tree
        this.applicationRef.attachView(componentRef.hostView);

        // Get DOM element from component
        domElement = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

        //  Append DOM element to the body
        document.body.appendChild(domElement);

        // Hide loader on popup init
        this.loaderService.hardCloseLoader();
    }
}
