import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { LocalizationModule } from '../../../directives/localization/localization.module';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';

@NgModule({
    declarations: [TermsConditionsComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        LocalizationModule,
    ],
    exports: [TermsConditionsComponent],
})
export class TermsConditionsModule { }
