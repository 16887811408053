import { ChoicesPositions, ChoicesType } from '@constants/choice';
import { Booking } from '@models/booking';
import { Choice } from '@models/choice';
import { Service } from '@models/service';

export class BookingTransactionModel {
    private data: Booking;

    constructor(createTransactionData) {
        this.data = createTransactionData;
    }

    /**
     * Set the new booking data
     * @param updatedData
     */
    public setData(updatedData) {
        this.data = updatedData;
    }

    /**
     * Will the given property name
     * @param {string} key
     * @return {Booking/null}
     */
    public get(key?: string): Booking | any | null {
        let data = null;

        if (!key) {
            data = this.data;
        }

        if (this.data[key]) {
            data = this.data[key];
        }

        return JSON.parse(JSON.stringify(data));
    }

    /**
     * Search for property by given array with keys
     * @param {Array<string>} keys
     * @return {any/null}
     */
    public getDeep(keys: [string]) {
        let data = null;

        if (Array.isArray(keys)) {
            data = this.data;

            for (let i = 0; i < keys.length; i++) {
                if (data[keys[i]]) {
                    data = data[keys[i]];
                } else {
                    data = undefined;
                    break;
                }
            }
        }

        return data;
    }

    public getTransactionChoices(position?: ChoicesPositions | null, type?: ChoicesType): Array<Choice> {
        if (
            typeof this.data.service !== 'number' &&
            this.data?.service?.choices &&
            typeof this.data?.service?.choices[0] !== 'number'
        ) {
            const choices: Choice[] = this.data.service.choices as Choice[];

            const filteredChoices: Array<Choice> = choices.filter((choice) => {

                if (position) {
                    if (type) {
                        return choice.positions && choice.positions.indexOf(position) !== -1 && choice.type === type;
                    } else {
                        return choice.positions && choice.positions.indexOf(position) !== -1;
                    }
                } else if (type) {

                    return choice.type === type;
                } else {
                    return 1;
                }
            });

            return filteredChoices;
        }

        return new Array();
    }
}
