import { SDKBookingProcessService } from '../../../../lib/xrm-sdk/src/lib/Services/SDKBookingProcessService';
import { SDKClientService } from '../../../../lib/xrm-sdk/src/lib/Services/SDKClientService';
import { SDKProfileService } from '../../../../lib/xrm-sdk/src/lib/Services/SDKProfileService';
import { SDKServicesDataService } from '../../../../lib/xrm-sdk/src/lib/Services/SDKServicesDataService';
import { ObfSDK } from '../../../../lib/xrm-sdk/src/ObfSDK';
import { obfSDKModule } from '../../../../lib/xrm-sdk/src/ObfSDKModule';
import { SDKModuleSettings } from './../../../../lib/xrm-sdk/src/lib/Api/Interfaces/SDKModuleSettings';
import { SDKAuthenticationService } from './../../../../lib/xrm-sdk/src/lib/Services/authentication/SDKAuthenticationService';
import { SDKAccountService } from './../../../../lib/xrm-sdk/src/lib/Services/SDKAccountService';
import { SDKBatchRequestService } from './../../../../lib/xrm-sdk/src/lib/Services/SDKBatchRequestService';
import { SDKSharedService } from './../../../../lib/xrm-sdk/src/lib/Services/SDKSharedService';
import { MainDataProvider } from './../../providers/xrm/main-data.provider';

import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { SDKSystemService } from 'lib/xrm-sdk/src/lib/Services/SDKSystemService';

@Injectable({
    providedIn: 'root',
})

export class XRMApiCommunicatorService {
    private sdkInstance: ObfSDK;
    private sdkConfiguration: SDKModuleSettings = null;

    constructor(
        private _mainDataProvider: MainDataProvider,

    ) {
        // console.log(client);
    }

    public initTheSKD(sdkHttpInterceptorHandler: Function) {
        // console.log(this._mainDataProvider.obfOptions);
        return new Promise((resolve, reject) => {
            if (this.sdkInstance) {
                resolve(true);
            } else {
                if (!this._mainDataProvider.obfEnv.api || !this._mainDataProvider.obfEnv.api.baseUrl || !this._mainDataProvider.obfEnv.api.accounts) {
                    reject('Missing configuration properties api_url or accounts_url');
                }

                this.sdkConfiguration = {
                    application: this._mainDataProvider.obfEnv.XApplication,
                    profile: this._mainDataProvider.obfEnv.XProfile,
                    api: this._mainDataProvider.obfEnv.api.baseUrl,
                    accounts_api: this._mainDataProvider.obfEnv.api.accounts,
                    cookieKey: this._mainDataProvider.obfEnv.cookieKey,
                };

                if (this._mainDataProvider.obfEnv && this._mainDataProvider.obfEnv.api.proxy) {
                    this.sdkConfiguration.api_proxy = this._mainDataProvider.obfEnv.api.proxy;
                }

                obfSDKModule.init(
                    this.sdkConfiguration,
                    (response: { sdkIns?: ObfSDK, error: any }) => {

                        if (response) {
                            // Successful sdk init
                            if (response.sdkIns) {
                                this.sdkInstance = response.sdkIns;
                                // console.error('complete init sdkInstance');
                                resolve(true);
                            } else if (response.error && response.error.message) { // sdk init failed
                                reject(response.error.message);
                            }

                        } else {
                            reject('[obfSDKModule] method miss configured response');
                        }
                    },
                    sdkHttpInterceptorHandler,
                );
            }
        });
    }

    public getSdkConfiguration() {
        return this.sdkConfiguration;
    }

    public setAuthSID(value) {
        // this.sdkInstance.setAuthSID(value);
    }

    public getInstance() {
        return this.sdkInstance;
    }

    public getProfileService(): SDKProfileService {
        return this.sdkInstance.ProfileService as SDKProfileService;
    }

    public getClientService(): SDKClientService {
        // console.error('GET CLIENT SERVICE');
        return this.sdkInstance.ClientService as SDKClientService;
    }

    public getServicesDataService(): SDKServicesDataService {
        return this.sdkInstance.ServicesDataService as SDKServicesDataService;
    }

    public getBookingProcessService(): SDKBookingProcessService {
        return this.sdkInstance.BookingProcessService as SDKBookingProcessService;
    }

    public getAuthenticationService(): SDKAuthenticationService {
        return this.sdkInstance.AuthenticationService as SDKAuthenticationService;
    }

    public getAccountService(): SDKAccountService {
        return this.sdkInstance.AccountService as SDKAccountService;
    }

    public getBatchRequestService(): SDKBatchRequestService {
        return this.sdkInstance.BatchRequestService as SDKBatchRequestService;
    }

    public getSharedService(): SDKSharedService {
        return this.sdkInstance.SharedService as SDKSharedService;
    }

    public getSystemService(): SDKSystemService {
        return this.sdkInstance.SystemService as SDKSystemService;
    }
}
