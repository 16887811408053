import { SDKQueryParams } from './../Api/Interfaces/SDKQueryParams';
import { ParamsOptionsBuilder } from '../Helpers/ParamsOptionsBuilder';
import { BackEndXrmService } from './backendservices/BackEndXrmService';
import ObfInjector from '../Helpers/Injector';

export class SDKServicesDataService {
    private apiPoints = {
        client: 'client',
        categories: 'client/categories',
        services: 'client/services',
        treats: 'client/treats',
        units: 'client/units',
        teams: 'client/teams'
    };
    private favoritesPaths: any = {
        addFavorite: 'add_favorite',
        removeFavorite: 'remove_favorite'
    };
    private _backEndXrmService: BackEndXrmService;
    private _paramsOptionsBuilder: ParamsOptionsBuilder;

    constructor() {
        this._backEndXrmService = ObfInjector.getRegistered('BackEndXrmService') as BackEndXrmService;
        this._paramsOptionsBuilder = ObfInjector.getRegistered('ParamsOptionsBuilder') as ParamsOptionsBuilder;
    }

    /**
     * Fetch all services
     * @param {Object} queryParams query parameters
     * @returns {Promise}
     */
    public getServices(queryParams: SDKQueryParams): Promise<any> {
        let params = this._paramsOptionsBuilder.paramBuilder(queryParams);
        params = params ? '/?' + params : '';

        return new Promise((resolve: Function, reject: Function) => {
            const subscriber = this._backEndXrmService
                .get(this.apiPoints.services + params)
                .subscribe(
                    res => {
                        resolve(res);
                    },
                    err => {
                        reject(err);
                    },
                    () => {
                        subscriber.unsubscribe();
                    }
                );
        });
    }

    /**
     * Fetch all categories
     * @param {Object} queryParams query parameters
     * @returns {Promise}
     */
    public getCategories(queryParams: SDKQueryParams): Promise<any> {
        let params = this._paramsOptionsBuilder.paramBuilder(queryParams);
        params = params ? '/?' + params : '';

        return new Promise((resolve: Function, reject: Function) => {

            const subscriber = this._backEndXrmService
                .get(this.apiPoints.categories + params)
                .subscribe(
                    res => {
                        res.debugUrl = this.apiPoints.categories + params; // !!! issue with wrong response debug search for: #e277c5 !!! 
                        resolve(res);
                    },
                    err => {
                        reject(err);
                    },
                    () => {
                        subscriber.unsubscribe();
                    }
                );
        });
    }

    /**
     * Fetch service by ID
     * @param {String} serviceId service ID
     * @param {Object} queryParams query parameters
     * @returns {Promise}
     */
    public getService(serviceId: string | number, queryParams: SDKQueryParams): Promise<any> {
        if (!serviceId) {
            throw Error('Is not given serviceid ');
        }

        let params = this._paramsOptionsBuilder.paramBuilder(queryParams);
        params = params ? '/?' + params : '';

        return new Promise((resolve: Function, reject: Function) => {

            const subscriber = this._backEndXrmService
                .get(this.apiPoints.services + `/${serviceId}` + params)
                .subscribe(
                    res => {
                        resolve(res);
                    },
                    err => {
                        reject(err);
                    },
                    () => {
                        subscriber.unsubscribe();
                    }
                );
        });
    }

    /**
     * Fetch service's payment methods
     * @param {String} serviceId service ID
     * @param {Object} queryParams query parameters
     * @returns {Promise} 
     */
    public getServicePaymentMethods(serviceId: string, queryParams: SDKQueryParams): Promise<any> {
        let params = this._paramsOptionsBuilder.paramBuilder(queryParams);
        params = params ? '/?' + params : '';

        return new Promise((resolve: Function, reject: Function) => {

            const subscriber = this._backEndXrmService
                .get(this.apiPoints.services + `/${serviceId}/payment_methods` + params)
                .subscribe(
                    res => {
                        resolve(res);
                    },
                    err => {
                        reject(err);
                    },
                    () => {
                        subscriber.unsubscribe();
                    }
                );
        });
    }

    /**
     * Fetch all treats
     * @param {Object} queryParams query parameters
     * @returns {Promise}
     */
    public fetchTreats(queryParams?: SDKQueryParams): Promise<any> {
        let params = this._paramsOptionsBuilder.paramBuilder(queryParams);
        params = params ? '/?' + params : '';

        return new Promise((resolve: Function, reject: Function) => {

            const subscriber = this._backEndXrmService
                .get(this.apiPoints.treats + params)
                .subscribe(
                    res => {
                        resolve(res);
                    },
                    err => {
                        reject(err);
                    },
                    () => {
                        subscriber.unsubscribe();
                    }
                );
        });
    }

    /**
     * Get the data for specific unit by unit id. 
     * !!! Important need to be pass query param transaction_id or booking_id.
     * @param {String} unitId
     * @param {SDKQueryParams} queryParams 
     * @returns {Promise} 
     */
    public getUnits(unitId: string, queryParams?: SDKQueryParams) {
        let params = this._paramsOptionsBuilder.paramBuilder(queryParams);
        params = params ? '/?' + params : '';

        return new Promise((resolve: Function, reject: Function) => {
            const subscriber = this._backEndXrmService
                .get(this.apiPoints.units + `/${unitId}` + params)
                .subscribe(
                    res => {
                        resolve(res);
                    },
                    err => {
                        reject(err);
                    },
                    () => {
                        subscriber.unsubscribe();
                    }
                );
        });
    }

    /**
     * Add unit as favorites
     * @param {String} unitId
     * @param {SDKQueryParams} queryParams 
     * @returns {Promise} 
     */
    public addUnitAsFavorite(unitId: string, queryParams?: SDKQueryParams): Promise<any> {
        let params = this._paramsOptionsBuilder.paramBuilder(queryParams);

        params = params ? '/?' + params : '';

        return new Promise((resolve: Function, reject: Function) => {
            const subscriber = this._backEndXrmService
                .post(this.apiPoints.units + `/${unitId}` + `/${this.favoritesPaths.addFavorite}` + params, null)
                .subscribe(
                    res => {
                        resolve(res);
                    },
                    err => {
                        reject(err);
                    },
                    () => {
                        subscriber.unsubscribe();
                    }
                );
        });
    }

    /**
     * Remove unit from favorites
     * @param {String} unitId
     * @param {SDKQueryParams} queryParams 
     * @returns {Promise} 
     */
    public removeUnitFromFavorites(unitId: string, queryParams?: SDKQueryParams): Promise<any> {
        let params = this._paramsOptionsBuilder.paramBuilder(queryParams);

        params = params ? '/?' + params : '';

        return new Promise((resolve: Function, reject: Function) => {
            const subscriber = this._backEndXrmService
                .post(this.apiPoints.units + `/${unitId}` + `/${this.favoritesPaths.removeFavorite}` + params, null)
                .subscribe(
                    res => {
                        resolve(res);
                    },
                    err => {
                        reject(err);
                    },
                    () => {
                        subscriber.unsubscribe();
                    }
                );
        });
    }

    /**
     * Get the data for specific team by team id. 
     * !!! Important need to be pass query param transaction_id or booking_id.
     * @param {String} teamId 
     * @param {SDKQueryParams} queryParams 
     * @returns {Promise} 
     */
    public getTeams(teamId: string, queryParams?: SDKQueryParams) {
        let params = this._paramsOptionsBuilder.paramBuilder(queryParams);
        params = params ? '/?' + params : '';

        return new Promise((resolve: Function, reject: Function) => {
            const subscriber = this._backEndXrmService
                .get(this.apiPoints.teams + `/${teamId}` + params)
                .subscribe(
                    res => {
                        resolve(res);
                    },
                    err => {
                        reject(err);
                    },
                    () => {
                        subscriber.unsubscribe();
                    }
                );
        });
    }

    /**
     * Add team as favorites
     * @param {String} teamId
     * @param {SDKQueryParams} queryParams 
     * @returns {Promise} 
     */
    public addTeamAsFavorite(teamId: string, queryParams?: SDKQueryParams): Promise<any> {
        let params = this._paramsOptionsBuilder.paramBuilder(queryParams);

        params = params ? '/?' + params : '';

        return new Promise((resolve: Function, reject: Function) => {
            const subscriber = this._backEndXrmService
                .post(this.apiPoints.teams + `/${teamId}` + `/${this.favoritesPaths.addFavorite}` + params, null)
                .subscribe(
                    res => {
                        resolve(res);
                    },
                    err => {
                        reject(err);
                    },
                    () => {
                        subscriber.unsubscribe();
                    }
                );
        });
    }

    /**
     * Remove unit from favorites
     * @param {String} teamId
     * @param {SDKQueryParams} queryParams 
     * @returns {Promise} 
     */
    public removeTeamFromFavorites(teamId: string, queryParams?: SDKQueryParams): Promise<any> {
        let params = this._paramsOptionsBuilder.paramBuilder(queryParams);
        params = params ? '/?' + params : '';

        return new Promise((resolve: Function, reject: Function) => {
            const subscriber = this._backEndXrmService
                .post(this.apiPoints.teams + `/${teamId}` + `/${this.favoritesPaths.removeFavorite}` + params, null)
                .subscribe(
                    res => {
                        resolve(res);
                    },
                    err => {
                        reject(err);
                    },
                    () => {
                        subscriber.unsubscribe();
                    }
                );
        });
    }
}
