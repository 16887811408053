export enum DataLayerCustomDimensions {
    Brand = 'brand',
    SourcePhone = 'source_phone',
    Market = 'market',
    Membership = 'membership',
    UserId = 'user_id',
    Profile = 'profile',
    TransactionType = 'transaction_type',
    Confirmed = 'confirmed',
    IsAnonymous = 'is_anonymous',
    LastPosition = 'last_position',
    Currency = 'currency_code',
  }
