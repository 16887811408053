import { ResponseValidator } from '../Validators/ResponseValidator';

import { ResponseType } from '../../Constants/ResponseType';

export function responseTest(s: Object | string, url: string, isActualBatch: boolean) {
    // ! TODO: Unit test
    // s = { responses: [{data: [], error: [{code: 5110, message: "Invalid namespace"}]}] };
    
    // Error
    if (s.hasOwnProperty(ResponseType.Error)) {
        return ResponseType.Error;
    }

    // Warning
    if (s.hasOwnProperty(ResponseType.Warnings)) {
        return ResponseType.Warnings;
    }

    // Batch
    if (s.hasOwnProperty(ResponseType.Batch)) {
        if (isActualBatch) {
            return ResponseType.Batch;
        } else {
            return ResponseType.Error;
        }
    }

    // Check if returned response is not blank object/array
    if (!s || s && !Object.keys(s).length) {
        return ResponseType.Empty;
    }

    // String/HTML
    if (typeof s === 'string') {
        // We expect html response only from this namespace!
        if (url && url.indexOf('get-auth') !== -1) {
            return ResponseType.Html;
        } else { // Probably response is php error send as html
            return ResponseType.Error;
        }
    }

     // Success
    if (Object.keys(s).length) {
        return ResponseType.Success;
    }
}

export class ResponseTypeValidator implements ResponseValidator {
    public isAcceptable(s: string, url: string, isActualBatch: boolean) {
        if (this.isValidJson(s)) {
            return responseTest(JSON.parse(s), url, isActualBatch);
        } else {
            return responseTest(s, url, isActualBatch);
        }
    }

    /**
     * Simple check for JSON
     * @param {string} jsonString
     */
    public isValidJson(jsonString: string): boolean {
        try {
            JSON.parse(jsonString);
        } catch (error) {
            return false;
        }
    
        return true;
    }
}
