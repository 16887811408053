//! moment.js locale configuration
//! locale : Lithuanian [lt]
//! author : Tony Genev

;(function (global, factory) {
    typeof exports === 'object' && typeof module !== 'undefined'
        && typeof require === 'function' ? factory(require('../../../../node_modules/moment-mini/moment.min')) :
    typeof define === 'function' && define.amd ? define(['../../../../node_modules/moment-mini/moment.min'], factory) :
    factory(global.moment)
 }(this, (function (moment) { 'use strict';

    //! moment.js locale configuration

    var lt = moment.defineLocale('lt', {
        months: 'sausio_vasario_kovo_balandžio_gegužė_birželio_liepos_rugpjūtis_rugsėjis_spalio_lapkričio_gruodžio'.split(
            '_'
        ),
        monthsShort: 'sau_vas_kov_bal_geg_bir_lie_rugp_rugs_spa_lap_gru'.split('_'),
        weekdays: 'sekmadienis_pirmadienis_antradienis_trečiadienis_ketvirtadienis_penktadienis_šeštadienis'.split(
            '_'
        ),
        weekdaysShort: 'sek_pir_ant_tre_ket_pen_šeš'.split('_'),
        weekdaysMin: 'se_pi_an_tr_ke_pe_še'.split('_'),
        longDateFormat: {
            LT: 'H:mm',
            LTS: 'H:mm:ss',
            L: 'D.MM.YYYY',
            LL: 'D MMMM YYYY',
            LLL: 'D MMMM YYYY H:mm',
            LLLL: 'dddd, D MMMM YYYY H:mm',
        },
        calendar: {
            sameDay: '[Šiandien в] LT',
            nextDay: '[Rytoj в] LT',
            nextWeek: 'dddd [в] LT',
            lastDay: '[Vakar в] LT',
            lastWeek: function () {
                switch (this.day()) {
                    case 0:
                    case 3:
                    case 6:
                        return '[Praeitis] dddd [in] LT';
                    case 1:
                    case 2:
                    case 4:
                    case 5:
                        return '[Praeitis] dddd [in] LT';
                }
            },
            sameElse: 'L',
        },
        relativeTime: {
            future: 'po to %s',
            past: 'prieš %s',
            s: 'kelias sekundes',
            ss: '%d sekundžių',
            m: 'minutė',
            mm: '%d minučių',
            h: 'valandą',
            hh: '%d valandos',
            d: 'dieną',
            dd: '%d dienos',
            w: 'savaitė',
            ww: '%d savaites',
            M: 'mėnuo',
            MM: '%d mėnuo',
            y: 'metų',
            yy: '%d metų',
        },
        dayOfMonthOrdinalParse: /\d{1,2}-(ев|ен|ти|ви|ри|ми)/,
        ordinal: function (number) {
            var lastDigit = number % 10,
                last2Digits = number % 100;
            if (number === 0) {
                return number + '-oji';
            } else if (last2Digits === 0) {
                return number + '-oji';
            } else if (last2Digits > 10 && last2Digits < 20) {
                return number + '-oji';
            } else if (lastDigit === 1) {
                return number + '-oji';
            } else if (lastDigit === 2) {
                return number + '-oji';
            } else if (lastDigit === 7 || lastDigit === 8) {
                return number + '-oji';
            } else {
                return number + '-oji';
            }
        },
        week: {
            dow: 1, // Monday is the first day of the week.
            doy: 7, // The week that contains Jan 7th is the first week of the year.
        },
    });

    return lt;

})));
