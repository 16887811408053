import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FsFocusHandlerDirective } from './fs-focus-handler.directive';

@NgModule({
    declarations: [FsFocusHandlerDirective],
    imports: [
        CommonModule,
    ],
    exports: [
        FsFocusHandlerDirective,
    ],
})
export class FsFocusHandlerModule { }
