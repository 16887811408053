import {
    additional, developmentConfig, localhostConfig, productionConfig, stageConfig,
} from './environment';

import { Environment } from '@models/environment.model';

function _window(): any {
    // return the global native browser window object
    return window;
}

export class config {
    /**
     * Return the obfOptions from the window
     */
    static getObfOptions() {
        // TODO VALIDATION !!! The OBJECT
        return ;
    }

    private initializeObfOptions = null;
    private currentDomain: string;
    private country: string;
    private environmentConfig: any;
    private obfOptions;

    /* Environment Configuration base on hosted domains */
    private localhostDomains: string[] = [
        'localhost',
        '10.2.1.200',
        '10.2.1.136',
        'dev.1dxr.com',
        '10.20.23.81',
        '192.168.0.143',
        '10.2.1.206',

        // nonbrand
        'obf-build.com',
    ];
    private developmentDomains: string[] = [
        'obf-v2-eagle.1dxr.com',
        'obf-dev.1dxr.com',
        'obf2-dev.1dxr.com',
        'obf-v2-test.1dxr.com',

        'accounts-dev.1dxr.com',
        'accounts-qa.1dxr.com',
        'accounts-test1.1dxr.com',
        'accounts-test2.1dxr.com',
        'accounts-test3.1dxr.com',
        'accounts-test4.1dxr.com',
        'accounts-test5.1dxr.com',

        // Pro Cross Booking
        'dev.booking.dxr.com',

        // Clients
        'obf2-uspaah.1dxr.com',
        'accounts-vhl.1dxr.com',

        // nonbrand
        'obf2-nonbrand.1dxr.com',

        // new auth
        'accounts-eagle.1dxr.com',
    ];

    private stageDomains: string[] = [
        'obf-stg.1dxr.com',
        'obf-eagle.1dxr.com',
        'obf2-stg.1dxr.com',
        'accounts-stg.1dxr.com',
    ];

    private productionDomains: string[] = [];

    constructor(obfOptions) {
        this.obfOptions = obfOptions;
        this.currentDomain = _window().document.location.hostname;
        this.initializeObfOptions = obfOptions;
        this.country = obfOptions.country ? obfOptions.country : 'UK'; // !FIX country interface
        this.environmentConfig = this.getEnvironmentConfig();
    }

    /**
     * By specific url will return different environment settings
     */
    private getEnvironmentConfig() {
        let environmentByCountry;

        if (this.localhostDomains.indexOf(this.currentDomain) !== -1) {

            environmentByCountry = localhostConfig;

            // Set api_url and accounts_url from obfOptions on localhost
            if (this.obfOptions && this.obfOptions.api_url) {

                for (const country in environmentByCountry) {

                    environmentByCountry[country].api.baseUrl = this.obfOptions.api_url + 'api/v2.2/';
                    
                    if (this.obfOptions.accounts_url) {
                        environmentByCountry[country].api.accounts = this.obfOptions.accounts_url;
                    }
                }
            }
        }

        if (this.developmentDomains.indexOf(this.currentDomain) !== -1) {

            environmentByCountry = developmentConfig;

            // Set api_url and accounts_url from obfOptions on development
            if (this.obfOptions && this.obfOptions.api_url) {

                for (const country in environmentByCountry) {

                    environmentByCountry[country].api.baseUrl = this.obfOptions.api_url + 'api/v2.2/';
                    
                    if (this.obfOptions.accounts_url) {
                        environmentByCountry[country].api.accounts = this.obfOptions.accounts_url;
                    }
                }
            }
        }

        if (this.stageDomains.indexOf(this.currentDomain) !== -1) {

            environmentByCountry = stageConfig;

            // Set api_url and accounts_url from obfOptions on stage
            if (this.obfOptions && this.obfOptions.api_url) {

                for (const country in environmentByCountry) {

                    environmentByCountry[country].api.baseUrl = this.obfOptions.api_url + 'api/v2.2/';
                    
                    if (this.obfOptions.accounts_url) {
                        environmentByCountry[country].api.accounts = this.obfOptions.accounts_url;
                    }
                }
            }
        }

        if (this.productionDomains.indexOf(this.currentDomain) !== -1) {
            environmentByCountry = productionConfig;
        }

        // Fallback if is not recognized domain will get production config.
        if (!environmentByCountry) {
            environmentByCountry = productionConfig;
        }

        if (environmentByCountry[this.country]) {
            environmentByCountry[this.country].config = this.country;
            return environmentByCountry[this.country];
        } else if (environmentByCountry['DEFAULT']) {
            environmentByCountry['DEFAULT'].config = 'DEFAULT';
            return environmentByCountry['DEFAULT'];
        } else { // set first environment in selected config as a default one
            const firstCountryInConfig: string = Object.keys(environmentByCountry)[0];

            environmentByCountry[firstCountryInConfig].config = firstCountryInConfig;

            return environmentByCountry[firstCountryInConfig];
        }
    }

    // * Hare we only append data don't change. 
    // !For change go to main-data.provider.ts -> setEnvironmentData
    public getEnvironment(): Environment {
        let environment = this.environmentConfig;

        // append to env config data 
        environment.additionalConfig = additional;

        return environment;
    }
}
