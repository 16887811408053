// import { Http, Headers, Response } from '@angular/http';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Authorize } from './../../../lib/xrm-sdk/src/lib/Api/ApiAuthorize';
import ObfInjector from './../../../lib/xrm-sdk/src/lib/Helpers/Injector';
import { MainDataProvider } from './../providers/xrm/main-data.provider';
import { CryptoService } from './crypto.service';
import { getUrlVars } from '../helpers/global-functions';

/**
 * The BackEndService is use to communicate with xrm api and accounts
 */
@Injectable({
    providedIn: 'root',
})

export class BackEndService {
    /**
     * App base communicate address
     */
    public baseUrl: string;
    /**
     * Headers for requests
     */
    private headers: HttpHeaders;

    /**
     * Imports
     * @param {Http} _http
     * @param {MainDataProvider} _mainDataProvider
     * @param {CryptoService} _cryptoService
     * @param {CookieService} cookieService
     */
    constructor(
        private _http: HttpClient,
        private _mainDataProvider: MainDataProvider,
        private _cryptoService: CryptoService,
        private cookieService: CookieService,
    ) {
        this.baseUrl = this._mainDataProvider.obfEnv.api.baseUrl;
    }

    /**
     * Check for response errors
     * Todo validate the error.
     * @param response
     */
    private checkForErrors(error: HttpErrorResponse) {
        // # console.log(error);
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
        } else {
            console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
        }

        // console.log(response);
        // if (response.status >= 200 && response.status < 300) {
        //     return response;
        // } else {
        //     let error = new Error(response.statusText);
        //     error['response'] = response;
        //     console.error(error);
        //     throw error;
        // }

        return throwError('HTTP ERROR BACKEND');
    }

    /**
     * Parse response to json
     * @param response
     */
    private getJSON(response: any) {
        return response.json();
    }

    /**
     * Create get request
     * @param {string} path  Path
     * @param {string} baseUrl Base path
     * @param {Array} headers [{ key: string; value: any },...]
     * @return {Observable}
     */
    public get(path: string, baseUrl?: string, headers?: [{ key: string; value: any }]): Observable<any> {
        baseUrl = baseUrl ? baseUrl : this.baseUrl;
        this.createHeaders(headers);
        return this._http.get(baseUrl + path, { headers: this.headers }).pipe(
            catchError(this.checkForErrors),
            // map(this.getJSON)
        );
    }

    /**
     * Create post request
     * @param {string} path
     * @param {object} data
     * @param {string} baseUrl
     * @param {Array} headers [{ key: string; value: any },...]
     * @param {boolean} withCredentials by defailt is false
     * @return {Observable}
     */
    public post(path: string, data, baseUrl?: string, headers?: { key: string; value: any }[], withCredentials = false): Observable<any> {
        /**
         * Rework and use this method for test purposes for login/register only!
         */
        baseUrl = baseUrl ? baseUrl : this.baseUrl;
        // this.createHeaders(headers);

        const auth: Authorize = ObfInjector.getRegistered('Authorize') as Authorize;

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'X-Application': this._mainDataProvider.obfEnv.XApplication,
                'X-Profile': this._mainDataProvider.obfEnv.XProfile,
            }),
            withCredentials: true,
        };

        if (auth.authorization) {
            httpOptions.headers.append('Authorization', auth.authorization);
        }

        return this._http.post(baseUrl + path, JSON.stringify(data), httpOptions).pipe(
            catchError(this.checkForErrors),
        );
    }

    /**
     * Create patch request
     * @param {string} path
     * @param {object} data
     * @param {string} baseUrl
     * @param {Array} headers [{ key: string; value: any },...]
     * @return {Observable}
     */
    public patch(path: string, data, baseUrl?: string, headers?: { key: string; value: any }[]): Observable<any> {
        baseUrl = baseUrl ? baseUrl : this.baseUrl;
        this.createHeaders(headers);

        return this._http.patch(baseUrl + path, JSON.stringify(data), { headers: this.headers });
    }

    /**
     * Create delete request
     * @param {string} path
     * @param {string} baseUrl
     * @param {Array} headers [{ key: string; value: any },...]
     * @return {Observable}
     */
    public delete(path: string, baseUrl?: string, headers?: { key: string; value: any }[]): Observable<any> {
        baseUrl = baseUrl ? baseUrl : this.baseUrl;
        this.createHeaders(headers);

        return this._http.delete(baseUrl + path, { headers: this.headers });
    }

    /**
     * Build headers before send evry request
     * @param headers
     */
    private createHeaders(headers: { key: string; value: any }[] = []) {
        const obfOptions = this._mainDataProvider.getResourceObfOptions();
        this.headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Accept: 'application/json, text/plain, */*',
            'X-Application': obfOptions.key,
            'X-Profile': obfOptions.profileID,
        });

        headers.map((el) => {
            this.headers = this.headers.append(el.key, el.value);
        });

        this.addAuthorizationHeader();
    }

    /**
     * Check is user authenticate and add it to headers
     */
    private addAuthorizationHeader() {

        const urlParams = getUrlVars();

        if (urlParams?.authorization) {
            // Set Authorization Param in LocaStorage for XRM SDK
            sessionStorage.setItem('authorization', urlParams.authorization);

            this.headers = this.headers.append('Authorization', urlParams.authorization);
            
        } else if (this.cookieService.check('user-login')) {

            this.clearSessionStorageFromAuthParam();

            const authToken = this._cryptoService.decryptData(this.cookieService.get('user-login'), this._mainDataProvider.obfEnv.cookieKey);
            this.headers = this.headers.append('Authorization', authToken);
        } else {
            this.clearSessionStorageFromAuthParam();
        }
    }

    private clearSessionStorageFromAuthParam() {
        if (sessionStorage?.getItem('authorization')) {
            try {
                sessionStorage.removeItem('authorization')
            } catch (e) {}
        }
    } 
}
