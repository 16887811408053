import { Directive, ElementRef, HostListener, Input, OnChanges } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[FsFocusHandler]',
})
export class FsFocusHandlerDirective implements OnChanges {
    @Input()
    public inputScrollParent = '.obf-main-content';

    @Input()
    public mainNavigation = '.obf-main-step-navigation';

    public elementNavigation: HTMLElement;

    public hiddenOverlay: HTMLElement;

    @Input()
    public dropdownElementMaxHeight = 0;

    @Input() public manualScroll: boolean;

    public ngOnChanges(changes: import('@angular/core').SimpleChanges): void {

        if (changes && changes.manualScroll && this.deviceDetectorService.isMobile()) {
            if (changes.manualScroll.currentValue) {
                this.scrollToItem();
            } else {
                this.scrollOutOfItem();
            }

            if (this.elementNavigation) {
                this.elementNavigation.classList.remove('refresh');
            }
        }
    }

    @HostListener('focus')
    public onfocus() {

        if (!this.deviceDetectorService.isMobile() || this.el.nativeElement.classList.contains('date-picker-input')) {
            return;
        }

        if (this.deviceDetectorService.getDeviceInfo().os === 'Android' && this.deviceDetectorService.getDeviceInfo().browser === 'Samsung') {
            this.scrollToItemAdditional();
        } else {
            this.scrollToItem();
        }

    }

    @HostListener('blur')
    public onblur() {
        if (!this.deviceDetectorService.isMobile() || this.el.nativeElement.classList.contains('date-picker-input')) {
            return;
        }

        this.scrollOutOfItem();
    }

    /**
     * Disable opening mobile device keyboard
     * @param event MousedownEvent
     */
    @HostListener('mousedown', ['$event']) public disableMobileKeyboard(event) {
        if (this.deviceDetectorService.getDeviceInfo().os === 'iOS' && this.el.nativeElement.readOnly) {
            event.preventDefault();
        }
    }

    constructor(private el: ElementRef, private deviceDetectorService: DeviceDetectorService) {
    }

    private scrollToItemAdditional() {
        const mainContent: any = window.document.querySelector(this.inputScrollParent);

        if (mainContent && typeof mainContent.scrollTo === 'function') {

            const scrollToPix = this.el.nativeElement.getBoundingClientRect().top + mainContent.scrollTop - 40;

            setTimeout(() => {
                mainContent.scrollTo({
                    top: scrollToPix,
                });
            }, 300);

        }
    }

    /**
     * Scroll to item
     */
    private scrollToItem(): void {

        const mainContent: any = window.document.querySelector(this.inputScrollParent);

        if (mainContent) {

            if (this.mainNavigation !== '') {
                this.elementNavigation = window.document.querySelector(this.mainNavigation);
            }

            // console.log('Device: ', this.deviceDetectorService.getDeviceInfo());

            if (this.elementNavigation && this.deviceDetectorService.getDeviceInfo().os === 'iOS' && this.deviceDetectorService.getDeviceInfo().browser === 'Safari') {
                this.elementNavigation.classList.add('refresh');
            }

            // setTimeout(() => {
            const datePickerElement: any = window.document.querySelector('.ngb-datepicker');
            let scrollToPix = this.el.nativeElement.getBoundingClientRect().top + mainContent.scrollTop - 40;

            if (window.innerHeight / 2 < scrollToPix + this.el.nativeElement.offsetHeight + this.dropdownElementMaxHeight) {
                    if (this.inputScrollParent === '.obf-main-content') {
                        const pageContent: any = window.document.querySelector('.obf-page-content');
                        let addBonusPix = window.innerHeight - pageContent.offsetHeight > 0 ? window.innerHeight - pageContent.offsetHeight : 0;

                        // Reduce bonus pixels with calendar's height when current field is date-picker
                        if (datePickerElement) {
                            const datePickerHeight: number = datePickerElement.offsetHeight;

                            addBonusPix -= datePickerHeight;
                        }

                        pageContent.style.paddingBottom = addBonusPix + this.el.nativeElement.offsetHeight + this.dropdownElementMaxHeight + 125 + 'px';
                    } else {
                        let reducePixels = 0;

                        // Reduce bonus pixels with calendar's height when current field is date-picker
                        if (datePickerElement) {
                            const datePickerHeight: number = datePickerElement.offsetHeight;

                            reducePixels -= datePickerHeight;
                        }

                        mainContent.style.paddingBottom = this.el.nativeElement.offsetHeight + this.dropdownElementMaxHeight + 125 + reducePixels +  'px';
                    }
                }

            if (this.deviceDetectorService.getDeviceInfo().os === 'iOS') {

                    // fake scroll coming from iOS mobiles
                    const fakeScroll = Math.round(this.el.nativeElement.getBoundingClientRect().top - ((window.innerHeight / 2) - this.el.nativeElement.offsetHeight) / 2);

                    // let textarea = document.getElementsByClassName('obf-header-section')[0];

                    // if (textarea) {

                    //     textarea.innerHTML += '[' + this.el.nativeElement.getBoundingClientRect().top + ' - ' + mainContent.scrollTop + '] ';
                    // }

                    if (fakeScroll > 0) {
                        scrollToPix = scrollToPix - fakeScroll;
                    }

                    if (this.el.nativeElement.getBoundingClientRect().top != 0 && mainContent && typeof mainContent.scrollTo === 'function') {
                        setTimeout(() => {
                            mainContent.scrollTo({
                                top: scrollToPix,
                                behavior: 'smooth',
                            });
                        }, 10);
                    }

                } else {

                    // IE doesn't support scrollTo
                    if (this.deviceDetectorService.getDeviceInfo().browser !== 'IE' && this.deviceDetectorService.getDeviceInfo().browser !== 'EDGE') {

                        if (mainContent && typeof mainContent.scrollTo === 'function') {

                            // setTimeout(() => {
                                mainContent.scrollTo({
                                    top: scrollToPix,
                                    behavior: 'smooth',
                                });
                            // }, 600);
                        }

                    }
                }
            // }, 520);
        }
    }

    /**
     * Remove "scroll to item"
     */
    private scrollOutOfItem(): void {

        if (this.mainNavigation !== '') {
            this.elementNavigation = window.document.querySelector(this.mainNavigation);
        }

        if (this.elementNavigation && this.deviceDetectorService.getDeviceInfo().os === 'iOS') {
            this.elementNavigation.classList.remove('refresh');
        }

        const mainContent: any = window.document.querySelector(this.inputScrollParent);
        if (this.inputScrollParent === '.obf-main-content') {
            const pageContent: any = window.document.querySelector('.obf-page-content');
            pageContent.style.paddingBottom = '';
        } else {
            mainContent.style.paddingBottom = '';
        }

    }
}
