import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InfoModalDirective } from './info-modal.directive';

@NgModule({
    declarations: [
        InfoModalDirective,
    ],
    imports: [
        CommonModule,
    ],
    exports: [
        InfoModalDirective,
    ],
})
export class InfoModalModule { }
