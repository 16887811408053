<div class="dynamic-iframe-popup">
    <span class="obf-close-btn icon-si-close-cancel" (click)="closeModal()"></span>
    
    <div class="dynamic-iframe-header">
       <!-- <h3 localizedText [textKeyword]="'choosePaymentMethodTitle'">Lookup for your address</h3> -->
       <h3>{{config.title}}</h3>
    </div>
    <div class="dynamic-iframe-body">
        <iframe class="lookup-iframe" src="{{config.url}}" frameborder="0">
        
        </iframe>
    </div>
    <div class="dynamic-iframe-footer">
        <div class="row justify-content-lg-center">
            <div class="col-lg-6">
                <app-button
                    [isSubmitButton]="true" 
                    [isFullWidth]="true" 
                    [label]="'Close'" 
                    [type]="'primary'"
                    (click)="closeModal()">
                </app-button>
            </div>
        </div>
    </div>
</div>

