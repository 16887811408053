import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';

import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';

import { PopupModalsService } from '../../services/popup-modals.service';

// #Templates
@Component({
    selector: 'app-parent',
    templateUrl: './parent.component.html',
    styleUrls: ['./parent.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
// #endTemplates

export class ParentComponent implements OnInit, OnDestroy {
    public showLoginAndRegisterModal = false;
    public subscriptionArr: Subscription[] = [];
    /**
     * Imports
     * @param deviceDetectorService depends
     * @param popupModalsService depends
     */
    constructor(
        private deviceDetectorService: DeviceDetectorService,
        private popupModalsService: PopupModalsService,
    ) { }

    public ngOnInit(): void {
        this.initialize();
    }

    public ngOnDestroy(): void {
        this.subscriptionArr.map((subscribe: Subscription) => subscribe.unsubscribe());
    }


    private initialize(): void {
        // this fix in mobile click on body
        if (this.deviceDetectorService.isMobile() || this.deviceDetectorService.isTablet()) {
            document.body.style.cursor = 'pointer';
        }

        this.subscriptionArr.push(
            this.popupModalsService.loginAndRegisterModalChange.subscribe((data) => {
                this.showLoginAndRegisterModal = data && data.action && data.action === 'open' ? true : false;
            }),
        );
    }

    /**
     * Check device type
     */
    private isMobile() {
        const isMobile = /Android|iPhone/i.test(window.navigator.userAgent);

        return isMobile;
    }
}
