import { genGUID } from "../../helpers/helpers";

export class PostMessaging {
    // 
    private iframeReference: Window;
    private registerEventHandler: Function;
    private urlCheck: string;

    // Contain all request waiting response from other side.
    private requestsStack: any[] = []
    private requestIndex = 0;

    // Contain all points to handle request from other side.
    private registeredRequestPoints: { id: string, point: string, handler: (data: any) => any }[] = [];

    // Contain all listeners for notifications.
    private registeredNotificationListeners: { id: string, point: string, handler: (data: any) => void }[] = [];

    constructor(Iframe: Window, url: string) {
        this.iframeReference = Iframe;
        this.urlCheck = url;
        this.addListenerResponse();
    }

    private send(type: 'pm-notification' | 'pm-request', message: PostMessage) {
        this.iframeReference.postMessage({ type: type, message: message }, '*');
    }

    public sendNotification(event: string, data: any) {
        const notification: PostMessageNotification = {
            type: 'pm-notification',
            message: {
                point: event,
                id: genGUID(),
                data: data,
            }
        }

        this.send(notification.type, notification.message);
    }

    public sendRequest(point: string, data: any, rHandler: (requestResponse: any) => void) {
        this.requestIndex += 1;

        const request: PostMessageRequest = {
            type: 'pm-request',
            message: {
                point: point,
                id: genGUID() + this.requestIndex,
                data: data
            },
            reqHandler: rHandler,
        }

        this.requestsStack.push(request);

        setTimeout(() => {
            this.removeRequestFromStack(request.message.id);
            request.reqHandler({ data: null, error: 'timeout' });
        }, 100000);

        this.send(request.type, request.message);
    }

    public registerRequestHandler(point: string, handler: (requestData: any) => any): string {
        const id = genGUID();

        this.registeredRequestPoints.push({
            id: id,
            point: point,
            handler: handler
        });

        return id;
    }

    public registerNotificationHandler(event: string, handler: (notification: any) => void): string {
        const id = genGUID();

        this.registeredNotificationListeners.push({
            id: id,
            point: event,
            handler: handler
        });

        return id;
    }


    // TODO implement remove notification handler
    public removeNotificationHandler(id: string): boolean {
        return true;
    }

    // TODO implement remove request handler
    public removeRequestHandler(id: string): boolean {
        return true;
    }

    /**
     * Remove request.
     * @param requestId 
     */
    private removeRequestFromStack(requestId: string) {
        let requestIndex = this.requestsStack.indexOf((request: any) => {
            return request.id = requestId;
        });

        this.requestsStack = this.requestsStack.splice(requestIndex, 1);
    }

    private addListenerResponse() {
        window.addEventListener(
            'message',
            async (e: any) => {
                //TODO Update to new syntax's when obf typescript is up.
                if ((this.urlCheck.indexOf(e.origin) != -1 || this.urlCheck !== null) && e.data && e.data.type && e.data.message) {

                    const postMessage: {
                        type: 'pm-notification' | 'pm-request',
                        message: PostMessage
                    } = e.data


                    if (postMessage.type === 'pm-request') {
                        // * Response from request.
                        const request: PostMessageRequest = this.requestsStack.find(req => {
                            return req.message.point === postMessage.message.point && req.message.id === postMessage.message.id;
                        })

                        if (request) {
                            request.reqHandler(postMessage.message.data);
                            this.removeRequestFromStack(request.message.id);
                            return;
                        }

                        // * RESPONSE TO REQUEST;
                        const requestHandler = this.registeredRequestPoints.find(req => {
                            return req.point === postMessage.message.point;
                        })

                        if (requestHandler) {
                            const data = await requestHandler.handler(postMessage.message.data);
                            const response = {
                                type: postMessage.type,
                                message: {
                                    id: postMessage.message.id,
                                    data: data,
                                    point: postMessage.message.point
                                }
                            }

                            this.send(response.type, response.message);
                        }

                    } else if (postMessage.type === 'pm-notification') {
                        const allNotificationListeners = this.registeredNotificationListeners.filter(listener => {
                            return listener.point === postMessage.message.point;
                        })

                        allNotificationListeners.forEach(listener => {
                            listener.handler(postMessage.message.data);
                        })
                    }
                }
            },
            true
        );
    }
}

interface PostMessage {
    point: string;
    id: string;
    data: any;
}

interface PostMessageRequest {
    type: 'pm-request';
    message: PostMessage;
    reqHandler: (response: any) => void;
}

interface PostMessageNotification {
    type: 'pm-notification';
    message: PostMessage;
}
