import { SDKValidationResponseService } from './SDKValidationResponseService';
import { SDKQueryParams } from '../Api/Interfaces/SDKQueryParams';
import { ApiResponseStatus } from '../Api/Interfaces/ApiResponseStatus';
import { ParamsOptionsBuilder } from '../Helpers/ParamsOptionsBuilder';
import { BackEndXrmService } from './backendservices/BackEndXrmService';
import { tap } from 'rxjs/operators';
import ObfInjector from '../Helpers/Injector';
import { SDKSharedService } from './SDKSharedService';

export class SDKProfileService {
    private _backEndXrmService: BackEndXrmService;
    private _sharedService: SDKSharedService;
    private _paramsOptionsBuilder: ParamsOptionsBuilder;
    private _validatorResponse: SDKValidationResponseService;
    public apiPoints = {
        configuration: 'client/configuration',
        validations: 'client/validations',
        profiles: 'client/profiles',
        modulesConfigurations: 'mock/client/modules'
    };

    constructor() {
        this._validatorResponse =
            ObfInjector.getRegistered('SDKValidationResponseService') as SDKValidationResponseService;
        this._backEndXrmService = ObfInjector.getRegistered('BackEndXrmService') as BackEndXrmService;
        this._paramsOptionsBuilder = ObfInjector.getRegistered('ParamsOptionsBuilder') as ParamsOptionsBuilder;
        this._sharedService = ObfInjector.getRegistered('SDKSharedService') as SDKSharedService;
    }

    /**
     * Get API Profiles
     * @param {Object} queryParams query parameters
     * @returns {Promise}
     */
    public getProfiles(queryParams?: any): Promise<any> {
        let params = this._paramsOptionsBuilder.paramBuilder(queryParams);
        params = params ? '/?' + params : '';

        return new Promise(
            (resolve: Function, reject: Function) => {
                const subscriber = this._backEndXrmService
                    .get(this.apiPoints.profiles + params)
                    .subscribe(
                        res => {
                            resolve(res);
                        },
                        err => {
                            reject(err);
                        },
                        () => {
                            subscriber.unsubscribe();
                        }
                    );
            }
        );
    }

    /**
     * Return current profile configuration
     * @param {Object} queryParams query parameters
     * @returns {Promise}
     */
    public getConfiguration(queryParams?: SDKQueryParams): Promise<any> {
        let params = this._paramsOptionsBuilder.paramBuilder(queryParams);
        params = params ? '/?include_fields=translations&' + params : '/?include_fields=translations';

        return new Promise(
            (resolve: Function, reject: Function) => {
                const subscriber = this._backEndXrmService
                    .get(this.apiPoints.configuration + params, { validateResponseType: 'configurationResponse' })
                    .subscribe(
                        res => {
                            resolve(res);
                        },
                        err => {
                            reject(err);
                        },
                        () => {
                            subscriber.unsubscribe();
                        }
                    );
            }
        );
    }

    /**
     * Return current profile modules configuration
     * @param {Object} queryParams query parameters
     * @returns {Promise}
     */
    public getModulesConfigurations(queryParams?: SDKQueryParams): Promise<any> {
        let params = this._paramsOptionsBuilder.paramBuilder(queryParams);
        params = params ? '/?' + params : '';

        return new Promise(
            (resolve: Function, reject: Function) => {
                const subscriber = this._backEndXrmService
                    .get(this.apiPoints.modulesConfigurations + params)
                    .subscribe(
                        res => {
                            resolve(res);
                        },
                        err => {
                            reject(err);
                        },
                        () => {
                            subscriber.unsubscribe();
                        }
                    );
            }
        );
    }

    /**
     * Return current profile validations
     * @param {Object} queryParams query parameters
     * @returns {Promise}
     */
    public getValidations(queryParams?: SDKQueryParams): Promise<any> {
        let params = this._paramsOptionsBuilder.paramBuilder(queryParams);
        params = params ? '/?' + params : '';

        return new Promise(
            (resolve: Function, reject: Function) => {
                const subscriber = this._backEndXrmService
                    .get(this.apiPoints.validations + params, { validateResponseType: 'validationResponse' })
                    .subscribe(
                        res => {
                            resolve(res);
                        },
                        err => {
                            reject(err);
                        },
                        () => {
                            subscriber.unsubscribe();
                        }
                    );
            }
        );
    }

    /**
     * Check API on SDK Init
     * @returns {Promise}
     */
    public initSdk(): Promise<any> {
        return new Promise((resolve: Function, reject: Function) => {
            this._sharedService.getServerTime()
                .then(
                    (response: any) => { // Success
                        resolve(response);
                    },
                    (response: any) => { // Error
                        let responseMessage: string = '';

                        if (response) {
                            // Batch response
                            if (response.hasOwnProperty('responses')) {
                                if (response.responses && response.responses.length) {
                                    for (let responseIndex: number = 0; responseIndex < response.responses.length; responseIndex++) {
                                        const batchResponse: any = response.responses[responseIndex];

                                        // Concat error messages
                                        if (batchResponse && batchResponse.error && batchResponse.error.length) {
                                            for (let errorIndex: number = 0; errorIndex < batchResponse.error.length; errorIndex++) {
                                                const error: any = batchResponse.error[errorIndex];

                                                responseMessage += `${error.message}\n`;
                                            }
                                        } else {
                                            responseMessage = 'No error message supplied in batch response';
                                        }
                                    }
                                }
                            } else { // Normal response
                                if (response.error && response.error.length) {
                                    for (let errorIndex: number = 0; errorIndex < response.error.length; errorIndex++) {
                                        const error: any = response.error[errorIndex];

                                        responseMessage += `${error.message}\n`;
                                    }
                                } else if (response.data) {
                                    responseMessage = 'No error message supplied in normal response';
                                }
                            }
                        }

                        // responseMessage = response ? (response.error ? response.error : response.data) : 'no response';
                        responseMessage = responseMessage ? responseMessage : '[server_time] Unexpected response received';

                        reject(responseMessage);
                    }
                );
        });
    }
}
