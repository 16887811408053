/**
 * Get data from a URL with native AJAX
 * @param  {String} application  The api application
 * @param  {String} profile   The api profile
 * @param  {String} path      The api path to get
 * @param  {String} method      The api path to get
 * @param  {Boolean} withCredentials  Enable Credentials option
 * @param  {Object} data  Send data to the api
 * @param  {Function} success Callback to run on success
 * @param  {Function} error   Callback to run on error
 * @param  {String} auth The api authorization header
 */
import { ResponseType } from '../Constants/ResponseType';

import { Observable } from 'rxjs';
import { ResponseTypeValidator } from '../Api/Validators/ResponseTypeValidator';
import { ResponseValidator } from '../Api/Validators/ResponseValidator';
import { HttpOptions } from './AjaxHttpOptions';
import { HttpHeader } from '../Api/Interfaces/HttpHeader';

declare global {
    interface Window {
        ActiveXObject: any;
        XMLHttpRequest: any;
    }
}

export class HttpClient {
    private httpOptions: HttpOptions;
    private actionUrl: string;

    constructor(actionUrl: string) {
        this.actionUrl = actionUrl;
    }

    private ajaxRequest(requestMethod: 'get' | 'post' | 'put' | 'del', options: HttpOptions, withCredentials: boolean, timeout?: number) {
        return new Observable(observer => {
            // const request = new XMLHttpRequest();
            let request: any;
            let xmlSupport = true;

            let resData: { [key: string]: any } = {
                data: null,
                success: null,
                warning: null,
                error: null,
                requestedUrl: options.actionUrl + options.path,
                statusCode: 0,
            }

            // Feature detection
            if (!window.XMLHttpRequest) {
                xmlSupport = false;
            }

            // Create new request
            if (xmlSupport) {
                request = new window.XMLHttpRequest();
            } else {
                request = new window.ActiveXObject('Microsoft.XMLHTTP');
            }

            request.addEventListener('load', () => {
                // a listener which executes when the xhr request succeeds
                // const responseText = request.responseText;
                const responseText = request.responseText;
                const requestedUrl = options.actionUrl + options.path; // fix for ie10 we dont have request.reposnseUrl

                // Validators to use
                const validators: {
                    [s: string]: ResponseValidator;
                } = {};

                validators.ResponseType = new ResponseTypeValidator();

                let jsonResponseText = responseText,
                    isActualBatch: boolean = options && options.data && options.data.hasOwnProperty('requests') ? true : false;

                const validResponseType = validators.ResponseType.isAcceptable(responseText, requestedUrl, isActualBatch);

                // console.log(responseText,jsonResponseText,validResponseType);
                try {
                    if (request.status === 200 && validResponseType === ResponseType.Html) {
                        observer.next(jsonResponseText);

                        // Api Return Success

                    } else if (request.status === 200 && validResponseType === ResponseType.Empty) { // Empty response

                        resData = Object.assign(resData, {
                            statusCode: 100102,
                            error: [
                                {
                                    message: 'Empty API Response',
                                    response: jsonResponseText,
                                    requestedUrl
                                }
                            ]
                        });

                        observer.error(resData);
                        observer.complete();

                    } else {
                        jsonResponseText = JSON.parse(jsonResponseText);
                        jsonResponseText.statusCode = request.status;
                        jsonResponseText.requestedUrl = requestedUrl;

                        // Api Return Error
                        if (request.status === 200 && validResponseType === ResponseType.Error) {
                            observer.error(Object.assign(resData, jsonResponseText));

                            // Api Return Warning
                        } else if (request.status === 200 && validResponseType === ResponseType.Warnings) {
                            observer.next(Object.assign(resData, jsonResponseText));

                            // Api Return Batch
                        } else if (request.status === 200 && validResponseType === ResponseType.Batch) {
                            // TODO map it with same structure like evry response 
                            observer.next(jsonResponseText);

                            // Api Return Success
                        } else if (request.status === 200 && validResponseType === ResponseType.Success) {
                            observer.next(Object.assign(resData, jsonResponseText));
                        } else {
                            resData = Object.assign(resData, {
                                statusCode: 100103,
                                error: [
                                    {
                                        message: 'Undefined Response Type',
                                        statusCode: request.status,
                                        response: jsonResponseText,
                                        requestedUrl
                                    }
                                ]
                            });

                            observer.error(resData);
                            observer.complete();
                        }
                    }
                } catch (err) {
                    resData = Object.assign(resData, {
                        statusCode: 100101,
                        error: [
                            {
                                message: 'JSON Response Error ' + err,
                                response: jsonResponseText,
                                requestedUrl
                            }
                        ]
                    });

                    observer.error(resData);
                    observer.complete();
                }

                observer.complete();
            });

            // Api Return Server Error
            request.addEventListener('error', (error: any) => {
                // Build response object as XRM Api Error, because in this case we DONT receive any response
                resData = Object.assign(resData, {
                    statusCode: 100100,
                    error: [
                        {
                            message: 'Connection error',
                            failedRequest: options.actionUrl + options.path
                        }
                    ]
                });

                observer.error(resData);
                observer.complete();
            });

            // Get Ajax Data and Set Headers and Properties
            request.open(requestMethod, options.actionUrl + options.path, true);

            options.headers.map((header: HttpHeader) => {
                request.setRequestHeader(header.name, header.data);
            });

            // Set the miliseconds before request being automatically terminated
            // Add custom timeouts
            if (timeout) {
                request.timeout = Number(timeout);
            } else {
                request.timeout = 25000;
            }

            // console.log('request',request.timeout, timeout);

            // Request timeout listener
            request.addEventListener('timeout', (error: any) => {
                // Build response object as XRM Api Error, because in this case we DONT receive any response
                resData = Object.assign(resData, {
                    statusCode: -1,
                    error: [
                        {
                            message: 'Request timeout',
                            failedRequest: options.actionUrl + options.path
                        }
                    ]
                });

                observer.error(resData);
                observer.complete();
            });

            // Add withCredentials option
            if (withCredentials) {
                request.withCredentials = true;
            }

            if (options.data) {
                if (options.data instanceof FormData) {
                    request.send(options.data);
                } else {
                    request.send(JSON.stringify(options.data));
                }
            } else {
                request.send();
            }
        });
    }

    public get(path: string, headers: HttpHeader[], withCredentials?: boolean, timeout?: number): Observable<any> {
        this.httpOptions = new HttpOptions(this.actionUrl);

        this.httpOptions.setRequestData(path, null, headers);

        return this.ajaxRequest('get', this.httpOptions, withCredentials, timeout);
    }

    public post(path: string, data: any, headers: HttpHeader[], withCredentials?: boolean, timeout?: number): Observable<any> {
        this.httpOptions = new HttpOptions(this.actionUrl);

        this.httpOptions.setRequestData(path, data, headers);

        if (!withCredentials) {
            withCredentials = false;
        }

        return this.ajaxRequest('post', this.httpOptions, withCredentials, timeout);
    }

    public changeActionUrl(actionUrl: string) {
        if (actionUrl.length) {
            this.httpOptions.actionUrl = actionUrl;
        } else {
            throw new Error('actionUrl must be not be empty string');
        }
    }
}
