import { AfterContentInit, Directive, ElementRef, HostListener, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
    selector: '[appSlideInOut]',
})
export class SlideInOutDirective implements AfterContentInit, OnChanges {
    /**
     * Slider state
     */
    @Input()
    public appSlideInOut = false;

    @Input()
    set LisenerElementChanges(elementRef) {
        // # console.log(elementRef);
        this.showContent();
    }

    constructor(private el: ElementRef) {}

    /**
     * Init the item adding the slider class
     */
    public ngAfterContentInit() {
        this.el.nativeElement.classList.add('obf-fs-slide-in-out');
        // this.calculateElementsHeight();
        this.showContent();
    }

    public ngOnChanges(changes: SimpleChanges) {
        this.showContent();
    }

    @HostListener('window:resize', ['$event'])
    public onResize(event) {
        this.showContent();
    }

    @HostListener('resize', ['$event'])
    public resizeBy(event) {
        // # console.log(event);
    }

    /**
     * Slide handler
     */
    public showContent() {
        if (this.appSlideInOut) {
            this.el.nativeElement.classList.add('obf-fs-visible');
            // this.el.nativeElement.style.maxHeight = 250 + 'px';
            this.el.nativeElement.style.display = 'block';
            this.el.nativeElement.style.opacity = 1;
        } else {
            // this.el.nativeElement.style.maxHeight = '0px';
            this.el.nativeElement.style.display = 'none';
            // this.el.nativeElement.style.height = 'auto';
            this.el.nativeElement.style.opacity = 0;
            this.el.nativeElement.classList.remove('obf-fs-visible');
        }
    }

    public calculateElementsHeight() {
        return this.el.nativeElement.scrollHeight;
    }
}
