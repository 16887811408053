<div class="obf-exit-popup-wrap" *ngIf="popupContentDef" [ngClass]="{open: exitOpen}">
    <div class="obf-exit-popup">
        <div class="obf-popup-head">
            <h4 class="obf-title">{{ popupContentDef?.title }}</h4>
            <span class="obf-icon icon-si-close-cancel" (click)="action('cancel')"></span>
        </div>
        
        <div class="obf-popup-body">
            <div class="obf-description" *ngIf="popupContentDef.description !== null">
                {{ popupContentDef?.description }}
            </div>
            <div class="obf-choices">
                <ul>
                    <li *ngFor="let item of leaveReasons" (click)="setSelectedReason(item)"
                        [ngClass]="{ 'obf-selected': selectedReason === item }">
                        <span class="obf-check-icon-wrap"></span>
                        {{ item.name }}
                    </li>
                </ul>
    
                <div *ngIf="selectedReason?.requires_comment" [ngClass]="{ 'obf-error':submitErr.comment }">
                    <input type="textarea" name="reason-comment" spellcheck="false" autocapitalize="off" autocomplete="off" 
                    autocorrect="off" [(ngModel)]="reasonComment">
                </div>

                <div
                    *ngIf="submitErr.comment && !submitErr.reason"
                    class="obf-error"
                    localizedText
                    [textKeyword]="'exitPopupCommentErr'">
                </div>

                <div
                    *ngIf="submitErr.reason"
                    class="obf-error"
                    [ngClass]="{ 'obf-error':submitErr.reason }"
                    localizedText
                    [textKeyword]="'exitPopupReasonErr'">
                </div>
            </div>
        </div>
    
        <div class="obf-popup-footer">
            <app-button
                [label]="popupContentDef?.buttons?.stay"
                [type]="'primary'"
                [state]="'default'"
                [isAutoWidth]="true"
                (click)="action('cancel')">
            </app-button>
            <app-button
                style="margin-bottom: 0"
                [label]="popupContentDef?.buttons?.leave"
                [type]="'tertiary'"
                [state]="'default'"
                (click)="action('exit')">
            </app-button>
        </div>
    </div>
</div>

