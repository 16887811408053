import { Injectable } from '@angular/core';
import { LocalizationProvider } from '@providers/localization.provider';
import { MainDataProvider } from '@providers/main-data.provider';
import { CryptoService } from '@services/crypto.service';
import { LoaderService } from '@services/loader.service';
import { interval } from 'rxjs';
import { popupCenter } from 'src/app/helpers/helper-functions';
/**
 * Documentation: 
 * https://docs.microsoft.com/en-us/azure/active-directory-b2c/openid-connect
 * Ticket: 
 * https://pm.1dxr.com/issues/69712
 * 
 */
@Injectable({
    providedIn: 'root',
})
export class AzureAuthService {
    private azureAuth = {
        login: 'ext/azure-login',
    };

    public popupInstance: any = null;

    constructor(
        private mainDataProvider: MainDataProvider,
        private _localizationProvider: LocalizationProvider,
        private loaderService: LoaderService,
        private cryptoService: CryptoService
    ) {}

    public beginAuth(): Promise<any> {
        return new Promise((resolve: Function, reject: Function) => {
            const loader = this.loaderService.showLoader(
                this._localizationProvider.getLocalizedText('loaderMsg25')
            );

            const azureAuth = this.mainDataProvider.getSocialAuthData().azure;

            const authorizationDomain =
                this.mainDataProvider.obfEnv.api.accounts +
                this.azureAuth.login;

            let authorizationResponse = null;

            const azurePostMessageHandler = (event) => {
                if (event?.data?.c_azure_login_data) {
                    authorizationResponse = event.data.c_azure_login_data;
                }
            };

            // This is setup for new authorization logic.
            const redirectUrl = this.mainDataProvider.obfEnv.api.accounts +
            this.azureAuth.login;

            const state = this.cryptoService.encryptData(
                {
                    redirect: redirectUrl,
                },
                this.mainDataProvider.obfEnv.cookieKey
            );

            const authUrl = `https://${azureAuth.tenant}.b2clogin.com/${azureAuth.tenant}.onmicrosoft.com/oauth2/v2.0/authorize?p=${azureAuth.policy}&client_id=${azureAuth.client_id}&nonce=${encodeURIComponent(state)}&redirect_uri=${authorizationDomain}&scope=openid&response_type=id_token&prompt=login`;

            window.addEventListener('message', azurePostMessageHandler);

            const centerPopup = popupCenter();

            const azurePopup = window.open(
                authUrl,
                'Booking Form Authorization Modal',
                'height=710,width=630,left=' +
                    centerPopup.left +
                    ',top=' +
                    centerPopup.top +
                    ',resizable=no,scrollbars=no,toolbar=no,menubar=no,location=no,directories=no, status=no'
            );

            this.popupInstance = azurePopup;

            const subscription = interval(1000).subscribe(() => {
                if (azurePopup && !azurePopup.closed) {
                    return;
                }

                if (authorizationResponse == null) {
                    try {
                        authorizationResponse =
                            localStorage.getItem('c_azure_login_data');
                        localStorage.removeItem('c_azure_login_data');
                    } catch (e) {}
                }

                if (authorizationResponse) {
                    const socialData: any = this.cryptoService.decryptData(
                        authorizationResponse,
                        this.mainDataProvider.obfEnv.cookieKey
                    );

                    if (socialData && !socialData.error) {
                        resolve(socialData);
                    } else {
                        resolve(socialData);
                    }
                } else {
                    reject(null);
                }

                window.removeEventListener('message', azurePostMessageHandler);
                this.loaderService.hideLoader(loader);
                subscription.unsubscribe();
            });
        });
    }
}
