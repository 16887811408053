import { Injectable, Injector } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { Choice } from '@models/choice';
import { NavigationConfig } from '@models/navigation-config';
import { Position } from '@models/position';
import { BehaviorSubject, Subject } from 'rxjs';
import { ChoicesPositions } from '../constants/choice';
import { positionsConfig } from '../constants/obf-paths';
import { scrollToTop } from '../helpers/global-functions';
import { OrderData } from './../providers/xrm/order-data.provider';
import { BreadcrumbsService } from './breadcrumbs.service';
import { PostMessageService } from './post-message.service';
import { ServicesService } from '@services/services.service';

// import { DataLayerPush } from '../services/data-layer.service';

@Injectable({
    providedIn: 'root',
})
export class NavigationService {
    public configuratorChoiceId: BehaviorSubject<null | number>;
    public successNavigation: Subject<{
        navigationOptions: { position: string, options: NavigationConfig }
        currentPosition: Position,
        prevPosition: Position,
    }> = new Subject();

    private currentLanguage = 'en';
    private isCreateTransactionWasSkipped = false;

    public lastCallbackSuccess: Function;
    public lastCallbackError: Function;
    private currentPosition: Position | null = null;
    public prevPosition : Position | null = null;
    public skipGuardNavigation = false;

    // list of all static positions
    private staticPositions: Array<String> = [
        ChoicesPositions.Init,
        ChoicesPositions.OnSummary,
        ChoicesPositions.BeforeConfirmation,
        ChoicesPositions.Confirmation
    ];

    // list of all dynamic positions
    private dynamicPositions: Array<String> = [
        ChoicesPositions.Configurator,
        ChoicesPositions.BeforeAvailability,
        ChoicesPositions.OnAvailability,
        ChoicesPositions.AfterAvailability,
        ChoicesPositions.BeforeSummary
    ];

    constructor(
        private router: Router,
        private orderData: OrderData,
        private postMessageService: PostMessageService,
        private breadcrumbsService: BreadcrumbsService,
        private injector: Injector
    ) {
        this.configuratorChoiceId = new BehaviorSubject(null);

        this.postMessageService.navigationSubject.subscribe((e) => {
            if (this.currentPosition) { // This prevent user to navigate back before obf is fully open
                this.breadcrumbsService.infoBreadcrumb('navigation-from-client', e);
                this.navigateMe(e);
            }
        });
    }

    public setSkipFlag(flag) {
        this.isCreateTransactionWasSkipped = flag;
    }

    public getSkipFlag() {
        return this.isCreateTransactionWasSkipped;
    }

    /**
     * Get specified step data by passed parameter and parameter type
     * @param { string } position passed step data which could be: position, id or step name
     * @param {'slug' | 'positions' | 'index'} dataFilter wanted step data to retrieve - ['slug' | 'positions' | 'index']
     * @return { Position | null } when is not find data for passed stepParam
     */
    public getPositionData(position: string | number, dataFilter?: 'slug' | 'positions' | 'index'): Position | null {
        let requestedPosition: Position;

        if (typeof position === 'number') {
            requestedPosition = positionsConfig.find((positionConfig: Position) => {
                let flag = false;

                if (positionConfig.children) {
                    flag = (positionConfig.children.find((childCheck) => childCheck.id && childCheck.id === position)) ? true : false;
                }

                if (!flag) { // if is not child check the parent
                    return positionConfig.id && positionConfig.id === position;
                }

                return flag;
            });
        } else {
            requestedPosition = positionsConfig.find((positionConfig: Position) => {
                let flag = false;

                if (positionConfig.children) {
                    flag = (positionConfig.children.find((childCheck) => childCheck.keywords.indexOf('' + position) !== -1)) ? true : false;
                }

                if (!flag) { // if is not child check the parent
                    return positionConfig.keywords.findIndex((el) => {
                        return ('' + position).includes(el);
                    }) !== -1;
                }

                return flag;
            });
        }

        if (!requestedPosition) {
            return null;
        }

        // Add the index for the position.
        requestedPosition.index = positionsConfig.indexOf(requestedPosition);

        if (dataFilter) {
            return requestedPosition[dataFilter];
        }

        return requestedPosition;
    }

    public getCurrentStep(): Position | null {
        const currentPosition = JSON.parse(JSON.stringify(this.currentPosition));
        if (currentPosition) {
            const url = this.router.url;
            if (currentPosition.children) {
                const currentChild = currentPosition.children.find((childCheck) => (childCheck.keywords as Array<string>).findIndex((keyWord) => url.includes(keyWord)) !== -1);
                currentPosition.activeChild = currentChild;
            }
            currentPosition.url = url;
        }

        return currentPosition;
    }

    /**
     * Function navigate from current step to specific step bu given step or slug
     * @param { string | 'next' | 'prev' } goToPosition work only with positions and prev and next.
     * @param {NavigationConfig} options
     */
    public navigateMe(goToPosition: string | 'prev' | 'next', options: NavigationConfig = { pathParams: [] }, staticPositionsToBeSkipped?: Array<String>): void {

        this.breadcrumbsService.infoBreadcrumb('navigation-me', JSON.stringify({position: goToPosition, options}));

        // Prevent bug with state=on_availability but choice missing
        if (goToPosition === 'on_availability') {
            const activeBooking = this.orderData.activeBooking.getValue() ? this.orderData.activeBooking.getValue().get() : null;
            
            if (activeBooking?.state?.position === 'on_availability') {

                const _servicesService: ServicesService =
                        this.injector.get(ServicesService);
    
                if (!activeBooking?.service?.choices || _servicesService.getChoicesByPositions(activeBooking.service.choices, 'on_availability')?.length === 0) {
                    goToPosition = 'on_summary';
                }
            }



        }
        
        // Push Data Layer Page info
        this.skipGuardNavigation = options.forceNavigate;
        // Check for given slug check is slug exist in positionsConfig and navigate ! if dont exist will throw error and stay in same step
        if (typeof goToPosition === 'string') {

            let currentPosition = this.getCurrentStep(),
                indexOfPath = null;

            switch (goToPosition) {
                case 'next':
                    // if (currentPosition.position === ChoicesPositions.Confirmation) {
                    //     indexOfPath = 0;
                    // } else {
                    //     indexOfPath = currentPosition.index + 1;
                    // }

                    if (currentPosition.position === ChoicesPositions.Init) {
                        if (currentPosition.activeChild && currentPosition.activeChild.position === ChoicesPositions.OnWelcome) {
                            //  if current active child is on_welcome and after_welcome should NOT be skipped - navigate to it
                            if (staticPositionsToBeSkipped && staticPositionsToBeSkipped.length && staticPositionsToBeSkipped.indexOf(ChoicesPositions.AfterWelcome) !== -1) {
                                indexOfPath = this.getNextPositionIndex(currentPosition.index, goToPosition, staticPositionsToBeSkipped);
                            } else {
                                indexOfPath = this.getPositionData(ChoicesPositions.Init).index;
    
                                goToPosition = ChoicesPositions.AfterWelcome;
                            }
                        } else {
                            indexOfPath = this.getNextPositionIndex(currentPosition.index, goToPosition, staticPositionsToBeSkipped);
                        }
                    } else if (currentPosition.position === ChoicesPositions.Confirmation) {
                        indexOfPath = 0;
                    } else {
                        indexOfPath = this.getNextPositionIndex(currentPosition.index, goToPosition, staticPositionsToBeSkipped);
                    }

                    break;
                case 'prev':
                    if (currentPosition === null || currentPosition.position === ChoicesPositions.Confirmation || currentPosition.position === ChoicesPositions.Init) {
                        indexOfPath = 0;
                    } else {
                        indexOfPath = this.getNextPositionIndex(currentPosition.index, goToPosition, staticPositionsToBeSkipped);
                    }

                    break;
                default:
                    const position = this.getPositionData(goToPosition);
                    
                    indexOfPath = position ? position.index : null;
                    if (!position) {
                        throw new Error('This position is unknown: ' + position + '. Please check obf-paths.ts');
                    }
                    break;
            }

            if (indexOfPath != null && positionsConfig[indexOfPath]) {
                let navigatePosition: Position;
                // * Check if is child navigation
                // * This is use for the coverage only for now.
                if (positionsConfig[indexOfPath].position === goToPosition || goToPosition === 'next' || goToPosition === 'prev') {
                    navigatePosition = JSON.parse(JSON.stringify(positionsConfig[indexOfPath]));
                } else {
                    // * Change get child component and fix the slug.
                    const childPosition = JSON.parse(JSON.stringify(positionsConfig[indexOfPath].children.find((posCheck) => posCheck.position === goToPosition)));
                    navigatePosition = childPosition;
                    navigatePosition.slug = positionsConfig[indexOfPath].slug + '/' + childPosition.slug;
                }

                let navigateMassive = [this.currentLanguage + '/' + navigatePosition.slug];

                // * If is not init step need to modify the path to load the lazy load module.
                if (positionsConfig[indexOfPath].position !== ChoicesPositions.Init) {
                    if (this.orderData.getResourceActiveBooking()) {

                        navigateMassive = [
                            this.currentLanguage + '/' + 'transaction/' + this.orderData.getResourceActiveBooking().get().id + '/' + navigatePosition.slug];
                    } else {
                        navigateMassive = [
                            this.currentLanguage + '/'
                        ]
                    }
                }

                if (options.pathParams && Array.isArray(options.pathParams)) {
                    navigateMassive.push(...options.pathParams); 
                }

                // Set the call back so if whe make redirect from path guard.Too trigger success after the navigation.
                if (options.successCB) {
                    this.lastCallbackSuccess = options.successCB;
                } else {
                    this.lastCallbackSuccess = undefined;
                }

                if (options.errorCB) {
                    this.lastCallbackError = options.errorCB;
                } else {
                    this.lastCallbackError = undefined;
                }

                this.router.navigate(navigateMassive, { skipLocationChange: true, queryParamsHandling: 'merge' }).then((data) => {
                    if (data) {
                        this.skipGuardNavigation = false;
                        this.currentPosition = this.getPositionData(positionsConfig[indexOfPath].position);
                        this.currentPosition.pathParams = options.pathParams;
                        this.orderData.setCurrentStep(this.getCurrentStep());

                        // scroll the user to top of the page
                        scrollToTop();
                        this.lastCallbackSuccess = undefined;
                        this.lastCallbackError = undefined;

                        this.successNavigation.next({
                            navigationOptions: { position: goToPosition, options },
                            currentPosition: this.getCurrentStep(),
                            prevPosition: currentPosition,
                        });

                        if(currentPosition) {
                            this.prevPosition = currentPosition;
                        }

                        this.postMessageService.updateIframeUrl();
                        
                        // Reset
                        this.router.routeReuseStrategy.shouldReuseRoute = (future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean => {
                            return future.routeConfig === curr.routeConfig;
                        };

                        if (options.successCB && typeof options.successCB == 'function') {
                            options.successCB(data);
                        }
                    } else {

                        this.lastCallbackSuccess = undefined;
                        this.lastCallbackError = undefined;

                        if (options.errorCB && typeof options.errorCB == 'function') {
                            options.errorCB(data);
                        }
                    }
                }); // ! skipLocationChange?: boolean Navigates without pushing a new state into history.
            } else {
                throw new Error('Path dont exist');
            }

        } else {
            throw new Error('navigateMe requires a string path - ' + goToPosition);
        }
    }

    /**
     * Return null if don't have next step.
     * @return {Position | null }
     */
    public getNextStep(): Position | null {
        return positionsConfig[this.currentPosition.index + 1] ? positionsConfig[this.currentPosition.index + 1] : null;
    }

    public resetConfiguratorChoiceId(): void {
        this.configuratorChoiceId.next(null);
    }

    /**
     * Current language setter
     * @param language current app language
     */
    public setCurrentLanguage(language: string): void {
        this.currentLanguage = language;
    }

    private getNextPositionIndex(currentPositionIndex: number, direction: 'prev' | 'next', staticPositionsToBeSkipped?: Array<String>): number {
        const getServiceChoices = (choicePosition: string): Array<Choice> => {
                if (!this.orderData.activeBooking.getValue()) {
                    return [];
                }
        
                const transaction = this.orderData.activeBooking.getValue().get();
        
                if (transaction && transaction.service && transaction.service.choices) {
                    const filteredChoices: Array<Choice> = transaction.service.choices.filter((choice) => {
        
                        if (choicePosition) {
                            return choice.positions && choice.positions.indexOf(choicePosition) !== -1;
                        } else {
                            return 1;
                        }
                    });
        
                    return filteredChoices;
                }
        
                return [];
            },
            indexToStartIteration: number = direction === 'next' ? currentPositionIndex + 1 : currentPositionIndex - 1;

            // index should be incremented (direction of the loop iteration)
            if (direction === 'next') {
                for (let positionIndex: number = indexToStartIteration; positionIndex < positionsConfig.length; positionIndex++) {
                    const positionConfig: Position = positionsConfig[positionIndex];

                    if (this.staticPositions.indexOf(positionConfig.position) !== -1) {
                        // if currently iterated position should be skipped (passed by the navigateMe caller) - skip it
                        if (staticPositionsToBeSkipped && staticPositionsToBeSkipped.length && staticPositionsToBeSkipped.indexOf(positionConfig.position) !== -1) continue;
                        else return this.getPositionData(positionConfig.position).index;
                    } else {
                        if (getServiceChoices(positionConfig.position).length) return this.getPositionData(positionConfig.position).index;
                        else continue // just proceed to next iteration
                    }
                }
            } else { // index should be decremented
                for (let positionIndex: number = indexToStartIteration; positionIndex >= 0; positionIndex--) {
                    const positionConfig: Position = positionsConfig[positionIndex];

                    if (this.staticPositions.indexOf(positionConfig.position) !== -1) {
                        // if currently iterated position should be skipped (passed by the navigateMe caller) - skip it
                        if (staticPositionsToBeSkipped && staticPositionsToBeSkipped.length && staticPositionsToBeSkipped.indexOf(positionConfig.position) !== -1) continue;
                        else return this.getPositionData(positionConfig.position).index;
                    } else {
                        if (getServiceChoices(positionConfig.position).length) return this.getPositionData(positionConfig.position).index;
                        else continue // just proceed to next iteration
                    }
                }
            }
    }
}