import IAPIDataSource from "../../DataSources/API/IAPIDataSource"

export default class APIExceptionLogger {
    private namespace = '/api/v2.2/client/'
    private path = 'exceptions';
    private dataSource
    private identifier;

    constructor(
        identifier: string,
        apiDataSource: IAPIDataSource
    ) {
        this.identifier = identifier;
        this.dataSource = apiDataSource;
    }

    public send(path: string, message: string, response: any, details: string) {
        const exceptionData = {
            path: path || '',
            message: message || '',
            body: response || '',
            external_identifier: this.identifier || '',
            exception: details || ''
        }

        this.dataSource.post(this.namespace, this.path, null, exceptionData)
            .then(success => {
                console.log(success);
            }).catch(error => {
                console.log(error)
            })

        return;
    }


}