import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MainDataProvider } from '@providers/main-data.provider';

@Component({
  selector: 'app-dynamic-iframe-modal',
  templateUrl: './dynamic-iframe-modal.component.html',
  styleUrls: ['./dynamic-iframe-modal.component.scss']
})
export class DynamicIframeModalComponent {

    @Input()
    public config = {
      title:'',
      url:''
    }

    constructor(public activeModal: NgbActiveModal, public _mainDataProvider: MainDataProvider) { }

    public closeModal() {
        this.activeModal.dismiss('exit');
    }

}
