<div class="obf-login-form-box">
    <div class="obf-message-box obf-error" *ngIf="componentSettings.errorMessages.length">
        <div class="obf-content-box">
            <div class="obf-icon">
                <i class="icon-si-close-circle" aria-hidden="true"></i>
            </div>
            <div class="obf-messages-holder">
                <span
                    class="obf-message"
                    *ngFor="let error of componentSettings.errorMessages"
                >
                    {{ error.message }}
                </span>
            </div>
        </div>
    </div>

    <div *ngIf="socialLogins.length">
        <div class="row">
            <div class="obf-form-item col-12" *ngFor="let scLogin of socialLogins">
                <app-button
                    (click)="loginWithSocial(scLogin)"
                    [socialType]="'login'"
                    [type]="scLogin.type"
                    [name]="scLogin.name"
                    [isFullWidth]="'100%'"
                ></app-button>
            </div>
        </div>
    </div>

    <div class="note obf-divider-box"  *ngIf="socialLogins.length && !disableEmailAuthorization">
        <span> {{ componentSettings?.form?.labels?.signUp?.label }} </span>
    </div>

    <ng-container *ngIf="!disableEmailAuthorization || (!socialLogins || !socialLogins.length)">
        <form #f="ngForm" [formGroup]="loginForm" novalidate (ngSubmit)="validateEmailForm()">
            <!-- /.msg-box -->
            <div class="login-wrap" [ngClass]="{'expand' : isBoxExpandeByDefault || isBoxExpanded && !isBoxExpandeByDefault, 'add-transition' : isBoxExpanded && !isBoxExpandeByDefault}">
                <div class="obf-fs-input-wrap">
                    <div>
                        <input
                            FsFocusHandler
                            [mainNavigation]="''"
                            [inputScrollParent]="'.obf-lr-modal'"
                            [fsTextField]="email.value"
                            [labelText]="componentSettings.form.fields.email.textFieldSettings.label"
                            [errorMessage]="componentSettings.form.fields.email.textFieldSettings.errMsg"
                            id="email"
                            [error]="(email.invalid && formSubmitted)"
                            type="email"
                            name="email"
                            formControlName="email"
                            autocomplete="email"
                            #username
                            required
                            />
                            <!-- placeholder="{{ componentSettings.form.fields.email.textFieldSettings.placeholder }}" -->
                    </div>
                </div>
    
                <div class="obf-fs-input-wrap">
                    <div class="obf-password-box">
                        <input
                            FsFocusHandler
                            [mainNavigation]="''"
                            [inputScrollParent]="'.obf-lr-modal'"
                            [fsTextField]="password.value"
                            [labelText]="componentSettings.form.fields.password.textFieldSettings.label"
                            [errorMessage]="componentSettings.form.fields.password.textFieldSettings.errMsg"
                            id="password"
                            type="{{ componentSettings.form.fields.password.textFieldSettings.showPassword ? 'text' : 'password' }}"
                            [error]="(password.invalid && formSubmitted)"
                            type="password"
                            name="password"
                            autocomplete="off"
                            formControlName="password"
                            required
                            />
                            <!-- placeholder="{{ componentSettings.form.fields.password.textFieldSettings.placeholder }}" -->
                        <div
                            class="obf-check"
                            (click)="
                                componentSettings.form.fields.password.textFieldSettings.showPassword = !componentSettings.form.fields.password
                                    .textFieldSettings.showPassword
                            "
                        >   
                                <i class="obf-icon icon-si-eye" aria-hidden="true" *ngIf="componentSettings.form.fields.password.textFieldSettings.showPassword"></i>   
                                <i class="obf-icon icon-si-crossed-eye" aria-hidden="true" *ngIf="!componentSettings.form.fields.password.textFieldSettings.showPassword"></i>
                        </div>
                    </div>
                </div>
    
                <div class="obf-fs-input-wrap">
                    <div class="obf-fs-check-inputs">
                        <input id="loggedIn" type="checkbox" name="loggedIn" formControlName="loggedIn" />
                        <label for="loggedIn">
                            <span class="obf-checkbox icon-si-check"></span>
                            {{ componentSettings.form.fields.loggedIn.textFieldSettings.label }}
                        </label>
                    </div>
                </div>
            </div>
    
            <div class="btn-wrap">
                <app-button
                    id="login-button-component"
                    [isSubmitButton]="true"
                    data-e2e="login-submit-button"
                    [label]="componentSettings?.form?.fields?.login?.label"
                    [type]="'primary'"
                    [isFullWidth]="true">
                </app-button>
            </div>
     
            <div class="obf-forgot-obf-password-box" *ngIf="!disableChangePassword">
                <span class="" (click)="resetPasswordHandler()">{{ componentSettings?.form?.navLinks?.forgotPassword?.label }}</span>
            </div>
    
        </form>
    </ng-container>

    <div *ngIf="isLoginOptional && isGuestAuthEnabled !== false || isGuestAuthEnabled">
        <div class="note obf-divider-box">
            <span> {{ componentSettings?.form?.labels?.signUp?.label }} </span>
        </div>
    
        <div class="continue-as-guest-wrapper">
            <p
                class="bold"
                localizedText
                [textKeyword]="'optionalLoginLabel'"
                (click)="continueAsGuest()"
            ></p>
        </div>
    </div>
</div>

<div #dynamicLoaderContainer></div>
