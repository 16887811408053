import { HeadersOptions } from './HeaderBuilder';
import { HttpClient } from '../../Ajax/AjaxHttpClient';
import { HttpInterceptor } from '../../Ajax/HttpInterceptor';
import { Configuration } from '../../Constants/Configuration';
import ObfInjector from '../../Helpers/Injector';
import { tap, mergeMap, retryWhen, map } from 'rxjs/operators';
import { Observable, throwError, timer } from 'rxjs';
import { SDKValidationResponseService } from '../SDKValidationResponseService';
export class BackEndAccountsService {
    private http: HttpClient;
    private configuration: Configuration;
    private _httpInterceptor: HttpInterceptor;
    private _validator: SDKValidationResponseService;

    constructor() {
        this._validator =
            ObfInjector.getRegistered('SDKValidationResponseService') as SDKValidationResponseService;
        this.configuration = ObfInjector.getRegistered('Configuration');
        this.http = new HttpClient(this.configuration.accountsServer);
        this._httpInterceptor = ObfInjector.getRegistered('HttpInterceptor');
    }

    public post(path: string, data: any, headersOptions: HeadersOptions, validateResponseType?: string) {
        const headers = ObfInjector.getRegistered('HeaderBuilder').makeHeaders(headersOptions);

        if (headersOptions && headersOptions.withCredentials) {
            return this.http.post(path, data, headers, headersOptions.withCredentials)
                .pipe(
                    map(response => {
                        if (validateResponseType) {
                            response = this._validator.ValidationResponseStructure(response, validateResponseType);
                        }
                        return response;
                    }),
                    tap(
                        (response: any) => { // Success
                            // Check response
                            this.checkResponse(response);
                        },
                        (response: any) => { // Error
                            // Check response
                            throwError(response);
                        }
                    ),
                    retryWhen(
                        // errors => {return errors.pipe(delay(1000), take(3));}
                        this.errorHandler())
                );
        } else {
            return this.http.post(path, data, headers)
                .pipe(
                    map(response => {
                        if (validateResponseType) {
                            response = this._validator.ValidationResponseStructure(response, validateResponseType);
                        }
                        return response;
                    }),
                    tap(
                        (response: any) => { // Success
                            // Check response
                            this.checkResponse(response);
                        },
                        (response: any) => { // Error
                            // Check response
                            this.checkResponse(response);
                        }
                    ),
                    retryWhen(
                        // errors => {return errors.pipe(delay(1000), take(3));}
                        this.errorHandler())
                );
        }
    }

    public get(path: string, headersOptions: HeadersOptions, validateResponseType?: string) {
        const headers = ObfInjector.getRegistered('HeaderBuilder').makeHeaders(headersOptions);

        if (headersOptions && headersOptions.withCredentials) {
            return this.http.get(path, headers, headersOptions.withCredentials)
                .pipe(
                    map(response => {
                        if (validateResponseType) {
                            response = this._validator.ValidationResponseStructure(response, validateResponseType);
                        }
                        return response;
                    }),
                    tap(
                        (response: any) => { // Success
                            // Check response
                            this.checkResponse(response);
                        },
                        (response: any) => { // Error
                            // Check response
                            throwError(response);
                        }
                    ),
                    retryWhen(
                        // errors => {return errors.pipe(delay(1000), take(3));}
                        this.errorHandler())
                );
        } else {
            return this.http.get(path, headers)
                .pipe(
                    map(response => {
                        if (validateResponseType) {
                            response = this._validator.ValidationResponseStructure(response, validateResponseType);
                        }
                        return response;
                    }),
                    tap(
                        (response: any) => { // Success
                            // Check response
                            this.checkResponse(response);
                        },
                        (response: any) => { // Error
                            // Check response
                            throwError(response);
                        }
                    ),
                    retryWhen(
                        // errors => {return errors.pipe(delay(1000), take(3));}
                        this.errorHandler())
                );
        }
    }

    private errorHandler() {
        let errorCount = 0;
        return (errorObserver: Observable<any>) => {
            return errorObserver.pipe(
                mergeMap((error: any, i) => {
                    errorCount += 1;
                    // if maximum number of retries have been met
                    // or response is a status code we don't wish to retry, throw error
                    if (
                        errorCount > 4 ||
                        error.statusCode === 200
                    ) {
                        this.checkResponse(error);
                        return throwError(error);
                    }
                    // retry after 1s, 2s, etc...
                    return timer(1000);
                }));
        };
    }

    /**
     * Apply OBF Response Checking algorythm in each request that SDK performs
     * @param response http response
     */
    private checkResponse(response: any): void {
        if (this._httpInterceptor.isResponseCheckerRegistered()) {
            this._httpInterceptor.checkResponse('apiError', response);
        }
    }
}
