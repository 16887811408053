import APIExceptionLogger from '../../Loggers/APIExceptionLogger/APIExceptionLogger';
import IAPIDataSource from './IAPIDataSource';
import { APIResponse } from './Responses/APIResponse';

interface Interceptor {
    id: number;
    interceptor: (response: APIResponse, url:string, body:any) => Promise<void>;
}

export default class APIDataSource implements IAPIDataSource {
    public appToken: string;
    public profile: string;
    public serverUrl: string;
    public sid: string;
    public appBuild: string;
    public language: string;

    public interceptorsCount = 0;
    public interceptors: Interceptor[] = [];
    public apiExceptionLogger: APIExceptionLogger;

    constructor(baseUrl: string, appToken: string, profile: string, sid: string, appBuild: string, language?: any) {
        this.serverUrl = baseUrl;
        this.appToken = appToken;
        this.profile = profile;
        this.sid = sid;
        this.appBuild = appBuild;
        this.language = language;
        this.apiExceptionLogger = new APIExceptionLogger(appBuild, this);
    }

    public registerInterceptor(interceptor: (response: APIResponse) => Promise<void>): number {
        this.interceptorsCount++;
        this.interceptors.push({ id: this.interceptorsCount, interceptor });
        return this.interceptorsCount;
    }

    public removeInterceptor(id: number): boolean {
        if (this.interceptors.length) {
            const interceptorIndex = this.interceptors.findIndex(el => el.id === id);
            if (interceptorIndex) {
                this.interceptors.splice(interceptorIndex, 1);
                return true;
            }
        }
        return false;
    }

    public async get(namespace: string, path: string, params: {} | null, ac?: AbortController): Promise<APIResponse> {
        return this.request('GET', namespace, path, params, null, ac);
    }

    public async post(namespace: string, path: string, params: {} | null, data: {} | null, ac?: AbortController): Promise<APIResponse> {
        return this.request('POST', namespace, path, params, data, ac);
    }

    public async request(
        method: string,
        namespace: string,
        path: string,
        params: {} | null,
        data: {} | null,
        ac: AbortController = new AbortController(),
    ): Promise<APIResponse> {
        return new Promise(async (resolve, reject) => {
            const timeout = 15000;
            const url = this.serverUrl + namespace;
            const body = { requests: [{ method, path, params, data }] };

            const headers = {
                body: JSON.stringify(body),
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-Application': this.appToken,
                    // 'X-Application-Build': this.appBuild,
                    // 'Authorization': this.sid,
                    'X-Profile': this.profile,
                    'X-Language': this.language ? this.language : ''
                },
                method: 'POST',
                signal: ac.signal,
            };

            if (this.sid) {
                Object.assign(headers.headers, { Authorization: this.sid });
            }

            // Timeout the request.
            let id: any = setTimeout(() => {
                id = null;
                ac.abort();
            }, timeout);

            // console.log("Base url: " + url)
            // console.log(JSON.stringify(headers, null, 2))
            // console.log(JSON.stringify(body, null, 2))

            await fetch(url, headers)
                .then((response) => {
                    if (!response.ok) {
                        return [{ error: [{ message: 'Something went wrong', code: response.status }] }];
                    }

                    return response.json();
                })
                .then(async (responseJson) => {
                    let data: APIResponse = { error: [{ message: 'Something went wrong', code: -1 }] };

                    if (responseJson.responses) {
                        data = responseJson.responses[0];
                    }
                    this.fireInterceptors(data, url, body);


                    if (data.error) {
                        // this.sendException(url, data);
                        reject(data);
                        return;
                    }
                    resolve(data);
                })
                .catch(async (error) => {
                    let errorData: APIResponse = { error: [{ message: 'Connection error', code: 100100 }] };

                    if (id == null) {
                        errorData = { error: [{ message: 'Request timeout', code: -1 }] };
                    }

                    this.fireInterceptors(errorData, url, body);
                    // this.sendException(url, errorData);
                    reject(errorData);
                });

            clearTimeout(id);

        });
    }

    private fireInterceptors(response: APIResponse, url: string, body: any) {
        if (this.interceptors) {
            for (let index = 0; index < this.interceptors.length; index++) {
                const el = this.interceptors[index];

                try {
                    el.interceptor(response, url, body);
                } catch (e) {
                    // console.error(e)
                }
            }
        }
    }

    private sendException(url, response) {
        if (response.error[0] && response.error[0].message && response.error[0].code) {
            this.apiExceptionLogger.send(
                url, 
                response.error[0].message, 
                response, 
                response.error[0].code);
        }
        console.log('EXCEPTION SEND', url, response);
    }
}


