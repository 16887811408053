import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LocalizationDirective } from './localization.directive';

@NgModule({
    declarations: [LocalizationDirective],
    imports: [
        CommonModule,
    ],
    exports: [
        LocalizationDirective,
    ],
})
export class LocalizationModule { }
