export const deprecatedKeywords: Array<{ oldKeyword: string, newKeyword: string }> = [
    {
        oldKeyword: 'fantasticClubPriceLabel',
        newKeyword: 'membershipClubPriceLabel',
    },
    {
        oldKeyword: 'socialAuthLabel',
        newKeyword: 'socialAuthLabel1',
    },
    {
        oldKeyword: 'addressFormPostcodeErrMsg',
        newKeyword: 'addressFormPostcodeErrMsg1',
    },
    {
        oldKeyword: 'addressFormAddressLineOneLabel',
        newKeyword: 'addressFormAddressLineOneLabel1',
    },
    {
        oldKeyword: 'addressFormAddressLineOnePlaceholder',
        newKeyword: 'addressFormAddressLineOnePlaceholder1',
    },
    {
        oldKeyword: 'addressFormAddressLineOneErrMsg',
        newKeyword: 'addressFormAddressLineOneErrMsg1',
    },
    {
        oldKeyword: 'serviceSearchInputPlacehodler',
        newKeyword: 'serviceSearchPlaceholder',
    },
];
