<div class="obf-payment-select-popup">
    <span class="obf-close-btn icon-si-close-cancel" (click)="closeModal()"></span>
    
    <div class="obf-payment-select-header">
       <h3 localizedText [textKeyword]="'choosePaymentMethodTitle'"></h3>
    </div>
    <div class="obf-payment-select-body">
        <div class="row justify-content-center" *ngFor="let payment of paymentsArray">
            <div class="col-12 col-md-6">
                <div class="payment-button" (click)="selectPayment(payment)" [ngClass]="{ selected: payment === selectedPayment}">
                    {{payment.title}}
                </div>
            </div>
        </div>
    </div>
    <div class="obf-payment-select-footer">
        <div class="row justify-content-lg-center">
            <div class="col-lg-6">
                <app-button
                    [isSubmitButton]="true"
                    [isFullWidth]="true"
                    [label]="'Confirm Booking'"
                    [type]="'primary'"
                    (click)="confirmBooking()"
                    [disable]="this.selectedPayment === null">
                </app-button>
            </div>
        </div>
    </div>
</div>
