/**
 */

import { Injectable } from '@angular/core';
import { Position } from '@models/position';
import { ConfigurationData } from '@providers/configuration-data.provider';
import { MainDataProvider } from '@providers/main-data.provider';
import { UserData } from '@providers/user-data.provider';
import { ChoicesPositions } from '../../constants/choice';
import { ErrorReportingService } from '../errors/error-reporting.service';
import { OrderData } from './../../providers/xrm/order-data.provider';
import { ServiceData } from './../../providers/xrm/service-data.provider';
import { NavigationService } from './../navigation.service';
import { EventTrackingService } from './event-tracking.service';


@Injectable({
    providedIn: 'root',
})

export class TrackingInteractionsService {
    private positionUpdate = null;
    private interactionAddressAlredyTriggered = false;
    private interactionServiceAlredyTriggered = false;
    private transactionTypeOnConfirmation = null;
    private obfIsOpen: boolean;
    private source: string;
    private transaction_id: string;
    private globalActiveData = null;

    constructor(
        private eventTrackingService: EventTrackingService,
        private navigationService: NavigationService,
        private errorReportingService: ErrorReportingService,
        private orderData: OrderData,
        private mainDataProvider: MainDataProvider,
        private serviceData: ServiceData,
        private configurationData: ConfigurationData,
        private userDataProvider: UserData,
    ) {

        // Enable interaction tracking for all clients
        console.log('data interaction initialize...');
        this.navigationService.successNavigation.subscribe((data) => {
            this.successfulNavigation(data.currentPosition, data.prevPosition);
        });
        this.setVisible(false);

    }

    /**
     * Set OBF Visibility for the Init and Open the OBF data pushing
     * @param openObfFlag flag property
     * @returns boolean
     */
    public setVisible(openObfFlag: boolean): any {
        return (this.obfIsOpen = openObfFlag);
    }

    private successfulNavigation(currentPositionObj: Position, previousPositionObj: Position): void {

        try {
            // DEBUG
            // console.log(arguments);
            let choiceItemsInSummary = null;
            this.source = null;
            this.transaction_id = null;
            const activeData = this.globalActiveData = this.orderData.activeBooking.getValue() ? this.orderData.activeBooking.getValue().get() : null;
            const obfOptions = this.mainDataProvider.getResourceObfOptions();
            const userData = this.userDataProvider.getUserData();
            let transactionType = activeData && activeData?.booking_id !== 0 ? 'reschedule' : 'new booking';

            // 1 - Anonymous
            // 2 - Generic (register form)
            // 3 - Social (Facebook)
            // 4 - Offline
            let isUserLogged = userData && userData?.type_id !== 1 ? true : false;
            this.source = obfOptions?.phone;
            this.transaction_id = activeData?.id ? activeData?.id : null;
              
            // console.log('DEBUG:',this.transaction_id, activeData, obfOptions, userData);

           

            // Set the last transaction type in the booking proccess because on confirm always will have booking_id
            if (currentPositionObj.position !== ChoicesPositions.Confirmation) {
                this.transactionTypeOnConfirmation = transactionType;
            }

            //  console.log('DEBUG:',this.transactionTypeOnConfirmation);

            

                // const categoryTitle = this.getCategoryNameByServiceId(activeData?.service?.id);
                // console.log('activeData' + categoryTitle, activeData);


                // Get micro step
                const microStepObj = currentPositionObj.hasOwnProperty('activeChild') ? currentPositionObj.activeChild : currentPositionObj;
                // console.log('serviceData', microStepObj);

                switch (currentPositionObj.position) {

                    case ChoicesPositions.Init:

                        // Check the micro steps to push the events
                        switch (microStepObj?.position) {
                            case ChoicesPositions.OnWelcome:
                                // Reset flags
                                this.interactionAddressAlredyTriggered = false;
                                this.interactionServiceAlredyTriggered = false;
                                break;

                            case ChoicesPositions.AfterWelcome:
                                // Send service select event
                                this.interactionServicePayloadTrigger(this.buildFullURL(currentPositionObj));
                                break;
                            
                            default:
                                break;
                        }
                        break;
                    case ChoicesPositions.Configurator:
                    case ChoicesPositions.BeforeAvailability:
                       
                        
                        // Check if the choice item address is skipped and fill the event on configuration
                        if (activeData?.address_display_text !== null) {
                            // Send address select event
                            this.interactionAddressPayloadTrigger(activeData?.address_display_text, this.buildFullURL(currentPositionObj));
                        }
                        
                        // Send service select event
                        this.interactionServicePayloadTrigger(this.buildFullURL(currentPositionObj));

                        // Update only ones on configuration micro steps
                        if (this.positionUpdate !== currentPositionObj.position) {
                            this.positionUpdate = currentPositionObj.position;
                        }

                        break;
                    case ChoicesPositions.OnAvailability:
                        choiceItemsInSummary = this.isInSummary(activeData?.service?.choices);

                        // Check if the choice item address is skipped and fill the event on configuration
                        if (activeData?.address_display_text !== null) {
                            // Send address select event
                            this.interactionAddressPayloadTrigger(activeData?.address_display_text, this.buildFullURL(currentPositionObj));
                        }

                        // Send service select event
                        this.interactionServicePayloadTrigger(this.buildFullURL(currentPositionObj));
                    

                        // Build payload
                        let sos_service_details_payload = {
                            choice_items: choiceItemsInSummary,
                            source: this.source,
                            main_url: obfOptions.hasOwnProperty('main_url')
                            ? obfOptions.main_url
                            : null,
                            transaction_id: this.transaction_id,
                            iframe_full_url: this.buildFullURL(currentPositionObj),
                        }

                        // SOS Interactions push
                        this.eventTrackingService.sosInteractionsPush('entered_configuration', sos_service_details_payload);
                        break;
                    case ChoicesPositions.OnSummary:
                        // console.log('activeData', activeData)

                        choiceItemsInSummary = this.isInSummary(activeData?.service?.choices);

                        // Build payload
                        let sos_avaialbility_payload = {
                            timeslot_formatted: activeData?.timeslot_formatted,
                            source: this.source,
                            main_url: obfOptions.hasOwnProperty('main_url')
                            ? obfOptions.main_url
                            : null,
                            transaction_id: this.transaction_id,
                            iframe_full_url: this.buildFullURL(currentPositionObj),
                        }

                        // SOS Interactions push
                        this.eventTrackingService.sosInteractionsPush('entered_availability', sos_avaialbility_payload);

                        break;
                    case ChoicesPositions.BeforeConfirmation:
                        // console.log('activeData', activeData);

                        choiceItemsInSummary = this.isInSummary(activeData?.service?.choices);

                         // Build payload
                         let sos_summary_payload = {
                            price: activeData?.price,
                            address_display: activeData?.address_display_text,
                            source: this.source,
                            main_url: obfOptions.hasOwnProperty('main_url')
                            ? obfOptions.main_url
                            : null,
                            iframe_full_url: this.buildFullURL(currentPositionObj),
                            transaction_id: this.transaction_id,
                        }

                        // SOS Interactions push
                        this.eventTrackingService.sosInteractionsPush('entered_summary', sos_summary_payload);
                        break;
                    case ChoicesPositions.Confirmation:
                        // console.log('activeData', activeData);


                        // Build payload
                        let sos_confirmation_payload = {
                            reference_number: activeData?.reference_number,
                            source: this.source,
                            main_url: obfOptions.hasOwnProperty('main_url')
                            ? obfOptions.main_url
                            : null,
                            iframe_full_url: this.buildFullURL(currentPositionObj),
                            transaction_id: this.transaction_id,
                        }

                        // SOS Interactions push
                        this.eventTrackingService.sosInteractionsPush('completed_booking', sos_confirmation_payload);
                        // reset on complete
                        this.transactionTypeOnConfirmation = null;
                        break;


                    default:
                        break;
                }
            

        this.pageView(currentPositionObj);
        // console.log('currentPositionObj', currentPositionObj);
        // console.log('obfOptions', obfOptions);

        } catch (error) {
            console.error(error);
            let formattedReportMessage = '';

            if (error && error.message && error.name) {
                // eslint-disable-next-line one-var
                const exception: string = 'Exception: "' + error.message + '"\n',
                    reason: string = 'Caused by: ' + error.name + '\n',
                    stack: string = error.stack ? 'Stack Trace: ' + error.stack + '\n' : '';

                formattedReportMessage = exception + reason + stack;
            } else {
                formattedReportMessage = 'No stacktrace provided';
            }

            // eslint-disable-next-line one-var
            const errorModel = {
                message: formattedReportMessage,
                reason: 'Interaction Tracking tag problem',
            },
                reportSubject = 'Interaction tracking tag';

            this.errorReportingService.report(
                {
                    emailErrors: [errorModel],
                },
                false,
                reportSubject,
            );
        }
    }

    private getCategoryNameByServiceId(serviceId: any): void {
        const categories = this.serviceData.getCategories();
        let currentServiceCategoryTitle = null;

        if (categories) {
            // Get Category Name for the service and wait to return it, then pushing the data
            const serviceCategory = categories.find((category) => {
                if (category.services) {
                    return category.services.find((service) => service.id === serviceId);
                }
            });

            currentServiceCategoryTitle = serviceCategory ? serviceCategory.title : null;

            return currentServiceCategoryTitle ? currentServiceCategoryTitle : null;
        }
    }

    private pageView(overridePath?: any): void {
        const stepObj = overridePath.hasOwnProperty('activeChild') ? overridePath.activeChild : overridePath;

        // Build event name from the screen titles
        let event_name = 'view_' + stepObj?.position;
        let choice_id, choices = null;

        const obfOptions = this.mainDataProvider.getResourceObfOptions();

        let viewDataObj = {
            source: this.source,
            main_url: obfOptions.hasOwnProperty('main_url')
            ? obfOptions.main_url
            : null,
            iframe_full_url: window.location.origin + '/#' + overridePath?.url,
            transaction_id: this.transaction_id
        };

        // Mapping the events for Interaction
        switch (stepObj?.position) {
            case ChoicesPositions.OnWelcome:
                event_name = 'view_service_list';
                break;
            case ChoicesPositions.AfterWelcome:
                event_name = 'view_postcode';
                break;
            case ChoicesPositions.NoCoverage:
                event_name = 'view_no_coverage';
                break;
            case ChoicesPositions.Configurator:
                event_name = 'view_configurator';
                choice_id = overridePath?.pathParams[0];
                choices = this.globalActiveData?.service?.choices;
                
                if(choices && choices.length !== 0) {
                    choices.forEach((choice) => {
                        if(choice.id == choice_id) {
                            viewDataObj['choice_id'] =  choice.id 
                            viewDataObj['choice_title'] = choice.title ;
                        }
                    });
                }     
                break;
            case ChoicesPositions.BeforeAvailability:
                event_name = 'view_before_avaialbility';
                choice_id = overridePath?.pathParams[0];
                choices = this.globalActiveData?.service?.choices;
                
                if(choices && choices.length !== 0) {
                    choices.forEach((choice) => {
                        if(choice.id == choice_id) {
                            viewDataObj['choice_id'] =  choice.id 
                            viewDataObj['choice_title'] = choice.title ;
                        }
                    });
                }     
                break;
            case ChoicesPositions.OnAvailability:
                event_name = 'view_availability';
                break;
            case ChoicesPositions.AfterAvailability:
                event_name = 'view_after_availability';
                break;
            case ChoicesPositions.BeforeSummary:
                event_name = 'view_before_summary';
                break;
            case ChoicesPositions.OnSummary:
                event_name = 'view_summary';
                break;
            case ChoicesPositions.BeforeConfirmation:
                event_name = 'view_before_confirmation';
                break;
            case ChoicesPositions.Confirmation:
                event_name = 'view_confirmation';
                break;
        
            default:
                break;
        }

        // Check if we have close flag and change the event name on the init view
        // Send close event. (on_close) always return to init step before closing the form
        if (this.obfIsOpen !== undefined && !this.obfIsOpen) {
            event_name = 'close_booking_form';
        }

        this.eventTrackingService.sosInteractionsPush(event_name, viewDataObj);
        
    }


    private interactionServicePayloadTrigger(full_url: string): void {
        
        if (!this.interactionServiceAlredyTriggered) {
            // Get selected service
            let service = this.serviceData.getLastServiceInitData();
            const obfOptions = this.mainDataProvider.getResourceObfOptions();

            if(!service) {
                service = this.globalActiveData?.service;
            }    
    
            // Build payload
            let sos_service_payload = {
                service_title: service['title'],
                service_id: service['id'],
                source: this.source,
                main_url: obfOptions.hasOwnProperty('main_url')
                ? obfOptions.main_url
                : null,
                iframe_full_url: full_url,
                transaction_id: this.transaction_id,
            }
    
            // SOS Interactions push
            this.eventTrackingService.sosInteractionsPush('entered_service', sos_service_payload);
            // Set flag the service event is triggered and there is no skip
            this.interactionServiceAlredyTriggered = true;
        }
    }

    private interactionAddressPayloadTrigger(full_address: string, full_url: string): void {
        if (!this.interactionAddressAlredyTriggered) {
            const obfOptions = this.mainDataProvider.getResourceObfOptions();
            // Build payload
            let sos_address_payload = {
                address_display: full_address,
                source: this.source,
                main_url: obfOptions.hasOwnProperty('main_url')
                ? obfOptions.main_url
                : null,
                iframe_full_url: full_url,
                transaction_id: this.transaction_id
            }

            // SOS Interactions push
            this.eventTrackingService.sosInteractionsPush('entered_postcode', sos_address_payload);
            // Set flag the address event is triggered and there is no skip
            this.interactionAddressAlredyTriggered = true;
        }
    }

    private buildFullURL(currentPositionObj: Position): string {
        if(currentPositionObj) {
            return (window.location.origin + '/#' + currentPositionObj?.url);
        } else return null;
    }
    

    /**
     * Add array of choices from  Booking Transaction
     * @param choices
     * @returns Return all the choice items from the summary
     */
    private isInSummary(choices): any {
        const summaryItems = [];
        if (choices && choices.length !== 0) {
            choices.forEach((choice) => {
                if (choice.choice_items) {
                    choice.choice_items.forEach((chItem) => {

                        if (chItem.choice_items && chItem.value > 0) {
                            chItem.choice_items.forEach((subChItem) => {
                                if (subChItem.value !== 0 &&
                                    subChItem.value !== '' &&
                                    subChItem.type !== 'address' &&
                                    !(
                                        typeof subChItem.value !== 'undefined'
                                        && subChItem.value !== null
                                        && subChItem.value.length !== null
                                        && subChItem.value.length === 0
                                    )) {
                                    if (subChItem.customize && subChItem.customize.gtm_item_tracking && subChItem.customize.gtm_item_tracking !== 'default') {
                                        // summaryItems.push({ id: subChItem.id, title: subChItem.title, value: subChItem.value, price: subChItem.display_price, type: subChItem.customize.gtm_item_tracking });

                                        const objectData: { [key: string]: any } = {
                                            id: subChItem.id,
                                            title: subChItem.title,
                                            value: subChItem.value,
                                            type: subChItem.customize.gtm_item_tracking,
                                        };

                                        if (
                                            subChItem.hasOwnProperty('display_price') &&
                                            subChItem.hasOwnProperty('display_price').length !== 0
                                        ) {
                                            objectData.price = subChItem.display_price;
                                        }

                                        summaryItems.push(objectData);
                                    } else {
                                        // console.log('chItem', chItem);
                                        const choiceType = choice.type === 'cross_sell' ? 'cross_sell' : 'default';
                                        const objectData: any = {
                                            id: subChItem.id,
                                            title: subChItem.title,
                                            value: subChItem.value,
                                            type: choiceType,
                                        };

                                        if (subChItem.hasOwnProperty('display_price') && subChItem.hasOwnProperty('display_price').length !== 0) {
                                            objectData.price = subChItem.display_price;
                                        }

                                        summaryItems.push(objectData);
                                    }
                                }
                            });
                        }

                        if (chItem.value !== 0 &&
                            chItem.value !== '' &&
                            chItem.type !== 'address' &&
                            !(
                                typeof chItem.value !== 'undefined'
                                && chItem.value !== null
                                && chItem.value.length !== null
                                && chItem.value.length === 0
                            )
                        ) {
                            if (chItem.customize && chItem.customize.gtm_item_tracking && chItem.customize.gtm_item_tracking !== 'default') {
                                // summaryItems.push({ id: chItem.id, title: chItem.title, value: chItem.value, price: chItem.display_price, type: chItem.customize.gtm_item_tracking });
                                const objectData: any = {
                                    id: chItem.id,
                                    title: chItem.title,
                                    value: chItem.value,
                                    type: chItem.customize.gtm_item_tracking,
                                };

                                if (chItem.hasOwnProperty('display_price') && chItem.hasOwnProperty('display_price').length !== 0) {
                                    objectData.price = chItem.display_price;
                                }

                                summaryItems.push(objectData);
                            } else {
                                const choiceType = choice.type === 'cross_sell' ? 'cross_sell' : 'default';
                                // console.log('choice', choice.type, chItem.title, chItem.value, choiceType);
                                const objectData: any = {
                                    id: chItem.id,
                                    title: chItem.title,
                                    value: chItem.value,
                                    type: choiceType,
                                };

                                if (chItem.hasOwnProperty('display_price') && chItem.hasOwnProperty('display_price').length !== 0) {
                                    objectData.price = chItem.display_price;
                                }

                                summaryItems.push(objectData);
                                // summaryItems.push({ id: chItem.id, title: chItem.title, value: chItem.value, price: chItem.display_price, type: choiceType });
                            }
                        }
                    });
                }
            });
        }

        return summaryItems;
    }

}
