
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { BreadcrumbsService } from '@services/breadcrumbs.service';
import { RouterlessTracking } from 'angulartics2';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { XRMApiCommunicatorService } from './../services/xrm/xrmapi-communicator.service';

@Injectable()
export class ParentGuard implements CanActivate {
    constructor(
        private _XRMApiCommunicatorService: XRMApiCommunicatorService,
        private breadcrumbsService: BreadcrumbsService,
        private _logger: NGXLogger,
        private router: Router
    ) { }

    public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        this._logger.log('ParentGuard canActivate');
        
        try {
            const currentNavigation = this.router.getCurrentNavigation();
            this.breadcrumbsService.infoBreadcrumb('currentNavigation', JSON.stringify(currentNavigation));
            this.breadcrumbsService.infoBreadcrumb('parent guard', JSON.stringify({ next: next, state: state }));
        } catch (e) {
            
            // secure if some strange error happened.
        }

        try {
            const currentNavigation = this.router.getCurrentNavigation();
            this._logger.log('currentNavigation ' + JSON.stringify(currentNavigation));
            this._logger.log('parent guard', JSON.stringify({ next: next, state: state }));
        } catch (error) {
            
        }

        if (this._XRMApiCommunicatorService.getInstance()) {
            return true;
        } else {
            this._logger.log('Navigation before XRMApiCommunicatorInit');
            return false;
        }
    }
}
