import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FsTextFieldDirective } from './fs-text-field.directive';

@NgModule({
    declarations: [FsTextFieldDirective],
    imports: [
        CommonModule,
    ],
    exports: [FsTextFieldDirective],
})
export class FsTextFieldModule { }
