import { BehaviorSubject } from 'rxjs';

import { Injectable } from '@angular/core';

import { LoaderComponent } from '../components/shared/loader/loader.component';
import { genLongGUID } from '../helpers/helper-functions';
import { MainDataProvider } from '../providers/xrm/main-data.provider';
import { LocalizationProvider } from '@providers/localization.provider';

@Injectable({
    providedIn: 'root',
})

export class LoaderService {
    public loaderComponentRef: LoaderComponent;

    public loaderStack = [];
    public loaderStatus: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(public _mainDataProvider: MainDataProvider, private _localizationProvider: LocalizationProvider) {}

    /**
     * Set the new loader reference to the loaderComponent
     * @param ref
     */
    public setLoaderRef(ref: LoaderComponent): void {
        this.loaderComponentRef = ref;
    }

    /**
     * Show the loader
     * @param loaderText
     * @param overlayEnabled
     */
    public showLoader(loaderText?: string, overlayEnabled = true, isInvisible = false) {
        loaderText = loaderText || this._localizationProvider.getLocalizedText('loaderDefaultMsg');

        const loader = {
            loaderId: genLongGUID(),
            settings: {
                text: loaderText,
                overlayEnabled,
                isInvisible,
            },
        };

        this.loaderStack.push(loader);

        this.loaderComponentRef.settings = loader.settings;
        this.loaderComponentRef.show(loaderText, overlayEnabled, isInvisible);
        this.loaderStatus.next(!!this.loaderStack.length);
        return loader;
    }

    /**
     * Hide the loader
     */
    public hideLoader(loader, forceHideLoader? : boolean) {

        if (!loader) {
            return;
        }

        if (forceHideLoader) {
            this.loaderStack = [];
        }

        const loaderIndex = this.loaderStack.indexOf((el) => {
            return el.loaderId === loader.loaderId;
        });

        this.loaderStack.splice(loaderIndex, 1);

        if (this.loaderComponentRef && !this.loaderStack.length) {
            this.loaderComponentRef.hide();
        } else {
            this.loaderComponentRef.settings = this.loaderStack[this.loaderStack.length - 1].settings;
        }

        this.loaderStatus.next(!!this.loaderStack.length);
    }

    public hardCloseLoader() {
        this.loaderStack = [];
        if (this.loaderComponentRef && !this.loaderStack.length) {
            this.loaderComponentRef.hide();
        }
        this.loaderStatus.next(!!this.loaderStack.length);

    }
}
