import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import ReactDOM from 'react-dom';
import React from 'react';
import { TipModuleComponentFactory } from '@serviceos-rn/tip';
import { MainDataProvider } from '@providers/main-data.provider';
import { Authorize } from 'lib/xrm-sdk/src/lib/Api/ApiAuthorize';
import ObfInjector from 'lib/xrm-sdk/src/lib/Helpers/Injector';
import { environment } from 'src/environments/environment';
import { OrderData } from '@providers/order-data.provider';
import { EventTrackingService } from '@services/tracking/event-tracking.service';
import { TipTrack } from '@models/tip-track';
import { CoreModuleFactory, IInitApplicationUseCase, IInitApplicationUseCaseType } from "@serviceos-rn/core";
import { LocalizationProvider } from '@providers/localization.provider';
import { ConfigurationService } from '@services/configuration.service';

@Component({
    selector: 'app-tip-pro',
    templateUrl: './tip-pro.component.html',
    styleUrls: ['./tip-pro.component.scss'],
})
export class TipProComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
    @ViewChild('reactModuleTipPro', { static: false })
    public reactModuleContainer!: ElementRef;
    rootComponent: any;

    @Output()
    tipEvent: EventEmitter<any> = new EventEmitter();

    @Input()
    payload = null;

    constructor(
        private _mainDataProvider: MainDataProvider,
        private _orderData: OrderData,
        private _eventTrackingService: EventTrackingService,
        private _localizationProvider: LocalizationProvider,
        private _configurationService: ConfigurationService
        ) {}

    ngOnInit(): void {

        const dataLayerObject: TipTrack = {
            event: 'tip_button_tapped',
            position: this.payload?.position            
        };

        if (this.payload?.position === 'booking_process') {
            dataLayerObject.booking_transaction_id = this.payload?.booking_transaction_id
        } else {
            dataLayerObject.booking_id = this.payload?.booking_id
        }

        this._eventTrackingService.push(dataLayerObject);

        this.initReactNativeCore();
    }

    private initReactNativeCore() {
        const configuration = this._configurationService.getConfigurationSettings();
        let conf = Object.assign({}, configuration);

        const auth: Authorize = ObfInjector.getRegistered(
                'Authorize'
            ) as Authorize,
            sid: string = auth.authorization,
            obfOptions = this._mainDataProvider.getResourceObfOptions(),
            api_url = this._mainDataProvider.obfEnv.api.baseUrl,
            currentLanguage = this._localizationProvider.getCurrentLanguage();

        if (conf?.application?.settings) {
            conf.application.settings.debug = !environment.production;
            conf.application.settings.api_version = 'v2.2';
            conf.application.settings.namespace = 'client';
            conf.application.settings.api_url = api_url;
            conf.application.settings.application_token = obfOptions.key;

            // obfOptions theme is with higher priority
            if (conf.application.theme?.colors) {
                if (obfOptions?.theme && obfOptions.theme['primary-color']) conf.application.theme.colors.primary_color = obfOptions.theme['primary-color'];
                if (obfOptions?.theme && obfOptions.theme['secondary-color']) conf.application.theme.colors.secondary_color = obfOptions.theme['secondary-color'];
            }
                
            // OBF do not support dark theme so far
            // if (conf.application.theme) conf.application.theme.color_scheme = 'light';
        }

        const params = {
                payload: {
                    ...this.payload,
                    integrated: true
                },
                state: {
                    sid,
                    profile_keyword: obfOptions.profile_id || obfOptions.profile,
                    language: currentLanguage ? currentLanguage : '',
                    configuration: conf
                },
                onEvent: (event) => {
                    if (!environment.production) {
                        console.warn('tipEvent', event);
                    }
                    
                    this.tipEvent.emit(event);
                },
            };

        if (params) {
            const component = TipModuleComponentFactory.create(params);

            const provider = CoreModuleFactory.create()

            provider.resolve<IInitApplicationUseCase>(IInitApplicationUseCaseType)
                .then(useCase => useCase?.execute())
                .then(() => {
                    this.rootComponent = component;
                    this.render()
                })

            this.render();
        }
    }

    ngOnChanges(): void {
        this.render();
    }

    ngAfterViewInit(): void {
        this.render();
    }

    ngOnDestroy(): void {
        if (this.rootComponent) {
            ReactDOM.unmountComponentAtNode(
                this.reactModuleContainer.nativeElement
            );
        }
    }

    private render() {
        if (this.rootComponent && this.reactModuleContainer?.nativeElement) {
            ReactDOM.render(
                React.createElement(this.rootComponent),
                this.reactModuleContainer.nativeElement
            );
        }
    }
}
