/**
 * Set/Get Authorization data for the api
 */
export class Authorize {
    private _profile: string;
    private _application: string;
    private _authorization: string;
    private _version: string | null = null;
    /**
     *
     * @param {String} profile
     * @param {String} application
     */
    constructor(profile?: string, application?: string, authorization?: string) {
        this._profile = profile;
        this._application = application;
        this._authorization = authorization;
    }

    get profile() {
        return this._profile;
    }

    set profile(value: string) {
        this._profile = value;
    }

    get version(): string {
        return this._version;
    }

    set version(value: string) {
        this._version = value;
    }

    get application() {
        return this._application;
    }

    set application(value: string) {
        this._application = value;
    }

    get authorization() {
        return this._authorization;
    }

    set authorization(value: string) {
        this._authorization = value;
    }
}
