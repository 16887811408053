import { LocalStorageService } from './../local-storage.service';
import { Injectable } from '@angular/core';
import { interval } from 'rxjs';
import { popupCenter } from 'src/app/helpers/helper-functions';
import { MainDataProvider } from './../../providers/xrm/main-data.provider';
import { CryptoService } from './../crypto.service';
import { LoaderService } from './../loader.service';
import { LocalizationProvider } from '@providers/localization.provider';

/**
 * Facebook authentication services use in register and login components
 */
@Injectable({
    providedIn: 'root',
})

export class FacebookAuthService {
    private facebookAuth = {
        login: 'ext/facebook-login',
        register: 'ext/facebook-register',
    };

    public popupInstance = null;

    constructor(
        private mainDataProvider: MainDataProvider,
        private loaderService: LoaderService,
        private cryptoService: CryptoService,
        private _localizationProvider: LocalizationProvider,
    ) { }

    /**
     * Open new window for communication with Accounts - they make the actual authentication and return us the response from login/register request
     * @param {sting} requestType could be "login" and "register"
     * @return {Promise}
     */
    public beginAuth(requestType: 'login' | 'register'): Promise<any> {
        return new Promise((resolve: Function, reject: Function) => {
            const loader = this.loaderService.showLoader(this._localizationProvider.getLocalizedText('loaderMsg12'));

            const key = this.mainDataProvider.getSocialAuthData().facebookAuthKey;
            const redirectUrl = this.mainDataProvider.obfEnv.api.accounts +
                (requestType === 'login' ? this.facebookAuth.login : this.facebookAuth.register);

                const facebookAuthURL = `https://www.facebook.com/dialog/oauth?client_id=${key}&redirect_uri=${redirectUrl}&state=eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ&response_type=token&scope=email,public_profile&display=popup`;

            let authenticationResponse = null;
            const fbLoginPostMessageHandler = (event) => {
                if (event && event.data && event.data.c_fb_login_data) {
                    authenticationResponse = event.data.c_fb_login_data;
                    fbAuthPopup.close();
                }
            };
            // append listener for event
            window.addEventListener('message', fbLoginPostMessageHandler);

            const centerPopup = popupCenter();
            const fbAuthPopup = window.open(
                facebookAuthURL,
                'Booking Form Authorization Modal',
                'height=710,width=630,left=' + centerPopup.left + ',top=' + centerPopup.top + ',resizable=no,scrollbars=no,toolbar=no,menubar=no,location=no,directories=no, status=no');

            this.popupInstance = fbAuthPopup;

            const subscription = interval(1000).subscribe(() => {

                // Wait to close popup.
                if (fbAuthPopup && !fbAuthPopup.closed) { return; }

                if (authenticationResponse == null) {
                    try {
                        authenticationResponse =
                        localStorage.getItem('c_fb_login_data');
                        localStorage.removeItem('c_fb_login_data');
                    } catch (e) {}
                }

                if (authenticationResponse) {

                    const socialData: any = this.cryptoService.decryptData(authenticationResponse, this.mainDataProvider.obfEnv.cookieKey);

                    if (socialData && !socialData.error) {
                        resolve(socialData);
                    } else {
                        resolve(socialData);
                    }
                } else {
                    reject(null);
                }

                window.removeEventListener('message', fbLoginPostMessageHandler);
                this.loaderService.hideLoader(loader);
                subscription.unsubscribe();

            });

        });
    }
}
