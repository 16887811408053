import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SlideInOutDirective } from './slide-in-out.directive';

@NgModule({
    declarations: [
        SlideInOutDirective,
    ],
    imports: [
        CommonModule,
    ],
    exports: [
        SlideInOutDirective,
    ],
})
export class SlideInOutModule { }
