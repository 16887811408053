
import { HttpHeader } from './../Api/Interfaces/HttpHeader';

export class HttpOptions {
    public actionUrl: string;
    public path: string;
    public data: object | null;
    public headers: HttpHeader[];

    constructor(actionUrl: string) {
        this.actionUrl = actionUrl || '';
    }

    set httpHeaders(headers: HttpHeader[]) {
        this.headers = headers;
    }

    public resetRequestData() {
        this.path = '';
        this.data = null;
        this.headers = [];
    }

    public setRequestData(path: string, data: object | null, headers: HttpHeader[]) {
        this.path = path;
        this.data = data;
        this.headers = headers;
    }
}
