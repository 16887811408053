<div class="obf-popup {{ popupSettings.icon }}">
    <div class="obf-popup-header">
        <span
            class="obf-close-btn icon-si-close-cancel"
            (click)="action(popupSettings.buttons.xButton.action)"
        ></span>
    </div>
    <div class="obf-popup-body">
        <div class="obf-icon-wrap" *ngIf="popupSettings.image">
            <img src="{{ popupSettings.image }}" alt="" />
        </div>

        <h4 class="obf-title" *ngIf="popupSettings.title">
            {{ popupSettings.title }}
        </h4>

        <div class="obf-description">
            {{ popupSettings.description }}
            <!-- <span class="link">test link</span> -->
        </div>
    </div>
    <div class="obf-popup-footer">
        <div class="standard-footer-actions" *ngIf="popupSettings.buttons.otherButtons.length">
            <app-button
                *ngFor="let item of popupSettings.buttons.otherButtons"
                [label]="item.label"
                [type]="item.type"
                [state]="item.state"
                [size]="item.size"
                (click)="action(item.action)"
                style="margin:5px 5px"
                [isFullWidth]="true"
            >
            </app-button>
        </div>
    </div>
</div>
