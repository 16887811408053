import { Injectable } from '@angular/core';
import { MainDataProvider } from './main-data.provider';
import { BehaviorSubject } from 'rxjs';
import { LocalizationProvider } from '@providers/localization.provider';

@Injectable()
export class ConfigurationData {
    // !TODO: Interfaces!
    private configurationSettings: any;
    private validationPatterns: any;
    private leaveReasons: any;
    private profile: any;
    public configurationSettingsBS: BehaviorSubject<any> = new BehaviorSubject(null);

    constructor(private _mainDataProvider: MainDataProvider, private _localizationProvider: LocalizationProvider) { }

    /**
     * ValidationPatterns setter
     * @param validationPatterns
     */
    public setValidationPatterns(validationPatterns: any): void {
        this.validationPatterns = validationPatterns;
    }

    /**
     * ValidationPatterns getter
     */
    public getValidationPatterns(): any {
        return this.validationPatterns;
    }

    /**
     * Profile getter
     */
    public getProfile(): any {
        return this.profile;
    }

    /**
     * Profile setter
     * @param profile
     */
    public setProfile(profile: any): void {
        return this.profile = profile;
    }

    /**
     * ConfigurationSettings setter
     * @param configuration
     */
    public setConfiguration(configuration: any): void {
        this.configurationSettings = configuration;
        
        // Set static texts for localization provider usage
        if (configuration?.customer?.texts?.general) {
            this._localizationProvider.setStaticConfigurationTexts(configuration.customer.texts.general);
        }
        
        this.configurationSettingsBS.next(configuration);

        const obfOptions = this._mainDataProvider.getResourceObfOptions();

        // Set theme from profile configuration if obfOption theme is missing
        if(!(obfOptions?.theme) && this.configurationSettings?.application?.theme?.colors) {

            const profileTheme = this.configurationSettings.application.theme.colors;
            const setupTheme = {};

            for (var key in profileTheme) {
                if (profileTheme.hasOwnProperty(key)) {
                    setupTheme[key.replace('_', '-')] = profileTheme[key];
                }
            }

            this._mainDataProvider.changeColorSchema(setupTheme);
        }
    }

    /**
     * ConfigurationSettings getter
     */
    public getConfiguration(): any {
        return this.configurationSettings;
    }

    /**
     * LeaveReasons setter
     * @param leaveReasons
     */
    public setLeaveReasons(leaveReasons: any): void {
        this.leaveReasons = leaveReasons;
    }

    /**
     * LeaveReasons getter
     */
    public getLeaveReasons(): any {
        return this.leaveReasons;
    }
}
