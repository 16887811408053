<div class="obf-lr-modal {{activeComponent.type}}-modal obf-nice-scroll" data-e2e="authentication-modal">

    <div class="obf-lr-wrap" @lrAnimation>
        <!-- Deleate ngIf when UX provide better ui for reset password  -->
        <button class="obf-btn-close icon-si-close-cancel" aria-label="Close" 
            (click)="closeModal('exit');"></button>

        <div class="lr-body">
            <div class="lr-header">
                <!-- <h3 class="obf-title">{{ activeComponent.title }}</h3> -->
                <button class="lr-tabs tab-{{ i + 1 }}" focusable="false" [attr.data-e2e]="topTab.open"
                    [ngClass]="{ active: topTab.open === activeComponent.type }"
                    *ngFor="let topTab of activeComponent.topLink; index as i" (click)="changeView({tab:topTab.open})">
                    {{ topTab.text }}
                </button>
                <!-- DELETE WHEN UX provide better ui for reset password -->
                <!-- <button class="lr-tabs icon-si-close-cancel" aria-label="Close"
                    *ngIf="activeComponent.type === 'resetPassword'" (click)="closeModal('exit');"></button> -->

                <div class="obf-indicator"></div>
            </div>

            <div class="lr-content active-{{ activeComponent.type }}">
                <div class="obf-tab-signin" [ngClass]="{ 'tab-signin-left': activeComponent.type === 'register' }">
                    <app-login [socialLogins]="socialLogins" [currentView]="activeComponent.type"
                        [prefillData]="prefillData" (changeView)="changeView($event)" (modalClose)="closeModal($event)">
                    </app-login>
                </div>

                <div class="obf-tab-signup" [ngClass]="{ 'tab-signup-left': activeComponent.type === 'register' }">
                    <!-- The link will be remove  -->
                    <div class="lr-tempore-link">
                        <div>
                            <p localizedText [textKeyword]="'openLoginFormLabel'"></p>
                        </div>
                        <div class="login-link">
                            <span
                                class="cursor"
                                (click)="changeView({tab:'login'})"
                                localizedText
                                [textKeyword]="'openLoginFormButtonLabel'">
                            </span>
                        </div>
                    </div>
                    <!-- The link will be remove  -->

                    <app-register [socialLogins]="socialLogins" [currentView]="activeComponent.type"
                        [prefillData]="prefillData" (changeView)="changeView($event)" (modalClose)="closeModal($event)">
                    </app-register>
                </div>

                <div class="obf-tab-reset-pass">
                    <app-reset-password (changeView)="changeView($event)" [currentView]="activeComponent.type"
                        [prefillData]="prefillData" (modalClose)="closeModal($event)">
                    </app-reset-password>
                </div>

                <div class="obf-tab-claim-account">
                    <app-claim-account (changeView)="changeView($event)" [currentView]="activeComponent.type"
                        [prefillData]="prefillData" (modalClose)="closeModal($event)">
                    </app-claim-account>
                </div>
            </div>
            <div class="claim-account-box"
                *ngIf="!disableClaimAccount && (!disableEmailAuthorization || (!socialLogins || !socialLogins.length)) && claimAccountSettings && claimAccountSettings.isActive && activeComponent.claimAccountLink; else claimAccountAlternative">
                <div class="claim-account-title">
                    <span>
                        {{ claimAccountSettings.title }}
                    </span>
                    <div class="tooltip-holder" *ngIf="claimAccountSettings.description">
                        <span class="tooltip-icon obf-icon icon-si-info-circle"></span>
                        <div class="tooltip-description" [innerHTML]="claimAccountSettings.description"></div>
                    </div>
                </div>
                <div class="claim-account-link">
                    <span class="cursor" (click)="changeView({tab:'claimAccount'})">
                        {{ claimAccountSettings.linkButton }} </span>
                </div>
            </div>
            <ng-template #claimAccountAlternative>
                <div class="obf-lr-footer" *ngIf="activeComponent.footer">
                    {{ activeComponent.footer.description }}
                    <span (click)="changeActiveModule(activeComponent.footer.open)">
                        {{ activeComponent.footer.link }}
                    </span>
                </div>
            </ng-template>
        </div>
    </div>
    <!-- <div class="obf-modal-overlay"></div> -->
</div>
