import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalizationProvider } from '@providers/localization.provider';
import { MainDataProvider } from 'src/app/providers/xrm/main-data.provider';
import { isValidJson } from '../../helpers/helper-functions';
import { ErrorHandlingService } from '../errors/error-handling.service';
import { LoaderService } from '../loader.service';
import { SDKQueryParams } from './../../../../lib/xrm-sdk/src/lib/Api/Interfaces/SDKQueryParams';
import { SDKProfileService } from './../../../../lib/xrm-sdk/src/lib/Services/SDKProfileService';
import { ConfigurationData } from './../../providers/xrm/configuration-data.provider';
import { CacheService } from './../cache.service';
import { ValidationService } from './validation.service';
import { XRMApiCommunicatorService } from './xrmapi-communicator.service';
import { Subscription } from 'rxjs';
import { initReactApplication } from '@serviceos-rn/core';

@Injectable({
    providedIn: 'root',
})

export class ConfigurationService {

    private xrmConnection: SDKProfileService;
    private apiPaths: { [key: string]: string } = {
        configuration: 'client/configuration',
        validations: 'client/validations',
        profile: 'client/profiles',
    };

    private subscriptions: Subscription[] = [];

    private isReactNativeCoreInit = false;

    /**
     * Imports
     * @param {LoaderService} _loaderService
     */
    constructor(
        private _loaderService: LoaderService,
        private _XRMApiCommunicatorService: XRMApiCommunicatorService,
        private _errorHandlingService: ErrorHandlingService,
        private _http: HttpClient,
        private _configurationDataProvider: ConfigurationData,
        private _cacheService: CacheService,
        private _validationService: ValidationService,
        private _mainDataProvider: MainDataProvider,
        private _localizationProvider: LocalizationProvider,
    ) {
        this.getXrmConnection();

        this.subscriptions.push(
            this._localizationProvider.activeLanguage.subscribe(
                (language) => {
                    if (language) {
                        this.getConfiguration(null, false).then(
                            (configuration: any) => {
    
                                if (!this.isReactNativeCoreInit) {
                                    this.initReactNativeCore(configuration);
                                }
                            },
                            () => {
                                
                            }
                        );
                    }
                }
            )
        )
    }

    private initReactNativeCore(configuration) {
        // Init React NativeCore
        const obfOptions = this._mainDataProvider.getResourceObfOptions(),
        envConfig = this._mainDataProvider.getEndpointConfig(),
        currentLanguage = this._localizationProvider.getCurrentLanguage(),
        api_url = this._mainDataProvider.obfEnv.api.baseUrl;

        const conf = Object.assign({}, configuration);

        if (conf?.application?.settings) {
            conf.application.settings.debug = true;
            conf.application.settings.api_version = 'v2.2';
            conf.application.settings.namespace = 'client';
            conf.application.settings.api_url = api_url;
            conf.application.settings.application_token = envConfig.application_token;

            // obfOptions theme is with higher priority
            if (conf.application.theme?.colors) {
                if (obfOptions?.theme && obfOptions.theme['primary-color']) conf.application.theme.colors.primary_color = obfOptions.theme['primary-color'];
                if (obfOptions?.theme && obfOptions.theme['secondary-color']) conf.application.theme.colors.secondary_color = obfOptions.theme['secondary-color'];
            }
                
            // OBF do not support dark theme so far
            // if (conf.application.theme) conf.application.theme.color_scheme = 'light';

        }

        const params = {
            payload: {
                disable_fetch_configuration: true
            },
            state: {
                sid: '',
                profile_keyword: envConfig.profile,
                language: currentLanguage,
                configuration: conf,
                application_build: '',
                react_build: '',
            }
        }

        this.isReactNativeCoreInit = true;

        initReactApplication(params);
    }

    public getXrmConnection() {
        if (!this.xrmConnection) {
            this.xrmConnection = this._XRMApiCommunicatorService.getProfileService();
        }
    }

    /**
     * Return the default configuration for the obf
     * @param callback
     */
    public getConfiguration(queryParams?: SDKQueryParams, useCache?: boolean, callback?: Function): Promise<any> {
        const loader = this._loaderService.showLoader(this._localizationProvider.getLocalizedText('loaderMsg18'));

        this.getXrmConnection();

        return new Promise((resolve: Function, reject: Function) => {
            let configurationSettings: any = this.getConfigurationSettings();

            useCache = useCache === undefined ? true : useCache;

            // Configuration exists in provider
            if (configurationSettings && useCache) {
                if (typeof callback === 'function') {
                    callback(configurationSettings);
                }

                resolve(configurationSettings);

                this._loaderService.hideLoader(loader);
            } else if (useCache && this._cacheService.checkCache('Configuration', this.apiPaths.configuration)) { // Check for cache
                configurationSettings = this._cacheService.getCache('Configuration');

                this._configurationDataProvider.setConfiguration(configurationSettings.data);

                if (typeof callback === 'function') {
                    callback(configurationSettings.data);
                }

                resolve(configurationSettings.data);

                this._loaderService.hideLoader(loader);
            } else { // Make a request
                this.xrmConnection.getConfiguration(queryParams)
                    .then(
                        (response: any) => { // Success
                            if (response.statusCode === 200) {
                                this._loaderService.hideLoader(loader);

                                // Error
                                if (response.decoder && response.decoder.error) {

                                    reject({ message: 'Failed to get configuration: ' + response.decoder.error, fatal: true });

                                    if (typeof callback === 'function') {
                                        callback(response);
                                    }

                                    return;
                                }

                                // Set data in provider
                                this._configurationDataProvider.setConfiguration(response.data);

                                // Set response in cache if useCashe is turned on
                                if (useCache) {
                                    this._cacheService.setCache('Configuration', response.data, 1, this.apiPaths.configuration);
                                }

                                if (typeof callback === 'function') {
                                    callback(response);
                                }

                                resolve(response.data);
                            } else {
                                this._loaderService.hideLoader(loader);

                                if (typeof callback === 'function') {
                                    callback(response);
                                }

                                reject({ message: 'Failed to get configuration: status code ' + response.statusCode, fatal: true });
                            }
                        },
                        (response: any) => { // Error

                            this._loaderService.hideLoader(loader);

                            if (typeof callback === 'function') {
                                callback(response);
                            }

                            reject({ message: 'Fail get configuration', fatal: true });
                        },
                    ).catch((error) => {
                        this._loaderService.hideLoader(loader);

                        if (typeof callback === 'function') {
                            callback(error);
                        }

                        reject({ message: 'Catch: Fail get configuration', fatal: true });
                    });
            }

        });
    }

    /**
     * Return the default validations for the obf
     * @param callback
     */
    public getValidations(queryParams?: SDKQueryParams, useCache?: boolean, callback?: Function): Promise<any> {
        const loader = this._loaderService.showLoader(this._localizationProvider.getLocalizedText('loaderMsg18'));

        this.getXrmConnection();

        return new Promise((resolve: Function, reject: Function) => {
            let validationPatterns: any = this.getValidationPatterns();

            if (validationPatterns) {
                this._validationService.setValidationPatterns(validationPatterns);
                if (typeof callback === 'function') {
                    callback(validationPatterns);
                }

                resolve(validationPatterns);

                this._loaderService.hideLoader(loader);
            } else if (useCache && this._cacheService.checkCache('Validations', this.apiPaths.validations)) { // Check for cache
                validationPatterns = this._cacheService.getCache('Validations');

                this._configurationDataProvider.setValidationPatterns(validationPatterns.data);
                this._validationService.setValidationPatterns(validationPatterns.data);

                if (typeof callback === 'function') {
                    callback(validationPatterns.data);
                }

                resolve(validationPatterns.data);

                this._loaderService.hideLoader(loader);
            } else {
                this.xrmConnection.getValidations(queryParams)
                    .then(
                        (response: any) => { // Success
                            if (response.statusCode === 200) {
                                if (response.decoder) {
                                    // Error
                                    if (response.decoder.error) {
                                        if (typeof callback === 'function') {
                                            callback(response);
                                        }

                                        reject({ message: 'Fail get validations: ' + response.decoder.error, fatal: true });
                                    } else { // Success
                                        // Set response in cache if cache is turned on
                                        if (useCache) {
                                            this._cacheService.setCache('Validations', response.data, 1, this.apiPaths.validations);
                                        }

                                        this._validationService.setValidationPatterns(response.data);
                                        // Set data in provider
                                        this._configurationDataProvider.setValidationPatterns(response.data);

                                        if (typeof callback === 'function') {
                                            callback(response);
                                        }

                                        resolve(response.data);
                                    }
                                }

                                this._loaderService.hideLoader(loader);
                            } else {
                                this._loaderService.hideLoader(loader);

                                if (typeof callback === 'function') {
                                    callback(response);
                                }

                                reject({ message: 'Fail get validations', fatal: true });
                            }
                        },
                        (response: any) => { // Error

                            this._loaderService.hideLoader(loader);

                            if (typeof callback === 'function') {
                                callback(response);
                            }

                            reject({ message: 'Fail get validations', fatal: true });
                        },
                    ).catch((error) => {
                        this._loaderService.hideLoader(loader);

                        if (typeof callback === 'function') {
                            callback(error);
                        }

                        reject({ message: 'Catch: Fail get validations', fatal: true });
                    });
            }
        });
    }

    /**
     * Retrieve configuration
     */
    public getConfigurationSettings(): any {
        return this._configurationDataProvider.getConfiguration();
    }

    public getProfileData(): any {
        return this._configurationDataProvider.getProfile();
    }

    /**
     * Retrieve validations
     */
    public getValidationPatterns(): any {
        return this._configurationDataProvider.getValidationPatterns();
    }

    /**
     * Get product review data
     */
    public getProductReviewData(productReviewEndpoint: string): any {

        return this._http.get(productReviewEndpoint)
            .toPromise();
    }

    /**
     * Check Configuration data status
     * @param {Boolean} checkInCache Flag which decides whether to check for Configuration in cache or not
     * @returns {Object}
     */
    public getConfigurationStatus(checkInCache: Boolean): { status: String, processingMethod: Function } {
        const configurationStatusData: { status: String, processingMethod: Function } = {
            status: 'missing',
            processingMethod: (response, useCache): Promise<any> => {
                return new Promise((resolve: Function, reject: Function) => {
                    // Error
                    if (response.decoder && response.decoder.error) {
                        reject({ message: 'Fail get configuration', response, fatal: true });
                    } else {
                        // Set data in provider
                        this._configurationDataProvider.setConfiguration(response.data);

                        // Set response in cache if useCashe is turned on
                        if (useCache) {
                            this._cacheService.setCache('Configuration', response.data, 1, this.apiPaths.configuration);
                        }

                        resolve();
                    }
                });
            },
        };

        let configurationSettings: any = this.getConfigurationSettings();

        // Configuration exists in provider
        if (configurationSettings) {
            configurationStatusData.status = 'cached';
        } else if (checkInCache && this._cacheService.checkCache('Configuration', this.apiPaths.configuration)) { // Check for cache
            configurationSettings = this._cacheService.getCache('Configuration');

            this._configurationDataProvider.setConfiguration(configurationSettings.data);

            configurationStatusData.status = 'cached';
        }

        // Bind current class scope to the method
        configurationStatusData.processingMethod.bind(this);

        return configurationStatusData;
    }

    /**
     * Check Validation data status
     * @param {Boolean} checkInCache Flag which decides whether to check for Validations in cache or not
     * @returns {Object}
     */
    public getValidationsStatus(checkInCache: Boolean): { status: String, processingMethod: Function } {
        const validationStatusData: { status: String, processingMethod: Function } = {
            status: 'missing',
            processingMethod: (response, useCache): Promise<any> => {
                return new Promise((resolve: Function, reject: Function) => {
                    if (response.decoder && response.decoder.error) {
                        // Error
                        reject({ message: 'Fail get validations: ' + response.error, fatal: true });
                    } else {
                        // Set response in cache if cache is turned on
                        if (useCache) {
                            this._cacheService.setCache('Validations', response.data, 1, this.apiPaths.validations);
                        }

                        this._validationService.setValidationPatterns(response.data);
                        // Set data in provider
                        this._configurationDataProvider.setValidationPatterns(response.data);

                        resolve();
                    }
                });

            },
        };

        let validationPatterns: any = this.getValidationPatterns();

        if (validationPatterns) {
            this._validationService.setValidationPatterns(validationPatterns);
            validationStatusData.status = 'cached';
        } else if (checkInCache && this._cacheService.checkCache('Validations', this.apiPaths.validations)) { // Check for cache
            validationPatterns = this._cacheService.getCache('Validations');

            this._validationService.setValidationPatterns(validationPatterns.data);
            this._configurationDataProvider.setValidationPatterns(validationPatterns.data);

            validationStatusData.status = 'cached';
        }

        // Bind current class scope to the method
        validationStatusData.processingMethod.bind(this);

        return validationStatusData;
    }

    public getProfile(queryParams?: SDKQueryParams, useCache?: boolean, callback?: Function) {

        const loader = this._loaderService.showLoader(this._localizationProvider.getLocalizedText('loaderMsg19'));

        this.getXrmConnection();

        return new Promise((resolve, reject) => {

            let profileData: any = this.getProfileData();

            // Configuration exists in provider
            if (profileData) {
                if (typeof callback === 'function') {
                    callback(profileData);
                }

                resolve(profileData);

                this._loaderService.hideLoader(loader);
            } else if (useCache && this._cacheService.checkCache('Profile', this.apiPaths.configuration)) { // Check for cache
                profileData = this._cacheService.getCache('Profile');

                this._configurationDataProvider.setConfiguration(profileData.data);

                if (typeof callback === 'function') {
                    callback(profileData.data);
                }

                resolve(profileData.data);

                this._loaderService.hideLoader(loader);
            } else { // Make a request
                this.xrmConnection.getProfiles(queryParams)
                    .then((response) => {
                        if (response.statusCode === 200) {
                            if (response.decoder && response.decoder.error) {

                                this._loaderService.hideLoader(loader);

                                if (typeof callback === 'function') {
                                    callback(response);
                                }

                                reject({ message: 'Fail get validations: ' + response.decoder.error, fatal: true });
                            } else if (response.decoder) {
                                this._loaderService.hideLoader(loader);

                                if (useCache) {
                                    this._cacheService.setCache('Profile', response.data, 1, this.apiPaths.profile);
                                }

                                this._configurationDataProvider.setProfile(response.data);

                                if (typeof callback === 'function') {
                                    callback(response);
                                }

                                resolve(response.data);
                            }
                        } else {
                            this._loaderService.hideLoader(loader);

                            if (typeof callback === 'function') {
                                callback(response);
                            }

                            reject({ message: 'Fail get validations', fatal: true });
                        }
                    }).catch((response) => {
                        this._loaderService.hideLoader(loader);

                        if (typeof callback === 'function') {
                            callback(response);
                        }

                        reject({ message: 'Fail get validations', fatal: true });
                    });

            }
        });
    }

    /**
     * Method which maps old profile_layout_config to new structure
     * @param propName property name
     */
     public getPropFromConfig(propName: string): any {
        let result: any = null;

        const configurationSettings: any = this.getConfigurationSettings(),
            getFallbackDataFromProfileLayout: Function = (property: string): any => {
                let propertyData: any = null;

                if (isValidJson(configurationSettings.profile_layout_config)) {
                    const profileLayoutConfig = JSON.parse(configurationSettings.profile_layout_config);

                    if (profileLayoutConfig[property]) propertyData = profileLayoutConfig[property];
                }

                return propertyData;
            };

        if (!configurationSettings) return null;

        switch(propName) {
            case 'payment_details_section':
                if (configurationSettings.book
                    && configurationSettings.book.texts
                    && configurationSettings.book.texts.payment_details_title 
                    && configurationSettings.book.texts.payment_details_description) {
                        result = {
                            title:  configurationSettings.book.texts.payment_details_title,
                            description: configurationSettings.book.texts.payment_details_description
                        };
                } else { // backup with old structure - profile_layout_config
                    result = getFallbackDataFromProfileLayout(propName);
                }

                break;
            case 'on_summary_parking_message':
                if (configurationSettings.book
                    && configurationSettings.book.texts
                    && configurationSettings.book.texts.on_summary_parking_message) {
                        result = configurationSettings.book.texts.on_summary_parking_message;
                } else { // backup with old structure - profile_layout_config
                    result = getFallbackDataFromProfileLayout(propName);
                }

                break;
            case 'benefits_config':
                if (configurationSettings.book
                    && configurationSettings.book.components
                    && configurationSettings.book.components.init_screen
                    && configurationSettings.book.components.init_screen.benefits_section) {
                        result = configurationSettings.book.components.init_screen.benefits_section;
                } else { // backup with old structure - profile_layout_config
                    result = getFallbackDataFromProfileLayout(propName);
                }

                break;
            case 'widget_configuration':
                if (configurationSettings.book
                    && configurationSettings.book.components
                    && configurationSettings.book.components.init_screen
                    && configurationSettings.book.components.init_screen.widget_section) {
                        result = configurationSettings.book.components.init_screen.widget_section;
                } else { // backup with old structure - profile_layout_config
                    result = getFallbackDataFromProfileLayout(propName);
                }

                break;
            case 'disable_react_search':
                if (configurationSettings.book
                    && configurationSettings.book.settings
                    && configurationSettings.book.settings.disable_react_search) {
                        result = configurationSettings.book.settings.disable_react_search;
                } else { // backup with old structure - profile_layout_config
                    result = getFallbackDataFromProfileLayout(propName);
                }

                break;
            case 'banners_configuration':
                if (configurationSettings.banner
                    && configurationSettings.banner.settings
                    && configurationSettings.banner.settings.configuration) { // this is TODO - structure of banner module is not known yet
                        result = configurationSettings.banner.settings.configuration;
                } else { // backup with old structure - profile_layout_config
                    result = getFallbackDataFromProfileLayout(propName);
                }

                break;
            case 'disableAutoMembershipModal':
                if (configurationSettings.book
                && configurationSettings.book.settings
                && configurationSettings.book.settings.disable_automembership_modal) {
                    result = configurationSettings.book.settings.disable_automembership_modal;
                } else { // backup with old structure - profile_layout_config
                    result = getFallbackDataFromProfileLayout(propName);
                }
                
                break;
            case 'promo_deals':
                if (configurationSettings.book
                    && configurationSettings.book.components
                    && configurationSettings.book.components.thank_you_page_screen
                    && configurationSettings.book.components.thank_you_page_screen.promo_deals) {
                        result = configurationSettings.book.components.thank_you_page_screen.promo_deals;
                } else { // backup with old structure - profile_layout_config
                    result = getFallbackDataFromProfileLayout(propName);
                }

                break;
            case 'leave_reasons_modal':
                if (configurationSettings.book
                    && configurationSettings.book.components
                    && configurationSettings.book.components.leave_reasons_modal) {
                        result = configurationSettings.book.components.leave_reasons_modal;
                } else { // backup with old structure - profile_layout_config
                    result = getFallbackDataFromProfileLayout(propName);
                }

                break;
            default:

                break;
        }

        return result;
    }
}
