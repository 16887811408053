import { AfterContentInit, ChangeDetectorRef, Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS} from '@angular/forms';
import Cleave from 'cleave.js';
import { generatorQuerySelector } from '../../../../helpers/generate-unique-query-selector-for-element';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[FsSpecialFields]',
    providers: [{ provide: NG_VALIDATORS, useExisting: FsSpecialFieldsDirective, multi: true }],
})
export class FsSpecialFieldsDirective implements AfterContentInit {
    /**
     * Card types and length validation
     */
    public cardsTypes = [];

    @Input()
    public specialFieldType: 'date-picker' | 'decimal' | 'birth-date';

    constructor(private el: ElementRef, private cdRef: ChangeDetectorRef) {
        // console.log(el);
    }

    /**
     * Listener for change of input data
     */
    public ngAfterContentInit() {
        this.specialTypeRestrict();
    }

    /**
     * Validation method
     * @param control
     */
    public validate(control: AbstractControl): AbstractControl {
        // fix the extra symbol
        if (control && control.value) {
            // Remove extra symbol for date-picker
            // if (this.specialFieldType && this.specialFieldType === 'date-picker') {
            //     if (control.value.length > 5) {
            //         control.setValue(control.value.slice(0, 5));
            //     }
            // }
        }

        return control;
    }

    public registerOnValidatorChange?(validate): void {

        // throw new Error("Method not implemented.");
    }

    /**
     * Set different restrictions for each special type
     */
    private specialTypeRestrict(): void {
        const elementUniqueSelector = generatorQuerySelector.addElementUniqueSelector(this.el);
        switch (this.specialFieldType) {
            case 'date-picker':
                {
                    this.el.nativeElement.classList.add('date-input-picker');
                    const exp = new Cleave(`.${elementUniqueSelector.selector}`, {
                        date: true,
                        datePattern: ['d', 'm'],
                    });
                }
                break;
            case 'decimal':
                {
                    this.el.nativeElement.classList.add('decimal-input');
                    const decimal = new Cleave(`.${elementUniqueSelector.selector}`, {
                        numeral: true,
                        numeralDecimalMark: '.',
                        delimiter: ' ',
                        numeralPositiveOnly: true,
                        // numeralThousandsGroupStyle: 'wan',
                    });
                }
                break;
            case 'birth-date':
                {
                    this.el.nativeElement.classList.add('birth-date-input');

                    const birthDate = new Cleave(`.${elementUniqueSelector.selector}`, {
                        date: true,
                        delimiter: '-',
                        datePattern: ['Y', 'm', 'd'],
                        onValueChanged: (input) => {
                            // this.cdRef.detectChanges();
                        },
                        // delimiter: '-',
                        // blocks: [4, 2, 2]
                    });
                }
                break;
            default:
                break;
        }
    }
}
