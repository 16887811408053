import { SDKQueryParams } from '../Api/Interfaces/SDKQueryParams';
import { BackEndXrmService } from './backendservices/BackEndXrmService';
import { ParamsOptionsBuilder } from '../Helpers/ParamsOptionsBuilder';
import { tap } from 'rxjs/operators';
import ObfInjector from '../Helpers/Injector';
import { ApiResponseStatus } from '../Api/Interfaces/ApiResponseStatus';

export class SDKBookingProcessService {
    private _backEndXrmService: BackEndXrmService;
    private _paramsOptionsBuilder: ParamsOptionsBuilder;

    private apiPoints = {
        bookingTransaction: 'client/booking_transactions',
        availability: 'client/availability',
        submitLeaveReason: '/leave', //  "https://{{BASE_URL}}/v2/client/booking_transactions/123/leave"
        leaveReasons: 'client/leave_reasons',
        challengeToken: '/three_d_security_two_challenge',
        appendTags: '/append_tags',
        paymentMethods: '/payment_methods',
        paymethodSetup: '/paymethod_setup',
        checkout: '/checkout'
    };

    constructor() {
        // tslint:disable-next-line:max-line-length

        this._paramsOptionsBuilder = ObfInjector.getRegistered('ParamsOptionsBuilder') as ParamsOptionsBuilder;
        this._backEndXrmService = ObfInjector.getRegistered('BackEndXrmService') as BackEndXrmService;
    }

    /**
     * Fetch existing transaction
     * @param {String} transactionId booking id
     * @param {Object} queryParams query parameters
     * @returns {Promise}
     */
    
    public fetchTransaction(transactionId: String, queryParams?: SDKQueryParams): Promise<any> {
        let params = this._paramsOptionsBuilder.paramBuilder(queryParams);
        params = params ? '/?' + params : '';

        return new Promise((resolve, reject) => {
            const subscriber = this._backEndXrmService
                .get(this.apiPoints.bookingTransaction + '/' + transactionId + params)
                .pipe(
                    tap(resData => { })
                )
                .subscribe(
                    res => {
                        resolve(res);
                    },
                    err => {
                        reject(err);
                    },
                    () => {
                        subscriber.unsubscribe();
                    }
                );
        });
    }

    /**
     * Update transaction
     * @param {Object}data transaction data
     * @param {String} transactionId transaction ID
     * @param {Object} queryParams query parameters
     * @returns {Promise}
     */
    public updateTransaction(data: any, transactionId: String, queryParams?: SDKQueryParams): Promise<any> {
        let params = this._paramsOptionsBuilder.paramBuilder(queryParams);
        params = params ? '/?' + params : '';

        return new Promise((resolve, reject) => {
            const subscriber = this._backEndXrmService
                .post(
                    this.apiPoints.bookingTransaction + '/'
                    + transactionId + params, data, {
                        validateResponseType: null,
                        additionalValidationData: null,
                        headersOptions: { requestToken: true }
                    })
                .pipe(
                    tap(resData => { })
                )
                .subscribe(
                    res => {
                        resolve(res);
                    },
                    err => {
                        reject(err);
                    },
                    () => {
                        subscriber.unsubscribe();
                    }
                );
        });
    }

    /**
     * Creates new booking transaction
     * @param {Object} data transaction creation data 
     * @param {Object} queryParams query parameters
     * @returns {Promise} 
     */
    public createTransaction(data: any, queryParams?: SDKQueryParams): Promise<any> {
        let params = this._paramsOptionsBuilder.paramBuilder(queryParams);
        params = params ? '/?' + params : '';

        return new Promise((resolve, reject) => {
            const subscriber = this._backEndXrmService
                .post(this.apiPoints.bookingTransaction + params, data, {
                    validateResponseType: null,
                    additionalValidationData: null,
                    headersOptions: { requestToken: true }
                })
                .pipe(
                    tap(resData => { })
                )
                .subscribe(
                    res => {
                        resolve(res);
                    },
                    err => {
                        reject(err);
                    },
                    () => {
                        subscriber.unsubscribe();
                    }
                );
        });
    }

    /**
     * Submits leave reason to booking transaction
     * @param {Object} data leave reason data
     * @param {String} transactionId transaction ID
     * @param {Object} queryParams query parameters
     * @returns {Promise}
     */
    public submitLeaveReason(data: any, transactionId: String, queryParams?: SDKQueryParams): Promise<any> {
        let params = this._paramsOptionsBuilder.paramBuilder(queryParams);
        params = params ? '/?' + params : '';

        return new Promise((resolve, reject) => {
            const subscriber = this._backEndXrmService
                // tslint:disable-next-line:max-line-length
                .post(this.apiPoints.bookingTransaction + '/' + transactionId + this.apiPoints.submitLeaveReason + params, data)
                .subscribe(
                    res => {
                        resolve(res);
                    },
                    err => {
                        reject(err);
                    },
                    () => {
                        subscriber.unsubscribe();
                    }
                );
        });
    }

    /**
     * Fetch leave reasons
     * @param {Object} queryParams
     * @returns {Promise}
     */
    public getLeaveReasons(queryParams?: SDKQueryParams): Promise<any> {
        let params = this._paramsOptionsBuilder.paramBuilder(queryParams);
        params = params ? '/?' + params : '';

        return new Promise((resolve, reject) => {

            const subscriber = this._backEndXrmService
                .get(this.apiPoints.leaveReasons + params, { validateResponseType: 'leaveReasonsResponse' })
                .subscribe(
                    res => {
                        resolve(res);
                    },
                    err => {
                        reject(err);
                    },
                    () => {
                        subscriber.unsubscribe();
                    }
                );
        });
    }

    /**
     * Get transaction availability
     * @param {String} transactionId the transaction id for the availabilities
     * @param {String} fromDate format yyyy-mm-dd
     * @param {String} toDate format yyyy-mm-dd
     * @returns {Promise}
     */
    public getAvailability(queryParams: SDKQueryParams): Promise<any> {
        let params = this._paramsOptionsBuilder.paramBuilder(queryParams);

        params = params ? '/?' + params : '';

        return new Promise((resolve, reject) => {
            const subscriber = this._backEndXrmService
                .get(this.apiPoints.availability + params)
                .pipe(
                    tap(resData => { })
                )
                .subscribe(
                    res => {
                        resolve(res);
                    },
                    err => {
                        reject(err);
                    },
                    () => {
                        subscriber.unsubscribe();
                    }
                );
        });
    }

    /**
     * Requests challenge token for the transaction
     * @param {String} transactionId 
     * @param {Object} queryParams 
     * @returns {Promise}
     */
    public requestChallengeToken(transactionId: String, queryParams: SDKQueryParams): Promise<any> {
        let params = this._paramsOptionsBuilder.paramBuilder(queryParams);

        params = params ? '/?' + params : '';

        return new Promise((resolve: Function, reject: Function) => {
            const subscriber = this._backEndXrmService
                .get(this.apiPoints.bookingTransaction + '/' + transactionId + this.apiPoints.challengeToken + params)
                .pipe(
                    tap(resData => { })
                )
                .subscribe(
                    res => {
                        resolve(res);
                    },
                    err => {
                        reject(err);
                    },
                    () => {
                        subscriber.unsubscribe();
                    });
        });
    }

    /**
     * ApppendTags too bookingtrransaction 
     * @param {Object} data
     * @param {String} transactionId
     * @param {Object} queryParams
     * @returns {Promise}
     */
    public appendTags(data: any, transactionId: String, queryParams?: SDKQueryParams): Promise<any> {
        let params = this._paramsOptionsBuilder.paramBuilder(queryParams);
        params = params ? '/?' + params : '';

        return new Promise((resolve, reject) => {

            const subscriber = this._backEndXrmService
                .post(this.apiPoints.bookingTransaction + '/' + transactionId + this.apiPoints.appendTags + params, data)
                .subscribe(
                    res => {
                        resolve(res);
                    },
                    err => {
                        reject(err);
                    },
                    () => {
                        subscriber.unsubscribe();
                    }
                );
        });
    }

    /**
     * Requests payment options for the transaction
     * @param {String} transactionId 
     * @param {Object} queryParams 
     * @returns {Promise}
     */
    public getPaymentMethods(transactionId: String, queryParams: SDKQueryParams): Promise<any> {
        let params = this._paramsOptionsBuilder.paramBuilder(queryParams);

        params = params ? '/?' + params : '';

        return new Promise((resolve: Function, reject: Function) => {
            const subscriber = this._backEndXrmService
                .get(this.apiPoints.bookingTransaction + '/' + transactionId + this.apiPoints.paymentMethods + params)
                .pipe(
                    tap(resData => { })
                )
                .subscribe(
                    res => {
                        resolve(res);
                    },
                    err => {
                        reject(err);
                    },
                    () => {
                        subscriber.unsubscribe();
                    });
        });
    }

    /**
     * Requests creation of paymentt setup
     * @param {String} transactionId 
     * @param {Object} queryParams 
     * @returns {Promise}
     */
    public getPaymethodSetup(data: any, transactionId: String, queryParams: SDKQueryParams): Promise<any> {
        let params = this._paramsOptionsBuilder.paramBuilder(queryParams);

        params = params ? '/?' + params : '';

        return new Promise((resolve: Function, reject: Function) => {
            const subscriber = this._backEndXrmService
                .post(this.apiPoints.bookingTransaction + '/' + transactionId + this.apiPoints.paymethodSetup + params, data)
                .pipe(
                    tap(resData => { })
                )
                .subscribe(
                    res => {
                        resolve(res);
                    },
                    err => {
                        reject(err);
                    },
                    () => {
                        subscriber.unsubscribe();
                    });
        });
    }

    /**
     * Initiates checkout procedure
     * @param {String} transactionId 
     * @param {Object} queryParams 
     * @returns {Promise}
     */
    public initiateCheckout(data: any, transactionId: String, queryParams: SDKQueryParams): Promise<any> {
        let params = this._paramsOptionsBuilder.paramBuilder(queryParams);

        params = params ? '/?' + params : '';

        return new Promise((resolve: Function, reject: Function) => {
            const subscriber = this._backEndXrmService
                .post(this.apiPoints.bookingTransaction + '/' + transactionId + this.apiPoints.checkout + params, data, {
                    validateResponseType: null,
                    additionalValidationData: null,
                    headersOptions: { requestToken: true }
                })
                .pipe(
                    tap(resData => { })
                )
                .subscribe(
                    res => {
                        resolve(res);
                    },
                    err => {
                        reject(err);
                    },
                    () => {
                        subscriber.unsubscribe();
                    });
        });
    }
}
