import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Injectable()
export class SpecialData {
    private headerConfig: BehaviorSubject<any> = new BehaviorSubject(null);
    public dataUserSearch: { searchValue: string, searchResultsLength: string }[] = [];
    public lastSearch: { searchValue: string, searchResultsLength: number } = { searchValue: '', searchResultsLength: 0 };

    // !!! this is more like session tags but are append to booking when we add shoping cart need to confirm the work flow of tags
    public tags: {
        seen_join_the_club: boolean;
    } = {
        seen_join_the_club: false,
    };

    constructor() { }
    /**
     * Set the search data from user
     * @param searchData
     */
    public setDataUserSearch(searchData) {
        this.dataUserSearch = searchData;
    }

    /**
     * Return uniq search
     */
    public getDataUserSearch() {
        return this.dataUserSearch.filter((obj, pos, arr) => {
            return arr.findIndex((mapObj) => {
                return mapObj.searchValue.indexOf(obj.searchValue) === 0;
            }) === pos;
        });
    }

    /**
     * Retrieve header config subscription
     */
    public getHeaderConfigSubscription(): Observable<any> {
        return this.headerConfig;
    }


     /**
     * Retrieve header config subscription
     */
      public getHeaderConfig() {
        return this.headerConfig.getValue();
    }
    /**
     *
     * @param progress Set header config
     */
    public setHeaderConfig(progress: any): void {
        this.headerConfig.next(progress);
    }
}
