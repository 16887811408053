import AES from 'crypto-js/aes';
import * as cryptoUtf8 from 'crypto-js/enc-utf8';
export class Crypto {
    public decryptCookie(data: any, key: string) {
        const decryptedData = AES.decrypt(data.toString(), key);
        let plaintext: any = '';

        try {
            plaintext = JSON.parse(decryptedData.toString(cryptoUtf8));
        } catch (err) {
            plaintext = '';
        }

        try {
            plaintext = decryptedData.toString(cryptoUtf8);
        } catch (err) {
            plaintext = '';
        }

        return plaintext;
    }

    public encryptCookie(data: any, key: string) {
        let encryptedData: any = '';

        if (typeof data === 'object') {
            encryptedData = AES.encrypt(JSON.stringify(data), key);
        } else {
            encryptedData = AES.encrypt(data.toString(), key);
        }

        return encryptedData;
    }
}
