import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';

@Injectable({
    providedIn: 'root',
})
export class BreadcrumbsService {

    constructor() { }

    public errorBreadcrumb(category: string, message: string) {
        try {
            Sentry.addBreadcrumb({
                category,
                message,
                level: Sentry.Severity.Error,
            });
        } catch (e) {
            console.log(e);
        }
    }

    public warnBreadcrumb(category: string, message: string) {
        try {
            Sentry.addBreadcrumb({
                category,
                message,
                level: Sentry.Severity.Warning,
            });
        } catch (e) {
            console.log(e);
        }
    }

    public infoBreadcrumb(category: string, message: string) {
        try {
            Sentry.addBreadcrumb({
                category,
                message,
                level: Sentry.Severity.Info,
            });
        } catch (e) {
            console.log(e);
        }
    }
}
