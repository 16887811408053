<div class="obf-pro-wrapper">
    <div class="obf-pro-header">
        <div class="obf-pro-avatar" [ngStyle]="{background: 'center / cover no-repeat #dbe2eb url(' + proInformation.image_url + ')'}">
        </div>
        <div class="obf-pro-info">

            <div *ngIf="proInformation.name" class="obf-pro-title">
                <span>{{ proInformation?.name }}</span>
            </div>
            <div *ngIf="proInformation && proInformation.stats && proInformation.stats.rating && proInformation.details && proInformation.details.gender" class="obf-pro-additional-info">
                <span class="unit-type" *ngIf="proInformation?.details?.gender">{{ proInformation.details.gender }}</span>
                <span class="rating" *ngIf="proInformation?.stats?.rating"><i class="obf-icon icon-si-rating"></i> {{proInformation.stats.rating}}</span>

                <span class="unit-social-holder">
                    <span class="add-to-favorites" *ngIf="proInformation.hasOwnProperty('favorite') && showAddFavorite" (click)="addAsFavorite()">
                            <i class="obf-icon icon-si-add-to-favourites{{ favoriteClassName }}"></i>
                        </span>
                </span>
            </div>

            <div class="obf-line-devider-box">
                <span class="line"></span>
            </div>

            <div *ngIf="proInformation" class="obf-pro-experience-holder">
                <ul>
                    <li *ngIf="proInformation?.stats?.total_bookings">
                        <span
                            localizedText
                            [textKeyword]="'unitTotalBookingsLabel'"
                            [insertionType]="'prepend'">
                            <i class="obf-icon icon-si-total-bookings"></i>
                            :
                        </span>
                        {{ proInformation?.stats?.total_bookings }}
                    </li>
                    <li *ngIf="proInformation?.stats?.experience">
                        <span
                            localizedText
                            [textKeyword]="'unitExperienceLabel'"
                            [insertionType]="'prepend'">
                            <i class="obf-icon icon-si-clock-time-start-at"></i>
                            :
                        </span>
                        {{ proInformation?.stats?.experience }}
                    </li>
                    <li *ngIf="proInformation?.details?.languages">
                        <span
                            localizedText
                            [textKeyword]="'unitLanguagesLabel'"
                            [insertionType]="'prepend'">
                            <i class="obf-icon icon-si-global-planet-icon"></i>
                            :
                        </span>
                        {{ proInformation?.details?.languages ? (proInformation?.details?.languages.toString().replace('[','').replace(']','')) : '' }}
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div *ngIf="descriptionTabsCount && !(descriptionTabsCount === 1 && proInformation && proInformation.stats && proInformation.stats.rating && proInformation.stats.total_ratings)" class="obf-pro-description-tabs">
        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="obf-nav-tabs" [ngClass]="{'no-cursor': descriptionTabsCount === 1}">
            <li [ngbNavItem]="1" *ngIf="proInformation?.badges" class="nav-item">
                <a ngbNavLink><span localizedText [textKeyword]="'unitBadgesLabel'"></span></a>
                <ng-template ngbNavContent>
                    <ul class="obf-badges-list" *ngIf="proInformation?.badges?.length">
                        <li *ngFor="let badge of proInformation?.badges">
                            <div class="badge-box" [ngStyle]="{'background-color':  badge.background_color }">
                                <img src="{{ badge.icon_url }}" alt="{{ badge.name }}" title="{{ badge.description }}">
                                <span>{{ badge.name }}</span>
                                <span *ngIf="badge.count_formatted && badge.count_formatted !== '0'" [ngStyle]="{'background-color':  badge.count_color, 'color': badge.text_color }" class="badge-counter">{{ badge.count_formatted }}</span>
                            </div>
                        </li>
                    </ul>
                </ng-template>
            </li>
            <li [ngbNavItem]="2" *ngIf="proInformation && proInformation.stats && proInformation.stats.rating && proInformation.stats.total_ratings" class="nav-item">
                <a ngbNavLink><span localizedText [textKeyword]="'unitRatingLabel'"></span></a>
                <ng-template ngbNavContent>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="rating-head-holder">
                                <span class="rating-head"><i class="obf-icon icon-si-rating"></i>{{ proInformation?.stats?.rating }}</span>
                                <span class="rating-subhead"><span localizedText [textKeyword]="'wordFrom'"></span> {{ proInformation?.stats?.total_ratings }} <span localizedText [textKeyword]="'wordReviews'"></span></span>
                            </div>
                            
                        </div>
                        
                    </div>
                </ng-template>
            </li>
            <li [ngbNavItem]="3" *ngIf="proInformation?.details?.documents" class="nav-item">
                <a ngbNavLink><span localizedText [textKeyword]="'unitDocumentsLabel'"></span></a>
                <ng-template ngbNavContent>
                    <ul class="obf-documents-list" *ngIf="proInformation?.details?.documents">
                        <li *ngFor="let document of proInformation?.details?.documents">
                            <span class="doc-title">{{document.name}}</span>
                            <span class="doc-type">{{document.type}}</span>
                            <span class="doc-view">
                                <a
                                    href="{{document.url}}"
                                    target="_blank"
                                    localizedText
                                    [textKeyword]="'unitDocumentViewButtonLabel'">
                                </a>
                            </span>
                        </li>
                    </ul>
                </ng-template>
            </li>
            <li [ngbNavItem]="4" *ngIf="proInformation?.details?.short_bio" class="nav-item">
                <a ngbNavLink><span localizedText [textKeyword]="'unitBioLabel'"></span></a>
                <ng-template ngbNavContent>
                    <div class="pro-bio-holder">
                        {{ proInformation?.details?.short_bio }}
                    </div>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-2 tab-content"></div>
    </div>
</div>    
    