import { PostMessageService } from './../post-message.service';
import { AffiliatesScriptsLoaderService } from './../affiliates-scripts-loader.service';
import { TrackingScriptsLoaderService } from './../tracking-scripts-loader.service';
import { ErrorHandlingService } from './../errors/error-handling.service';
import { MainDataProvider } from './../../providers/xrm/main-data.provider';
import { XRMApiCommunicatorService } from './xrmapi-communicator.service';
import { Subject } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { config } from '../../../environments/env.config.manager';
import { NGXLogger } from 'ngx-logger';
import { SDKModuleSettings } from '../../../../lib/xrm-sdk/src/lib/Api/Interfaces/SDKModuleSettings';
import { ConfigurationService } from './configuration.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})


export class InitConfigDataService {
    private configEnvManager: config;

  

    constructor(
        private _mainDataProvider: MainDataProvider,
        private xrmApiCommunicatorService: XRMApiCommunicatorService,
        private _errorHandlingService: ErrorHandlingService,
        private logger: NGXLogger,
        private injector: Injector,
        private postMessageService: PostMessageService
    ) {
    }

    public getDefaultObfOptions() {
        return new Promise(async (resolve, reject) => {
            let obfOptions;

            if (window === parent) {
                // change getObfOptions inside config to static
                obfOptions = config.getObfOptions(); // * get obf options from window when is not iframe

            } else {
                obfOptions = await this.postMessageService.request('options', null).catch((e) => {
                    // noting
                });
            }

            // * if we cant get ObfOptions Return to iframe to close obf and redirect
            if (!obfOptions) {
                this.sendFailCreateEnv(['Fetch obfOptions fail']);
                reject('Fail to get default obfOptions');
                return;
            }

            resolve(obfOptions);
        });
    }

    public getParentCookies() {
        return new Promise(async (resolve, reject) => {
            let parentCookies;

            // * check obf window when is not iframe
            if (window !== parent) {
                parentCookies = await this.postMessageService.request('cookies', null).catch((e) => {
                    // console.log(e);
                });
            }

            // * if we cant get cookies Return to iframe to close obf and redirect
            if (!parentCookies) {
                this.sendFailCreateEnv(['Fetch parent cookies fail']);
                reject('Can\'t read the parent cookies!');
                return;
            }

            resolve(parentCookies);
        });
    }

    public getParentDomain() {
        return new Promise(async (resolve, reject) => {
            let parentDomain;

            // * check obf window when is not iframe
            if (window !== parent) {
                parentDomain = await this.postMessageService.request('parent-domain', null).catch((e) => {
                    // console.log(e);
                });
            }

            // * if we cant get cookies Return to iframe to close obf and redirect
            if (!parentDomain) {
                this.sendFailCreateEnv(['Fetch parent domain fail']);
                reject('Can\'t read the parent domain!');
                return;
            }

            resolve(parentDomain);
        });
    }

    public setupEnvironment(obfOptions) {
        this.configEnvManager = new config(obfOptions);
        const environment = this.configEnvManager.getEnvironment();
        this._mainDataProvider.setEnvironmentData(environment, obfOptions);
    }

    public initXrmApiCommunicator(obfOptions) {
        return new Promise<void>(async (resolve, reject) => {
            // * init the Api communicator
            try {
                await this.xrmApiCommunicatorService.initTheSKD((requestType: string, response: any) => { this._errorHandlingService.handle(requestType, response); });
                // if (obfOptions.csid) {
                // console.error('CSID', obfOptions.csid)
                // await this.xrmApiCommunicatorService.setAuthSID(obfOptions.csid);
                // }
            } catch (errorMessage) {
                this.sendFailCreateEnv(['Fail init api communicator']);

                reject(errorMessage);

                return;
            }

            this.sendSuccessCreateEnv(this.xrmApiCommunicatorService.getSdkConfiguration());

            resolve();
        });
    }

    public initTrackingScripts() {
        // Add Tracking Scripts on Init
        const configurationService: ConfigurationService = this.injector.get(ConfigurationService),
                configurationSettings: any = configurationService.getConfigurationSettings();

        const TRACKING_SCRIPTS_TYPE = {
                    gtmTracking: 'GTM',
                    gtagTracking: 'GTAG'
                };

        let trackingType = null;
        let trackingGtmCode = null;
        let trackingGtagCode = null;
        let trackingIds = [];    


         // GTAG check and set
         if(configurationSettings?.hasOwnProperty('global_tag_manager_id') && configurationSettings?.global_tag_manager_id.length !== 0) {
            trackingGtagCode = configurationSettings.global_tag_manager_id;
            trackingType = TRACKING_SCRIPTS_TYPE.gtagTracking;
            trackingIds.push({type: trackingType, id: trackingGtagCode});
        }

        // GTM check and set
        if(configurationSettings?.hasOwnProperty('google_tag_manager_id') && configurationSettings?.google_tag_manager_id.length !== 0) {
            trackingGtmCode = configurationSettings.google_tag_manager_id;
            trackingType = TRACKING_SCRIPTS_TYPE.gtmTracking;

            trackingIds.push({type: trackingType, id: trackingGtmCode});
        }

        console.log('[DEBUG] trackingIds',trackingIds);
 
        const trackingScriptsLoader = this.injector.get(TrackingScriptsLoaderService);
        // You can load multiple scripts by just providing the key as argument into load method of the service
        if (trackingScriptsLoader) {
            trackingIds.forEach(tracking => {
                trackingScriptsLoader.load(tracking.type, 'init', tracking.id).then(data => {
                    // Script Loaded Successfully
                }).catch(error => this.logger.log(error));
            });
        }



    }

    public initAffiliatesScripts() {
        // Add Affiliates Scripts on Init
        let sourceAbbr = this._mainDataProvider.getResourceObfOptions().source_abbr;
        const affiliatesScriptsLoader = this.injector.get(AffiliatesScriptsLoaderService);
        // You can load multiple scripts by just providing the key as argument into load method of the service
        if (affiliatesScriptsLoader) {
            affiliatesScriptsLoader.load('init', sourceAbbr).then(data => {
                // Script Loaded Successfully
            }).catch(error => this.logger.log(error));
        }
    }

    public sendFailCreateEnv(errors?: any[]) {
        this.postMessageService.sendState('error', {
            errorType: 'createEnv',
            errors,
        });
    }

    public sendSuccessCreateEnv(data: SDKModuleSettings) {
        data = JSON.parse(JSON.stringify(data));
        if (data.cookieKey) {
            data.cookieKey = null;
        }

        this.postMessageService.sendState('sdk-config', data);
    }
}
