import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class WindowRef {
  public getNativeWindow(): any { return window; }
}

@Injectable({
  providedIn: 'root',
})
export class DocumentRef {
  public getNativeDocument(): any { return document; }
}
