import { CommonModule } from '@angular/common';
// ! MODULES
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModalModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ButtonsModule } from '../obf-ui/components/others-ui-elements/buttons/buttons.module';
import { FsSpecialFieldsModule } from '../obf-ui/directives/fs-special-fields/fs-special-fields.module';
import { FsTextFieldModule } from '../obf-ui/directives/fs-text-field/fs-text-field.module';
import { SlideInOutModule } from '../obf-ui/directives/slide-in-out/slide-in-out.module';
import { SortPipeModule } from '../obf-ui/pipes/sort-pipe/sort-pipe.module';
import { EmptyStringValidatorModule } from './../obf-ui/validators/empty-string-validator/empty-string-validator.module';

// ! COMPONENTS
import { CustomModalComponent } from './components/modals/custom-modal/custom-modal.component';
import { ExitPopupComponent } from './components/modals/exit-popup/exit-popup.component';
import { LoginAndRegisterComponent } from './components/modals/login-and-register/login-and-register.component';
import { LoginComponent } from './components/modals/login-and-register/login/login.component';
import { RegisterComponent } from './components/modals/login-and-register/register/register.component';
import { ResetPasswordComponent } from './components/modals/login-and-register/reset-password/reset-password.component';
import { StandardPopupComponent } from './components/modals/standard-popup/standard-popup.component';
import { ProComponent } from './components/modals/unit-modal/pro/pro.component';
import { TeamComponent } from './components/modals/unit-modal/team/team.component';
import { UnitModalComponent } from './components/modals/unit-modal/unit-modal.component';
import { HeaderComponent } from './components/shared/header/header.component';

// ! DIRECTIVES
import { DynamicColorChangeModule } from '../obf-ui/directives/dynamic-color-change/dynamic-color-change.module';
import { FsFocusHandlerModule } from '../obf-ui/directives/fs-focus-handler/fs-focus-handler.module';
import { InfoModalModule } from '../obf-ui/directives/info-modal/info-modal.module';
import { LocalizationModule } from '../obf-ui/directives/localization/localization.module';
import { HighlightPipeModule } from '../obf-ui/pipes/highlight-pipe/highlight-pipe.module';
import { ClaimAccountComponent } from './components/modals/login-and-register/claim-account/claim-account.component';
import { PaymentSelectModalComponent } from './components/modals/payment-select-modal/payment-select-modal.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { DynamicIframeModalComponent } from './components/modals/dynamic-iframe-modal/dynamic-iframe-modal.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { TipProComponent } from './components/shared/tip-pro/tip-pro.component';
import { TipProModalComponent } from './components/modals/tip-pro-modal/tip-pro-modal.component';
import { PaymentComponent } from './components/shared/payment/payment.component';
import { TermsConditionsModule } from '../obf-ui/components/others-ui-elements/terms-conditions/terms-conditions.module';
import { SearchServicesComponent } from './components/shared/search-services/search-services.component';

@NgModule({
    declarations: [
        LoginAndRegisterComponent,
        LoginComponent,
        RegisterComponent,
        ResetPasswordComponent,
        ExitPopupComponent,
        StandardPopupComponent,
        CustomModalComponent,
        HeaderComponent,
        ClickOutsideDirective,
        UnitModalComponent,
        TeamComponent,
        ProComponent,
        PaymentSelectModalComponent,
        ClaimAccountComponent,
        DynamicIframeModalComponent,
        TipProComponent,
        TipProModalComponent,
        PaymentComponent,
        SearchServicesComponent
    ],
    imports: [
        NgbModalModule,
        NgbNavModule,
        CommonModule,
        FsTextFieldModule,
        FsSpecialFieldsModule,
        FsFocusHandlerModule,
        LocalizationModule,
        HighlightPipeModule,
        SlideInOutModule,
        ButtonsModule,
        FormsModule,
        ReactiveFormsModule,
        DynamicColorChangeModule,
        SortPipeModule,
        InfoModalModule,
        EmptyStringValidatorModule,
        NgxIntlTelInputModule,
        TermsConditionsModule
    ],
    exports: [
        NgbNavModule,
        NgbModalModule,
        FormsModule,
        ButtonsModule,
        LoginAndRegisterComponent,
        LoginComponent,
        RegisterComponent,
        ResetPasswordComponent,
        ExitPopupComponent,
        StandardPopupComponent,
        CustomModalComponent,
        HeaderComponent,
        DynamicColorChangeModule,
        UnitModalComponent,
        PaymentSelectModalComponent,
        DynamicIframeModalComponent,
        ClickOutsideDirective,
        EmptyStringValidatorModule,
        TipProComponent,
        PaymentComponent,
        SearchServicesComponent
    ],
})
export class SharedModule { }
