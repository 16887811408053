import { NavigationService } from 'src/app/services/navigation.service';
import { ConfigurationData } from './../providers/xrm/configuration-data.provider';
import { BookingDetailsService } from '@services/booking-details.service';
import { Injectable } from '@angular/core';
import { ChoicesType, ChoicesPositions } from '@constants/choice';
import { OrderData } from '@providers/xrm/order-data.provider';
import { ScriptService } from 'ngx-script-loader';
import { BehaviorSubject, combineLatest, forkJoin, merge, Subscription } from 'rxjs';
import { debounceTime, retry } from 'rxjs/operators';
import { MainDataProvider } from './../providers/xrm/main-data.provider';
import { UserData } from './../providers/xrm/user-data.provider';
import { LoaderService } from './loader.service';
import { PostMessageService } from './post-message.service';
import moment from 'moment-mini';
import { LocalizationProvider } from '@providers/localization.provider';

declare global {
    interface Window {
        fcWidget: any;
    }
}

@Injectable({
    providedIn: 'root',
})

export class ChatService {
    public chatStatus: BehaviorSubject<'loading' | 'loaded' | 'error' | 'init'>;

    public loaderOpen = false;
    public loader;

    public isChatOpen = false;

    public chatElement: HTMLElement;

    public feedChatDataSubscription: Subscription = null;

    public eventChatDataSubscription: Subscription = null;

    public chatInitObject = {
        token: '5fb3aa44-f7a9-4758-8806-f1acfa3e6009',
        host: 'https://wchat.freshchat.com',
        hideChatButton: true
    };

    constructor(
        private _postMessageService: PostMessageService,
        private loaderService: LoaderService,
        private _mainDataProvider: MainDataProvider,
        private scriptService: ScriptService,
        private orderData: OrderData,
        private userData: UserData,
        private bookingDetailsService: BookingDetailsService,
        private configurationData: ConfigurationData,
        private navigationService: NavigationService,
        private _localizationProvider: LocalizationProvider,
    ) {
        this.chatStatus = new BehaviorSubject(undefined);

        if (this._mainDataProvider.obfEnv.obfType === 'page') {
            this.appendDependencies();

        } else if (this._mainDataProvider.obfEnv.obfType === 'iframe') {
            this.chatStatus.next('init');

            this.chatFromSite();
            this.feedDataChat();
            this.eventsDataToChatOpen();
        }
    }

    public openChat() {
        if (this.chatStatus.getValue() === 'loaded') {
            if (this.loaderOpen) {
                this.loaderOpen = false;
            }

            if (this._mainDataProvider.obfEnv.obfType === 'page') {
                this.openObfChat();
            } else if (this._mainDataProvider.obfEnv.obfType === 'iframe') {
                this._postMessageService.openChat();
            }
        } else if (this.chatStatus.getValue() !== 'error') {
            this.loader = this.loaderService.showLoader(this._localizationProvider.getLocalizedText('loaderMsg7'));
            this.loaderOpen = true;
        } else if (this.chatStatus.getValue() === 'error') {
            // TODO open modal and say we got problem with chat for now !!!
        }
    }

    public closeChat() {
        if (this.chatStatus.getValue() === 'loaded') {
            if (this.loaderOpen) {
                this.loaderOpen = false;
            }

            if (this._mainDataProvider.obfEnv.obfType === 'page') {
                this.close();
            } else if (this._mainDataProvider.obfEnv.obfType === 'iframe') {
                this._postMessageService.closeChat();
            }
        } else if (this.chatStatus.getValue() !== 'error') {
            this.loader = this.loaderService.showLoader(this._localizationProvider.getLocalizedText('loaderMsg7'));
            this.loaderOpen = true;
        } else if (this.chatStatus.getValue() === 'error') {
            // TODO open modal and say we got problem with chat for now !!!
        }
    }

    public close() {
        window.fcWidget.close();
    }

    public chatFromSite() {
        this._postMessageService.chatStatus.subscribe((e) => {
            if (!e) {
                return;
            }

            if (e.type === 'chat-loaded' && e.data === 'success' || e.type === 'chat-status' && e.data === 'open' || e.type === 'chat-status' && e.data === 'close') {
                this.chatStatus.next('loaded');

                // if (e.data == 'success') {
                //     this.feedDataChat();
                //     this.eventsDataToChatOpen();
                // }

                if (e.data == 'open') {
                    this.isChatOpen = true;
                } else if (e.data == 'close') {
                    this.isChatOpen = false;
                }

                if (this.loaderOpen) {
                    this.openChat();
                }
            } else if (e.type === 'chat-object' && e.data === 'success') {
                this.chatStatus.next('loading');
            } else if (e.type === 'chat-object' && e.data === 'error') {
                this.chatStatus.next('error');
                // TODO send error to email
                if (this.loaderOpen) {
                    // TODO say to user we got problem
                    this.loaderService.hideLoader(this.loader);
                }
            }
        });
    }

    public feedDataChat(): void {
        this.feedChatDataSubscription = combineLatest(
            [
                this.orderData.getResourceObserverActiveBooking(),
                this.userData.getSubscription(),
                this._postMessageService.formStateBS
            ]
        ).pipe(debounceTime(200)).subscribe(([bookingTransactions, userData, formState]) => {
            const bookingData = bookingTransactions ? bookingTransactions.get() : null;

            const endpoint = this._mainDataProvider.obfEnv.api.baseUrl;
            const configuration = this.configurationData.getConfiguration()
            const company_phone = this._mainDataProvider.getResourceObfOptions()?.phone && this._mainDataProvider.getResourceObfOptions()?.phone.replace(/\s/g, '') || '';
            let appointment: any | any[] | null = null;
            let total = null;

            if (bookingData) {
                appointment = bookingTransactions.getTransactionChoices(null, ChoicesType.Availability);
                if (appointment && appointment.length) {
                    appointment = appointment[0].choice_items.map(el => el.value_formatted);
                }
            }

            if (bookingData?.price?.price_breakdown && bookingData.price.price_breakdown.length) {
                total = bookingData.price.price_breakdown.find(el => el.type === 'total')
            }

            if (userData) {
                let data = {
                    firstName: userData?.first_name,
                    lastName: userData?.first_name,
                    email: userData?.username,
                    phone: this.userData.getUserDefaultPhone()?.value || '-',
                    phoneCountry: configuration?.country_code ? '+' + configuration?.country_code : '-',
                    userType: userData.membership ? 'member' : 'not a member',
                    membershipExpireDate: userData?.membership?.valid_to ? moment.unix(userData.membership.valid_to).format('DD-MM-YYYY') : '-',
                    status: `${endpoint}order?answer=&client_phone=${this.userData.getUserDefaultPhone()?.value}&company_phone=${company_phone}`,
                    bookingTransactionId: bookingData?.id || '-',
                    postcode: bookingData?.address_formatted || '-',
                    refNumber: bookingData?.reference_number || '-',
                    serviceTitle: bookingData?.service?.title || '-',
                    selectedSlot: appointment && total ? appointment.join(' -- ') + ` - ${total?.value}` : '-',
                }

                if (!this.orderData.formOpen) {
                    data = {
                        ...data,
                        ...{
                            status: '-',
                            bookingTransactionId: '-',
                            postcode: '-',
                            refNumber: '-',
                            serviceTitle: '-',
                            selectedSlot: '-'
                        }
                    }
                }

                this._postMessageService.sendState('chat', data);
            }
        });
    }


    public lastData: {
        serviceTitle: string,
        addressFormatted: string,
        totalPrice: string,
        checkoutTotalPrice: string,
        card: any
    } = {
            serviceTitle: '',
            addressFormatted: '',
            totalPrice: '',
            checkoutTotalPrice: '',
            card: null
        };
    public eventsDataToChatOpen() {
        this.eventChatDataSubscription = combineLatest(
            [
                this.orderData.getResourceObserverActiveBooking(),
                this.bookingDetailsService.transactionDetailsBehavior
            ]
        ).pipe(debounceTime(200)).subscribe(([bookingTransaction, bookingDetails]) => {
            const bookingT = bookingTransaction ? bookingTransaction.get() : null;

            // console.log('BookingDetailsData', bookingDetails);
            // console.log('BookingTransaction', bookingT);

            if (bookingT) {
                if (bookingDetails?.service?.title && this.lastData.serviceTitle !== bookingDetails?.service?.title) {
                    this.lastData.serviceTitle = bookingDetails?.service?.title;
                    this._postMessageService.sendState('chat-event', {
                        event: 'service_details',
                        data: {
                            serviceName: bookingDetails.service.title
                        }
                    })
                }

                if (bookingDetails?.postcode?.value && this.lastData?.addressFormatted !== bookingDetails?.postcode?.value) {
                    this.lastData.addressFormatted = bookingDetails?.postcode?.value;
                    this._postMessageService.sendState('chat-event', {
                        event: 'service_details',
                        data: {
                            addressFormatted: bookingDetails.postcode.value
                        }
                    })
                }

                if (bookingT?.price?.price_breakdown && bookingT.price.price_breakdown.length) {
                    const total = bookingT.price.price_breakdown.find(el => el.type === 'total');
                    const totalValue = total?.value ? total.value : '-';
                    
                    if (this.lastData.totalPrice !== totalValue) {
                        this.lastData.totalPrice = totalValue;
                        this._postMessageService.sendState('chat-event', {
                            event: 'service_details',
                            data: {
                                totalPrice: totalValue
                            }
                        });
                    }

                    if (this.lastData.checkoutTotalPrice !== totalValue && bookingT?.reference_number && this.navigationService.getCurrentStep()?.position === ChoicesPositions.Confirmation) {
                        this.lastData.checkoutTotalPrice = totalValue;

                        this._postMessageService.sendState('chat-event', {
                            event: 'checkout',
                            data: {
                                totalPrice: totalValue
                            }
                        });
                    }
                }

                if (bookingDetails?.serviceDetails?.value || bookingDetails?.appointment?.value) {
                    let cardData = {};
                    let serviceDetailsCardData: any = {};
                    let appointment = {};

                    if (bookingDetails?.serviceDetails?.value) {
                        serviceDetailsCardData = bookingDetails?.serviceDetails?.value.map((choice, index) => {
                            let choiceObj = {}

                            choice.choice_items.map(el => {
                                const item = {};
                                item[el.title] = el.value;

                                choiceObj = {
                                    ...choiceObj,
                                    ...item
                                }
                            })


                            return choiceObj;
                        })

                        if (Array.isArray(serviceDetailsCardData)) {
                            serviceDetailsCardData = serviceDetailsCardData.reduce((obj, item) => {
                                obj = {
                                    ...obj,
                                    ...item
                                }
                                return obj
                            }, {});
                        }
                    }

                    if (bookingDetails?.appointment?.value && bookingDetails?.appointment?.value?.length) {
                        appointment = bookingDetails?.appointment?.value.reduce((data, choice) => {
                            const itemData = choice.choice_items.reduce((iData, choice_item) => {
                                iData[choice_item.title] = choice_item.value_formatted || null;
                                return iData
                            }, {})

                            return { ...data, ...itemData };
                        }, {})
                    }

                    cardData = {
                        ...serviceDetailsCardData,
                        ...appointment
                    }

                    if (this.lastData.card == null || JSON.stringify(this.lastData.card) !== JSON.stringify(cardData)) {
                        this.lastData.card = cardData;
                        this._postMessageService.sendState('chat-event', {
                            event: 'update_card',
                            data: cardData
                        })
                    }
                }
            }
        })
    }

    public eventsDataToChatClose() {
        if (this.eventChatDataSubscription) {
            this.eventChatDataSubscription.unsubscribe();
            this.eventChatDataSubscription = null;
        }
    }

    public stopFeedDataChat(): void {
        if (this.feedChatDataSubscription) {
            this.feedChatDataSubscription.unsubscribe();
            this.feedChatDataSubscription = null;
        }
    }

    private initChat() {
        window.fcWidget.on('widget:loaded', (resp: any) => {
            this.chatStatus.next('loaded');
            this.chatElement = document.querySelector('#fc_frame');

            if (this.loaderOpen) {
                this.loaderService.hideLoader(this.loader);
                this.openChat();
            }
        });
        window.fcWidget.init(this.chatInitObject);
    }

    private appendDependencies() {
        this.chatStatus.next('init');

        const subscription = this.scriptService.loadScript('https://wchat.freshchat.com/js/widget.js').pipe(retry(1)).subscribe((res) => {
            this.chatStatus.next('loading');
            this.initChat();
            subscription.unsubscribe();
        }, (err) => {
            subscription.unsubscribe();

            this.chatStatus.next('error');
            // TODO send error to email
            if (this.loaderOpen) {
                // TODO say to user we got problem
                this.loaderService.hideLoader(this.loader);
            }
        });
    }

    private openObfChat() {
        window.fcWidget.open();
    }
}
