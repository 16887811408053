import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { LocalizationProvider } from '@providers/localization.provider';
import { MainDataProvider } from '@providers/main-data.provider';
import { TeamsUnitsService } from 'src/app/services/xrm/teams-units.service';

@Component({
    selector: 'app-team',
    templateUrl: './team.component.html',
    styleUrls: ['./team.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class TeamComponent implements OnInit {

    @Input() public teamInformation = null;
    @Output() public favoriteActionPerformed: EventEmitter<Boolean> = new EventEmitter(false);

    public currentUnit = null;
    public favoriteClassName = '';
    public unitExperienceLabel = this._localizationProvider.getLocalizedText('unitExperienceLabel');
    public active = 1;

    // Initial reviews count
    public reviewsCount: any = {
        veryPoor: 0,
        poor: 0,
        okay: 0,
        good: 0,
        superb: 0,
    };

    public descriptionTabsCount = 0;

    constructor(
        private teamUnitsService: TeamsUnitsService,
        private cdRef: ChangeDetectorRef,
        private _mainDataProvider: MainDataProvider,
        private _localizationProvider: LocalizationProvider,
    ) { }

    public ngOnInit() {
        this.extractReviewsCount();

        this.favoriteClassName = this.teamInformation.favorite ? '-full' : '';

        this.descriptionTabsCount = [
            this.teamInformation && this.teamInformation.stats && this.teamInformation.stats.rating && this.teamInformation.stats.total_ratings, // Ratings tab condition
            this.teamInformation && this.teamInformation.details && this.teamInformation.details.documents, // Documents tab condition
            this.teamInformation && this.teamInformation.units && this.teamInformation.units.length, // Units tab condition
            this.teamInformation && this.teamInformation.badges // Badges tab condition
        ]
        .filter((tab: Boolean) => tab)
        .length;
    }

    public viewUnitInfo(unit) {
        this.currentUnit = unit;
    }

    /**
     * Get each reviews count
     */
    private extractReviewsCount(): void {
        if (this.teamInformation && this.teamInformation.stats && this.teamInformation.stats.ratings && this.teamInformation.stats.ratings.length) {
            const mappedStats: Array<{ rating: number, statName: string}> = [
                {
                    rating: 1,
                    statName: 'veryPoor',
                },
                {
                    rating: 2,
                    statName: 'poor',
                },
                {
                    rating: 3,
                    statName: 'okay',
                },
                {
                    rating: 4,
                    statName: 'good',
                },
                {
                    rating: 5,
                    statName: 'superb',
                },
            ];

            for (let statIndex = 0; statIndex < mappedStats.length; statIndex++) {
                const stat: any = mappedStats[statIndex];

                this.reviewsCount[stat.statName] = this.teamInformation.stats.ratings.filter((ratingStats: any) => {
                    return ratingStats.rating === stat.rating;
                }).length;
            }
        }
    }

    /**
     * Add team as favorite
     */
    public addAsFavorite() {
        const proType = 'team';

        // Unit is already added as favorite
        if (this.teamInformation.favorite) {
            this.teamUnitsService.removeFromFavorites(proType, this.teamInformation.id)
                .then(
                    (response: any) => {
                        this.fetchInformation()
                            .then(
                                (info: any) => { // Success
                                    this.teamInformation = info;

                                    this.favoriteClassName = this.teamInformation.favorite ? '-full' : '';

                                    this.cdRef.detectChanges();

                                    this.favoriteActionPerformed.emit(this.teamInformation.favorite);
                                },
                                (error: any) => { // Error
                                    //
                                },
                            );

                    },
                    (response: any) => {
                        //
                    },
                )
                .catch(
                    (error: any) => {
                        //
                    },
                );
        } else {
            this.teamUnitsService.addAsFavorite(proType, this.teamInformation.id)
                .then(
                    (response: any) => {
                        this.fetchInformation()
                            .then(
                                (info: any) => { // Success
                                    this.teamInformation = info;

                                    this.favoriteClassName = this.teamInformation.favorite ? '-full' : '';

                                    this.cdRef.detectChanges();

                                    this.favoriteActionPerformed.emit(this.teamInformation.favorite);
                                },
                                (error: any) => { // Error
                                  //
                                },
                            );
                    },
                    (response: any) => {
                        //
                    },
                )
                .catch(
                    (error: any) => {
                        //
                    },
                );
        }
    }

    /**
     * Change class name of favorite icon
     * @param event
     */
    public changeFavoriteClassName(event: any): void {
        if (event.type === 'mouseleave') {
            this.favoriteClassName = this.teamInformation.favorite ? '-full' : '';
        } else if (event.type === 'mouseover') {
            this.favoriteClassName = this.teamInformation.favorite ? '' : '-full';
        }
    }

    /**
     * Fetch pro information
     */
    private fetchInformation(): Promise<any> {
        return new Promise((resolve: Function, reject: Function) => {
            this.teamUnitsService.getTeam(this.teamInformation.id)
                .then(
                    (response: any) => { // Success
                        if (response && !response.error) { return resolve(response.data[0]); }

                        reject(response);
                    },
                    (error: any) => { // Error
                        reject(error);
                    },
                );
        });
    }
}
