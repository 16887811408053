import * as Cookies from 'es-cookie';
import { Configuration } from './../../Constants/Configuration';
import { HttpHeader } from '../../Api/Interfaces/HttpHeader';
import ObfInjector from '../../Helpers/Injector';
import { Authorize } from '../../Api/ApiAuthorize';
import { Crypto } from '../../Helpers/crypto-js';

declare global {
    interface Window {
        hj: any;
    }
}

export class HeaderBuilder {
    constructor() { }

    private getApiAuthorize() {
        const auth: Authorize = ObfInjector.getRegistered('Authorize') as Authorize;

        return auth;
    }

    /**
     * Gets hotjar id
     */
    private getHotjarId(): number {

        const hj = window.hj = window.hj || {};
        let hotjarSessionId = null;

        try {
            hotjarSessionId = hj.pageVisit.property
                .get('userId')
                .split('-')
                .shift();
        } catch (e) {
            try {
                hotjarSessionId = hj.globals
                    .get('userId')
                    .split('-')
                    .shift();
            } catch (e) {
                hotjarSessionId = null;
            }
        }

        return hotjarSessionId;
    }

    public makeHeaders(headersOptions?: HeadersOptions) {

        const headers: HttpHeader[] = [];

        if (headersOptions && headersOptions.contentType) {
            headers.push({ name: 'Content-Type', data: headersOptions.contentType });
        } else {
            headers.push({ name: 'Content-Type', data: 'application/json;charset=UTF-8' });
        }

        const auth = this.getApiAuthorize();

        headers.push({ name: 'X-Application', data: auth.application });
        headers.push({ name: 'X-Profile', data: auth.profile });

        if (headersOptions && headersOptions.requestToken) {
            // tslint:disable-next-line:max-line-length
            headers.push({ name: 'X-Request-Token', data: this.getRandom8SymbolString() + this.getRandom8SymbolString() });
        }

        const _crypto: Crypto = ObfInjector.getRegistered('Crypto') as Crypto;
        const _configuration: Configuration = ObfInjector.getRegistered('Configuration');

        if (_configuration.currentEnv?.gateway) {
            headers.push({ name: 'X-Gateway', data: _configuration.currentEnv.gateway });
        }

        // Unique user id for Interaction cookie
        const interactionCookie = Cookies.get('__sosint_uid');
        let localStorageItem: any;

        try {
            localStorageItem = localStorage.getItem('__sosint_uid');
        } catch (e) {
            //..
        }
        
        // Set Interaction tracking in the header if the cookie exist
        if(interactionCookie || localStorageItem) {
            headers.push({ name: 'X-Vsid', data: interactionCookie ? interactionCookie : localStorageItem });
        }

        if (_configuration.cookieKey) {
            const userCookie = Cookies.get('user-login') || Cookies.get('_user-login');
            let authorizationItem: any;

            try {
                authorizationItem = sessionStorage.getItem('authorization');
            } catch (e) {
                //..
            }

            let userCookieValue = '';
            
            if (authorizationItem) {
                auth.authorization = authorizationItem;
                
            } else if (userCookie) {
                userCookieValue = _crypto.decryptCookie(userCookie, _configuration.cookieKey);
                auth.authorization = userCookieValue;
            }

            if (auth.authorization) {
                headers.push({ name: 'Authorization', data: auth.authorization });

            } else if (userCookie && userCookieValue) {

                headers.push(
                    { name: 'Authorization', data: userCookieValue }
                );

            } else if (userCookie) {
                Cookies.remove('user-login');
            }
        }

        headers.push({ name: 'X-Requested-With', data: 'XMLHttpRequest' });

        const hotJarId = this.getHotjarId();

        if (hotJarId) {
            headers.push({ name: 'X-Hotjar-Id', data: hotJarId });
        }

        // Check for current language in local storage
        let appCurrentLanguage: any;

        try {
            appCurrentLanguage = localStorage.getItem('app_default_language');
        } catch (e) {
            //..
        }

        if (appCurrentLanguage) {
            headers.push({ name: 'X-Language', data: appCurrentLanguage });
        }

        return headers;

        // const auth = this.getApiAuthorize();

        // headers.push({ name: 'X-Application', data: auth.application });
        // headers.push({ name: 'X-Profile', data: auth.profile });

        // const _crypto: Crypto = ObfInjector.getRegistered('Crypto') as Crypto;
        // const _configuration: Configuration = ObfInjector.getRegistered('Configuration');

        // const userCookie = Cookies.get('user-login');
        // console.error('_configuration.cookieKey', _configuration.cookieKey);
        // console.error('userCookie', userCookie);

        // if (auth.authorization) {
        //     headers.push({ name: 'Authorization', data: auth.authorization});
        // } else if (userCookie) {
        //     try {

        // tslint:disable-next-line:max-line-length
        //         headers.push({ name: 'Authorization', data: _crypto.decryptCookie(userCookie, _configuration.cookieKey) });
        //     } catch (e) {
        //         console.error('e: ', e);
        //         Cookies.remove('user-login');
        //     }
        // }

        // headers.push({ name: 'X-Requested-With', data: 'XMLHttpRequest' });

        // const hotJarId = this.getHotjarId();

        // if (hotJarId) {
        //     headers.push({ name: 'X-Hotjar-Id', data: hotJarId });
        // }

        // return headers;

    }

    private getRandom8SymbolString() {
        return Math.floor((1 + Math.random()) * 0x10000000)
            .toString(16);
    }
}

export interface HeadersOptions {
    withCredentials?: boolean;
    contentType?: string;
    timeout?: number;
    requestToken?: boolean;
}
